import React, { useEffect, useState } from 'react';
import { Checkbox, Spin, Button, message, Input } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import {
  MdsaFilterTableByTaxaDataQueryVariables,
  MdsaFilterTableByTaxaDataQuery,
  UpdateAndStartMdsaTaskMutationVariables,
  UpdateAndStartMdsaTaskMutation,
  MdsaInParam,
  Enum_Mdsa_Task_Key_Enum,
} from '../graphql/graphql-types';
import QiimeMetadataTable from '../components/QiimeMetadataTable';
import AppLayout from '../components/AppLayout';
import { logger } from '../utils/helpers';
import EcFormItem from '../components/EcFormItem';
import { mdsaTasksInitialValues } from '../utils/globals';

// call to graphql file
const mdsaFilterTableByTaxaDataQuery = loader(
  '../graphql/queries/mdsaFilterTableByTaxaDataQuery.graphql',
);

const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);

// error message to be displayed when both input fields are empty of filter feature and checkobox is selected
const filterFeatureErrorMessage = 'Please enter --p-include or --p-exclude value and try again';

// MdsaFilterTableByTaxa form type
type MdsaFilterTableByTaxaFormType = {
  filterFeatureOption: boolean;
  taxaFilterIncludeInputValue?: string;
  taxaFilterExcludeInputValue?: string;
  filterSampleOption: boolean;
  sampleIds?: Array<string>;
};

// react functional component
const MdsaFilterTableByTaxa: React.FC = () => {
  // extracting id and  taskId from url
  const { id, taskId } = useParams();

  // useForm declaration
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, control, errors, watch, setValue, clearErrors, setError } = useForm<
    MdsaFilterTableByTaxaFormType
  >();

  const navigate = useNavigate();

  // query to extract url
  const { data, loading, error } = useQuery<
    MdsaFilterTableByTaxaDataQuery,
    MdsaFilterTableByTaxaDataQueryVariables
  >(mdsaFilterTableByTaxaDataQuery, {
    variables: {
      mdsa_id: parseInt(id, 10),
      taskId: parseInt(taskId, 10),
    },
  });

  // Mutation to update and start mdsa task
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  // state to set loading on start analysis button while running mutation
  const [startAnalysisButtonLoading, setStartAnalysisButtonLoading] = useState<boolean>(false);
  // state to set start in update and start mdsa task mutation
  const [started_at] = useState(new Date());

  const inParamsData = data?.task?.in_params as Array<MdsaInParam>;

  useEffect(() => {
    if (Array.isArray(inParamsData) && inParamsData.length > 0) {
      inParamsData.forEach((item) => {
        if (item.key === '--p-include') {
          if (item.value) {
            setValue('taxaFilterIncludeInputValue', item.value as string);
            setValue('filterFeatureOption', true);
          }
        }
        if (item.key === '--p-exclude') {
          if (item.value) {
            setValue('taxaFilterExcludeInputValue', item.value as string);
            setValue('filterFeatureOption', true);
          }
        }
        if (item.key === '--p-exclude-ids') {
          if (item.rawValue) {
            setValue('filterSampleOption', true);
            setValue('sampleIds', item.rawValue as string[]);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inParamsData]);

  // if error in fetching query
  if (error) {
    return (
      <AppLayout screenTitle="Microbiome downstream analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
      </AppLayout>
    );
  }
  // while loading query data
  if (loading) {
    return (
      <AppLayout screenTitle="Microbiome downstream analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  // watch and stores value of filterFeatureOption
  const filterFeatureOptionVal = watch('filterFeatureOption');
  // watch and stores value of taxaFilterIncludeInputValue
  const taxaFilterIncludeInputVal = watch('taxaFilterIncludeInputValue');
  // watch  and stores value of taxaFilterExcludeInputValue
  const taxaFilterExcludeInputVal = watch('taxaFilterExcludeInputValue');

  return (
    <AppLayout screenTitle="Microbiome downstream analysis">
      <>
        <h2>Filter table & seqs by taxa</h2>
        <form
          style={{ width: '100%' }}
          onSubmit={handleSubmit(
            ({
              filterFeatureOption,
              taxaFilterIncludeInputValue,
              taxaFilterExcludeInputValue,
              sampleIds,
              filterSampleOption,
            }) => {
              if (
                !data ||
                !data.task ||
                !Array.isArray(data.task.in_params) ||
                data.task.in_params.length === 0
              ) {
                // If the task does not have in_params defined
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.error('Task does not have in_params');
                return;
              }

              // if filter feature option is selected and both input fields are empty error is displayed
              if (
                filterFeatureOption &&
                !taxaFilterExcludeInputValue &&
                !taxaFilterIncludeInputValue
              ) {
                setError('filterFeatureOption', { message: filterFeatureErrorMessage });
                return;
              }

              setStartAnalysisButtonLoading(true);

              // obtains the initial value of task filterTableByTaxa
              const defaultInParams = mdsaTasksInitialValues.find(
                (task) => task.key === Enum_Mdsa_Task_Key_Enum.FilterTableByTaxa,
              )?.in_params as Array<MdsaInParam>;

              // const to store immutable copy of in_params
              const taskInParamImmutableConst = JSON.parse(
                JSON.stringify(defaultInParams),
              ) as Array<MdsaInParam>;

              // Set the in_params value according to the inputs provided by the user
              /* eslint-disable no-param-reassign */
              taskInParamImmutableConst.forEach((item) => {
                if (
                  item.key === '--p-include' &&
                  filterFeatureOption &&
                  taxaFilterIncludeInputValue
                ) {
                  item.enabled = true;
                  item.rawValue = taxaFilterIncludeInputValue;
                  item.value = taxaFilterIncludeInputValue;
                }

                if (
                  item.key === '--p-exclude' &&
                  filterFeatureOption &&
                  taxaFilterExcludeInputValue
                ) {
                  item.enabled = true;
                  item.rawValue = taxaFilterExcludeInputValue;
                  item.value = taxaFilterExcludeInputValue;
                }

                if (
                  item.key === '--p-exclude-ids' &&
                  filterSampleOption &&
                  Array.isArray(sampleIds) &&
                  sampleIds.length > 0
                ) {
                  item.enabled = true;
                  item.rawValue = sampleIds;
                }
              });

              // call to mutation
              updateAndStartMdsaTask({
                variables: {
                  taskId: parseInt(taskId, 10),
                  taskUpdateInput: {
                    started_at,
                    in_params: taskInParamImmutableConst,
                  },
                },
              })
                .then(() => {
                  setStartAnalysisButtonLoading(false);
                  navigate(`/mdsa/${parseInt(id, 10)}`);
                })
                .catch((err) => {
                  setStartAnalysisButtonLoading(false);
                  logger(err);
                });
            },
          )}
        >
          <div style={{ marginTop: 30, marginBottom: 20 }}>
            <EcFormItem
              name="filterFeatureOption"
              control={control}
              defaultValue={false}
              render={({ onChange, value }): JSX.Element => {
                return (
                  <Checkbox
                    checked={value as boolean}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      if (e.target.checked) {
                        setValue('taxaFilterIncludeInputValue', undefined);
                        setValue('taxaFilterExcludeInputValue', undefined);
                        clearErrors([
                          'taxaFilterIncludeInputValue',
                          'taxaFilterExcludeInputValue',
                          'filterFeatureOption',
                        ]);
                      }
                    }}
                    style={{ fontSize: 18 }}
                  >
                    Filter by specific Taxa (qiime taxa filter-table & qiime taxa filter-seqs)
                  </Checkbox>
                );
              }}
            />

            <div style={{ marginLeft: 25, display: filterFeatureOptionVal ? 'block' : 'none' }}>
              <p style={{ margin: 0, marginTop: 5 }}>--p-include</p>
              <EcFormItem
                name="taxaFilterIncludeInputValue"
                control={control}
                defaultValue={null}
                render={({ onChange, value }) => {
                  return (
                    <Input
                      allowClear
                      style={{ width: 250 }}
                      onChange={(e) => {
                        // if filter feature option is selected and both input fields are empty error is displayed
                        if (
                          !e.target.value &&
                          !taxaFilterExcludeInputVal &&
                          filterFeatureOptionVal
                        ) {
                          setError('filterFeatureOption', {
                            message: filterFeatureErrorMessage,
                          });
                        } else {
                          clearErrors('filterFeatureOption');
                        }
                        onChange(e.target.value);
                      }}
                      value={value as number}
                    />
                  );
                }}
                helpText="One or more search terms that indicate which taxa should be included in the resulting table. If providing more than one term, terms should be delimited by the comma character. By default, all taxa will be included."
              />
            </div>
            <div style={{ marginLeft: 25, display: filterFeatureOptionVal ? 'block' : 'none' }}>
              <p style={{ margin: 0, marginTop: 5 }}>--p-exclude</p>
              <EcFormItem
                name="taxaFilterExcludeInputValue"
                control={control}
                defaultValue={null}
                render={({ onChange, value }) => {
                  return (
                    <Input
                      allowClear
                      style={{ width: 250 }}
                      onChange={(e) => {
                        // if filter feature option is selected and both input fields are empty error is displayed
                        if (
                          !e.target.value &&
                          !taxaFilterIncludeInputVal &&
                          filterFeatureOptionVal
                        ) {
                          setError('filterFeatureOption', {
                            message: filterFeatureErrorMessage,
                          });
                        } else {
                          clearErrors('filterFeatureOption');
                        }
                        onChange(e.target.value);
                      }}
                      value={value as number}
                    />
                  );
                }}
                helpText="One or more search terms that indicate which taxa should be excluded from the resulting table. If providing more than one term, terms should be delimited by the comma character. By default, no taxa will be excluded."
              />
              <p style={{ color: 'red', marginTop: 10 }}>
                {errors && errors.filterFeatureOption ? errors.filterFeatureOption.message : null}
              </p>
            </div>
          </div>
          <div style={{ marginTop: 30, marginBottom: 20 }}>
            <EcFormItem
              name="filterSampleOption"
              control={control}
              defaultValue={false}
              render={({ onChange, value }): JSX.Element => {
                return (
                  <Checkbox
                    checked={value as boolean}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      if (!e.target.checked) {
                        setValue('sampleIds', []);
                        clearErrors(['sampleIds']);
                      }
                    }}
                    style={{ fontSize: 18 }}
                  >
                    Filter by samples - Select samples to <strong>exclude</strong> (qiime
                    feature-table filter-samples)
                  </Checkbox>
                );
              }}
            />

            <div
              style={{
                marginTop: 20,
                marginLeft: 25,
                display: watch('filterSampleOption') ? 'block' : 'none',
              }}
            >
              <p style={{ margin: 0, marginTop: 5 }}>--p-exclude-ids</p>
              <EcFormItem
                name="sampleIds"
                rules={{
                  validate: watch('filterSampleOption')
                    ? (value: Array<string>) =>
                        value.length > 0 || 'Please select sample(s) and try again.'
                    : undefined,
                }}
                errors={errors}
                defaultValue={[]}
                control={control}
                containerStyle={{ width: '100%' }}
                render={({ onChange, value }): JSX.Element => {
                  if (data && data.metadataFile && data.metadataFile.url) {
                    return (
                      // call to qiimeMetadata table component to display table which contain data obtain by parsing remote file url
                      <QiimeMetadataTable
                        metadataFileUrl={data.metadataFile.url}
                        selectedRowKeys={value as Array<string>}
                        setSelectedRowKeys={(selectedSamplesId) => {
                          onChange(selectedSamplesId);
                        }}
                      />
                    );
                  }
                  return <div />;
                }}
              />
            </div>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: 40 }}
            disabled={
              !watch('filterSampleOption') &&
              !taxaFilterIncludeInputVal &&
              !taxaFilterExcludeInputVal
            }
            loading={startAnalysisButtonLoading}
          >
            Start Analysis
          </Button>
        </form>
      </>
    </AppLayout>
  );
};

export default MdsaFilterTableByTaxa;
