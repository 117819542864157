import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useQuery, useApolloClient } from '@apollo/client';
import { Spin } from 'antd';
import AppLayout from '../components/AppLayout';
import ProjectForm from '../forms/ProjectForm';
import {
  ViewProjectQuery,
  ViewProjectQueryVariables,
  Project,
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
  MetadataColumn,
  Maybe,
} from '../graphql/graphql-types';
import { logger } from '../utils/helpers';

// call to graphql files using loader
const viewProjectQuery = loader('../graphql/queries/viewProjectQuery.graphql');
const getFilesUrlQuery = loader('../graphql/queries/getFilesUrlQuery.graphql');

// initialData type
type InitialDataType = Pick<
  Project,
  | 'business_unit'
  | 'description'
  | 'end_date'
  | 'id'
  | 'protocol_s3_key'
  | 'species'
  | 'start_date'
  | 'tags'
  | 'title'
> & { qiime_metadata_columns?: Maybe<Array<MetadataColumn>> };

// React functional component
const EditProjectScreen: React.FC = () => {
  // State to store the download url of file uploaded in project
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  // extracted id from url param
  const { id } = useParams();
  // apollo Client
  const apolloClient = useApolloClient();
  // Query to fetch project data for filling initial values in edit mode
  const { data, loading, error } = useQuery<ViewProjectQuery, ViewProjectQueryVariables>(
    viewProjectQuery,
    {
      variables: { id: parseInt(id, 10) },
      onCompleted: (projectQueryData) => {
        /* Query for fetching download url of protocol file */
        const s3Key = projectQueryData.project_by_pk?.protocol_s3_key;
        if (s3Key) {
          apolloClient
            .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
              query: getFilesUrlQuery,
              variables: { keys: [s3Key] },
            })
            .then((fileUrlResponse) => {
              const downloadUrlResponse = fileUrlResponse.data.getFilesUrl;
              if (
                downloadUrlResponse &&
                Array.isArray(downloadUrlResponse) &&
                downloadUrlResponse.length > 0
              ) {
                /* File download url */
                const url = downloadUrlResponse[0]?.url;
                if (url) {
                  setDownloadUrl(url);
                }
              }
            })
            .catch((fileUrlError) => {
              logger(fileUrlError);
            });
        }
      },
    },
  );
  if (error) {
    return (
      <AppLayout screenTitle="Edit Project">
        <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
      </AppLayout>
    );
  }
  const projectData = data?.project_by_pk;
  if (projectData && !loading) {
    const {
      description,
      start_date,
      end_date,
      species,
      business_unit,
      tags,
      title,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      qiime_metadata_columns,
      protocol_s3_key,
    } = projectData;
    const initialData: InitialDataType = {
      id: parseInt(id, 10),
      description,
      start_date,
      end_date,
      species,
      business_unit,
      tags,
      title,
      qiime_metadata_columns: qiime_metadata_columns as Array<MetadataColumn>,
      protocol_s3_key,
    };
    return (
      <AppLayout screenTitle="Edit Project">
        <div>
          <h4 style={{ marginBottom: 20 }}>Project ID {id}</h4>
          <ProjectForm mode="edit" initialData={initialData} fileUrl={downloadUrl} />
        </div>
      </AppLayout>
    );
  }
  // if loading then loading indicator will display
  return (
    <AppLayout screenTitle="Edit Project">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        <Spin size="large" />
      </div>
    </AppLayout>
  );
};

export default EditProjectScreen;
