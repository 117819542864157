import React, { useState, useReducer } from 'react';
import { Button, Select, Spin, Modal, Table, Space, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useQuery, useMutation, ApolloError, useApolloClient } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { OptionProps } from 'antd/es/select';
import {
  ProjectSrOptionsQueryVariables,
  ProjectSrOptionsQuery,
  AddSrsToMdsaMutationVariables,
  AddSrsToMdsaMutation,
  Sequencing_Run,
  SrMetadataFileUrlQueryVariables,
  SrMetadataFileUrlQuery,
  StartMdsaQiimeAnalysisMutation,
  StartMdsaQiimeAnalysisMutationVariables,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import EcFormItem from '../components/EcFormItem';
import QiimeMetadataTable from '../components/QiimeMetadataTable';
import { logger } from '../utils/helpers';
import { filterSelectOptions } from '../utils/globals';
import MdsaMergeTableImport from '../components/MdsaMergeTableImport';
import { SelectedSeqRunType, Action } from '../utils/types';

// Form Type
type FormType = {
  // to store selected project id
  project_id: number | null;
  //  to store selected sr id
  sr_id: number | null;
};

// call to graphql files
const projectSrOptionsQuery = loader('../graphql/queries/projectSrOptionsQuery.graphql');
const addSrsToMdsaMutation = loader('../graphql/mutations/addSrsToMdsaMutation.graphql');
const srMetadataFileUrlQuery = loader('../graphql/queries/srMetadataFileUrlQuery.graphql');
const startMdsaQiimeAnalysisMutation = loader(
  '../graphql/mutations/startMdsaQiimeAnalysisMutation.graphql',
);

// Reducer for selectedSeqRun. To handle add sr, delete sr and update sr
const reducer = (state: SelectedSeqRunType, action: Action): SelectedSeqRunType => {
  switch (action.type) {
    // Add new SR to the analysis
    case 'add':
      return [
        ...state,
        {
          sr_id: action.sr_id,
          url: action.url,
          selectedMetadataRows: [],
          s3_key: action.s3_key,
        },
      ] as SelectedSeqRunType;
    // Remove SR from the selected list
    case 'remove':
      return state.filter((e) => e.sr_id !== action.sr_id);
    // Updated the metadata sample rows selected by the user for this SR
    case 'updateSelectedMetadataRows': {
      return state.map((e) => {
        if (e.sr_id === action.sr_id) {
          return {
            ...e,
            selectedMetadataRows: action.selectedMetadataRows,
          };
        }
        return e;
      }) as SelectedSeqRunType;
    }
    // Add SR from existing MDSA
    case 'addExisting': {
      return [
        ...state,
        {
          sr_id: action.sr_id,
          url: action.url,
          selectedMetadataRows: action.selectedMetadataRows,
          s3_key: action.s3_key,
        },
      ] as SelectedSeqRunType;
    }

    default:
      throw new Error();
  }
};

// react functional component
const MdsaMergeTables: React.FC = () => {
  // State to store the info of all the SRs selected for this analysis
  const [selectedSeqRun, dispatchSelectedSeqRun] = useReducer(reducer, []);

  // useState to set modal visibility
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  // useState to set sr_id of selected/current Sequencing run
  const [srId, setSrId] = useState<number | null>(null);

  // State to store started_at for srLinkFastqMutation
  const [started_at] = useState(new Date());

  // useState to set loading indicator of merge tables button
  const [mergeFeatureButtonLoading, setMergeFeatureButtonLoading] = useState<boolean>(false);

  // useState to set loading indicator of add analysis button
  const [addToAnalysisButtonLoading, setAddToAnalysisButtonLoading] = useState<boolean>(false);

  // indicates whether to show MdsaMergeTable or not
  const [showMdsaMergeTableImport, setShowMdsaMergeTableImport] = useState<boolean>(true);

  // call to query to fetch projects and sr select input's options
  const { data, loading, error } = useQuery<ProjectSrOptionsQuery, ProjectSrOptionsQueryVariables>(
    projectSrOptionsQuery,
  );

  // mutation to add the selected sequencing runs to this MDSA for merging tables
  const [addSrsToMdsa] = useMutation<AddSrsToMdsaMutation, AddSrsToMdsaMutationVariables>(
    addSrsToMdsaMutation,
  );

  /* Mutation to start qiime analysis of mdsa */
  const [startMdsaQiimeAnalysis] = useMutation<
    StartMdsaQiimeAnalysisMutation,
    StartMdsaQiimeAnalysisMutationVariables
  >(startMdsaQiimeAnalysisMutation);

  // const to get mdsa id, mdsa task id from url
  const { id, taskId } = useParams();

  // call to navigation
  const navigate = useNavigate();

  // apollo Client
  const apolloClient = useApolloClient();

  // useForm declaration
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, control, errors, watch, reset } = useForm<FormType>({
    defaultValues: {
      project_id: null,
      sr_id: null,
    },
  });
  // if error in fetching query
  if (error) {
    return (
      <AppLayout screenTitle="Merge table from SR(s)">
        <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
      </AppLayout>
    );
  }
  // while loading query data
  if (loading) {
    return (
      <AppLayout screenTitle="Merge table from SR(s)">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  const selectedProjectId = watch('project_id');

  // array to display Seq run option or select sr based on value of selected project
  const seqRunSelectOptions: Array<Pick<Sequencing_Run, 'id' | 'title'>> = [];

  if (data && Array.isArray(data.project) && selectedProjectId) {
    const alreadySelectedSrIds = selectedSeqRun.map((entry) => entry.sr_id);
    for (let i = 0; i < data.project.length; i++) {
      const singleProject = data.project[i];
      if (singleProject.id === selectedProjectId) {
        singleProject.sequencing_runs.forEach((sr) => {
          if (!alreadySelectedSrIds.includes(sr.id)) {
            seqRunSelectOptions.push(sr);
          }
        });
        break;
      }
    }
  }

  // selected sequencing run data which will use to pass data to qiime metadata column
  const currentSrData = selectedSeqRun ? selectedSeqRun.find((e) => e.sr_id === srId) : null;

  const { Column } = Table;

  // function used to calculate total selected samples
  const getTotalSampleCounts = () => {
    // stores the value of count
    let count = 0;
    selectedSeqRun.forEach((seq) => {
      count += seq.selectedMetadataRows.length;
    });
    return count;
  };

  return (
    <AppLayout screenTitle="Merge table from SR(s)">
      <>
        {/* modal start */}
        <Modal
          visible={modalVisible}
          title={srId ? `SR-${srId}` : null}
          onCancel={() => {
            if (srId) {
              // Reset form
              reset();
              setModalVisible(false);
            }
          }}
          okButtonProps={{
            disabled: currentSrData?.selectedMetadataRows.length === 0,
          }}
          onOk={() => {
            setModalVisible(false);
            reset();
          }}
          width="85%"
        >
          <>
            <p>Select the samples to use in this analysis</p>
            {/* table component which will display in modal and store qiime metadata fetched from papaparse */}
            <QiimeMetadataTable
              key={currentSrData?.sr_id}
              metadataFileUrl={currentSrData ? currentSrData.url : ''}
              selectedRowKeys={
                currentSrData
                  ? currentSrData.selectedMetadataRows.map((entry) => entry['sample-id'])
                  : []
              }
              setSelectedRows={(newSelectedRows: Array<Record<string, string>>) => {
                if (srId) {
                  dispatchSelectedSeqRun({
                    type: 'updateSelectedMetadataRows',
                    sr_id: srId,
                    selectedMetadataRows: newSelectedRows,
                  });
                }
              }}
            />
          </>
        </Modal>
        {/* modal end */}

        {showMdsaMergeTableImport ? (
          <MdsaMergeTableImport
            setShowMdsaMergeTableImport={setShowMdsaMergeTableImport}
            dispatchSelectedSeqRun={dispatchSelectedSeqRun}
            currentMdsaId={parseInt(id, 10)}
          />
        ) : (
          <>
            <form
              onSubmit={handleSubmit((formData) => {
                if (formData.sr_id !== null) {
                  setAddToAnalysisButtonLoading(true);
                  // call to query to fetch url based on selected sr
                  apolloClient
                    .query<SrMetadataFileUrlQuery, SrMetadataFileUrlQueryVariables>({
                      query: srMetadataFileUrlQuery,
                      variables: {
                        sr_id: formData.sr_id,
                      },
                    })
                    .then((res) => {
                      setAddToAnalysisButtonLoading(false);
                      // const to get url and s3Key from query data
                      const srOutFile = res?.data?.srOutFileUrl;
                      if (srOutFile && formData.sr_id) {
                        dispatchSelectedSeqRun({
                          type: 'add',
                          sr_id: formData.sr_id,
                          url: srOutFile.url,
                          s3_key: srOutFile.s3_key,
                        });
                        setSrId(formData.sr_id);
                        setModalVisible(true);
                      }
                    })
                    .catch((err: ApolloError) => {
                      setAddToAnalysisButtonLoading(false);
                      logger(err);
                    });
                }
              })}
            >
              <Space style={{ marginBottom: 20 }} size="middle">
                <EcFormItem
                  label="Project"
                  name="project_id"
                  control={control}
                  defaultValue={null}
                  rules={{ required: 'Please select project and try again' }}
                  errors={errors}
                  as={
                    <Select
                      showSearch
                      placeholder="Select Project"
                      style={{ width: 200 }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        filterSelectOptions(input, option as OptionProps)
                      }
                    >
                      {data && Array.isArray(data.project)
                        ? data.project.map(
                            (item): JSX.Element => (
                              <Select.Option value={item.id} key={item.id}>
                                {item.title}
                              </Select.Option>
                            ),
                          )
                        : null}
                    </Select>
                  }
                />
                <EcFormItem
                  label="Sequencing Run"
                  name="sr_id"
                  control={control}
                  defaultValue={null}
                  rules={{ required: 'Please select SR and try again' }}
                  errors={errors}
                  as={
                    <Select
                      showSearch
                      placeholder="Select SR"
                      style={{ width: 200 }}
                      disabled={!selectedProjectId}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        filterSelectOptions(input, option as OptionProps)
                      }
                    >
                      {seqRunSelectOptions.map((e) => {
                        return (
                          <Select.Option value={e.id} key={e.id}>
                            {`SR${e.id} - ${e.title as string}`}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  }
                />
                <Button
                  type="default"
                  htmlType="submit"
                  style={{ marginTop: 20 }}
                  disabled={watch('sr_id') === null}
                  loading={addToAnalysisButtonLoading}
                >
                  Add to Analysis
                </Button>
                <Button type="default" style={{ marginTop: 20 }} onClick={() => reset()}>
                  Reset
                </Button>
              </Space>
            </form>
            {/* logic to display selected sample data */}
            {Array.isArray(selectedSeqRun) && selectedSeqRun.length > 0 ? (
              <>
                {selectedSeqRun.map((item) => {
                  return (
                    <div>
                      <Space align="center" style={{ marginBottom: 10 }} size="middle">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <h3 style={{ margin: 0 }}>SR-{item.sr_id}</h3>
                          <p
                            style={{ margin: 0, marginLeft: 5 }}
                          >{`(${item.selectedMetadataRows.length} samples selected)`}</p>
                        </div>
                        <EditOutlined
                          onClick={() => {
                            setSrId(item.sr_id);
                            setModalVisible(true);
                          }}
                        />
                        <Popconfirm
                          title="Are you sure to remove the SR?"
                          onConfirm={() => {
                            dispatchSelectedSeqRun({ type: 'remove', sr_id: item.sr_id });
                          }}
                          okText="Yes"
                          cancelText="No"
                          icon={<DeleteOutlined style={{ color: 'red' }} />}
                        >
                          <DeleteOutlined />
                        </Popconfirm>
                      </Space>
                      {Array.isArray(item.selectedMetadataRows) &&
                      item.selectedMetadataRows.length > 0 ? (
                        <Table
                          className="qiimeMetadataTable"
                          scroll={{ x: 'max-content' }}
                          bordered
                          rowKey={Object.keys(item.selectedMetadataRows[0])[0]}
                          size="small"
                          dataSource={item.selectedMetadataRows}
                        >
                          {Object.keys(item.selectedMetadataRows[0]).map((rowData) => {
                            return (
                              <Column
                                title={rowData}
                                dataIndex={rowData}
                                key={rowData}
                                fixed={rowData === 'sample-id' ? 'left' : undefined}
                              />
                            );
                          })}
                        </Table>
                      ) : null}
                    </div>
                  );
                })}
                <p>
                  <b>Total samples :</b> {getTotalSampleCounts()}
                </p>
                <Button
                  type="primary"
                  onClick={() => {
                    setMergeFeatureButtonLoading(true);
                    // mutation call
                    addSrsToMdsa({
                      variables: {
                        mdsa_task_id: parseInt(taskId, 10),
                        mdsa_id: parseInt(id, 10),
                        _set: {
                          started_at,
                        },
                        all_sample_ids: selectedSeqRun
                          .map((e) => e.selectedMetadataRows.map((sample) => sample['sample-id']))
                          .flatMap((item) => item),
                        objects: selectedSeqRun.map((e) => {
                          return {
                            mdsa_id: parseInt(id, 10),
                            sr_id: e.sr_id,
                            sample_ids: e.selectedMetadataRows.map((item) => {
                              return item['sample-id'];
                            }),
                          };
                        }),
                      },
                    })
                      .then(() => {
                        /* Call to startMdsaQiimeAnalysis mutation */
                        startMdsaQiimeAnalysis({
                          variables: {
                            taskId: parseInt(taskId, 10),
                          },
                        })
                          .then(() => {
                            setMergeFeatureButtonLoading(false);
                            navigate(`/mdsa/${parseInt(id, 10)}`);
                          })
                          .catch((mutationErr: ApolloError) => {
                            setMergeFeatureButtonLoading(false);
                            logger(mutationErr);
                          });
                      })
                      .catch((err: ApolloError) => {
                        setMergeFeatureButtonLoading(false);
                        logger(err);
                      });
                  }}
                  loading={mergeFeatureButtonLoading}
                >
                  {/* Change btn text based on 1 or more tables are added to analysis */}
                  {selectedSeqRun.length === 1 ? 'Use single table' : 'Merge feature tables'}
                </Button>
              </>
            ) : null}
          </>
        )}
      </>
    </AppLayout>
  );
};

export default MdsaMergeTables;
