import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Spin } from 'antd';
import {
  MdsaMetadataUploadDataQuery,
  MdsaMetadataUploadDataQueryVariables,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import MdsaMetadataUploadFile from '../components/MdsaMetadataUploadFile';
import MdsaMetadataConfirm from '../components/MdsaMetadataConfirm';
import { MdsaUploadMetadataScreenType, UploadFileDetailsType } from '../utils/types';

const mdsaMetadataUploadDataQuery = loader(
  '../graphql/queries/mdsaMetadataUploadDataQuery.graphql',
);

/* React functional component */
const MdsaUploadMetadata: React.FC = () => {
  /* Extracting mdsa id and task id from url params */
  const { id, taskId } = useParams();

  /* State to store started_at */
  const [started_at] = useState(new Date());

  /* State to store key of the current screen */
  const [currentScreen, setCurrentScreen] = useState<MdsaUploadMetadataScreenType>(
    'mdsaMetadataUploadFile',
  );

  /* State to store selected file details */
  const [uploadFileDetails, setUploadFileDetails] = useState<UploadFileDetailsType>({
    file: null,
    fileList: [],
  });

  /* Query to fetch mdsa metadata upload metadata */
  const { data, loading, error: queryError } = useQuery<
    MdsaMetadataUploadDataQuery,
    MdsaMetadataUploadDataQueryVariables
  >(mdsaMetadataUploadDataQuery, {
    variables: {
      mdsa_id: parseInt(id, 10),
    },
  });

  /* Loading indicator while fetching data */
  if (loading) {
    return (
      <AppLayout screenTitle="Metadata for merged samples">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  // if any error occurred while fetching data will show error message
  if (queryError) {
    return (
      <AppLayout screenTitle="Metadata for merged samples">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  /* Storing tasks data of the MDSA */
  const mdsaSeqRuns = data?.mdsa_by_pk?.mdsa_sequencing_runs;

  /* Storing the list of sample ids selected by the user in 'mergeTablesSr' task */
  const sampleIds = data?.mdsa_by_pk?.sample_ids as Array<string>;

  /* Storing current metadata file url from mdsa out files */
  const currentMetadataFileUrl = data?.mdsaOutFileUrl?.url;

  /* Storing s3 keys of all sequencing runs' metadata files */
  const seqRunMetadataFilesS3Keys: Array<string> = [];
  if (Array.isArray(mdsaSeqRuns) && mdsaSeqRuns.length > 0) {
    mdsaSeqRuns.forEach((mdsaSr) => {
      seqRunMetadataFilesS3Keys.push(
        `sequencing_run/SR${mdsaSr.sr_id}/SR${mdsaSr.sr_id}_metadata.tsv`,
      );
    });
  }

  /* Function returns screen based on currentScreen state */
  const renderScreen = () => {
    if (currentScreen === 'mdsaMetadataUploadFile') {
      return (
        <MdsaMetadataUploadFile
          seqRunMetadataFilesS3Keys={seqRunMetadataFilesS3Keys}
          sampleIds={sampleIds}
          currentMetadataFileUrl={currentMetadataFileUrl}
          updateUploadFileDetails={setUploadFileDetails}
          uploadFileDetails={uploadFileDetails}
          updateCurrentScreen={setCurrentScreen}
          mdsaId={id}
        />
      );
    }
    if (currentScreen === 'mdsaMetadataConfirm') {
      return (
        <MdsaMetadataConfirm
          mdsaId={id}
          updateCurrentScreen={setCurrentScreen}
          taskStartedAt={started_at}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          uploadedFile={uploadFileDetails.file}
          mdsaTaskId={taskId}
        />
      );
    }

    return <div />;
  };

  return <AppLayout screenTitle="Metadata for merged samples">{renderScreen()}</AppLayout>;
};

export default MdsaUploadMetadata;
