import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { init } from '@sentry/electron/renderer';
import * as Sentry from '@sentry/react';
import apolloClient from './graphql/apolloClient';
import App from './App';
import 'antd/dist/antd.css';
import reportWebVitals from './reportWebVitals';

if (window.ecp29Desktop && window.ecp29Desktop.isDesktopApp) {
  init(
    {
      dsn: 'https://eea95b4d913feb3ec8286381b1acfe4e@o1204593.ingest.sentry.io/4506698467639296',
      integrations: [],
      enabled:
        !!process.env.REACT_APP_ENVIRONMENT &&
        (process.env.REACT_APP_ENVIRONMENT === 'development' ||
          process.env.REACT_APP_ENVIRONMENT === 'production'),
      environment: process.env.REACT_APP_ENVIRONMENT || 'development',
      release: process.env.REACT_APP_ENVIRONMENT || 'development',
    },
    Sentry.init,
  );
} else {
  Sentry.init({
    dsn: 'https://3c093083e1f33cbc764a066007f54b44@o1204593.ingest.sentry.io/4506704450617344',
    integrations: [],
    enabled:
      !!process.env.REACT_APP_ENVIRONMENT &&
      (process.env.REACT_APP_ENVIRONMENT === 'development' ||
        process.env.REACT_APP_ENVIRONMENT === 'production'),
    environment: process.env.REACT_APP_ENVIRONMENT || 'development',
    release: process.env.REACT_APP_ENVIRONMENT || 'development',
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
