import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, Col, Divider, InputNumber, Row, Spin } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AppLayout from '../components/AppLayout';
import { mdsaTasksInitialValues } from '../utils/globals';
import {
  Enum_Mdsa_Task_Key_Enum,
  MdsaInParam,
  MdsaTaskParamsMetadataColumnsQuery,
  MdsaTaskParamsMetadataColumnsQueryVariables,
  MetadataColumn,
  UpdateAndStartMdsaTaskMutation,
  UpdateAndStartMdsaTaskMutationVariables,
} from '../graphql/graphql-types';
import EcFormItem from '../components/EcFormItem';
import { logger } from '../utils/helpers';

const mdsaCartQuery = loader('../graphql/queries/mdsaTaskParamsMetadataColumnsQuery.graphql');
const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);

// input param form type
type FormType = {
  // type for field metadata columns i.e. variables
  variables: string[] | null;
  // type to store pertaxa value
  pertaxa: number | null;
};

// schema use to pass to yup resolver for form validation
const schema = yup.object().shape({
  variables: yup
    .array()
    .min(1, 'Please select at least one metadata column and try again')
    .required('Please select at least one metadata column and try again')
    .nullable(),
});

// const to store initial values for task cart
const cartInitialValues = mdsaTasksInitialValues.find(
  (item) => item.key === Enum_Mdsa_Task_Key_Enum.Cart,
);

// const use to store form default values for task cart
const cartFormDefaultValue: FormType = {
  variables: null,
  pertaxa: null,
};

// if logic to set form default values to actual default values passed in in_param for that task.
if (
  cartInitialValues &&
  Array.isArray(cartInitialValues.in_params) &&
  cartInitialValues.in_params.length > 0
) {
  (cartInitialValues.in_params as MdsaInParam[]).forEach((item) => {
    if (item.key === '--variables' && item.rawValue) {
      cartFormDefaultValue.variables = item.rawValue as string[];
    }
    if (item.key === '--pertaxa' && item.rawValue) {
      cartFormDefaultValue.pertaxa = item.rawValue as number;
    }
  });
}

const MdsaCart: React.FC = () => {
  // Extracting id and task id from url params
  const { id, taskId } = useParams();

  // creating navigate instance
  const navigate = useNavigate();

  // state to check whether all metadata columns are selected or not
  const [checkAll, setCheckAll] = useState<boolean>(false);

  // state use to determine loading indicator on start analysis button
  const [startAnalysisButtonLoading, setStartAnalysisButtonLoading] = useState<boolean>(false);

  // state to set started_at value to a time when user open the start analysis screen in update and start mdsa task mutation
  const [started_at] = useState(new Date());

  // query use to fetch details of task metadata column and metadata file url
  const { data, loading: queryLoading, error: queryError } = useQuery<
    MdsaTaskParamsMetadataColumnsQuery,
    MdsaTaskParamsMetadataColumnsQueryVariables
  >(mdsaCartQuery, {
    variables: {
      taskId: parseInt(taskId, 10),
    },
    fetchPolicy: 'network-only',
  });

  // Mutation to update and start mdsa task
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  // useForm declarations
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, control, setValue, watch } = useForm<FormType>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: cartFormDefaultValue,
  });

  // const to store mdsa metadata
  const mdsaMetadataColumnsData = data?.task?.mdsa.metadata_columns as MetadataColumn[];

  // const to store in_params for mdsa task fetched from query
  const mdsaTaskInParams = data?.task?.in_params as MdsaInParam[];

  // useEffect to set initial values of params to the existing one when page is reload
  useEffect(() => {
    if (Array.isArray(mdsaTaskInParams) && mdsaTaskInParams.length > 0)
      mdsaTaskInParams.forEach((item) => {
        if (item.key === '--variables' && item.rawValue) {
          const rawValue = item.rawValue as string[];
          setValue('variables', rawValue);
          setCheckAll(rawValue.length === mdsaMetadataColumnsData.length);
        }

        if (item.key === '--pertaxa') {
          setValue('pertaxa', item.rawValue as number);
        }
      });
  }, [mdsaTaskInParams, setValue, mdsaMetadataColumnsData]);

  /* Loading indicator while fetching data */
  if (queryLoading) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  // if any error occurred while fetching data will show error message
  if (queryError) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  // onSubmit function to execute on clicking start analysis button
  const onSubmit = (formData: FormType) => {
    setStartAnalysisButtonLoading(true);

    // obtains the initial value of task cart
    const defaultInParams =
      cartInitialValues && (cartInitialValues.in_params as Array<MdsaInParam>);

    // const to store immutable copy of in_params
    const taskInParamImmutableConst = JSON.parse(JSON.stringify(defaultInParams)) as Array<
      MdsaInParam
    >;

    /* eslint-disable no-param-reassign */
    taskInParamImmutableConst.forEach((item) => {
      if (item.key === '--variables') {
        item.rawValue = formData.variables;
      }
      if (item.key === '--pertaxa') {
        item.rawValue = formData.pertaxa || null;
        item.value = formData.pertaxa || null;
        item.enabled = !!formData.pertaxa;
      }
    });
    /* eslint-enable no-param-reassign */

    // call to mutation
    updateAndStartMdsaTask({
      variables: {
        taskId: parseInt(taskId, 10),
        taskUpdateInput: {
          started_at,
          in_params: taskInParamImmutableConst,
        },
      },
    })
      .then(() => {
        setStartAnalysisButtonLoading(false);
        navigate(`/mdsa/${parseInt(id, 10)}`);
      })
      .catch((err) => {
        setStartAnalysisButtonLoading(false);
        logger(err);
      });
  };

  // const to store value of form field variables
  const formFieldVariables = watch('variables');

  return (
    <AppLayout screenTitle="Microbiome Downstream Analysis">
      <>
        <h2 style={{ marginBottom: 20 }}>CART</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <EcFormItem
            label="Metadata columns"
            name="variables"
            control={control}
            errors={errors}
            isFieldRequired
            render={({ onChange, value }) => {
              return (
                <>
                  <Checkbox
                    checked={checkAll}
                    indeterminate={
                      checkAll ? false : !!(formFieldVariables && formFieldVariables.length > 0)
                    }
                    style={{ marginTop: 10, marginBottom: 10 }}
                    value={value as boolean}
                    onChange={(e) => {
                      setCheckAll(e.target.checked);
                      setValue(
                        'variables',
                        e.target.checked ? mdsaMetadataColumnsData.map((key) => key.column) : null,
                      );
                    }}
                  >
                    Check all
                  </Checkbox>

                  <Divider
                    style={{
                      marginTop: 8,
                      marginBottom: 18,
                    }}
                  />

                  <Checkbox.Group
                    style={{
                      width: '100%',
                      marginBottom: 15,
                    }}
                    value={value as Array<string>}
                    onChange={(checkedValue) => {
                      // if any of the metadata column is unchecked then 'check all' checkbox will be unchecked.
                      // It will be checked only when all the metadata columns are checked
                      setCheckAll(checkedValue.length === mdsaMetadataColumnsData.length);
                      onChange(checkedValue);
                    }}
                  >
                    {Array.isArray(mdsaMetadataColumnsData) &&
                    mdsaMetadataColumnsData.length > 0 ? (
                      <Row>
                        {mdsaMetadataColumnsData.map((item) => (
                          <Col span={6} style={{ paddingBottom: 4 }} key={item.column}>
                            <Checkbox value={item.column}>{item.column}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    ) : null}
                  </Checkbox.Group>
                </>
              );
            }}
          />

          <EcFormItem
            label="--pertaxa"
            name="pertaxa"
            control={control}
            containerStyle={{ marginTop: 15 }}
            render={({ onChange, value }) => {
              return (
                <InputNumber
                  value={value as number}
                  onChange={onChange}
                  step={0.01}
                  style={{ width: 300, marginBottom: 20 }}
                  placeholder="Please enter pertaxa value"
                />
              );
            }}
          />

          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: 20 }}
            loading={startAnalysisButtonLoading}
          >
            Start Analysis
          </Button>
        </form>
      </>
    </AppLayout>
  );
};

export default MdsaCart;
