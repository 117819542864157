import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Select, Spin, Button, message } from 'antd';
import {
  MdsaFilterTableByTaxaDataQueryVariables,
  MdsaFilterTableByTaxaDataQuery,
  UpdateAndStartMdsaTaskMutationVariables,
  UpdateAndStartMdsaTaskMutation,
  MdsaInParam,
  Enum_Mdsa_Task_Key_Enum,
} from '../graphql/graphql-types';
import { distanceMatrixOptions, mdsaTasksInitialValues } from '../utils/globals';
import QiimeMetadataTable from '../components/QiimeMetadataTable';
import EcFormItem from '../components/EcFormItem';
import AppLayout from '../components/AppLayout';

// call to graphql file
const mdsaFilterTableByTaxaDataQuery = loader(
  '../graphql/queries/mdsaFilterTableByTaxaDataQuery.graphql',
);
const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);
// MdsaFilterDistanceMatrixForm type definition
type MdsaFilterDistanceMatrixFormType = {
  distanceMatrixValue: string | null;
  sampleIds: Array<string>;
};

// react functional component
const MdsaFilterDistanceMatrix: React.FC = () => {
  const { id, taskId } = useParams();
  // state to set loading on start analysis button while running mutation
  const [startAnalysisButtonLoading, setStartAnalysisButtonLoading] = useState<boolean>(false);
  // state to set start in update and start mdsa task mutation
  const [started_at] = useState(new Date());

  // useForm declaration
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, control, errors, setValue } = useForm<MdsaFilterDistanceMatrixFormType>({
    defaultValues: { distanceMatrixValue: null, sampleIds: [] },
  });

  // query to extract url
  const { data, loading, error } = useQuery<
    MdsaFilterTableByTaxaDataQuery,
    MdsaFilterTableByTaxaDataQueryVariables
  >(mdsaFilterTableByTaxaDataQuery, {
    variables: {
      mdsa_id: parseInt(id, 10),
      taskId: parseInt(taskId, 10),
    },
    fetchPolicy: 'network-only',
  });

  // Mutation to update and start mdsa task
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  const navigate = useNavigate();

  /* Mdsa in params data */
  const mdsaInParamsData = data?.task?.in_params as Array<MdsaInParam>;

  // checks if default value is present if present sets it accordingly
  useEffect(() => {
    if (Array.isArray(mdsaInParamsData) && mdsaInParamsData.length > 0) {
      mdsaInParamsData.forEach((item) => {
        if (item.key === '--i-distance-matrix' && item.value) {
          setValue('distanceMatrixValue', item.value as string);
        }
        if (
          item.key === '--p-exclude-ids' &&
          Array.isArray(item.rawValue) &&
          item.rawValue.length > 0
        ) {
          setValue('sampleIds', item.rawValue as string[]);
        }
      });
    }
  }, [mdsaInParamsData, setValue]);

  // if error in fetching query
  if (error) {
    return (
      <AppLayout screenTitle="Microbiome downstream analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
      </AppLayout>
    );
  }
  // while loading query data
  if (loading) {
    return (
      <AppLayout screenTitle="Microbiome downstream analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }
  return (
    <AppLayout screenTitle="Microbiome downstream analysis">
      <div>
        <h2>Filter distance matrix</h2>
        <form
          style={{ width: '100%' }}
          onSubmit={handleSubmit(({ distanceMatrixValue, sampleIds }) => {
            // If the task does not have in_params defined
            if (
              !data ||
              !data.task ||
              !Array.isArray(data.task.in_params) ||
              data.task.in_params.length === 0
            ) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.error('Task does not have in_params');
              return;
            }

            setStartAnalysisButtonLoading(true);

            // obtains the initial value of task FilterDistanceMatrix
            const defaultInParams = mdsaTasksInitialValues.find(
              (task) => task.key === Enum_Mdsa_Task_Key_Enum.FilterDistanceMatrix,
            )?.in_params as Array<MdsaInParam>;

            // const to store immutable copy of in_params
            const taskInParamImmutableConst = JSON.parse(JSON.stringify(defaultInParams)) as Array<
              MdsaInParam
            >;

            /* Updating in_params data based on user input */
            /* eslint-disable no-param-reassign */
            taskInParamImmutableConst.forEach((item) => {
              if (item.key === '--i-distance-matrix' && distanceMatrixValue) {
                item.enabled = true;
                item.value = distanceMatrixValue;
                item.rawValue = distanceMatrixValue;
              }
              if (
                item.key === '--p-exclude-ids' &&
                Array.isArray(sampleIds) &&
                sampleIds.length > 0
              ) {
                item.enabled = true;
                item.rawValue = sampleIds;
              }
            });

            // call to mutation
            updateAndStartMdsaTask({
              variables: {
                taskId: parseInt(taskId, 10),
                taskUpdateInput: {
                  started_at,
                  in_params: taskInParamImmutableConst,
                },
              },
            })
              .then(() => {
                setStartAnalysisButtonLoading(false);
                navigate(`/mdsa/${parseInt(id, 10)}`);
              })
              .catch((err) => {
                setStartAnalysisButtonLoading(false);
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.error(err);
              });
          })}
        >
          <div style={{ marginTop: 30, marginBottom: 20 }}>
            <EcFormItem
              label="Distance Matrix (--i-distance-matrix)"
              name="distanceMatrixValue"
              control={control}
              rules={{ required: 'Please select a Distance Matrix and try again' }}
              errors={errors}
              as={
                <Select style={{ width: 200 }} placeholder="Select matrix" showArrow>
                  {distanceMatrixOptions.map((item) => (
                    <Select.Option key={item.name} value={item.value}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              }
            />
          </div>

          <EcFormItem
            label={
              <span>
                Select the samples to <strong>exclude</strong> from the analysis (--p-exclude-ids)
              </span>
            }
            name="sampleIds"
            rules={{
              required: true,
              validate: (value: Array<string>) =>
                value.length > 0 || 'Please select sample(s) and try again.',
            }}
            errors={errors}
            control={control}
            containerStyle={{ width: '100%' }}
            render={({ onChange, value }): JSX.Element => {
              if (data && data.metadataFile && data.metadataFile.url) {
                return (
                  // call to qiimeMetadata table component to display table which contain data obtain by parsing remote file url
                  <QiimeMetadataTable
                    metadataFileUrl={data.metadataFile.url}
                    selectedRowKeys={value as Array<string>}
                    setSelectedRowKeys={(selectedSamplesId) => {
                      onChange(selectedSamplesId);
                    }}
                  />
                );
              }
              return <div />;
            }}
          />
          <Button
            type="primary"
            htmlType="submit"
            loading={startAnalysisButtonLoading}
            style={{ marginTop: 10 }}
          >
            Start Analysis
          </Button>
        </form>
      </div>
    </AppLayout>
  );
};

export default MdsaFilterDistanceMatrix;
