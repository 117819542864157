import React, { useState } from 'react';
import { Button, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { SRUploadMetadataScreenType, UploadFileDetailsType } from '../utils/types';

const { Dragger } = Upload;

/* Prop type of SrMetadala file upload */
type SrMetadataUploadFilePropType = {
  /* setState to update current component */
  updateCurrentScreen: (currentScreen: SRUploadMetadataScreenType) => void;
  /* State to store selected file by user */
  uploadFileDetails: UploadFileDetailsType;
  /* set state function to updated selected file by user */
  updateUploadFileDetails: (currentFileDetails: UploadFileDetailsType) => void;
};

/* React Functional component */
const SrMetadataUploadFile: React.FC<SrMetadataUploadFilePropType> = ({
  updateCurrentScreen,
  updateUploadFileDetails,
  uploadFileDetails,
}) => {
  /* State to decide whether to show error of no file selected */
  const [showNoFileSelectedErr, setShowNoFileSelectedErr] = useState(false);

  return (
    <>
      <h3>Upload metadata tsv file</h3>
      <div style={{ marginBottom: 20, marginTop: 35, width: 300 }}>
        <Dragger
          onChange={(info) => {
            let newFileList = [...info.fileList];
            newFileList = newFileList.slice(-1);
            const fileExtension = newFileList[0].name.split('.').pop();
            if (fileExtension === 'tsv') {
              updateUploadFileDetails({ file: info.file, fileList: newFileList });
            } else {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.error('Only .tsv files are allowed');
            }
            setShowNoFileSelectedErr(false);
          }}
          beforeUpload={() => {
            return false;
          }}
          onRemove={() => {
            updateUploadFileDetails({ file: null, fileList: [] });
            return false;
          }}
          fileList={uploadFileDetails.fileList}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Only TSV files are allowed</p>
        </Dragger>

        {showNoFileSelectedErr ? (
          <p style={{ color: 'red', marginTop: 15 }}>Please select a file and try again!</p>
        ) : null}
      </div>
      <Button type="primary" onClick={() => updateCurrentScreen('metadataColumns')}>
        Edit Metadata Columns
      </Button>
      <Button
        type="primary"
        style={{ marginLeft: 35 }}
        onClick={() => {
          /* if user do not select any file then following error will be shown */
          if (uploadFileDetails.file === null && uploadFileDetails.fileList.length === 0) {
            setShowNoFileSelectedErr(true);
          } else {
            setShowNoFileSelectedErr(false);
            updateCurrentScreen('metadataVerifyUpload');
          }
        }}
      >
        Upload
      </Button>
    </>
  );
};

export default SrMetadataUploadFile;
