import React, { ReducerAction, useState } from 'react';
import { Button, Select, message } from 'antd';
import Papa from 'papaparse';
import { useApolloClient, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import EcFormItem from './EcFormItem';
import {
  ExistingMdsaOptionsQuery,
  MdsaSrQuery,
  MdsaSrQueryVariables,
  SrMetadataFileUrlQuery,
  SrMetadataFileUrlQueryVariables,
} from '../graphql/graphql-types';
import { SelectedSeqRunType, Action } from '../utils/types';
import { logger } from '../utils/helpers';

// call to graphql files
const existingMdsaOptionsQuery = loader('../graphql/queries/existingMdsaOptionsQuery.graphql');
const srMetadataFileUrlQuery = loader('../graphql/queries/srMetadataFileUrlQuery.graphql');
const mdsaSrQuery = loader('../graphql/queries/mdsaSrQuery.graphql');

// type definition for props used in component
type MdsaMergeTableImportProps = {
  // used to update state value of showMdsaMergeTableImport Component
  setShowMdsaMergeTableImport: React.Dispatch<React.SetStateAction<boolean>>;
  // used to update the value of selectedSeqRun
  dispatchSelectedSeqRun: React.Dispatch<
    ReducerAction<(prevState: SelectedSeqRunType, action: Action) => SelectedSeqRunType>
  >;
  // The id of current MDSA
  currentMdsaId: number;
};

const MdsaMergeTableImport = ({
  setShowMdsaMergeTableImport,
  dispatchSelectedSeqRun,
  currentMdsaId,
}: MdsaMergeTableImportProps): JSX.Element => {
  // used to show loading on Import button until data is fetched
  const [showButtonLoader, setShowButtonLoader] = useState<boolean>(false);

  // query to fetch mdsa data to be displayed in select option
  const { data, loading } = useQuery<ExistingMdsaOptionsQuery>(existingMdsaOptionsQuery);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, watch, handleSubmit } = useForm<{ mdsaId: undefined | number }>({
    defaultValues: { mdsaId: undefined },
  });

  // instance of apolloClient using hook
  const apolloClient = useApolloClient();

  return (
    <>
      <h2>Import SR(s) from previous Mdsa</h2>
      <form
        onSubmit={handleSubmit(async (formData) => {
          setShowButtonLoader(true);
          if (formData.mdsaId) {
            try {
              // query used to fetch sr's of mdsa
              const result = await apolloClient.query<MdsaSrQuery, MdsaSrQueryVariables>({
                query: mdsaSrQuery,
                variables: { id: formData.mdsaId },
                fetchPolicy: 'no-cache',
              });
              // stores the values of sr ids of mdsa
              const srs = result.data.mdsa_sequencing_run;
              if (Array.isArray(srs) && srs.length > 0) {
                for (let i = 0; i < srs.length; i++) {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  const { sr_id, sample_ids } = srs[i];

                  // eslint-disable-next-line no-await-in-loop
                  const res = await apolloClient.query<
                    SrMetadataFileUrlQuery,
                    SrMetadataFileUrlQueryVariables
                  >({
                    query: srMetadataFileUrlQuery,
                    variables: {
                      sr_id,
                    },
                  });
                  // stores the outfile data
                  const srOutFile = res.data.srOutFileUrl;
                  if (srOutFile) {
                    Papa.parse(srOutFile.url, {
                      download: true,
                      header: true,
                      skipEmptyLines: true,
                      comments: '#',
                      complete: (papaParseRes) => {
                        let selectedMetadataRows = papaParseRes.data as Array<
                          Record<string, string>
                        >;
                        if (Array.isArray(sample_ids)) {
                          selectedMetadataRows = (papaParseRes.data as Array<
                            Record<string, string>
                          >).filter((row) => {
                            return sample_ids.includes(row['sample-id']);
                          });
                        }

                        dispatchSelectedSeqRun({
                          type: 'addExisting',
                          sr_id,
                          url: srOutFile.url,
                          s3_key: srOutFile.s3_key,
                          selectedMetadataRows,
                        });
                      },
                      error: (error) => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        message.error(error.message);
                      },
                    });
                  }
                }
              }
              setShowMdsaMergeTableImport(false);
              setShowButtonLoader(false);
            } catch (error) {
              logger(error);
              setShowButtonLoader(false);
            }
          }
        })}
      >
        <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 10 }}>
          <EcFormItem
            label="Select MDSA"
            name="mdsaId"
            control={control}
            as={
              <Select
                placeholder="Select MDSA"
                style={{ width: 350 }}
                loading={loading}
                showSearch
                optionFilterProp="label"
                filterOption
                options={
                  data && Array.isArray(data.mdsa) && data.mdsa.length > 0
                    ? data.mdsa
                        .filter((mdsa) => mdsa.id !== currentMdsaId)
                        .map((item) => {
                          return { value: item.id, label: `[MDSA-${item.id}] ${item.title}` };
                        })
                    : undefined
                }
              />
            }
          />
          <Button
            disabled={!watch('mdsaId')}
            type="default"
            htmlType="submit"
            style={{ marginLeft: 20 }}
            loading={showButtonLoader}
          >
            Import SR(s)
          </Button>
        </div>
      </form>
      <p style={{ marginTop: 20, marginLeft: 5, fontWeight: 'bold' }}>OR</p>
      <Button
        type="default"
        onClick={() => {
          setShowMdsaMergeTableImport(false);
        }}
      >
        Continue without import
      </Button>
    </>
  );
};

export default MdsaMergeTableImport;
