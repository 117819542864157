import React, { useContext } from 'react';
import { User } from '../graphql/graphql-types';

export type UserType = Pick<User, 'email' | 'first_name' | 'id' | 'last_name'>;

// The type for the data in UserContext
type UserContextType = {
  user: UserType | undefined;
};

// Context that will be used as a global store for the app
export const UserContext = React.createContext<UserContextType>({
  user: undefined,
});

export const useUser = (): UserContextType => useContext<UserContextType>(UserContext);
