/* eslint-disable react/no-danger */
import React, { useState, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import {
  Spin,
  Descriptions,
  Table,
  Button,
  message,
  Popconfirm,
  Tag,
  Modal,
  Input,
  Select,
  Alert,
} from 'antd';
import { loader } from 'graphql.macro';
import { CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  MdsaDashboardQuery,
  MdsaDashboardQueryVariables,
  TaskOutFiles,
  Mdsa_Task,
  Enum_Mdsa_Task_Key_Enum,
  GetFilesUrlOutput,
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
  StartMdsaQiimeAnalysisMutation,
  StartMdsaQiimeAnalysisMutationVariables,
  SkipMdsaTaskMutation,
  SkipMdsaTaskMutationVariables,
  MdsaMarkAsCompletedMutation,
  MdsaMarkAsCompletedMutationVariables,
  Enum_Mdsa_Status_Enum,
  AwsBatchJobType,
  UpdateMdsaRemarksMutation,
  UpdateMdsaRemarksMutationVariables,
  ResetMdsaUptoTaskMutation,
  ResetMdsaUptoTaskMutationVariables,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import { logger } from '../utils/helpers';
import { mdsaTaskKeyNameMap, mdsaSkippableTaskKeys } from '../utils/globals';
import { TaskStatusType } from '../utils/types';
import EcFormItem from '../components/EcFormItem';
import styles from './ScreenStyles.module.scss';

const { Column } = Table;

/* MDSA task type */
type MdsaTaskType = Pick<
  Mdsa_Task,
  | 'id'
  | 'dependent_on'
  | 'ended_at'
  | 'in_files'
  | 'in_params'
  | 'key'
  | 'out_data'
  | 'out_files'
  | 'position'
  | 'started_at'
  | 'skipped'
  | 'aws_batch_job'
>;

const mdsaDashboardQuery = loader('../graphql/queries/mdsaDashboardQuery.graphql');
const getFilesUrlQuery = loader('../graphql/queries/getFilesUrlQuery.graphql');
const startMdsaQiimeAnalysisMutation = loader(
  '../graphql/mutations/startMdsaQiimeAnalysisMutation.graphql',
);
const skipMdsaTaskMutation = loader('../graphql/mutations/skipMdsaTaskMutation.graphql');
const mdsaMarkAsCompletedMutation = loader(
  '../graphql/mutations/mdsaMarkAsCompletedMutation.graphql',
);
const updateMdsaRemarksMutation = loader('../graphql//mutations/updateMdsaRemarksMutation.graphql');
const resetMdsaUptoTaskPositionMutatation = loader(
  '../graphql/mutations//resetMdsaUptoTaskMutation.graphql',
);

/* Variable to store task keys for which have to show View Results Btn */
const viewResultsForTaskKeys = [
  Enum_Mdsa_Task_Key_Enum.MergeTablesSr,
  Enum_Mdsa_Task_Key_Enum.FilterTableByTaxa,
  Enum_Mdsa_Task_Key_Enum.GroupTableBarPlot,
  Enum_Mdsa_Task_Key_Enum.PhylogeneticDiversityAnalysis,
  Enum_Mdsa_Task_Key_Enum.FilterDistanceMatrix,
  Enum_Mdsa_Task_Key_Enum.CustomPcoaPlot,
  Enum_Mdsa_Task_Key_Enum.Ancom,
  Enum_Mdsa_Task_Key_Enum.AlphaGroupSignificance,
  Enum_Mdsa_Task_Key_Enum.BetaGroupSignificance,
  Enum_Mdsa_Task_Key_Enum.TableFilterFeatures,
  Enum_Mdsa_Task_Key_Enum.Picrust2,
  Enum_Mdsa_Task_Key_Enum.Blast,
  Enum_Mdsa_Task_Key_Enum.TaxonomicClassification,
  Enum_Mdsa_Task_Key_Enum.TaxaBarPlot,
  Enum_Mdsa_Task_Key_Enum.Lefse,
  Enum_Mdsa_Task_Key_Enum.Cart,
  Enum_Mdsa_Task_Key_Enum.HmqReport,
];

const startQiimeAnalysisTaskKeys = [
  Enum_Mdsa_Task_Key_Enum.MergeTablesSummary,
  Enum_Mdsa_Task_Key_Enum.CoreMicrobiomeAnalysis,
  Enum_Mdsa_Task_Key_Enum.HmqReport,
];

/* React functional component */
const MdsaDashboard: React.FC = () => {
  /* Extracting id from url param */
  const { id } = useParams();

  const apolloClient = useApolloClient();

  /* State to store download urls of out_files uploaded by user for every task */
  const [fileDownloadUrls, setFileDownloadUrls] = useState<Array<GetFilesUrlOutput> | null>(null);

  /* State to store refresh status buttons loading */
  const [refreshStatusButtonLoading, setRefreshStatusButtonLoading] = useState<boolean>(false);

  /* State to store selected mdsa task key to start qiime analysis for start buttons loading wherever needed */
  const [
    selectedMdsaTaskKeyToStartQiimeAnalysis,
    setSelectedMdsaTaskKeyToStartQiimeAnalysis,
  ] = useState<Enum_Mdsa_Task_Key_Enum | null>(null);

  /* State to store selected mdsa task key to skip task for skip buttons loading wherever needed */
  const [
    selectedMdsaTaskKeyToSkipTask,
    setSelectedMdsaTaskKeyToSkipTask,
  ] = useState<Enum_Mdsa_Task_Key_Enum | null>(null);

  // state to set mark as complete button loading indicator
  const [markAsCompleteButtonLoading, setMarkAsCompleteButtonLoading] = useState<boolean>(false);

  // State to store the logs of failed task to be viewed in Modal. Also is being used to show and hide the modal
  const [awsJobLogs, setAwsJobLogs] = useState<string>('');

  // indicates whether to show remarks button loading
  const [showRemarksButtonLoading, setShowRemarksButtonLoading] = useState<boolean>(false);
  // indicates whether to show reset mdsa upto task modal
  const [showResetMdsaUptoTaskModal, setShowResetMdsaUptoTaskModal] = useState<boolean>(false);
  // indicates whether to show confirm button loading while submit
  const [showResetMdsaButtonLoading, setShowResetMdsaButtonLoading] = useState<boolean>(false);
  // stores the value of task poistion to reset upto
  const [taskPositionToResetUpto, setTaskPositionToResetUpto] = useState<string | undefined>(
    undefined,
  );

  /* Mutation to start qiime analysis of mdsa */
  const [startMdsaQiimeAnalysis] = useMutation<
    StartMdsaQiimeAnalysisMutation,
    StartMdsaQiimeAnalysisMutationVariables
  >(startMdsaQiimeAnalysisMutation);

  /* Mutation to skip mdsa task */
  const [skipMdsaTask] = useMutation<SkipMdsaTaskMutation, SkipMdsaTaskMutationVariables>(
    skipMdsaTaskMutation,
  );

  // useMutation for mdsaMarkAsCompletedMutation
  const [mdsaMarkAsCompleted] = useMutation<
    MdsaMarkAsCompletedMutation,
    MdsaMarkAsCompletedMutationVariables
  >(mdsaMarkAsCompletedMutation);

  // useMutation for updateMdsaRemarks
  const [updateMdsaRemark] = useMutation<
    UpdateMdsaRemarksMutation,
    UpdateMdsaRemarksMutationVariables
  >(updateMdsaRemarksMutation);

  // useMutation for reset Mdsa upto task
  const [resetMdsaUptoTaskPosition] = useMutation<
    ResetMdsaUptoTaskMutation,
    ResetMdsaUptoTaskMutationVariables
  >(resetMdsaUptoTaskPositionMutatation);

  // useForm  declaration
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, control, errors, setValue } = useForm<{ remarks: string | undefined }>({
    defaultValues: {
      remarks: undefined,
    },
  });

  /* Get download urls of out_files uploaded by user */
  const getFilesDownloadUrls = useCallback(
    (queryData: MdsaDashboardQuery) => {
      const mdsaTasksData = queryData?.mdsa_by_pk?.tasks;
      const s3KeysList: Array<string> = [];
      if (Array.isArray(mdsaTasksData) && mdsaTasksData.length > 0) {
        mdsaTasksData.forEach((item) => {
          if (item.out_files) {
            const outFiles = item.out_files as TaskOutFiles;
            if (Array.isArray(outFiles) && outFiles.length > 0) {
              outFiles.forEach((outFile) => {
                if (outFile.show_in_dashboard_table) {
                  s3KeysList.push(outFile.s3_key);
                }
              });
            }
          }
        });
      }
      if (s3KeysList.length > 0) {
        /* To get download urls for all out files */
        apolloClient
          .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
            query: getFilesUrlQuery,
            variables: { keys: s3KeysList },
          })
          .then((fileUrls) => {
            const downloadUrlResponse = fileUrls.data.getFilesUrl;
            const downloadUrlsWithKey: Array<GetFilesUrlOutput> = [];
            if (Array.isArray(downloadUrlResponse) && downloadUrlResponse.length > 0) {
              downloadUrlResponse.forEach((item) => {
                const key = item?.key;
                const url = item?.url;
                if (url && key) {
                  downloadUrlsWithKey.push({ key, url });
                }
              });
              setFileDownloadUrls(downloadUrlsWithKey);
            }
          })
          .catch((error) => {
            logger(error);
          });
      }
    },
    [apolloClient],
  );

  /* Query to fetch mdsa data for current analysis */
  const { data, loading: mdsaDashboardDataLoading, error: queryError, refetch } = useQuery<
    MdsaDashboardQuery,
    MdsaDashboardQueryVariables
  >(mdsaDashboardQuery, {
    variables: {
      id: parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
    onCompleted: (queryData) => {
      getFilesDownloadUrls(queryData);
      if (queryData.mdsa_by_pk && queryData.mdsa_by_pk.remarks) {
        setValue('remarks', queryData.mdsa_by_pk.remarks);
      }
    },
  });

  /* Object Storing status for each task */
  const taskStatus = {} as Record<Enum_Mdsa_Task_Key_Enum, TaskStatusType>;
  Object.values(Enum_Mdsa_Task_Key_Enum).forEach((key) => {
    taskStatus[key] = '';
  });

  /* Loading indicator while fetching data */
  if (mdsaDashboardDataLoading) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <>
          <h3>MDSA-{id}</h3>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
            }}
          >
            <Spin size="large" />
          </div>
        </>
      </AppLayout>
    );
  }

  /* if any error occurred while fetching data will show error message */
  if (queryError) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  /* Storing fetched mdsa data */
  const mdsaData = data?.mdsa_by_pk;

  /* Storing tasks data of fetched mdsa */
  const mdsaTasksData = data?.mdsa_by_pk?.tasks;

  // stores the value of options to be provided to reset mdsa task
  // The tasks/steps that the user can reset to
  const mdsaTaskResetOptions: { label: string; value: number }[] = [];

  // indicates if any task is in progress
  let isAnyMdsaTaskInProgress = false;

  /* Handle start merge tables summary */
  const handleStartMdsaQiimeAnalysis = (taskId: number) => {
    startMdsaQiimeAnalysis({
      variables: {
        taskId,
        setStartedAt: true,
      },
      refetchQueries: [
        {
          query: mdsaDashboardQuery,
          variables: {
            id: parseInt(id, 10),
          },
        },
      ],
    })
      .then(() => {
        setSelectedMdsaTaskKeyToStartQiimeAnalysis(null);
      })
      .catch((mutationErr) => {
        setSelectedMdsaTaskKeyToStartQiimeAnalysis(null);
        logger(mutationErr);
      });
  };

  /* Determining status of each task */
  if (Array.isArray(mdsaTasksData) && mdsaTasksData.length > 0) {
    mdsaTasksData.forEach((record) => {
      const awsBatchJob = record.aws_batch_job as AwsBatchJobType;

      if (awsBatchJob && awsBatchJob.status === 'FAILED') {
        taskStatus[record.key] = 'Failed';
      } else if (record.skipped) {
        taskStatus[record.key] = 'Skipped';
        // A skipped task/step will be part of the list of tasks that will be shown in reset task select options
        if (record.key !== Enum_Mdsa_Task_Key_Enum.MergeTablesSr) {
          mdsaTaskResetOptions.push({
            label: mdsaTaskKeyNameMap[record.key],
            value: record.position,
          });
        }
      } else if (record.started_at !== null && record.ended_at === null) {
        taskStatus[record.key] = 'In Progress';
        isAnyMdsaTaskInProgress = true;
      } else if (record.ended_at !== null) {
        taskStatus[record.key] = 'Completed';
        // A completed task/step will be part of the list of tasks that will be shown in reset task select options
        // mergeTablesSr will not be part of the list of tasks that will be shown in reset task select options
        if (record.key !== Enum_Mdsa_Task_Key_Enum.MergeTablesSr) {
          mdsaTaskResetOptions.push({
            label: mdsaTaskKeyNameMap[record.key],
            value: record.position,
          });
        }
      } else if (record.started_at === null && record.dependent_on === null) {
        taskStatus[record.key] = 'Ready';
      } else if (
        record.started_at === null &&
        record.dependent_on !== null &&
        Array.isArray(record.dependent_on) &&
        record.dependent_on.length > 0
      ) {
        taskStatus[record.key] = 'Ready';
        for (let i = 0; i < record.dependent_on.length; i++) {
          const taskKey = record.dependent_on[i] as Enum_Mdsa_Task_Key_Enum;
          if (taskStatus[taskKey] !== 'Completed' && taskStatus[taskKey] !== 'Skipped') {
            taskStatus[record.key] = 'N/A';
            break;
          }
        }
      }
    });
  }

  /* Rendering actions for each table row */
  const renderTableRowActions = (record: MdsaTaskType) => {
    /* Variable to store label for button in actions column based on tasks status */
    let btnText = '';
    /* Variable to store route link for action button based on tasks key */
    let linkToRoute = '';
    /* Array of action Buttons based on conditions */
    const tableRowActionButtons: Array<JSX.Element> = [];
    if (mdsaData && mdsaData.status !== Enum_Mdsa_Status_Enum.Completed) {
      if (taskStatus[record.key] === 'Ready' || taskStatus[record.key] === 'Failed') {
        btnText = taskStatus[record.key] === 'Ready' ? 'Start' : 'Re-start';
        if (record.key === Enum_Mdsa_Task_Key_Enum.MergeTablesSr) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/mergeTablesSr`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.UploadMetadata) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/uploadMetadata`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.TaxonomicClassification) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/taxonomicClassification`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.FilterTableByTaxa) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/filterTableByTaxa`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.FilterDistanceMatrix) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/filterDistanceMatrix`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.TaxaBarPlot) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/taxaBarPlot`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.GroupTableBarPlot) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/groupTableBarPlot`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.PhylogeneticDiversityAnalysis) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/phylogeneticDiversityAnalysis`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.CustomPcoaPlot) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/customPcoaPlot`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.Ancom) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/ancom`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.BetaGroupSignificance) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/betaDiversity`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.AlphaGroupSignificance) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/alphaDiversity`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.TableFilterFeatures) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/tableFilterFeatures`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.Picrust2) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/picrust2`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.Blast) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/blast`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.Lefse) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/lefse`;
        } else if (record.key === Enum_Mdsa_Task_Key_Enum.Cart) {
          linkToRoute = `/mdsa/${id}/task/${record.id}/cart`;
        } else if (startQiimeAnalysisTaskKeys.includes(record.key)) {
          tableRowActionButtons.push(
            <Button
              type="default"
              loading={record.key === selectedMdsaTaskKeyToStartQiimeAnalysis}
              onClick={() => {
                setSelectedMdsaTaskKeyToStartQiimeAnalysis(record.key);
                handleStartMdsaQiimeAnalysis(record.id);
              }}
              style={{ marginRight: 10 }}
              key="btn"
            >
              {btnText}
            </Button>,
          );
        }

        if (mdsaSkippableTaskKeys.includes(record.key)) {
          tableRowActionButtons.push(
            <Popconfirm
              key="skip-btn"
              title="Do you want to skip this task?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                setSelectedMdsaTaskKeyToSkipTask(record.key);
                skipMdsaTask({
                  variables: {
                    id: record.id,
                  },
                  refetchQueries: [
                    {
                      query: mdsaDashboardQuery,
                      variables: {
                        id: parseInt(id, 10),
                      },
                    },
                  ],
                  awaitRefetchQueries: true,
                })
                  .then(() => {
                    setSelectedMdsaTaskKeyToSkipTask(null);
                  })
                  .catch((err) => {
                    setSelectedMdsaTaskKeyToSkipTask(null);
                    logger(err);
                  });
              }}
            >
              <Button
                loading={record.key === selectedMdsaTaskKeyToSkipTask}
                style={{ marginRight: 10 }}
                type="default"
                key="skip-btn"
              >
                Skip
              </Button>
            </Popconfirm>,
          );
        }
      }

      if (taskStatus[record.key] === 'In Progress') {
        btnText = 'Refresh Status';
        return (
          <Button
            type="default"
            key="refresh-status-btn"
            style={{ marginRight: 10 }}
            onClick={() => {
              setRefreshStatusButtonLoading(true);
              /* Refetch mdsaDashboard query to refresh status of tasks */
              refetch()
                .then((res) => {
                  getFilesDownloadUrls(res.data);
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  message.success('Updated status of the analysis was fetched.');
                  setTimeout(() => {
                    setRefreshStatusButtonLoading(false);
                  }, 1000);
                })
                .catch((error) => {
                  setRefreshStatusButtonLoading(false);
                  logger(error);
                });
            }}
            loading={refreshStatusButtonLoading}
          >
            {btnText}
          </Button>
        );
      }
    }

    if (taskStatus[record.key] === 'Completed') {
      if (viewResultsForTaskKeys.includes(record.key) && !record.skipped) {
        btnText = 'View Results';
        linkToRoute = `/mdsa/${id}/task/${record.id}/results`;
      }
      if (
        record.key === Enum_Mdsa_Task_Key_Enum.UploadMetadata &&
        taskStatus[Enum_Mdsa_Task_Key_Enum.MergeTablesSummary] !== 'In Progress' &&
        (taskStatus[Enum_Mdsa_Task_Key_Enum.TaxonomicClassification] === 'Ready' ||
          taskStatus[Enum_Mdsa_Task_Key_Enum.TaxonomicClassification] === 'N/A')
      ) {
        btnText = 'Re-upload';
        linkToRoute = `/mdsa/${id}/task/${record.id}/uploadMetadata`;
      }
    }

    if (taskStatus[record.key] === 'Failed') {
      const awsBatchJob = record.aws_batch_job as AwsBatchJobType;
      tableRowActionButtons.unshift(
        <Button
          key="view-logs-btn"
          onClick={() => {
            setAwsJobLogs(awsBatchJob.logs as string);
          }}
          style={{ marginBottom: 10, marginTop: 10, marginRight: 10 }}
          type="default"
        >
          View Logs
        </Button>,
      );
    }

    if (btnText !== '' && linkToRoute !== '') {
      if (btnText === 'Re-start') {
        tableRowActionButtons.push(
          <Button type="default" key="re-start-btn" style={{ marginRight: 10 }}>
            <Link to={linkToRoute}>{btnText}</Link>
          </Button>,
        );
      } else {
        tableRowActionButtons.unshift(
          <Button type="default" key="start-btn" style={{ marginRight: 10 }}>
            <Link to={linkToRoute}>{btnText}</Link>
          </Button>,
        );
      }
    }

    // below code is to show "add columns" button for task upload metaData.
    if (
      record.key === Enum_Mdsa_Task_Key_Enum.UploadMetadata &&
      taskStatus[Enum_Mdsa_Task_Key_Enum.TaxonomicClassification] === 'Completed' &&
      (taskStatus[Enum_Mdsa_Task_Key_Enum.PhylogeneticDiversityAnalysis] === 'N/A' ||
        taskStatus[Enum_Mdsa_Task_Key_Enum.PhylogeneticDiversityAnalysis] === 'Ready')
    ) {
      tableRowActionButtons.push(
        <Button key="addColumnsBtn" type="default" style={{ marginRight: 10 }}>
          <Link to={`/mdsa/${id}/task/${record.id}/addColumns`}>Add Columns</Link>
        </Button>,
      );
    }
    return tableRowActionButtons;
  };

  /* Rendering out files for each row in table if any */
  const renderTableRowFiles = (record: MdsaTaskType) => {
    const fileDownloadButtons: Array<JSX.Element> = [];
    if (taskStatus[record.key] === 'Completed') {
      if (Array.isArray(record.out_files) && record.out_files.length > 0) {
        (record.out_files as TaskOutFiles).forEach((out_file) => {
          const outFile = out_file;
          if (Array.isArray(fileDownloadUrls) && fileDownloadUrls.length > 0) {
            for (let i = 0; i < fileDownloadUrls.length; i++) {
              if (fileDownloadUrls[i].key === outFile.s3_key) {
                const fileDownloadButton = (
                  <div key={outFile.name}>
                    <Button type="link" href={fileDownloadUrls[i].url}>
                      {outFile.name}
                    </Button>
                  </div>
                );
                fileDownloadButtons.push(fileDownloadButton);
              }
            }
          }
        });
      }
    }
    return fileDownloadButtons;
  };

  // mark as complete button onClick function
  const markAsCompleteButtonOnClickFunction = () => {
    setMarkAsCompleteButtonLoading(true);
    mdsaMarkAsCompleted({
      variables: {
        id: parseInt(id, 10),
      },
      update: (cache, cacheData) => {
        const cacheStatus = cacheData.data?.update_mdsa_by_pk?.status;
        if (mdsaData)
          cache.modify({
            id: cache.identify(mdsaData),
            fields: {
              status(updatedStatus: Enum_Mdsa_Status_Enum) {
                if (cacheStatus)
                  // eslint-disable-next-line no-param-reassign
                  updatedStatus = cacheStatus;
                return updatedStatus;
              },
            },
          });
      },
    })
      .then(() => setMarkAsCompleteButtonLoading(false))
      .catch((err) => {
        setMarkAsCompleteButtonLoading(false);
        logger(err);
      });
  };

  // function called on close of reset mdsa modal
  const handleCloseOfResetMdsa = () => {
    setTaskPositionToResetUpto(undefined);
    setShowResetMdsaUptoTaskModal(false);
  };

  return (
    <AppLayout screenTitle="Microbiome Downstream Analysis" hideBackBtn>
      <>
        <Modal
          title="Logs"
          visible={!!awsJobLogs}
          onOk={() => {
            setAwsJobLogs('');
          }}
          onCancel={() => {
            setAwsJobLogs('');
          }}
          cancelButtonProps={{ style: { display: 'none' } }}
          width="90%"
        >
          <pre dangerouslySetInnerHTML={{ __html: awsJobLogs }} />
        </Modal>
        <Modal
          title="Reset MDSA to a particular step"
          visible={!!showResetMdsaUptoTaskModal}
          okText="Confirm"
          closable={!showResetMdsaButtonLoading}
          maskClosable={!showResetMdsaButtonLoading}
          style={{ width: '60%' }}
          onCancel={() => {
            handleCloseOfResetMdsa();
          }}
          confirmLoading={showResetMdsaButtonLoading}
          footer={[
            <Button
              key="back"
              onClick={() => {
                handleCloseOfResetMdsa();
              }}
              disabled={showResetMdsaButtonLoading}
            >
              Cancel
            </Button>,
            <Popconfirm
              key="reset-btn"
              title="Do you want to reset this task?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                if (taskPositionToResetUpto) {
                  setShowResetMdsaButtonLoading(true);
                  resetMdsaUptoTaskPosition({
                    variables: {
                      mdsaId: parseInt(id, 10),
                      resetMdsaUptoTaskPosition: parseInt(taskPositionToResetUpto, 10),
                    },
                  })
                    .then(() => {
                      if (refetch) {
                        refetch()
                          .then(() => {
                            setShowResetMdsaButtonLoading(false);
                            handleCloseOfResetMdsa();
                          })
                          .catch((error) => {
                            logger(error);
                          });
                      }
                    })
                    .catch((error) => {
                      logger(error);
                      setTaskPositionToResetUpto(undefined);
                      handleCloseOfResetMdsa();
                    });
                }
              }}
            >
              <Button
                disabled={showResetMdsaButtonLoading || !taskPositionToResetUpto}
                type="primary"
                key="submit"
                style={{ marginLeft: 15 }}
              >
                Confirm
              </Button>
            </Popconfirm>,
          ]}
        >
          <p>Select the step/task to reset to</p>
          <Select
            style={{ width: '100%' }}
            showSearch
            value={taskPositionToResetUpto}
            filterOption
            optionFilterProp="label"
            placeholder="Select step/task"
            onChange={(value) => {
              setTaskPositionToResetUpto(value);
            }}
            options={mdsaTaskResetOptions.length > 0 ? mdsaTaskResetOptions : undefined}
          />
        </Modal>
        <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center' }}>
          <h2 style={{ display: 'inline', margin: 0, marginRight: 5 }}>MDSA-{id}</h2>
          {mdsaData && mdsaData.status === Enum_Mdsa_Status_Enum.Completed ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Completed
            </Tag>
          ) : (
            <>
              <Tag color="processing">Ongoing</Tag>
              <Button type="default" style={{ marginLeft: 15 }}>
                <Link to={`/mdsa/${id}/edit`}>Edit Info</Link>
              </Button>
              {/* We will disable reset btn if any task/analysis is in progress */}
              <Button
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  setShowResetMdsaUptoTaskModal(true);
                }}
                disabled={isAnyMdsaTaskInProgress}
              >
                Reset
              </Button>
            </>
          )}
        </div>

        <h3>{mdsaData && mdsaData.title ? mdsaData.title : ''}</h3>
        <h4>{mdsaData && mdsaData.description ? mdsaData.description : ''}</h4>
        <Descriptions size="small" column={2}>
          <Descriptions.Item label="Root MDSA">
            {mdsaData && mdsaData.source_root_mdsa_id ? (
              <Link to={`/mdsa/${mdsaData.source_root_mdsa_id}`}>
                MDSA-{mdsaData.source_root_mdsa_id}
              </Link>
            ) : (
              '-'
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Parent MDSA">
            {mdsaData && mdsaData.source_mdsa_id ? (
              <Link to={`/mdsa/${mdsaData.source_mdsa_id}`}>MDSA-{mdsaData.source_mdsa_id}</Link>
            ) : (
              '-'
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Started On">
            {Array.isArray(mdsaTasksData) &&
            mdsaTasksData.length > 0 &&
            mdsaTasksData[0].started_at ? (
              moment(mdsaTasksData[0].started_at).format('DD MMM YYYY [at] LT')
            ) : (
              <span style={{ fontStyle: 'italic' }}>Analysis not started</span>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Tags" style={{ width: 350 }}>
            {mdsaData && mdsaData.tags ? mdsaData.tags : 'N/A'}
          </Descriptions.Item>
        </Descriptions>

        <div style={{ display: 'flex', marginBottom: 20 }}>
          <EcFormItem
            label="Remarks"
            labelClassName="ant-descriptions-item-label"
            name="remarks"
            errors={errors}
            control={control}
            as={
              <Input.TextArea allowClear className={styles.mdsaDashboardTextAreaInput} rows={4} />
            }
          />
          <Button
            type="default"
            onClick={handleSubmit((formData) => {
              setShowRemarksButtonLoading(true);
              updateMdsaRemark({ variables: { id: parseInt(id, 10), remarks: formData.remarks } })
                .then(() => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  message.success('Remarks saved successfully');
                  setShowRemarksButtonLoading(false);
                })
                .catch((err) => {
                  setShowRemarksButtonLoading(false);
                  logger(err);
                });
            })}
            loading={showRemarksButtonLoading}
            style={{ alignSelf: 'flex-end', marginLeft: 10 }}
          >
            Save Remarks
          </Button>
        </div>

        {mdsaData?.primary_feature_table_file_name ? (
          <Alert
            message={
              <span>
                <strong>{mdsaData?.primary_feature_table_file_name}</strong> - This is the current
                primary feature table and will be used for for all analysis.
              </span>
            }
            type="info"
            showIcon
            style={{ maxWidth: 750 }}
          />
        ) : null}

        {mdsaData && Array.isArray(mdsaData.tasks) && mdsaData.tasks.length > 0 ? (
          <Table<MdsaTaskType>
            bordered
            dataSource={mdsaData.tasks}
            rowKey="id"
            size="small"
            style={{ marginTop: 25 }}
            pagination={false}
            rowClassName={(record) => (taskStatus[record.key] === 'N/A' ? styles.disabledRow : '')}
            tableLayout="fixed"
          >
            <Column
              title="Task/Analysis"
              dataIndex="key"
              key="key"
              render={(value: Enum_Mdsa_Task_Key_Enum) => mdsaTaskKeyNameMap[value]}
              width={200}
            />
            <Column<MdsaTaskType>
              title="Status"
              dataIndex="status"
              key="status"
              render={(value, record) => taskStatus[record.key]}
              width={100}
            />
            <Column<MdsaTaskType>
              title="Actions"
              dataIndex="actions"
              key="actions"
              render={(value, record) => renderTableRowActions(record)}
              width={180}
            />
            <Column<MdsaTaskType>
              title="Files"
              dataIndex="files"
              key="files"
              render={(value, record) => renderTableRowFiles(record)}
              width={200}
            />
          </Table>
        ) : null}
        {mdsaData && mdsaData.status === Enum_Mdsa_Status_Enum.Open ? (
          <Button
            type="primary"
            style={{ marginTop: 20 }}
            loading={markAsCompleteButtonLoading}
            onClick={markAsCompleteButtonOnClickFunction}
            disabled
          >
            Mark as Completed
          </Button>
        ) : null}
      </>
    </AppLayout>
  );
};

export default MdsaDashboard;
