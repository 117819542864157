import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button, message } from 'antd';
import { useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useMutation, ApolloError, useQuery, Reference, useApolloClient } from '@apollo/client';
import {
  AddUserMutation,
  AddUserMutationVariables,
  User_Insert_Input,
  AllUsersQuery,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import EcFormItem from '../components/EcFormItem';
import { logger } from '../utils/helpers';

const addUserMutation = loader('../graphql/mutations/addUserMutation.graphql');
const allUsersQuery = loader('../graphql/queries/allUsersQuery.graphql');

/* React Functional component */
const CreateUser: React.FC = () => {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  /* State to store loading state of submit button */
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  /* Mutation to add new user */
  const [addUser] = useMutation<AddUserMutation, AddUserMutationVariables>(addUserMutation);

  /* Query to fecth all users data for updating cache */
  const { data } = useQuery<AllUsersQuery>(allUsersQuery);

  /* useform declaration with default values  */
  const { handleSubmit, errors, control } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    mode: 'onSubmit',
  });
  return (
    <AppLayout screenTitle="Create new User" hideBackBtn>
      <form
        onSubmit={handleSubmit((newUserData) => {
          setIsSubmitLoading(true);
          addUser({
            variables: {
              object: {
                email: newUserData.email,
                first_name: newUserData.firstName,
                last_name: newUserData.lastName,
              } as User_Insert_Input,
            },
            update: (cache, { data: addedUserData }) => {
              /* Updating cache of allUsers query */
              if (data && data.users) {
                cache.writeQuery({
                  query: allUsersQuery,
                  data: {
                    users: [...data.users, addedUserData],
                  },
                });
              }
            },
          })
            .then(() => {
              setIsSubmitLoading(false);
              navigate('/users');
            })
            .catch((error: ApolloError) => {
              setIsSubmitLoading(false);
              if (
                error.message ===
                'Uniqueness violation. duplicate key value violates unique constraint "user_email_key"'
              ) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.error('User with this email already exists');
              } else {
                logger(error);
              }
            });
        })}
      >
        <div style={{ marginBottom: 20, width: 300 }}>
          <EcFormItem
            label="First Name"
            name="firstName"
            control={control}
            rules={{ required: 'Please enter first name and try again' }}
            errors={errors}
            as={<Input allowClear />}
          />
        </div>
        <div style={{ marginBottom: 20, width: 300 }}>
          <EcFormItem
            label="Last Name"
            name="lastName"
            control={control}
            rules={{ required: 'Please enter last name and try again' }}
            errors={errors}
            as={<Input allowClear />}
          />
        </div>
        <div style={{ marginBottom: 20, width: 300 }}>
          <EcFormItem
            label="Email"
            name="email"
            control={control}
            rules={{
              required: 'Please enter email and try again',
              pattern: {
                // eslint-disable-next-line no-useless-escape
                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid email address',
              },
            }}
            errors={errors}
            as={<Input allowClear />}
          />
        </div>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSubmitLoading}
          style={{ marginTop: 20 }}
        >
          Submit
        </Button>
      </form>
    </AppLayout>
  );
};

export default CreateUser;
