import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin, Button, message, Checkbox, Radio, InputNumber } from 'antd';
import { mdsaTasksInitialValues, positiveIntegerValidation } from '../utils/globals';
import {
  UpdateAndStartMdsaTaskMutationVariables,
  UpdateAndStartMdsaTaskMutation,
  MdsaInParam,
  Enum_Mdsa_Task_Key_Enum,
  MdsaTaskInParamsWithPrimaryTableNameQuery,
  MdsaTaskInParamsWithPrimaryTableNameQueryVariables,
} from '../graphql/graphql-types';
import styles from './ScreenStyles.module.scss';
import AppLayout from '../components/AppLayout';
import EcFormItem from '../components/EcFormItem';

// call to graphql file
const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);
const mdsaTaskInParamsWithPrimaryTableNameQuery = loader(
  '../graphql/queries/mdsaTaskInParamsWithPrimaryTableNameQuery.graphql',
);

// type definition for MdsaPicrustForm
type MdsaPicrustFormDataType = {
  // to store placement tool value
  placementTool: PlacementToolType | null;
  // to store in traits value
  inTraits: Array<InTraitsType>;
  // to store stratified value
  stratified: boolean | null;
  // to store per Sequence Contrib
  perSequenceContrib: boolean | null;
  // to store max nsti value
  maxNsti: number | null;
  // to store min reads value
  minReads: number | null;
  // to store min samples value
  minSamples: number | null;
  // to store hsp method value
  hspMethod: HspMethodsType | null;
};

/* type of all possible placement tool used in mdsa picrust2 */
type PlacementToolType = 'epa-ng' | 'sepp';

/* type of all possible hsp methods used in mdsa picrust2 */
type HspMethodsType = 'mp' | 'emp_prob' | 'pic' | 'scp' | 'subtree_average';

/* type of all possible intraits used in mdsa picrust2 */
type InTraitsType = 'COG' | 'EC' | 'KO' | 'PFAM' | 'TIGRFAM';

// placementTools options for MdsaPicrust2
const placementToolsOptions: Array<{
  label: PlacementToolType;
  value: PlacementToolType;
}> = [
  { label: 'epa-ng', value: 'epa-ng' },
  { label: 'sepp', value: 'sepp' },
];

// inTraits options for MdsaPicrust2
const inTraitsOptions: Array<{
  label: InTraitsType;
  value: InTraitsType;
}> = [
  { label: 'COG', value: 'COG' },
  { label: 'EC', value: 'EC' },
  { label: 'KO', value: 'KO' },
  { label: 'PFAM', value: 'PFAM' },
  { label: 'TIGRFAM', value: 'TIGRFAM' },
];

// hsp options for MdsaPicrust2
const hspMethodOptions: Array<{
  label: HspMethodsType;
  value: HspMethodsType;
}> = [
  { label: 'mp', value: 'mp' },
  { label: 'emp_prob', value: 'emp_prob' },
  { label: 'pic', value: 'pic' },
  { label: 'scp', value: 'scp' },
  { label: 'subtree_average', value: 'subtree_average' },
];

// obtains  mdsaPicrust2 task details
const mdsaPicrust2InitialValues = mdsaTasksInitialValues.find(
  (item) => item.key === Enum_Mdsa_Task_Key_Enum.Picrust2,
);

// stores the default values to set
const mdsaPicrust2FormDefaultValues: MdsaPicrustFormDataType = {
  placementTool: null,
  inTraits: [],
  stratified: null,
  perSequenceContrib: null,
  maxNsti: null,
  minReads: null,
  minSamples: null,
  hspMethod: null,
};

// sets the default value obtained from mdsaPicrust2InitialValues
if (
  mdsaPicrust2InitialValues &&
  Array.isArray(mdsaPicrust2InitialValues.in_params) &&
  mdsaPicrust2InitialValues.in_params.length > 0
) {
  (mdsaPicrust2InitialValues.in_params as MdsaInParam[]).forEach((item) => {
    if (item.key === '--placement_tool') {
      mdsaPicrust2FormDefaultValues.placementTool = item.rawValue as PlacementToolType;
    }
    if (item.key === '--in_traits') {
      mdsaPicrust2FormDefaultValues.inTraits = item.rawValue as Array<InTraitsType>;
    }
    if (item.key === '--max_nsti') {
      mdsaPicrust2FormDefaultValues.maxNsti = item.rawValue as number | null;
    }
    if (item.key === '--min_reads') {
      mdsaPicrust2FormDefaultValues.minReads = item.rawValue as number | null;
    }
    if (item.key === '--min_samples') {
      mdsaPicrust2FormDefaultValues.minSamples = item.rawValue as number | null;
    }
    if (item.key === '--hsp_method') {
      mdsaPicrust2FormDefaultValues.hspMethod = item.rawValue as HspMethodsType;
    }
  });
}

// react functional component
const MdsaPicrust2: React.FC = () => {
  const { id, taskId } = useParams();
  // state to set loading on start analysis button while running mutation
  const [startAnalysisButtonLoading, setStartAnalysisButtonLoading] = useState<boolean>(false);
  // state to set start in update and start mdsa task mutation
  const [started_at] = useState(new Date());

  // call to MdsaTaskInParamsWithPrimaryTableName query
  const { data, loading, error } = useQuery<
    MdsaTaskInParamsWithPrimaryTableNameQuery,
    MdsaTaskInParamsWithPrimaryTableNameQueryVariables
  >(mdsaTaskInParamsWithPrimaryTableNameQuery, {
    variables: {
      taskId: parseInt(taskId, 10),
    },
    fetchPolicy: 'network-only',
  });

  // Mutation to update and start mdsa task
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  // useForm declaration
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, control, errors, setValue } = useForm<MdsaPicrustFormDataType>({
    resolver: yupResolver(
      yup.object().shape({
        maxNsti: yup
          .number()
          .typeError('Only positive values are allowed.')
          .nullable()
          .notRequired()
          .positive('Only positive values are allowed.')
          .moreThan(0, 'Value should be greater than 0'),
        minSamples: positiveIntegerValidation.moreThan(1, 'Value should be greater than 1'),
        minReads: positiveIntegerValidation.moreThan(1, 'Value should be greater than 1'),
        placementTool: yup.string().required('Please select --placement_tool and try again'),
        inTraits: yup.array().min(1, 'Please select --in_traits and try again'),
        hspMethod: yup.string().required('Please select --hsp_method and try again'),
      }),
    ),
    mode: 'onChange',
    defaultValues: mdsaPicrust2FormDefaultValues,
  });

  // obtained navigation from hook
  const navigate = useNavigate();

  /* Mdsa task in params data */
  const mdsaTaskInParams = data?.task?.in_params as Array<MdsaInParam>;
  /* Primary feature table file name from mdsa data */
  const primaryFeatureTableFileName = data?.task?.mdsa.primary_feature_table_file_name;

  // obtains initial values and sets it to fileds
  useEffect(() => {
    if (Array.isArray(mdsaTaskInParams) && mdsaTaskInParams.length > 0) {
      mdsaTaskInParams.forEach((param) => {
        if (param.key === '--placement_tool' && param.value) {
          setValue('placementTool', param.value as string);
        } else if (param.key === '--in_traits' && param.value) {
          setValue('inTraits', param.rawValue as Array<InTraitsType>);
        } else if (param.key === '--stratified') {
          setValue('stratified', param.enabled as boolean | null);
        } else if (param.key === '--per_sequence_contrib') {
          setValue('perSequenceContrib', param.enabled as boolean | null);
        } else if (param.key === '--max_nsti' && param.value) {
          setValue('maxNsti', param.rawValue as number);
        } else if (param.key === '--min_reads' && param.value) {
          setValue('minReads', param.rawValue as number);
        } else if (param.key === '--min_samples' && param.value) {
          setValue('minSamples', param.rawValue as number);
        } else if (param.key === '--hsp_method') {
          setValue('hspMethod', param.value as string);
        }
      });
    }
  }, [mdsaTaskInParams, setValue]);

  // if error in fetching query
  if (error) {
    return (
      <AppLayout screenTitle="Microbiome downstream analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
      </AppLayout>
    );
  }
  // while loading query data
  if (loading) {
    return (
      <AppLayout screenTitle="Microbiome downstream analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  return (
    <AppLayout screenTitle="Microbiome downstream analysis">
      <>
        <h2 style={{ marginBottom: 20 }}>PICRUSt2</h2>
        <p style={{ fontStyle: 'italic' }}>
          Feature table: <strong>{primaryFeatureTableFileName}</strong> will be used for analysis.
        </p>
        <form
          onSubmit={handleSubmit(
            ({
              placementTool,
              inTraits,
              stratified,
              perSequenceContrib,
              maxNsti,
              minReads,
              minSamples,
              hspMethod,
            }: MdsaPicrustFormDataType) => {
              // If the task does not have in_params defined
              if (
                !data ||
                !data.task ||
                !Array.isArray(data.task.in_params) ||
                data.task.in_params.length === 0
              ) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.error('Task does not have in_params');
                return;
              }

              setStartAnalysisButtonLoading(true);

              // obtains the initial value of task  picrust2
              const defaultInParams = mdsaTasksInitialValues.find(
                (task) => task.key === Enum_Mdsa_Task_Key_Enum.Picrust2,
              )?.in_params as Array<MdsaInParam>;

              // const to store immutable copy of in_params
              const taskInParamImmutableConst = JSON.parse(
                JSON.stringify(defaultInParams),
              ) as Array<MdsaInParam>;

              // Set the in_params value according to the inputs provided by the user
              /* eslint-disable no-param-reassign */
              taskInParamImmutableConst.forEach((item) => {
                if (item.key === '--placement_tool' && placementTool) {
                  item.rawValue = placementTool as string;
                  item.value = placementTool as string;
                }
                if (item.key === '--in_traits' && inTraits) {
                  item.rawValue = inTraits;
                  item.value = inTraits.join(',');
                }
                if (item.key === '--stratified' && stratified) {
                  item.enabled = true;
                }
                if (item.key === '--per_sequence_contrib' && perSequenceContrib) {
                  item.enabled = true;
                }
                if (item.key === '--max_nsti' && maxNsti) {
                  item.enabled = true;
                  item.value = maxNsti.toString();
                  item.rawValue = maxNsti;
                }
                if (item.key === '--min_reads' && minReads) {
                  item.enabled = true;
                  item.value = minReads.toString();
                  item.rawValue = minReads;
                }
                if (item.key === '--min_samples' && minSamples) {
                  item.enabled = true;
                  item.value = minSamples.toString();
                  item.rawValue = minSamples;
                }
                if (item.key === '--hsp_method' && hspMethod) {
                  item.value = hspMethod as string;
                  item.rawValue = hspMethod as string;
                }
              });

              // call to mutation
              updateAndStartMdsaTask({
                variables: {
                  taskId: parseInt(taskId, 10),
                  taskUpdateInput: {
                    started_at,
                    in_params: taskInParamImmutableConst,
                  },
                },
              })
                .then(() => {
                  setStartAnalysisButtonLoading(false);
                  navigate(`/mdsa/${parseInt(id, 10)}`);
                })
                .catch((err) => {
                  setStartAnalysisButtonLoading(false);
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  message.error(err);
                });
            },
          )}
        >
          <EcFormItem
            label="--placement_tool"
            name="placementTool"
            control={control}
            errors={errors}
            render={({ onChange, value }) => (
              <Radio.Group
                value={value as string}
                onChange={(e) => onChange(e.target.value)}
                className={styles.input}
                key={value as string}
              >
                {placementToolsOptions.map((item) => (
                  <Radio key={item.value as string} value={item.value as string}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            )}
            containerStyle={{ marginTop: 15 }}
          />

          <EcFormItem
            label="--in_traits"
            name="inTraits"
            control={control}
            errors={errors}
            render={({ onChange, value }) => (
              <Checkbox.Group
                value={value as Array<string>}
                onChange={(e) => onChange(e)}
                className={styles.input}
                key={value as string}
              >
                {inTraitsOptions.map((item) => (
                  <Checkbox key={item.value as string} value={item.value as string}>
                    {item.label}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            )}
            containerStyle={{ marginTop: 15 }}
          />

          <EcFormItem
            name="stratified"
            control={control}
            errors={errors}
            render={({ onChange, value }) => (
              <Checkbox
                onChange={(e) => onChange(e.target.checked)}
                checked={value as boolean}
                className={styles.input}
              >
                --stratified
              </Checkbox>
            )}
            containerStyle={{ marginTop: 15 }}
          />

          <EcFormItem
            name="perSequenceContrib"
            control={control}
            errors={errors}
            render={({ onChange, value }) => (
              <Checkbox
                onChange={(e) => onChange(e.target.checked)}
                checked={value as boolean}
                className={styles.input}
              >
                --per_sequence_contrib
              </Checkbox>
            )}
            containerStyle={{ marginTop: 15 }}
          />

          <EcFormItem
            label="--max_nsti"
            name="maxNsti"
            control={control}
            errors={errors}
            as={
              <InputNumber
                min={0.1}
                style={{ width: 200, marginTop: 5 }}
                className={styles.input}
                precision={2}
              />
            }
            containerStyle={{ marginTop: 15 }}
          />

          <EcFormItem
            label="--min_reads"
            name="minReads"
            control={control}
            errors={errors}
            min={2}
            as={<InputNumber style={{ width: 200, marginTop: 5 }} />}
            containerStyle={{ marginTop: 15 }}
          />

          <div className={styles.container}>
            <EcFormItem
              label="--min_samples"
              name="minSamples"
              control={control}
              errors={errors}
              min={2}
              as={<InputNumber style={{ width: 200, marginTop: 5, marginBottom: 10 }} />}
              containerStyle={{ marginTop: 15 }}
            />
          </div>
          <div className={styles.container}>
            <EcFormItem
              label="--hsp_method"
              name="hspMethod"
              control={control}
              errors={errors}
              render={({ onChange, value }) => (
                <Radio.Group
                  value={value as string}
                  onChange={(e) => onChange(e.target.value)}
                  className={styles.input}
                  key={value as string}
                >
                  {hspMethodOptions.map((item) => (
                    <Radio key={item.value} value={item.value as string}>
                      {item.label}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
              containerStyle={{ marginTop: 15 }}
            />
          </div>
          <Button
            type="primary"
            htmlType="submit"
            loading={startAnalysisButtonLoading}
            style={{ marginTop: 20 }}
          >
            Start Analysis
          </Button>
        </form>
      </>
    </AppLayout>
  );
};

export default MdsaPicrust2;
