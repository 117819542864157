import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import moment from 'moment';
import { Modal, Descriptions } from 'antd';
import {
  DataSyncDescribeTaskExecutionQueryVariables,
  DataSyncDescribeTaskExecutionQuery,
} from '../graphql/graphql-types';

/* FileUploadInProgressModal prop type */
type FileUploadInProgressModalPropsType = {
  taskExecArn: string;
  setTaskExecArn: React.Dispatch<React.SetStateAction<string | null>>;
};

const dataSyncDescribeTaskExecutionQuery = loader(
  '../graphql/queries/dataSyncDescribeTaskExecutionQuery.graphql',
);

/* variable to store fetch data time interval */
let fetchDataTimeInterval: ReturnType<typeof setInterval>;

/* React functional component */
const FileUploadInProgressModal: React.FC<FileUploadInProgressModalPropsType> = ({
  taskExecArn,
  setTaskExecArn,
}) => {
  /* Query to fetch describe task execution data */
  const { data, refetch } = useQuery<
    DataSyncDescribeTaskExecutionQuery,
    DataSyncDescribeTaskExecutionQueryVariables
  >(dataSyncDescribeTaskExecutionQuery, {
    variables: {
      taskExecutionArn: taskExecArn,
    },
    fetchPolicy: 'network-only',
  });

  /* Variable to store describe task execution data fetched using query */
  const describeTaskExecutionData = data?.dataSyncDescribeTaskExecution;

  /**
   * This useEffect is used to set 5 seconds interval and refetch query after this time interval
   * to get updated data
   */
  useEffect(() => {
    /* setTimeout is used to delay first interval by 5 sec */
    setTimeout(() => {
      fetchDataTimeInterval = setInterval(() => {
        /* refetch query after every 5 seconds */
        if (refetch) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          refetch();
        }
      }, 5000);
    }, 5000);
    return () => {
      /* Clear interval after component un-mounts */
      clearInterval(fetchDataTimeInterval);
    };
  }, [refetch]);

  return (
    <Modal
      visible={!!taskExecArn}
      centered
      onCancel={() => {
        clearInterval(fetchDataTimeInterval);
        setTaskExecArn(null);
      }}
      footer={null}
    >
      <h2>File upload in progress</h2>
      <Descriptions column={1} size="small">
        <Descriptions.Item label="Status">
          {describeTaskExecutionData && describeTaskExecutionData.status
            ? describeTaskExecutionData.status
            : 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Start time">
          {describeTaskExecutionData && describeTaskExecutionData.startTime
            ? moment(new Date(describeTaskExecutionData.startTime)).format('DD MMM YYYY [at] LT')
            : 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Total files to be uploaded">
          {describeTaskExecutionData &&
          describeTaskExecutionData.estimatedFilesToTransfer !== null &&
          describeTaskExecutionData.estimatedFilesToTransfer !== undefined
            ? describeTaskExecutionData.estimatedFilesToTransfer
            : 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Files uploaded">
          {describeTaskExecutionData &&
          describeTaskExecutionData.filesTransferred !== null &&
          describeTaskExecutionData.filesTransferred !== undefined
            ? describeTaskExecutionData.filesTransferred
            : 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Remaining files">
          {describeTaskExecutionData &&
          describeTaskExecutionData.filesTransferred !== null &&
          describeTaskExecutionData.filesTransferred !== undefined &&
          describeTaskExecutionData.estimatedFilesToTransfer !== null &&
          describeTaskExecutionData.estimatedFilesToTransfer !== undefined
            ? describeTaskExecutionData.estimatedFilesToTransfer -
              describeTaskExecutionData.filesTransferred
            : 'N/A'}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default FileUploadInProgressModal;
