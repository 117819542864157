import React from 'react';
import { InfoCircleFilled } from '@ant-design/icons';

/* InfoBox component prop type */
type InfoBoxPropType = {
  message: string;
};

/* React functional component */
const InfoBox: React.FC<InfoBoxPropType> = ({ message }) => {
  return (
    <div
      style={{
        backgroundColor: '#DEEBFF',
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
        borderRadius: 3,
      }}
    >
      <InfoCircleFilled style={{ color: '#0052CC', marginRight: 10, fontSize: 20 }} />
      <span>{message}</span>
    </div>
  );
};

export default InfoBox;
