import firebase from 'firebase/app';
import 'firebase/auth';
import { logger } from './helpers';

// Typescript type for firebase config
type FirebaseConfig = {
  [key: string]: {
    apiKey: string;
    authDomain: string;
    databaseURL?: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId?: string;
  };
};

// Firebase config
const firebaseConfig: FirebaseConfig = {
  development: {
    apiKey: 'AIzaSyAj2Iz18tGI1JPFAgKS8TpbyS4IdOl_jkI',
    authDomain: 'ecp29-development.firebaseapp.com',
    projectId: 'ecp29-development',
    storageBucket: 'ecp29-development.appspot.com',
    messagingSenderId: '962224667834',
    appId: '1:962224667834:web:cc1362415160d7feb41683',
  },
  // staging: {},
  production: {
    apiKey: 'AIzaSyA7UEd8a1RG5XQgs0DlUDElpyMKRJTeWxo',
    authDomain: 'ecp29-production.firebaseapp.com',
    projectId: 'ecp29-production',
    storageBucket: 'ecp29-production.appspot.com',
    messagingSenderId: '483716650837',
    appId: '1:483716650837:web:447a172889141c782701dc',
  },
};

export const app = firebase.initializeApp(
  firebaseConfig[process.env.REACT_APP_ENVIRONMENT || 'development'],
);

export const firebaseAuth = firebase.auth();

// if user is logging in using desktop app then setting setPersistence to NONE, if logging in using browser then setting it to LOCAL
firebaseAuth
  .setPersistence(
    window.ecp29Desktop && window.ecp29Desktop.isDesktopApp
      ? firebase.auth.Auth.Persistence.NONE
      : firebase.auth.Auth.Persistence.LOCAL,
  )
  .catch((err) => {
    logger(err);
  });

export default firebase;
