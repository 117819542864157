import React from 'react';
import { Button, Row, Col, Input, Radio } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useFieldArray, useForm } from 'react-hook-form';
import papa from 'papaparse';
import FileSaver from 'file-saver';
import { MetadataColumn, MetadataColumnTypeEnum, SrSamples } from '../graphql/graphql-types';
import EcFormItem from './EcFormItem';
import { SRUploadMetadataScreenType } from '../utils/types';
import { notAllowedColumnNames } from '../utils/globals';
import AlertBox from './AlertBox';

/* Prop type of SrMetadala columns */
type SrMetadataColumnsPropType = {
  /* Prop to check for page is in create mode or edit mode */
  mode: 'create' | 'edit';
  /* Sequencing run id */
  srId: string;
  /* setState to update current component */
  updateCurrentScreen: (currentScreen: SRUploadMetadataScreenType) => void;
  /* Project metadata columns for sequencing run */
  projectMetadataColumns: Array<MetadataColumn>;
  /* State to store Metadata Columns of upload metadata */
  metadataColumns: Array<MetadataColumn> | undefined;
  /* set state to update metadata columns of upload metadata */
  updateMetadataColumns: (currentFormData: Array<MetadataColumn>) => void;
  /* Link fastq out data samples */
  linkFastqSamples: Array<SrSamples>;
  /* If screen opens in edit mode initial value of meatdata columns to render */
  initialMetadataColumns: Array<MetadataColumn>;
  /* If screen opens in edit mode download url of already uploaded metadata file  */
  currentMetadataFileUrl: string | null;
};

/* React Functional component */
const SrMetadataColumns: React.FC<SrMetadataColumnsPropType> = ({
  mode,
  updateCurrentScreen,
  projectMetadataColumns,
  metadataColumns,
  updateMetadataColumns,
  linkFastqSamples,
  srId,
  initialMetadataColumns,
  currentMetadataFileUrl,
}) => {
  const { control, errors, handleSubmit } = useForm<{
    qiime_metadata_columns: Array<MetadataColumn>;
  }>(
    mode === 'create'
      ? {
          defaultValues: {
            qiime_metadata_columns:
              Array.isArray(metadataColumns) && metadataColumns.length > 0
                ? metadataColumns
                : projectMetadataColumns,
          },
        }
      : {
          defaultValues: {
            qiime_metadata_columns:
              Array.isArray(metadataColumns) && metadataColumns.length > 0
                ? metadataColumns
                : initialMetadataColumns,
          },
        },
  );

  /* useFieldArray to store metadata column values */
  const { fields, append: appendMetadaColumn, remove: removeMetadataColumn } = useFieldArray<
    MetadataColumn
  >({
    control,
    name: 'qiime_metadata_columns',
  });

  /* Function to create tsv file data and then creates a file which user can download */
  const handleDownloadTemplateTsv = (dataToConvert: Array<MetadataColumn>) => {
    /* Creating data which can further used to create template tsv file in the required format */
    const samplesDataToParse = [['sample-id'], ['#q2:types']];
    /**
     *  Storing all column names in first array element of sampleDataToParse with first value fixed as sample-id so that it can be used as header in tsv file
     *  Similarly storing all types in second array element of sampleDataToParse with first value fixed as #q2:types
     */
    dataToConvert.forEach((item) => {
      samplesDataToParse[0].push(item.column);
      samplesDataToParse[1].push(item.type);
    });

    /* Storing all link fastq sample id's as a new array element in sampleDataToParse */
    linkFastqSamples.forEach((item) => {
      samplesDataToParse.push([item.sample_id]);
    });

    /* Unparsing created data using papaparse to store it in tsv file format */
    const unparsedSampleMetadata = papa.unparse(
      {
        data: samplesDataToParse,
        fields: [],
      },
      {
        delimiter: '\t',
      },
    );

    /* File blob create for file saver to save file */
    const fileBlob = new Blob([unparsedSampleMetadata]);

    /* Saving file on user's device using saveAs */
    FileSaver.saveAs(fileBlob, `SR-${srId}_metadata_template.tsv`);
  };

  return (
    <>
      <h3>Metadata Columns</h3>

      <AlertBox />

      {fields.map((item, index) => {
        return (
          <Row gutter={[35, 15]} key={item.id}>
            <Col sm={24} lg={5}>
              <EcFormItem
                name={`qiime_metadata_columns[${index}].column`}
                control={control}
                defaultValue={item.column}
                rules={{
                  required: 'Please enter column name and try again',
                  pattern: {
                    value: /^[a-z][a-z\d-]*[a-z\d]$/,
                    message: 'Only lower case alphabets, hyphen (-) and numbers are allowed',
                  },
                  validate: (value) => {
                    if (notAllowedColumnNames.includes(value)) {
                      return 'You cannot use ‘id’, ‘sampleid’, ‘sample id’, ‘sample-id’ as column names';
                    }
                    return true;
                  },
                }}
                errors={errors}
                as={<Input placeholder="Column Name " allowClear />}
              />
            </Col>
            <Col
              sm={24}
              lg={5}
              style={{
                alignSelf: 'center',
                padding: 5,
                marginBottom: 2,
                paddingRight: 0,
              }}
            >
              <EcFormItem
                name={`qiime_metadata_columns[${index}].type`}
                control={control}
                defaultValue={item.type}
                render={({ onChange, value }): JSX.Element => (
                  <Radio.Group
                    onChange={(e) => onChange(e.target.value)}
                    value={value as string}
                    defaultValue="categorical"
                    style={{ paddingLeft: 10 }}
                  >
                    <Radio value="categorical">Categorical</Radio>
                    <Radio value="numeric" style={{ paddingLeft: 5 }}>
                      Numeric
                    </Radio>
                  </Radio.Group>
                )}
              />
            </Col>
            {fields.length > 1 ? (
              <MinusCircleOutlined
                style={{
                  alignSelf: 'center',
                  color: 'grey',
                  margin: 0,
                }}
                onClick={(): void => {
                  removeMetadataColumn(index);
                }}
              />
            ) : null}
          </Row>
        );
      })}

      <Button
        style={{ marginBottom: 10 }}
        icon={<PlusOutlined style={{ color: 'grey' }} />}
        onClick={(): void => {
          appendMetadaColumn({
            column: '',
            type: MetadataColumnTypeEnum.Categorical,
          });
        }}
      >
        New Column
      </Button>

      <div style={{ marginTop: 25 }}>
        {mode === 'edit' && currentMetadataFileUrl ? (
          <Button type="primary" href={currentMetadataFileUrl} style={{ marginRight: 35 }}>
            Download current metadata file
          </Button>
        ) : null}
        <Button
          type="primary"
          onClick={handleSubmit((data) => {
            const qiimeData = data?.qiime_metadata_columns;
            if (qiimeData) {
              handleDownloadTemplateTsv(qiimeData);
            }
          })}
        >
          Download template tsv
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: 35 }}
          onClick={handleSubmit((data) => {
            updateMetadataColumns(data.qiime_metadata_columns);
            updateCurrentScreen('metadataUploadFile');
          })}
        >
          Proceed
        </Button>
      </div>
    </>
  );
};

export default SrMetadataColumns;
