import React, { useState } from 'react';
import { Spin, Descriptions, Button } from 'antd';
import { loader } from 'graphql.macro';
import { useQuery, useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  SrTaskResultDataQueryVariables,
  SrTaskResultDataQuery,
  SrTaskInputParams,
  TaskOutFiles,
  GetFilesUrlOutput,
  GetFilesUrlQueryVariables,
  GetFilesUrlQuery,
  OutFile,
} from '../graphql/graphql-types';
import { logger } from '../utils/helpers';
import AppLayout from '../components/AppLayout';

// call to graphql files
const srTaskResultDataQuery = loader('../graphql/queries/srTaskResultDataQuery.graphql');
const getFilesUrlQuery = loader('../graphql/queries/getFilesUrlQuery.graphql');

// react functional component
const SRResult: React.FC = () => {
  const { taskId } = useParams();

  // useState to store download url of files
  const [downloadUrls, setDownloadUrls] = useState<Array<GetFilesUrlOutput>>([]);

  // apollo Client
  const apolloClient = useApolloClient();

  // query to get sr task results data
  const { data, loading, error } = useQuery<SrTaskResultDataQuery, SrTaskResultDataQueryVariables>(
    srTaskResultDataQuery,
    {
      variables: { id: parseInt(taskId, 10) },
      onCompleted: ({ sr_task_by_pk }) => {
        // const to store out_files data from query
        const queryOutFilesData = sr_task_by_pk?.out_files as TaskOutFiles;

        if (Array.isArray(queryOutFilesData) && queryOutFilesData.length > 0) {
          // call to query to fetch urls based on s3key
          apolloClient
            .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
              query: getFilesUrlQuery,
              variables: {
                keys: queryOutFilesData.map((file) => file.s3_key),
              },
            })
            .then((res) => {
              const { getFilesUrl } = res.data;
              // array to store generated url respective to s3key which will then pass to setDownloadUrl
              const filesUrlArray: Array<GetFilesUrlOutput> = [];
              if (getFilesUrl) {
                getFilesUrl.forEach((e) => {
                  const key = e?.key;
                  const url = e?.url;
                  if (url && key) {
                    filesUrlArray.push({ key, url });
                  }
                });
                setDownloadUrls(filesUrlArray);
              }
            })
            .catch((err) => logger(err));
        }
      },
    },
  );
  //  query loading indicator while fetching data
  if (loading) {
    return (
      <AppLayout screenTitle="Sequencing Run - Results">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }
  // if error occur while fetching query data
  if (error) {
    return (
      <AppLayout screenTitle="Sequencing Run - Results">
        <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
      </AppLayout>
    );
  }

  // const to store in_param data from query
  const inParamData = data?.sr_task_by_pk?.in_params as SrTaskInputParams;
  // const to store out_files data from query
  const outFilesData = data?.sr_task_by_pk?.out_files as TaskOutFiles;

  // used to store sequencing run data
  const seqRunData = data?.sr_task_by_pk?.sequencing_run;

  return (
    <AppLayout screenTitle="Sequencing Run - Results">
      <>
        <h2>DADA2</h2>
        {seqRunData && seqRunData.id ? <h3>SR{seqRunData.id}</h3> : null}
        {seqRunData && seqRunData.title ? <h4>{seqRunData.title}</h4> : null}
        {seqRunData && seqRunData.description ? <h4>{seqRunData.description}</h4> : null}

        <Descriptions size="small">
          <Descriptions.Item label="Project">
            {seqRunData && seqRunData.project && seqRunData.project.title
              ? seqRunData.project.title
              : ''}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions title="Files" column={1} bordered style={{ width: 600, marginTop: 5 }}>
          <Descriptions.Item label="Artifacts" style={{ paddingTop: 2, paddingBottom: 2 }}>
            {
              // filter array based on extension if extension is other than 'qzv' filter it to artifact type
              outFilesData
                .filter((item: OutFile) => {
                  const fileExtension = item.name.split('.').pop();
                  return fileExtension !== 'qzv';
                })
                .map((file) => {
                  // const to store url of individual file
                  const fileUrl = downloadUrls.find((item) => item.key === file.s3_key)?.url;
                  return (
                    <Button
                      type="link"
                      href={fileUrl ?? '#'}
                      style={{
                        display: 'flex',
                      }}
                    >
                      {file.name}
                    </Button>
                  );
                })
            }
          </Descriptions.Item>
          <Descriptions.Item label="Visualizations" style={{ paddingTop: 2, paddingBottom: 2 }}>
            {
              // filter array based on extension if extension is 'qzv' filter it to visualization type
              outFilesData
                .filter((item: OutFile) => {
                  const fileExtension = item.name.split('.').pop();
                  return fileExtension === 'qzv';
                })
                .map((file) => {
                  // const to store url of individual file
                  const fileUrl = downloadUrls.find((item) => item.key === file.s3_key)?.url;
                  return (
                    <Button
                      type="link"
                      href={fileUrl ?? '#'}
                      style={{
                        display: 'flex',
                      }}
                    >
                      {file.name}
                    </Button>
                  );
                })
            }
          </Descriptions.Item>
        </Descriptions>

        <Descriptions title="Inputs" style={{ marginTop: 40 }} column={1}>
          {Array.isArray(inParamData) && inParamData.length > 0
            ? inParamData.map((item) => {
                return <Descriptions.Item label={item.key}>{item.value}</Descriptions.Item>;
              })
            : null}
        </Descriptions>
      </>
    </AppLayout>
  );
};

export default SRResult;
