import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin, Button, message, Checkbox } from 'antd';
import { alphaDiversityMetrics, mdsaTasksInitialValues } from '../utils/globals';
import {
  MdsaTaskInParamsQueryVariables,
  MdsaTaskInParamsQuery,
  UpdateAndStartMdsaTaskMutationVariables,
  UpdateAndStartMdsaTaskMutation,
  MdsaInParam,
  Enum_Mdsa_Task_Key_Enum,
} from '../graphql/graphql-types';
import styles from './ScreenStyles.module.scss';
import AppLayout from '../components/AppLayout';
import EcFormItem from '../components/EcFormItem';

// call to graphql file
const mdsaTaskInParamsQuery = loader('../graphql/queries/mdsaTaskInParamsQuery.graphql');
const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);

// react functional component
const MdsaAlphaDiversity: React.FC = () => {
  const { id, taskId } = useParams();
  // state to set loading on start analysis button while running mutation
  const [startAnalysisButtonLoading, setStartAnalysisButtonLoading] = useState<boolean>(false);
  // state to set start in update and start mdsa task mutation
  const [started_at] = useState(new Date());

  // call to mdsaTaskInParam query
  const { data, loading, error } = useQuery<MdsaTaskInParamsQuery, MdsaTaskInParamsQueryVariables>(
    mdsaTaskInParamsQuery,
    {
      variables: {
        id: parseInt(taskId, 10),
      },
      fetchPolicy: 'network-only',
    },
  );

  // Mutation to update and start mdsa task
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  // useForm declaration
  const { handleSubmit, control, errors } = useForm<{ diversityMetrics: Array<string> }>();

  const navigate = useNavigate();

  // if error in fetching query
  if (error) {
    return (
      <AppLayout screenTitle="Microbiome downstream analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
      </AppLayout>
    );
  }
  // while loading query data
  if (loading) {
    return (
      <AppLayout screenTitle="Microbiome downstream analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  /* Mdsa task in params data */
  const mdsaTaskInParams = data?.task?.in_params as Array<MdsaInParam>;

  /* Variable to store diversity metrics initial value if any */
  let diversityMetricsInitialValue: Array<string> = [];

  /* Check whether initially values are present in in_params data */
  if (Array.isArray(mdsaTaskInParams) && mdsaTaskInParams.length > 0) {
    mdsaTaskInParams.forEach((item) => {
      if (item.key === 'diversityMetrics') {
        diversityMetricsInitialValue = item.rawValue as string[];
      }
    });
  }

  return (
    <AppLayout screenTitle="Microbiome downstream analysis">
      <>
        <h2 style={{ marginBottom: 20 }}>Statistical Analysis - Alpha Group Significance</h2>
        <form
          onSubmit={handleSubmit(({ diversityMetrics }) => {
            // If the task does not have in_params defined
            if (
              !data ||
              !data.task ||
              !Array.isArray(data.task.in_params) ||
              data.task.in_params.length === 0
            ) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.error('Task does not have in_params');
              return;
            }

            setStartAnalysisButtonLoading(true);

            // obtains the initial value of task AlphaGroupSignificance
            const defaultInParams = mdsaTasksInitialValues.find(
              (task) => task.key === Enum_Mdsa_Task_Key_Enum.AlphaGroupSignificance,
            )?.in_params as Array<MdsaInParam>;

            // const to store immutable copy of in_params
            const taskInParamImmutableConst = JSON.parse(JSON.stringify(defaultInParams)) as Array<
              MdsaInParam
            >;

            // Set the in_params value according to the inputs provided by the user
            /* eslint-disable no-param-reassign */
            taskInParamImmutableConst.forEach((item) => {
              if (
                Array.isArray(diversityMetrics) &&
                diversityMetrics.length > 0 &&
                item.key === 'diversityMetrics'
              ) {
                item.enabled = true;
                item.rawValue = diversityMetrics;
              }
            });
            /* eslint-enable no-param-reassign */

            // call to mutation
            updateAndStartMdsaTask({
              variables: {
                taskId: parseInt(taskId, 10),
                taskUpdateInput: {
                  started_at,
                  in_params: taskInParamImmutableConst,
                },
              },
            })
              .then(() => {
                setStartAnalysisButtonLoading(false);
                navigate(`/mdsa/${parseInt(id, 10)}`);
              })
              .catch((err) => {
                setStartAnalysisButtonLoading(false);
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.error(err);
              });
          })}
        >
          <EcFormItem
            label="Select diversity metrics"
            name="diversityMetrics"
            control={control}
            rules={{
              required: true,
              validate: (value: Array<string>) =>
                value.length > 0 || 'Please select atleast one diversity matrix and try again',
            }}
            errors={errors}
            defaultValue={diversityMetricsInitialValue}
            render={({ onChange, value }) => (
              <Checkbox.Group value={value as Array<string>} onChange={(e) => onChange(e)}>
                {Array.isArray(alphaDiversityMetrics) && alphaDiversityMetrics.length > 0
                  ? alphaDiversityMetrics.map((item) => (
                      <div style={{ marginTop: 6 }} key={item.value}>
                        <Checkbox value={item.value}>{item.name}</Checkbox>
                      </div>
                    ))
                  : null}
              </Checkbox.Group>
            )}
          />
          <p className={styles.highlightedInstruction}>
            The platform will run alpha-group-significance for each metric selected
          </p>
          <Button
            type="primary"
            htmlType="submit"
            loading={startAnalysisButtonLoading}
            style={{ marginTop: 10 }}
          >
            Start Analysis
          </Button>
        </form>
      </>
    </AppLayout>
  );
};

export default MdsaAlphaDiversity;
