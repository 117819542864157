/* eslint-disable react/no-danger */
import React, { useState, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Table, Descriptions, Spin, Button, Modal } from 'antd';
import { loader } from 'graphql.macro';
import moment from 'moment';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import {
  SeqRunDashboardQuery,
  SeqRunDashboardQueryVariables,
  Sr_Task,
  Enum_Sr_Task_Key_Enum,
  SrLinkFastqOutData,
  TaskOutFiles,
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
  OutFile,
  GetFilesUrlOutput,
  StartSrQiimeAnalysisMutation,
  StartSrQiimeAnalysisMutationVariables,
  AwsBatchJobType,
  SrTaskInputParams,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import styles from './ScreenStyles.module.scss';
import { logger } from '../utils/helpers';
import { TaskStatusType } from '../utils/types';
import SrLinkFastqStatus from '../components/SrLinkFastqStatus';

const { Column } = Table;

const seqRunDashboardQuery = loader('../graphql/queries/seqRunDashboardQuery.graphql');
const getFilesUrlQuery = loader('../graphql/queries/getFilesUrlQuery.graphql');
const startSrQiimeAnalysisMutation = loader(
  '../graphql/mutations/startSrQiimeAnalysisMutation.graphql',
);
/* Sequencing run task type */
type SeqRunTaskType = Pick<
  Sr_Task,
  | 'id'
  | 'dependent_on'
  | 'ended_at'
  | 'in_params'
  | 'key'
  | 'out_data'
  | 'out_files'
  | 'position_sr'
  | 'started_at'
  | 'aws_batch_job'
  | 'failed_at'
  | 'failed_logs'
>;

/* Labels for task/analysis column */
const srTaskLabels: Record<Enum_Sr_Task_Key_Enum, string> = {
  [Enum_Sr_Task_Key_Enum.LinkFastq]: 'Link FASTQ files',
  [Enum_Sr_Task_Key_Enum.UploadMetadata]: 'Upload metadata',
  [Enum_Sr_Task_Key_Enum.QcSummary]: 'QC Summary',
  [Enum_Sr_Task_Key_Enum.Dada2]: 'DADA2',
};

/* React functional component */
const SequencingRunDashboard: React.FC = () => {
  /* fetch id from url param */
  const { id } = useParams();

  const apolloClient = useApolloClient();

  /* State to store download urls of out_files uploaded by user for every task */
  const [fileDownloadUrls, setFileDownloadUrls] = useState<Array<GetFilesUrlOutput> | null>(null);

  // state to set loading of qc summary start button
  const [qcSummaryStartButtonLoading, setQcSummaryStartButtonLoading] = useState<boolean>(false);

  // State to store the logs of failed task to be viewed in Modal. Also is being used to show and hide the modal
  const [failedLogs, setFailedLogs] = useState<string | undefined>(undefined);

  // function to get urls of out_files uploaded by user
  const getFilesUrlFunc = useCallback(
    (queryData: SeqRunDashboardQuery) => {
      const tasksData = queryData?.sequencing_run_by_pk?.tasks;
      const s3KeysList: Array<string> = [];
      if (Array.isArray(tasksData) && tasksData.length > 0) {
        tasksData.forEach((item) => {
          if (item.out_files) {
            const outFiles = item.out_files as TaskOutFiles;
            if (Array.isArray(outFiles) && outFiles.length > 0) {
              outFiles.forEach((outFile) => {
                if (outFile.show_in_dashboard_table) {
                  s3KeysList.push(outFile.s3_key);
                }
              });
            }
          }
        });
      }
      /* To get download url's for all out files */
      apolloClient
        .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
          query: getFilesUrlQuery,
          variables: { keys: s3KeysList },
        })
        .then((fileUrls) => {
          const downloadUrlResponse = fileUrls.data.getFilesUrl;
          const downloadUrlsWithKey: Array<GetFilesUrlOutput> = [];
          if (Array.isArray(downloadUrlResponse) && downloadUrlResponse.length > 0) {
            downloadUrlResponse.forEach((item) => {
              const key = item?.key;
              const url = item?.url;
              if (url && key) {
                downloadUrlsWithKey.push({ key, url });
              }
            });
            setFileDownloadUrls(downloadUrlsWithKey);
          }
        })
        .catch((error) => {
          logger(error);
        });
    },
    [apolloClient],
  );

  /* Query to fetch sequencing run data */
  const { data, loading: seqRunDataLoading, error: queryError } = useQuery<
    SeqRunDashboardQuery,
    SeqRunDashboardQueryVariables
  >(seqRunDashboardQuery, {
    fetchPolicy: 'network-only',
    pollInterval: 10000,
    variables: { id: parseInt(id, 10) },
    onCompleted: (queryData) => {
      getFilesUrlFunc(queryData);
    },
  });

  // mutation which will call onClick of qcsummary start button.
  const [startSrQiimeAnalysis] = useMutation<
    StartSrQiimeAnalysisMutation,
    StartSrQiimeAnalysisMutationVariables
  >(startSrQiimeAnalysisMutation);

  /* if any error occurred while fetching data will show error message */
  if (queryError) {
    return (
      <AppLayout screenTitle="Sequencing Run analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  /* Loading indicator while fetching data */
  if (seqRunDataLoading) {
    return (
      <AppLayout screenTitle="Sequencing Run analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  /* Storing fetched sequencing run data */
  const seqRunData = data?.sequencing_run_by_pk;

  /* Storing project data of a sequencing run */
  const seqRunProjectData = data?.sequencing_run_by_pk?.project;

  /* Storing created by data of a sequencing run */
  const seqRunCreatedByData = data?.sequencing_run_by_pk?.created_by;

  /* Storing tasks data of a sequencing run */
  const seqRunTaskData = data?.sequencing_run_by_pk?.tasks;

  /* Storing out data of link fastq task after confirming fastq files */
  let linkFastqOutData;

  if (Array.isArray(seqRunTaskData) && seqRunTaskData.length > 0) {
    linkFastqOutData = seqRunTaskData[0].out_data as SrLinkFastqOutData;
  }

  /* Object Storing status for each task */
  const taskStatus: Record<Enum_Sr_Task_Key_Enum, TaskStatusType> = {
    [Enum_Sr_Task_Key_Enum.LinkFastq]: '',
    [Enum_Sr_Task_Key_Enum.UploadMetadata]: '',
    [Enum_Sr_Task_Key_Enum.QcSummary]: '',
    [Enum_Sr_Task_Key_Enum.Dada2]: '',
  };

  // function will execute on click of start button that is when qc summary is in ready state
  const qcSummaryStartButtonFunc = (taskId: number) => {
    setQcSummaryStartButtonLoading(true);
    startSrQiimeAnalysis({
      variables: {
        taskId,
      },
      refetchQueries: [
        {
          query: seqRunDashboardQuery,
          variables: { id: parseInt(id, 10) },
        },
      ],
    })
      .then(() => {
        setQcSummaryStartButtonLoading(false);
      })
      .catch((err) => {
        setQcSummaryStartButtonLoading(false);
        logger(err);
      });
  };

  /* Determining status of each task */
  if (Array.isArray(seqRunTaskData) && seqRunTaskData.length > 0) {
    seqRunTaskData.forEach((record) => {
      const awsBatchJob = record.aws_batch_job as AwsBatchJobType;
      if ((awsBatchJob && awsBatchJob.status === 'FAILED') || record.failed_at !== null) {
        taskStatus[record.key] = 'Failed';
      } else if (record.started_at !== null && record.ended_at === null) {
        taskStatus[record.key] = 'In Progress';
      } else if (record.ended_at !== null) {
        taskStatus[record.key] = 'Completed';
      } else if (record.started_at === null && record.dependent_on === null) {
        taskStatus[record.key] = 'Ready';
      } else if (
        record.started_at === null &&
        record.dependent_on !== null &&
        Array.isArray(record.dependent_on) &&
        record.dependent_on.length > 0
      ) {
        taskStatus[record.key] = 'Ready';
        for (let i = 0; i < record.dependent_on.length; i++) {
          const taskKey = record.dependent_on[i] as Enum_Sr_Task_Key_Enum;
          if (taskStatus[taskKey] !== 'Completed') {
            taskStatus[record.key] = 'N/A';
            break;
          }
        }
      }
    });
  }

  /* Rendering actions for each row in table */
  const renderTableRowActions = (record: SeqRunTaskType) => {
    let btnText = '';
    let linkToRoute = '';
    let downloadUrl = '';
    const tableRowActionButtons: Array<JSX.Element> = [];
    if (taskStatus[record.key] === 'Ready' || taskStatus[record.key] === 'Failed') {
      btnText = taskStatus[record.key] === 'Ready' ? 'Start' : 'Re-start';
      if (record.key === 'linkFastq') {
        linkToRoute = `/sr/${id}/task/${record.id}/linkFastq`;
      } else if (record.key === 'uploadMetadata') {
        linkToRoute = `/sr/${id}/task/${record.id}/uploadMetadata`;
      } else if (record.key === 'dada2') {
        linkToRoute = `/sr/${id}/task/${record.id}/dada2`;
      } else if (record.key === 'qcSummary') {
        tableRowActionButtons.push(
          <Button
            key="start-btn"
            type="default"
            onClick={() => {
              qcSummaryStartButtonFunc(record.id);
            }}
            loading={qcSummaryStartButtonLoading}
          >
            {btnText}
          </Button>,
        );
      }
    }

    if (taskStatus[record.key] === 'Completed') {
      // The 'Re-upload' btn should be shown only till the next analysis is started.
      if (record.key === 'uploadMetadata' && taskStatus.qcSummary === 'Ready') {
        btnText = 'Re-upload';
        if (Array.isArray(record.out_files) && record.out_files.length > 0) {
          const outFile = record.out_files[0] as OutFile;
          if (Array.isArray(fileDownloadUrls) && fileDownloadUrls.length > 0) {
            fileDownloadUrls.forEach((item) => {
              if (item.key === outFile.s3_key) {
                downloadUrl = item.url;
              }
            });
          }
        }

        linkToRoute = `/sr/${id}/task/${record.id}/uploadMetadata`;
      }
      // when dada2 status is complete then View Results button will be shown
      if (record.key === 'dada2') {
        btnText = 'View Results';
        linkToRoute = `/sr/${id}/task/${record.id}/result`;
      }
    }

    if (taskStatus[record.key] === 'Failed') {
      // const to store the error logs for 1st and 2nd step of sr task
      const failedLogMessage = record.failed_logs;

      // const to store the aws batch error logs for 3rd and 4th step of sr task
      const awsBatchJob = record.aws_batch_job as AwsBatchJobType;

      tableRowActionButtons.unshift(
        <Button
          key="logs-btn"
          onClick={() => {
            setFailedLogs(failedLogMessage || (awsBatchJob.logs as string));
          }}
          style={{ marginBottom: 10, marginTop: 10, marginRight: 15 }}
          type="default"
        >
          View Logs
        </Button>,
      );
    }

    if (btnText !== '') {
      tableRowActionButtons.push(
        <Button type="default" key="start-btn">
          {linkToRoute !== '' ? (
            <Link to={linkToRoute} state={downloadUrl !== '' ? { downloadUrl } : null}>
              {btnText}
            </Link>
          ) : null}
        </Button>,
      );
    }

    return tableRowActionButtons;
  };

  /* Rendering files for each row in table */
  const renderTableRowFiles = (record: SeqRunTaskType) => {
    const fileDownloadButtons: Array<JSX.Element> = [];
    if (taskStatus[record.key] === 'Completed') {
      if (Array.isArray(record.out_files) && record.out_files.length > 0) {
        record.out_files.forEach((out_file: any) => {
          const outFile = out_file as OutFile;
          if (Array.isArray(fileDownloadUrls) && fileDownloadUrls.length > 0) {
            for (let i = 0; i < fileDownloadUrls.length; i++) {
              if (fileDownloadUrls[i].key === outFile.s3_key) {
                const fileDownloadButton = (
                  <div key={outFile.name}>
                    <Button type="link" href={fileDownloadUrls[i].url}>
                      {outFile.name}
                    </Button>
                  </div>
                );
                fileDownloadButtons.push(fileDownloadButton);
              }
            }
          }
        });
      }
    }
    return fileDownloadButtons;
  };

  return (
    <AppLayout screenTitle="Sequencing Run analysis" hideBackBtn>
      <>
        <Modal
          title="Logs"
          visible={!!failedLogs}
          onOk={() => {
            setFailedLogs(undefined);
          }}
          onCancel={() => {
            setFailedLogs(undefined);
          }}
          cancelButtonProps={{ style: { display: 'none' } }}
          width="90%"
        >
          <pre dangerouslySetInnerHTML={{ __html: failedLogs as string }} />
        </Modal>
        <div style={{ display: 'flex' }}>
          <h2>SR{id}</h2>
          <Button type="default" style={{ marginLeft: 15 }}>
            {/* only to edit the SR's info and not create the SR tasks */}
            <Link to={`/sr/${id}/edit/info?onlyUpdateSr=true`}>Edit Info</Link>
          </Button>
        </div>
        {seqRunData && seqRunData.title ? <h3>{seqRunData.title}</h3> : null}
        {seqRunData && seqRunData.description ? <h4>{seqRunData.description}</h4> : null}
        <Descriptions size="small" style={{ marginTop: 20 }}>
          <Descriptions.Item label="Project" span={3}>
            {seqRunProjectData && seqRunProjectData.title ? seqRunProjectData.title : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Created On">
            {seqRunData && seqRunData.created_at
              ? moment(seqRunData.created_at).format('DD MMM, YYYY')
              : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Created By">
            {seqRunCreatedByData && seqRunCreatedByData.first_name && seqRunCreatedByData.last_name
              ? seqRunCreatedByData.first_name.concat(' ', seqRunCreatedByData.last_name)
              : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Tags">
            {seqRunData && seqRunData.tags ? seqRunData.tags : 'N/A'}
          </Descriptions.Item>
          {linkFastqOutData && linkFastqOutData.samples ? (
            <Descriptions.Item label="No. of samples">
              {linkFastqOutData.samples.length}
            </Descriptions.Item>
          ) : null}
        </Descriptions>

        {seqRunData && Array.isArray(seqRunData.tasks) && seqRunData.tasks.length > 0 ? (
          <Table<SeqRunTaskType>
            bordered
            dataSource={seqRunData.tasks}
            rowKey="key"
            size="small"
            style={{ marginTop: 25 }}
            pagination={false}
            rowClassName={(record) => (taskStatus[record.key] === 'N/A' ? styles.disabledRow : '')}
            tableLayout="fixed"
          >
            <Column
              title="Task/Analysis"
              dataIndex="key"
              key="key"
              render={(task: Enum_Sr_Task_Key_Enum) => srTaskLabels[task]}
              width={200}
            />
            <Column<SeqRunTaskType>
              title="Status"
              dataIndex="status"
              key="status"
              render={(value, record) => {
                // For in progress linkFastq task
                if (
                  record.key === Enum_Sr_Task_Key_Enum.LinkFastq &&
                  taskStatus[record.key] === 'In Progress'
                ) {
                  let datasyncTaskExecutionArn;

                  if (Array.isArray(record.in_params)) {
                    const inParams = record.in_params as SrTaskInputParams;
                    for (let i = 0; i < inParams.length; i++) {
                      const param = inParams[i];

                      if (param.key === 'datasyncTaskExecutionArn') {
                        datasyncTaskExecutionArn = param.value as string;
                      }
                    }
                  }

                  return (
                    <>
                      <p>{taskStatus[record.key]}</p>
                      <SrLinkFastqStatus datasyncTaskExecutionArn={datasyncTaskExecutionArn} />
                    </>
                  );
                }

                return taskStatus[record.key];
              }}
              width={100}
            />
            <Column<SeqRunTaskType>
              title="Actions"
              dataIndex="actions"
              key="actions"
              render={(value, record) => renderTableRowActions(record)}
              width={180}
            />
            <Column<SeqRunTaskType>
              title="Files"
              dataIndex="files"
              key="files"
              render={(value, record) => renderTableRowFiles(record)}
              width={200}
            />
          </Table>
        ) : null}
      </>
    </AppLayout>
  );
};

export default SequencingRunDashboard;
