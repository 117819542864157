import React, { useState } from 'react';
import { Button, Input, message } from 'antd';
import { MailOutlined, LockFilled } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import EcFormItem from '../components/EcFormItem';
import { firebaseAuth } from '../utils/firebase';
import { ReactComponent as Logo } from '../assets/logo_purina_with-name.svg';

// login form type
type LoginFormType = {
  // type of email id user enter for login
  email: string;
  // type of password which user enter for login
  password: string;
};

// react functional component
const LoginScreen: React.FC = () => {
  // state to set loading of login button
  const [isLoginButtonLoading, setIsLoginButtonLoading] = useState<boolean>(false);
  // state to set loading of reset password button
  const [resetLoading, setResetLoading] = useState<boolean>(false);

  //  useform declaration with default values
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, control, setError, watch, getValues, reset } = useForm<
    LoginFormType
  >({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  });

  // when user click login button sign in function will execute which will determine valid user through firebase authentication
  const signInFunc = (data: LoginFormType) => {
    setIsLoginButtonLoading(true);
    // firebase signIn  function to validate users email and password
    firebaseAuth
      .signInWithEmailAndPassword(data.email, data.password)
      .then(() => setIsLoginButtonLoading(false))
      .catch((err) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (err.code === 'auth/user-not-found') {
          setError('email', {
            type: 'manual',
            message: 'Your email is not registered with this app. Please contact your admin.',
          });
          setIsLoginButtonLoading(false);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (err.code === 'auth/invalid-email') {
          setError('email', {
            type: 'manual',
            message: 'Entered email id is incorrect.',
          });
          setIsLoginButtonLoading(false);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (err.code === 'auth/wrong-password') {
          setError('password', {
            type: 'manual',
            message: 'Wrong password entered. Please try again.',
          });
          setIsLoginButtonLoading(false);
        }
      });
  };
  // reset Password func will execute when user click reset password button, it use Firebase auth function to send mail
  const resetPasswordFunc = () => {
    setResetLoading(true);
    // getting value of  email field
    const emailValue = getValues('email');
    // This firebase connectivity is invoke when user click reset password and sending email
    firebaseAuth
      .sendPasswordResetEmail(emailValue)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success(
          'An email has been sent to your Email ID. Please follow the instructions in it to reset your password.',
        );
        reset({
          email: '',
        });
        setResetLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (error.code === 'auth/user-not-found') {
          setError('email', {
            type: 'manual',
            message: 'Your email is not registered with this app. Please contact your admin.',
          });
          setResetLoading(false);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (error.code === 'auth/invalid-email') {
          setError('email', {
            type: 'manual',
            message: 'Entered email id is incorrect.',
          });
          setResetLoading(false);
        }
      });
  };

  return (
    <div
      style={{
        backgroundColor: '#FAFBFC',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 5,
          boxShadow: '#9FA19F 0px 0px 10px',
          boxSizing: 'border-box',
          padding: 30,
          width: 350,
        }}
      >
        <div style={{ width: 250, marginBottom: 30, marginLeft: 15 }}>
          <Logo />
        </div>
        {/* <h2 style={{ textAlign: 'center', marginTop: 0, marginBottom: 20 }}>Sign in</h2> */}
        {/* login form start */}
        <form onSubmit={handleSubmit((data) => signInFunc(data))}>
          <div style={{ marginBottom: 20 }}>
            <EcFormItem
              name="email"
              control={control}
              rules={{
                required: 'Please enter email and try again',
                pattern: {
                  // eslint-disable-next-line no-useless-escape
                  value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email address',
                },
              }}
              errors={errors}
              as={
                <Input
                  allowClear
                  prefix={
                    <MailOutlined style={{ color: '#C5BFBF', fontSize: 16, marginRight: 10 }} />
                  }
                  placeholder="Please enter Email"
                />
              }
            />
          </div>
          <div>
            <EcFormItem
              name="password"
              control={control}
              rules={{
                required: 'Please enter password and try again',
              }}
              errors={errors}
              as={
                <Input.Password
                  prefix={
                    <LockFilled style={{ color: '#C5BFBF', fontSize: 16, marginRight: 10 }} />
                  }
                  placeholder="Please enter Password"
                />
              }
            />
          </div>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: 20 }}
            block
            loading={isLoginButtonLoading}
            disabled={watch('email') === '' || watch('password') === '' || resetLoading}
          >
            Login with Email
          </Button>
          <Button
            style={{ marginTop: 20, marginBottom: 5 }}
            block
            onClick={() => resetPasswordFunc()}
            disabled={watch('email') === '' || isLoginButtonLoading}
            loading={resetLoading}
          >
            Reset Password
          </Button>
        </form>
      </div>
    </div>
  );
};
export default LoginScreen;
