import React from 'react';
import { Table } from 'antd';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import moment from 'moment';
import { CompletedMdsaQuery, CompletedMdsaQueryVariables, Mdsa } from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import ColumnsSearchInput from '../components/ColumnsSearchInput';
import { colors } from '../utils/globals';

const { Column } = Table;
// completed mdsa table type
type CompletedMdsaType = Pick<Mdsa, 'id' | 'title' | 'description' | 'tags' | 'updated_at'>;
// call to graphql files
const completedMdsaQuery = loader('../graphql/queries/completedMdsaQuery.graphql');

// react functional component
const CompletedMdsaScreen: React.FC = () => {
  // query to fetch completed mdsa data
  const { data, loading, error } = useQuery<CompletedMdsaQuery, CompletedMdsaQueryVariables>(
    completedMdsaQuery,
    { fetchPolicy: 'network-only' },
  );

  // if error in fetching query
  if (error) {
    return (
      <AppLayout screenTitle="Completed Downstream Analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
      </AppLayout>
    );
  }

  return (
    <AppLayout screenTitle="Completed Downstream Analysis">
      <Table<CompletedMdsaType>
        bordered
        dataSource={data && Array.isArray(data.mdsa) ? data.mdsa : []}
        loading={loading}
        rowKey="id"
      >
        <Column<CompletedMdsaType>
          title="ID"
          dataIndex="id"
          key="id"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsSearchInput
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="id"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? colors.tintColor : undefined }} />
          )}
          onFilter={(value, record) =>
            `MDSA${record.id}`.toLowerCase().includes(value.toString().toLowerCase())
          }
          render={(id: string) => {
            return <Link to={`/mdsa/${id}`}>MDSA{id}</Link>;
          }}
        />

        <Column<CompletedMdsaType>
          title="Completed On"
          dataIndex="updated_at"
          key="updated_at"
          sorter={(a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix()}
          render={(updated_at: string) => {
            return moment(updated_at).format('D MMM, YYYY');
          }}
        />

        <Column<CompletedMdsaType>
          title="Title"
          dataIndex="title"
          key="title"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsSearchInput
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="title"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? colors.tintColor : undefined }} />
          )}
          onFilter={(value, record) =>
            record.title
              ? record.title.toLowerCase().includes(value.toString().toLowerCase())
              : false
          }
        />

        <Column<CompletedMdsaType>
          title="Description"
          dataIndex="description"
          key="description"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsSearchInput
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="description"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? colors.tintColor : undefined }} />
          )}
          onFilter={(value, record) =>
            record.description
              ? record.description.toLowerCase().includes(value.toString().toLowerCase())
              : false
          }
        />

        <Column<CompletedMdsaType>
          title="Tags"
          dataIndex="tags"
          key="tags"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsSearchInput
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="tags"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? colors.tintColor : undefined }} />
          )}
          onFilter={(value, record) =>
            record.tags ? record.tags.toLowerCase().includes(value.toString().toLowerCase()) : false
          }
        />
        <Column<CompletedMdsaType> title="Remarks" dataIndex="remarks" key="remarks" />
      </Table>
    </AppLayout>
  );
};

export default CompletedMdsaScreen;
