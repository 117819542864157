import React, { useState } from 'react';
import { Spin, Descriptions, Button } from 'antd';
import { loader } from 'graphql.macro';
import { useQuery, useApolloClient } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';
import {
  GetFilesUrlOutput,
  GetFilesUrlQueryVariables,
  GetFilesUrlQuery,
  OutFile,
  MdsaTaskResultQuery,
  MdsaTaskResultQueryVariables,
  TaskOutFiles,
  SrIdsOfMdsaQuery,
  SrIdsOfMdsaQueryVariables,
  MdsaInParam,
  Enum_Mdsa_Task_Key_Enum,
  BlastOutData,
} from '../graphql/graphql-types';
import { logger } from '../utils/helpers';
import AppLayout from '../components/AppLayout';
import {
  mdsaTaskKeyNameMap,
  taxaLevels,
  distanceMatrixOptions,
  alphaDiversityMetrics,
} from '../utils/globals';
import BlastFeatureMapping from './results/BlastFeatureMapping';
import BlastMappedResults from './results/BlastMappedResults';

const mdsaTaskResultQuery = loader('../graphql/queries/mdsaTaskResultQuery.graphql');
const getFilesUrlQuery = loader('../graphql/queries/getFilesUrlQuery.graphql');
const srIdsOfMdsaQuery = loader('../graphql/queries/srIdsOfMdsaQuery.graphql');

/* React functional component */
const MdsaResults: React.FC = () => {
  /* Extracting id, task id from url param */
  const { id, taskId } = useParams();

  const apolloClient = useApolloClient();

  /* State o store download urls of files */
  const [downloadUrls, setDownloadUrls] = useState<Array<GetFilesUrlOutput>>([]);

  /* State to store sr ids of mdsa */
  const [srIdsOfMdsa, setSrIdsOfMdsa] = useState<Array<number> | null>(null);

  /* Query to fetch Mdsa task result data */
  const { data, loading: mdsaTaskResultLoading, error } = useQuery<
    MdsaTaskResultQuery,
    MdsaTaskResultQueryVariables
  >(mdsaTaskResultQuery, {
    variables: {
      id: parseInt(taskId, 10),
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ mdsa_task_by_pk }) => {
      /* Storing out_files of mdsa */
      const outFilesData = mdsa_task_by_pk?.out_files as TaskOutFiles;
      if (Array.isArray(outFilesData) && outFilesData.length > 0) {
        apolloClient
          .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
            query: getFilesUrlQuery,
            variables: {
              keys: outFilesData.map((file) => file.s3_key),
            },
          })
          .then((res) => {
            const { getFilesUrl } = res.data;
            /* Array to store generated url respective to s3key which will then pass to setDownloadUrl */
            const filesUrlArray: Array<GetFilesUrlOutput> = [];
            if (getFilesUrl) {
              getFilesUrl.forEach((e) => {
                const key = e?.key;
                const url = e?.url;
                if (url && key) {
                  filesUrlArray.push({ key, url });
                }
              });
              setDownloadUrls(filesUrlArray);
            }
          })
          .catch((err) => logger(err));
      }

      /* Storing task key of mdsa */
      const taskKey = mdsa_task_by_pk?.key;
      if (taskKey && taskKey === 'mergeTablesSr') {
        /* Call to srIdsOfMdsa query only if task key is 'mergeTablesSr' */
        apolloClient
          .query<SrIdsOfMdsaQuery, SrIdsOfMdsaQueryVariables>({
            query: srIdsOfMdsaQuery,
            variables: {
              mdsa_id: parseInt(id, 10),
            },
            fetchPolicy: 'network-only',
          })
          .then((res) => {
            const mdsaSrData = res.data.mdsa_sequencing_run;
            if (Array.isArray(mdsaSrData) && mdsaSrData.length > 0) {
              const srIds: Array<number> = [];
              mdsaSrData.forEach((item) => {
                srIds.push(item.sr_id);
              });
              setSrIdsOfMdsa(srIds);
            }
          })
          .catch((err) => {
            logger(err);
          });
      }
    },
  });

  /* Storing task key of mdsa */
  const taskKey = data?.mdsa_task_by_pk?.key;

  /* Storing out_files data of mdsa */
  const taskOutFilesData = data?.mdsa_task_by_pk?.out_files as TaskOutFiles;

  /* Storing in_params data of mdsa */
  const taskInParamsData = data?.mdsa_task_by_pk?.in_params as Array<MdsaInParam>;

  // stores the mdsa information
  const mdsaData = data?.mdsa_task_by_pk?.mdsa;

  /* Loading indicator while fetching data */
  if (mdsaTaskResultLoading) {
    return (
      <AppLayout screenTitle="">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  /* if any error occurred while fetching data will show error message */
  if (error) {
    return (
      <AppLayout screenTitle="">
        <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
      </AppLayout>
    );
  }

  /* Function to render inputs of tasks based on task key */
  const renderInputsForTasks = () => {
    if (taskKey) {
      /* Show descriptions field below when task's key is 'mergeTablesSr' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.MergeTablesSr) {
        if (!srIdsOfMdsa) {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spin />
            </div>
          );
        }
        return (
          <Descriptions.Item label="Sequencing Run(s)">
            {Array.isArray(srIdsOfMdsa) && srIdsOfMdsa.length > 0
              ? srIdsOfMdsa.map((item, index) => {
                  return (
                    <Link key={item} to={`/sr/${item}`}>
                      <span style={{ textDecorationLine: 'underline' }}>SR{item}</span>
                      {index !== srIdsOfMdsa.length - 1 ? ', ' : null}
                    </Link>
                  );
                })
              : null}
          </Descriptions.Item>
        );
      }

      /* Show descriptions field below when task's key is 'filterTableByTaxa' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.FilterTableByTaxa) {
        if (Array.isArray(taskInParamsData) && taskInParamsData.length > 0) {
          return taskInParamsData.map((item) => {
            if (item.key === '--p-include' && item.enabled) {
              return (
                <Descriptions.Item label="--p-include (taxa filter)">
                  {item.value}
                </Descriptions.Item>
              );
            }
            if (item.key === '--p-exclude' && item.enabled) {
              return (
                <Descriptions.Item label=" --p-exclude (taxa filter)">
                  {item.value}
                </Descriptions.Item>
              );
            }
            if (
              item.key === '--p-exclude-ids' &&
              item.enabled &&
              Array.isArray(item.rawValue) &&
              item.rawValue.length > 0
            ) {
              return (
                <Descriptions.Item label="Samples Excluded">
                  {item.rawValue.join(', ')}
                </Descriptions.Item>
              );
            }
            return null;
          });
        }
      }

      /* Show descriptions field below when task's key is 'taxaBarPlot' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.TaxaBarPlot) {
        if (Array.isArray(taskInParamsData) && taskInParamsData.length > 0) {
          return taskInParamsData.map((item) => {
            if (item.key === 'taxaCollapseLevels') {
              // stores the value of taxaName to display
              const taxaName =
                item.enabled && Array.isArray(item.rawValue) && item.rawValue.length > 0
                  ? taxaLevels
                      .filter((taxaLevel) => (item.rawValue as string[]).includes(taxaLevel.level))
                      .map((taxaLevel) => {
                        return taxaLevel.name;
                      })
                      .join(', ')
                  : '';

              return (
                <>
                  <Descriptions.Item label="Create Relative Abundance file?">
                    {item.enabled ? 'Yes' : 'No'}
                  </Descriptions.Item>
                  {item.enabled && taxaName ? (
                    <Descriptions.Item label="Taxa collapse level">
                      {item.value ? `${item.value} (${taxaName})` : taxaName}
                    </Descriptions.Item>
                  ) : null}
                </>
              );
            }

            return null;
          });
        }
      }

      /* Show descriptions field below when task's key is 'groupTableBarPlot' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.GroupTableBarPlot) {
        if (Array.isArray(taskInParamsData) && taskInParamsData.length > 0) {
          return taskInParamsData.map((item) => {
            if (item.key === '--m-metadata-column' && item.rawValue) {
              return (
                <Descriptions.Item label="Grouped by column(s)">
                  {Array.isArray(item.rawValue) && item.rawValue.length > 0
                    ? item.rawValue.join(', ')
                    : item.rawValue}
                </Descriptions.Item>
              );
            }
            if (item.key === '--p-mode' && item.value) {
              return <Descriptions.Item label={item.key}>{item.value}</Descriptions.Item>;
            }
            if (item.key === '--p-level') {
              // stores the value of taxaName to display
              let taxaName;
              // this is to handle older MDSA analysis where ‘--p-level’ is a string and not array of taxa
              if (item.value) {
                taxaName =
                  item.enabled && item.value
                    ? taxaLevels.find((taxaLevel) => taxaLevel.level === item.value)?.name
                    : null;
              } else {
                taxaName =
                  item.enabled && Array.isArray(item.rawValue) && item.rawValue.length > 0
                    ? taxaLevels
                        .filter((taxaLevel) =>
                          (item.rawValue as string[]).includes(taxaLevel.level),
                        )
                        .map((taxaLevel) => {
                          return taxaLevel.name;
                        })
                        .join(', ')
                    : '';
              }

              return (
                <>
                  <Descriptions.Item label="Create Relative Abundance file?">
                    {item.enabled ? 'Yes' : 'No'}
                  </Descriptions.Item>
                  {item.enabled && taxaName ? (
                    <Descriptions.Item label="Taxa collapse level">
                      {item.value ? `${item.value} (${taxaName})` : taxaName}
                    </Descriptions.Item>
                  ) : null}
                </>
              );
            }

            return null;
          });
        }
      }

      /* Show descriptions field below when task's key is 'phylogeneticDiversityAnalysis' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.PhylogeneticDiversityAnalysis) {
        return (
          <>
            <Descriptions.Item label="Feature table">
              {mdsaData?.primary_feature_table_file_name}
            </Descriptions.Item>
            {Array.isArray(taskInParamsData) && taskInParamsData.length > 0
              ? taskInParamsData.map((item) => {
                  if (item.key === '--p-sampling-depth') {
                    return (
                      <Descriptions.Item label="Sampling Depth">{item.rawValue}</Descriptions.Item>
                    );
                  }
                  return null;
                })
              : null}
          </>
        );
      }

      /* Show descriptions field below when task's key is 'filterDistanceMatrix' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.FilterDistanceMatrix) {
        if (Array.isArray(taskInParamsData) && taskInParamsData.length > 0) {
          return taskInParamsData.map((item) => {
            if (item.key === '--i-distance-matrix') {
              const distanceMatrixName = distanceMatrixOptions.find(
                (ele) => ele.value === item.value,
              )?.name;
              return (
                <Descriptions.Item label="Distance matrix">{distanceMatrixName}</Descriptions.Item>
              );
            }
            if (
              item.key === '--p-exclude-ids' &&
              Array.isArray(item.rawValue) &&
              item.rawValue.length > 0
            ) {
              return (
                <Descriptions.Item label="Samples Excluded">
                  {item.rawValue.join(', ')}
                </Descriptions.Item>
              );
            }
            return null;
          });
        }
      }

      /* Show descriptions field below when task's key is 'customPcoaPlot' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.CustomPcoaPlot) {
        if (Array.isArray(taskInParamsData) && taskInParamsData.length > 0) {
          return taskInParamsData.map((item) => {
            if (item.key === '--i-pcoa') {
              const distanceMatrixName = distanceMatrixOptions.find(
                (ele) => ele.value === item.value,
              )?.name;
              return (
                <Descriptions.Item label="Distance matrix">{distanceMatrixName}</Descriptions.Item>
              );
            }
            if (item.key === '--p-custom-axes' && item.rawValue) {
              return (
                <Descriptions.Item label="Metadata column(s)">
                  {Array.isArray(item.rawValue) && item.rawValue.length > 0
                    ? item.rawValue.join(', ')
                    : item.rawValue}
                </Descriptions.Item>
              );
            }
            return null;
          });
        }
      }

      /* Show descriptions field below when task's key is 'ancom' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.Ancom) {
        if (Array.isArray(taskInParamsData) && taskInParamsData.length > 0) {
          return taskInParamsData.map((item) => {
            if (item.key === '--p-min-frequency' && item.enabled) {
              return <Descriptions.Item label="Minimum frequency">{item.value}</Descriptions.Item>;
            }
            if (item.key === '--p-max-frequency' && item.enabled) {
              return <Descriptions.Item label="Maximum frequency">{item.value}</Descriptions.Item>;
            }
            if (item.key === '--p-level' && item.enabled) {
              const taxaName = item.enabled
                ? taxaLevels.find((taxaLevel) => taxaLevel.level === item.value)?.name
                : null;
              return (
                <Descriptions.Item label="Taxa collapse level">
                  {item.value} ({taxaName})
                </Descriptions.Item>
              );
            }
            if (
              item.key === 'metadataColumns' &&
              item.enabled &&
              Array.isArray(item.rawValue) &&
              item.rawValue.length > 0
            ) {
              return (
                <Descriptions.Item label="Select metadata column(s)">
                  {' '}
                  {item.rawValue.join(', ')}
                </Descriptions.Item>
              );
            }
            return null;
          });
        }
      }

      /* Show descriptions field below when task's key is 'alphaGroupSignificance' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.AlphaGroupSignificance) {
        if (Array.isArray(taskInParamsData) && taskInParamsData.length > 0) {
          return taskInParamsData.map((item) => {
            if (item.key === 'diversityMetrics') {
              const diversityMetricsNames = alphaDiversityMetrics
                .filter((ele) => {
                  if (Array.isArray(item.rawValue) && item.rawValue.length > 0) {
                    const rawValueData = item.rawValue as Array<string>;
                    return rawValueData.includes(ele.value);
                  }
                  return null;
                })
                .map((val) => val.name);
              return (
                <Descriptions.Item label="Diversity Metrics">
                  {diversityMetricsNames.join(', ')}
                </Descriptions.Item>
              );
            }

            return null;
          });
        }
      }

      /* Show descriptions field below when task's key is 'betaGroupSignificance' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.BetaGroupSignificance) {
        if (Array.isArray(taskInParamsData) && taskInParamsData.length > 0) {
          return taskInParamsData.map((item) => {
            if (item.key === 'distanceMatrices') {
              const distanceMatrixNameData = distanceMatrixOptions
                .filter((ele) => {
                  if (Array.isArray(item.rawValue) && item.rawValue.length > 0) {
                    const rawValueData = item.rawValue as Array<string>;
                    return rawValueData.includes(ele.value);
                  }
                  return false;
                })
                .map((val) => val.name);
              return (
                <Descriptions.Item label="Distance Matrices">
                  {distanceMatrixNameData.join(', ')}
                </Descriptions.Item>
              );
            }
            if (
              item.key === 'metadataColumns' &&
              Array.isArray(item.rawValue) &&
              item.rawValue.length > 0
            ) {
              return (
                <Descriptions.Item label="Metadata columns">
                  {item.rawValue.join(', ')}
                </Descriptions.Item>
              );
            }
            return null;
          });
        }
      }
      /* Show descriptions field below when task's key is 'tableFilterFeatures' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.TableFilterFeatures) {
        if (Array.isArray(taskInParamsData) && taskInParamsData.length > 0) {
          return taskInParamsData.map((item) => {
            if (item.enabled) {
              return <Descriptions.Item label={item.key}>{item.value}</Descriptions.Item>;
            }
            return null;
          });
        }
      }
      /* Show descriptions field below when task's key is 'picRust2' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.Picrust2) {
        if (Array.isArray(taskInParamsData) && taskInParamsData.length > 0) {
          return taskInParamsData.map((item) => {
            if (item.enabled) {
              if (item.key === '--stratified' || item.key === '--per_sequence_contrib') {
                return <p style={{ fontWeight: 'bold' }}>{item.key}</p>;
              }
              return (
                <Descriptions.Item key={item.key} label={item.key}>
                  {item.value}
                </Descriptions.Item>
              );
            }
            return null;
          });
        }
      }
      /* Show below descriptions field below when task's key is 'lefse' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.Lefse) {
        if (Array.isArray(taskInParamsData) && taskInParamsData.length > 0) {
          return taskInParamsData.map((item) => {
            if (
              item.key === 'sampleIds' &&
              Array.isArray(item.rawValue) &&
              item.rawValue.length > 0
            ) {
              return (
                <Descriptions.Item label="Selected samples to include in the analysis">
                  {(item.rawValue as string[]).join(',')}
                </Descriptions.Item>
              );
            }
            if (item.key === 'groupMetadataColumn') {
              return <Descriptions.Item label="Group">{item.rawValue}</Descriptions.Item>;
            }
            if (item.key === 'taxaRank') {
              return <Descriptions.Item label="Taxa Rank">{item.rawValue}</Descriptions.Item>;
            }
            if (item.key === '--alpha' && item.enabled) {
              return (
                <Descriptions.Item label="P-value cut-off for significant taxa">
                  {item.rawValue}
                </Descriptions.Item>
              );
            }
            if (item.key === '--title' && item.enabled) {
              return (
                <Descriptions.Item label="Title for lefse plot">{item.rawValue}</Descriptions.Item>
              );
            }
            return null;
          });
        }
      }
      /* Show below descriptions field when task's key is 'cart' */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.Cart) {
        if (Array.isArray(taskInParamsData) && taskInParamsData.length > 0) {
          return taskInParamsData.map((item) => {
            if (
              item.key === '--variables' &&
              Array.isArray(item.rawValue) &&
              item.rawValue.length > 0
            ) {
              return (
                <Descriptions.Item label="Metadata columns">
                  {(item.rawValue as string[]).join(', ')}
                </Descriptions.Item>
              );
            }
            if (item.key === '--pertaxa' && item.enabled) {
              return <Descriptions.Item label="--pertaxa">{item.rawValue}</Descriptions.Item>;
            }
            return null;
          });
        }
      }
      /* Show below descriptions field when task's key is 'HmqReport */
      if (taskKey === Enum_Mdsa_Task_Key_Enum.HmqReport) {
        if (Array.isArray(taskInParamsData) && taskInParamsData.length > 0) {
          return taskInParamsData.map((item) => {
            if (item.key === '--taxa.rank' && item.enabled) {
              return <Descriptions.Item label="--taxa-rank">{item.rawValue}</Descriptions.Item>;
            }
            if (item.key === '--num_taxa' && item.enabled) {
              return <Descriptions.Item label="--num_taxa">{item.rawValue}</Descriptions.Item>;
            }
            return null;
          });
        }
      }
    }
    return null;
  };

  // renders blast result
  const renderBlastResults = () => {
    // stores the value of out data
    const outData = data?.mdsa_task_by_pk?.out_data;
    if (outData) {
      return (
        <div style={{ marginTop: 40 }}>
          <p className="ant-descriptions-title">BLAST Results</p>
          <BlastMappedResults outData={outData as Array<BlastOutData>} />
        </div>
      );
    }
    // stores the value of s3key of blast-results.json files used to get url
    const s3key = taskOutFilesData.find((item) => item.name === 'blastn-results.json')?.s3_key;
    if (s3key) {
      return (
        <div style={{ marginTop: 40 }}>
          <p className="ant-descriptions-title">Map BLAST results to the feature IDs</p>
          <BlastFeatureMapping s3Key={s3key} taskId={taskId} />
        </div>
      );
    }
    return null;
  };

  return (
    <AppLayout screenTitle={taskKey ? `${mdsaTaskKeyNameMap[taskKey]} - Results` : ''}>
      <>
        <h2>MDSA-{id}</h2>
        <h3>{mdsaData && mdsaData.title ? mdsaData.title : ''}</h3>
        <h4>{mdsaData && mdsaData.description ? mdsaData.description : ''}</h4>
        <Descriptions size="small">
          <Descriptions.Item label="Remarks">
            {mdsaData && mdsaData.remarks ? mdsaData.remarks : ''}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Files" column={1} bordered style={{ width: 600, marginTop: 5 }}>
          <Descriptions.Item label="Artifacts" style={{ paddingTop: 2, paddingBottom: 2 }}>
            {
              /* Filter array based on extension (if extension is other than 'qzv' filter it to artifact type) */
              taskOutFilesData &&
                taskOutFilesData
                  .filter((item: OutFile) => {
                    const fileExtension = item.name.split('.').pop();
                    return fileExtension !== 'qzv';
                  })
                  .map((file) => {
                    /* Storing url of each file */
                    const fileUrl = downloadUrls.find((item) => item.key === file.s3_key)?.url;
                    return (
                      <Button
                        type="link"
                        href={fileUrl ?? '#'}
                        style={{
                          display: 'flex',
                        }}
                        key={file.name}
                      >
                        {file.name}
                      </Button>
                    );
                  })
            }
          </Descriptions.Item>
          <Descriptions.Item label="Visualizations" style={{ paddingTop: 5, paddingBottom: 5 }}>
            {
              /* Filter array based on extension (if extension is 'qzv' filter it to visualization type) */
              taskOutFilesData &&
                taskOutFilesData
                  .filter((item: OutFile) => {
                    const fileExtension = item.name.split('.').pop();
                    return fileExtension === 'qzv';
                  })
                  .map((file) => {
                    // const to store url of individual file
                    const fileUrl = downloadUrls.find((item) => item.key === file.s3_key)?.url;
                    return (
                      <Button
                        type="link"
                        href={fileUrl ?? '#'}
                        style={{
                          display: 'flex',
                        }}
                        key={file.name}
                      >
                        {file.name}
                      </Button>
                    );
                  })
            }
          </Descriptions.Item>
        </Descriptions>
        {taskKey !== Enum_Mdsa_Task_Key_Enum.Blast ? (
          <Descriptions title="Inputs" style={{ marginTop: 40 }} column={1}>
            {renderInputsForTasks()}
          </Descriptions>
        ) : (
          renderBlastResults()
        )}
      </>
    </AppLayout>
  );
};

export default MdsaResults;
