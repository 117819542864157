import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin, Button, Select, InputNumber, message } from 'antd';
import { useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { OptionProps } from 'antd/es/select';
import {
  MdsaTaskParamsMetadataColumnsQuery,
  MdsaTaskParamsMetadataColumnsQueryVariables,
  UpdateAndStartMdsaTaskMutation,
  UpdateAndStartMdsaTaskMutationVariables,
  MetadataColumn,
  MdsaInParam,
  Enum_Mdsa_Task_Key_Enum,
} from '../graphql/graphql-types';
import EcFormItem from '../components/EcFormItem';
import AppLayout from '../components/AppLayout';
import { taxaLevels, filterSelectOptions, mdsaTasksInitialValues } from '../utils/globals';
import { logger } from '../utils/helpers';
import styles from './ScreenStyles.module.scss';

const mdsaTaskParamsMetadataColumnsQuery = loader(
  '../graphql/queries/mdsaTaskParamsMetadataColumnsQuery.graphql',
);
const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);

/* Validation schema to validate only positive integers */
const numberValidationSchema = yup
  .number()
  .typeError('Only positive integer values are allowed.')
  .nullable()
  .notRequired()
  .positive('Only positive integer values are allowed.')
  .integer('Only positive integer values are allowed.')
  .moreThan(-1);

const MdsaAncom: React.FC = () => {
  /* Extracting id and task id from url params */
  const { id, taskId } = useParams();

  const navigate = useNavigate();

  /* State to store started_at for updateAndStartMdsaTask */
  const [started_at] = useState(new Date());

  /* State to store start analysis buttons loading */
  const [startAnalysisBtnLoading, setStartAnalysisBtnLoading] = useState(false);

  /* useForm declaration with default values  */
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, control, setValue } = useForm<{
    minFrequency: number | null;
    maxFrequency: number | null;
    taxaCollapseLevel: string | null;
    metadataColumns: Array<string>;
  }>({
    resolver: yupResolver(
      yup.object().shape({
        minFrequency: numberValidationSchema,
        maxFrequency: numberValidationSchema,
        metadataColumns: yup
          .array()
          .min(1, 'Please select one or more metadata columns and try again'),
      }),
    ),
    mode: 'onChange',
    defaultValues: {
      minFrequency: null,
      maxFrequency: null,
      taxaCollapseLevel: null,
      metadataColumns: [],
    },
  });

  /* Query to fetch mdsa data for mdsa group table bar plot */
  const { data, loading: mdsaTaskParamsMetadataColumnsLoading, error: queryError } = useQuery<
    MdsaTaskParamsMetadataColumnsQuery,
    MdsaTaskParamsMetadataColumnsQueryVariables
  >(mdsaTaskParamsMetadataColumnsQuery, {
    variables: {
      taskId: parseInt(taskId, 10),
    },
    fetchPolicy: 'network-only',
  });

  /* Mutation to update and start mdsa task */
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  /* Function to update and start mdsa task on click start analysis button */
  const handleUpdateAndStartMdsaTask = (inParamsData: Array<MdsaInParam>) => {
    setStartAnalysisBtnLoading(true);
    updateAndStartMdsaTask({
      variables: {
        taskId: parseInt(taskId, 10),
        taskUpdateInput: {
          started_at,
          in_params: inParamsData,
        },
      },
    })
      .then((res) => {
        const updatedMdsaTaskId = res.data?.update_mdsa_task_by_pk?.id;
        if (updatedMdsaTaskId) {
          setStartAnalysisBtnLoading(false);
          navigate(`/mdsa/${id}`);
        }
      })
      .catch((err) => {
        setStartAnalysisBtnLoading(false);
        logger(err);
      });
  };

  /* Mdsa task in params data */
  const mdsaTaskInParams = data?.task?.in_params as Array<MdsaInParam>;

  // checks if default value is present if present sets it accordingly
  useEffect(() => {
    /* Check whether initially values are present in in_params data */
    if (Array.isArray(mdsaTaskInParams) && mdsaTaskInParams.length > 0) {
      mdsaTaskInParams.forEach((item) => {
        if (item.key === '--p-min-frequency' && item.rawValue) {
          setValue('minFrequency', item.rawValue as number);
        }
        if (item.key === '--p-max-frequency' && item.rawValue) {
          setValue('maxFrequency', item.rawValue as number);
        }
        if (item.key === '--p-level' && item.value) {
          setValue('taxaCollapseLevel', item.value as string);
        }
        if (item.key === 'metadataColumns') {
          setValue('metadataColumns', item.rawValue as string[]);
        }
      });
    }
  }, [mdsaTaskInParams, setValue]);

  /* Mdsa metadata columns data */
  const mdsaMetadataColumnsData = data?.task?.mdsa.metadata_columns as MetadataColumn[];

  /* Loading indicator while fetching data */
  if (mdsaTaskParamsMetadataColumnsLoading) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  /* if any error occurred while fetching data will show error message */
  if (queryError) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  return (
    <AppLayout screenTitle="Microbiome downstream analysis">
      <>
        <h2>Statistical Analysis - ANCOM</h2>
        <EcFormItem
          label="Minimum frequency"
          name="minFrequency"
          control={control}
          errors={errors}
          as={<InputNumber min={0} style={{ width: 200, marginTop: 5 }} />}
        />
        <div style={{ marginTop: 15 }}>
          <EcFormItem
            label="Maximum frequency"
            name="maxFrequency"
            control={control}
            errors={errors}
            as={<InputNumber min={0} style={{ width: 200, marginTop: 5 }} />}
          />
        </div>

        <div style={{ marginTop: 15 }}>
          <EcFormItem
            label="Taxa collapse level"
            name="taxaCollapseLevel"
            control={control}
            errors={errors}
            as={
              <Select placeholder="Select level" style={{ width: 300, marginTop: 5 }} allowClear>
                {Array.isArray(taxaLevels) && taxaLevels.length > 0
                  ? taxaLevels.map((item) => (
                      <Select.Option value={item.level} key={item.level}>
                        {item.name}
                      </Select.Option>
                    ))
                  : null}
              </Select>
            }
          />
        </div>

        <div style={{ marginTop: 20 }}>
          <EcFormItem
            label="Select metadata column(s)"
            name="metadataColumns"
            helpText="Maximum of 5 columns can be selected"
            control={control}
            errors={errors}
            isFieldRequired
            render={({ onChange, value }) => {
              return (
                <Select
                  showSearch
                  value={value as Array<string>}
                  onChange={(val) => {
                    if (Array.isArray(val) && val.length > 5) {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      message.error('Maximum of 5 columns can be selected');
                      return;
                    }
                    onChange(val);
                  }}
                  mode="multiple"
                  maxTagCount={5}
                  placeholder="Select a metadata column"
                  style={{ width: 300, marginTop: 5 }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    filterSelectOptions(input, option as OptionProps)
                  }
                >
                  {Array.isArray(mdsaMetadataColumnsData) && mdsaMetadataColumnsData.length > 0
                    ? mdsaMetadataColumnsData.map((item) => (
                        <Select.Option value={item.column} key={item.column}>
                          {item.column}
                        </Select.Option>
                      ))
                    : null}
                </Select>
              );
            }}
          />
        </div>
        <p className={styles.highlightedInstruction}>
          The platform will run ANCOM for each column selected.
        </p>
        <Button
          onClick={handleSubmit((ancomData) => {
            // obtains the initial value of task Ancom
            const defaultInParams = mdsaTasksInitialValues.find(
              (task) => task.key === Enum_Mdsa_Task_Key_Enum.Ancom,
            )?.in_params as Array<MdsaInParam>;

            // const to store immutable copy of in_params
            const taskInParamImmutableConst = JSON.parse(JSON.stringify(defaultInParams)) as Array<
              MdsaInParam
            >;

            /* Updating in_params data based on user input */
            /* eslint-disable no-param-reassign */
            taskInParamImmutableConst.forEach((item) => {
              if (item.key === '--p-min-frequency' && ancomData.minFrequency) {
                item.enabled = true;
                item.value = ancomData.minFrequency.toString();
                item.rawValue = ancomData.minFrequency;
              }
              if (item.key === '--p-max-frequency' && ancomData.maxFrequency) {
                item.enabled = true;
                item.value = ancomData.maxFrequency.toString();
                item.rawValue = ancomData.maxFrequency;
              }
              if (item.key === '--p-level' && ancomData.taxaCollapseLevel) {
                item.enabled = true;
                item.value = ancomData.taxaCollapseLevel;
                item.rawValue = ancomData.taxaCollapseLevel;
              }
              if (item.key === 'metadataColumns' && ancomData.metadataColumns) {
                item.enabled = true;
                item.rawValue = ancomData.metadataColumns;
              }
            });
            /* eslint-enable no-param-reassign */

            handleUpdateAndStartMdsaTask(taskInParamImmutableConst);
          })}
          type="primary"
          loading={startAnalysisBtnLoading}
        >
          Start Analysis
        </Button>
      </>
    </AppLayout>
  );
};

export default MdsaAncom;
