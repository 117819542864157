import React, { useEffect, useState } from 'react';
import { Button, message, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import papa from 'papaparse';
import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import axios, { AxiosError } from 'axios';
import {
  MdsaFileUploadConfigQuery,
  MdsaFileUploadConfigQueryVariables,
  MdsaTaskIdOfMergeTableSummaryQuery,
  MdsaTaskIdOfMergeTableSummaryQueryVariables,
  MetadataColumn,
  MetadataColumnTypeEnum,
  UpdateAndStartMdsaTaskMutation,
  UpdateAndStartMdsaTaskMutationVariables,
  UpdateMdsaMetadataMutation,
  UpdateMdsaMetadataMutationVariables,
} from '../graphql/graphql-types';
import { MdsaUploadMetadataScreenType } from '../utils/types';
import { logger } from '../utils/helpers';

const { Column } = Table;

/* Type for table data when there are no errors */
type TableDataType = {
  tableColumns: Array<string>;
  tableDataSource: Array<Record<string, string>>;
};

type MdsaMetadataConfirmPropType = {
  /* mdsa id */
  mdsaId: string;
  /* setState to update current component */
  updateCurrentScreen: (currentScreen: MdsaUploadMetadataScreenType) => void;
  /* Task started at timestamp */
  taskStartedAt: Date;
  /* Selected file by user */
  uploadedFile: any;
  /* mdsa task id */
  mdsaTaskId: string;
};

const mdsaFileUploadConfigQuery = loader('../graphql/queries/mdsaFileUploadConfigQuery.graphql');
const updateMdsaMetadataMutation = loader(
  '../graphql/mutations/updateMdsaMetadataMutation.graphql',
);
const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);
const mdsaTaskIdOfMergeTableSummaryQuery = loader(
  '../graphql/queries/mdsaTaskIdOfMergeTableSummaryQuery.graphql',
);

/* React functional component */
const MdsaMetadataConfirm: React.FC<MdsaMetadataConfirmPropType> = ({
  updateCurrentScreen,
  mdsaId,
  taskStartedAt,
  uploadedFile,
  mdsaTaskId,
}) => {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  /* State to store table data when there are no errors(as number of columns will be dynamically decided based on mdsa metadata file) */
  const [tableData, setTableData] = useState<TableDataType>({
    tableColumns: [],
    tableDataSource: [],
  });

  /* Loading state of confirm metadata button */
  const [confirmMetadataLoading, setConfirmMetadataLoading] = useState(false);
  /* State for table loading indicator while fetching the data */
  const [tableLoadingIndicator, setTableLoadingIndicator] = useState<boolean>(true);

  /* Mutation to update mdsa metadata */
  const [updateMdsaMetadata] = useMutation<
    UpdateMdsaMetadataMutation,
    UpdateMdsaMetadataMutationVariables
  >(updateMdsaMetadataMutation);

  /* Mutation to update and start mdsa task */
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  useEffect(() => {
    /* Parsing uploaded mdsa metadata file by user using papaparse */
    papa.parse(uploadedFile, {
      header: true,
      delimiter: '\t',
      complete: (results) => {
        const metaFields = results.meta.fields;
        setTableLoadingIndicator(false);
        if (Array.isArray(metaFields) && metaFields.length > 0) {
          setTableData({
            tableColumns: metaFields,
            tableDataSource: results.data as Array<Record<string, string>>,
          });
        }
      },
      error: (error) => {
        setTableLoadingIndicator(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
      },
    });
  }, [uploadedFile]);

  /* Function to Confirm metadata */
  const handleConfirmMetadata = async () => {
    try {
      setConfirmMetadataLoading(true);
      /* Metadata columns  variable for update mdsa metadata mutation */
      const metadataColumns: Array<MetadataColumn> = [];
      tableData.tableColumns.forEach((item) => {
        if (item !== 'sample-id') {
          metadataColumns.push({
            column: item,
            type: tableData.tableDataSource[0][item] as MetadataColumnTypeEnum,
          });
        }
      });
      /* Query to fetch info/config for uploading file to S3 */
      const { data } = await apolloClient.query<
        MdsaFileUploadConfigQuery,
        MdsaFileUploadConfigQueryVariables
      >({
        query: mdsaFileUploadConfigQuery,
        variables: {
          files: [
            {
              fileName: 'metadata.tsv',
              contentType: 'text/tab-separated-values',
            },
          ],
          mdsa_id: parseInt(mdsaId, 10),
        },
        fetchPolicy: 'no-cache',
      });

      if (data && Array.isArray(data.mdsaFileUploadConfig) && data.mdsaFileUploadConfig[0]) {
        const {
          algorithm,
          contentDisposition,
          contentType,
          credential,
          date,
          key,
          policy,
          signature,
          url,
        } = data.mdsaFileUploadConfig[0];

        /* Creating formdata from query response */
        const formData: FormData = new FormData();
        formData.append('key', key);
        formData.append('policy', policy);
        formData.append('x-amz-algorithm', algorithm);
        formData.append('x-amz-credential', credential);
        formData.append('x-amz-date', date);
        formData.append('x-amz-signature', signature);
        formData.append('Content-Type', contentType);
        formData.append('Content-Disposition', contentDisposition);
        formData.append('file', uploadedFile);

        /* Axios request to upload file to server */
        await axios.post(url, formData);

        /* Updating mdsa metadata using mutation after successful file upload */
        await updateMdsaMetadata({
          variables: {
            mdsa_id: parseInt(mdsaId, 10),
            mdsa_task_id: parseInt(mdsaTaskId, 10),
            metadata_columns: metadataColumns,
            setInput: {
              started_at: taskStartedAt,
              ended_at: new Date(),
              out_files: [
                {
                  name: 'metadata.tsv',
                  s3_key: key,
                  show_in_dashboard_table: true,
                },
              ],
            },
          },
        });

        // calls the query MdsaTaskIdOfMergeTableSummaryQuery
        const result = await apolloClient.query<
          MdsaTaskIdOfMergeTableSummaryQuery,
          MdsaTaskIdOfMergeTableSummaryQueryVariables
        >({
          query: mdsaTaskIdOfMergeTableSummaryQuery,
          variables: { mdsaId: parseInt(mdsaId, 10) },
        });
        // stores the value of mergeTableSummary task id
        const mergeTableSummaryTaskId = result.data.mdsa_task[0].id;
        if (mergeTableSummaryTaskId) {
          // starts the analysis for mergeTableSummaryTask
          await updateAndStartMdsaTask({
            variables: {
              taskId: mergeTableSummaryTaskId,
              taskUpdateInput: { started_at: new Date(), out_files: null, ended_at: null },
            },
          });
          navigate(`/mdsa/${mdsaId}`);
          setConfirmMetadataLoading(false);
        }
      }
    } catch (error) {
      logger(error as ApolloError | AxiosError);
      setConfirmMetadataLoading(false);
    }
  };

  return (
    <>
      <h3>Confirm Uploaded Metadata</h3>

      <Table
        size="small"
        className="qiimeMetadataTable"
        scroll={{ x: 'max-content' }}
        bordered
        dataSource={tableData.tableDataSource}
        rowKey="sample-id"
        loading={tableLoadingIndicator}
      >
        {tableData.tableColumns.map((item) => (
          <Column
            title={item}
            dataIndex={item}
            key={item}
            fixed={item === 'sample-id' ? 'left' : undefined}
          />
        ))}
      </Table>

      <Button type="primary" onClick={() => updateCurrentScreen('mdsaMetadataUploadFile')}>
        Re-Upload
      </Button>
      <Button
        loading={confirmMetadataLoading}
        type="primary"
        style={{ marginLeft: 25 }}
        onClick={handleConfirmMetadata}
      >
        Confirm metadata
      </Button>
    </>
  );
};

export default MdsaMetadataConfirm;
