import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import CreateProjectScreen from './screens/CreateProjectScreen';
// import HomeScreen from './screens/HomeScreen';
import ViewOpenSR from './screens/ViewOpenSRs';
import EditSeqRunInfo from './screens/EditSeqRunInfo';
import ViewAllProjectsScreen from './screens/ViewAllProjectsScreen';
import ViewProjectScreen from './screens/ViewProjectScreen';
import EditProjectScreen from './screens/EditProjectScreen';
import EditProjectUserAccess from './screens/EditProjectUserAccess';
import SequencingRunDashboard from './screens/SequencingRunDashboard';
import SRLinkFastq from './screens/SRLinkFastq';
import SRUploadMetadata from './screens/SRUploadMetadata';
import SRDada2 from './screens/SRDada2';
import CreateMdsa from './screens/CreateMdsa';
import OngoingMdsa from './screens/OngoingMdsas';
import MdsaMergeTables from './screens/MdsaMergeTables';
import ViewAllUsers from './screens/ViewAllUsers';
import CreateUser from './screens/CreateUser';
import MdsaFilterTableByTaxa from './screens/MdsaFilterTableByTaxa';
import MdsaUploadMetadata from './screens/MdsaUploadMetadata';
import MdsaDashboard from './screens/MdsaDashboard';
import TaxonomyClassifiers from './screens/TaxonomyClassifiers';
import MdsaTaxonomicClassification from './screens/MdsaTaxonomicClassification';
import { UserContext } from './contexts/UserContext';
import { MeQuery, MeQueryVariables } from './graphql/graphql-types';
import { firebaseAuth } from './utils/firebase';
import LoadingScreen from './screens/LoadingScreen';
import LoginScreen from './screens/LoginScreen';
import SRResult from './screens/SRResult';
import ViewCompletedSRs from './screens/ViewCompletedSRs';
import MdsaResults from './screens/MdsaResults';
import MdsaGroupTableBarPlot from './screens/MdsaGroupTableBarPlot';
import MdsaPhylogeneticDiversityAnalysis from './screens/MdsaPhylogeneticDiversityAnalysis';
import MdsaFilterDistanceMatrix from './screens/MdsaFilterDistanceMatrix';
import MdsaCustomPcoaPlot from './screens/MdsaCustomPcoaPlot';
import MdsaAncom from './screens/MdsaAncom';
import MdsaBetaDiversity from './screens/MdsaBetaDiversity';
import MdsaAlphaDiversity from './screens/MdsaAlphaDiversity';
import CompletedMdsaScreen from './screens/CompletedMdsaScreen';
import ChangePassword from './screens/ChangePassword';
import EditMdsaInfo from './screens/EditMdsaInfo';
import MdsaTableFilterFeatures from './screens/MdsaTableFilterFeatures';
import MdsaPicrust2 from './screens/MdsaPicrust2';
import MdsaBlast from './screens/MdsaBlast';
import UpdateMdsaMetadataScreen from './screens/updateMdsaMetadata/UpdateMdsaMetadataScreen';
import MdsaTaxaBarPlot from './screens/MdsaTaxaBarPlot';
import MdsaLefse from './screens/MdsaLefse';
import MdsaCart from './screens/MdsaCart';
import DesktopSrCreate from './screens/DesktopSrCreate';
import SamplesReceived from './screens/SamplesReceived';
import HmqSamplesLayout from './components/HmqSamplesLayout';
import SamplesLibraryPreparation from './screens/SamplesLibraryPreparation';
import SamplesSequencing from './screens/SamplesSequencing';
import HmqSettings from './screens/HmqSettings';

const meQuery = loader('./graphql/queries/meQuery.graphql');

// react functional component
const App: React.FC = () => {
  // state to determine the initial loading of app
  const [isAppLoading, setIsAppLoading] = useState<boolean>(true);
  // state to determine user is logged in or not depending on firebase onAuthStateChange function
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  // This lazy query is used to fetch the logged in user's details
  const [fetchUserData, { data }] = useLazyQuery<MeQuery, MeQueryVariables>(meQuery, {
    fetchPolicy: 'no-cache',
  });

  // componentDidMount - Register the firebase auth's onAuthStateChanged listener
  useEffect(() => {
    // Register the onAuthStateChanged listener. It will run the callback function whenver the user's auth state changes (log in or sign out)
    const unregisterAuthStateChangedObserver = firebaseAuth.onAuthStateChanged((fbUser) => {
      setTimeout(() => {
        setIsAppLoading(false);
      }, 1000);

      // If the user has logged in
      if (fbUser) {
        fetchUserData();
        setIsLoggedIn(true);
      }
      // If the user logged out
      else {
        setIsLoggedIn(false);
      }
    });
    return (): void => {
      // Detach the listener before component unmounts
      unregisterAuthStateChangedObserver();
    };
  }, [fetchUserData]);

  // when app loading is true
  if (isAppLoading) return <LoadingScreen />;
  // when user is  not logged in sign in screen will be called
  if (!isLoggedIn) return <LoginScreen />;

  const user = data?.me?.user;
  return (
    <UserContext.Provider value={{ user: user === null ? undefined : user }}>
      <Router>
        <Routes>
          {/* <Route path="/" element={<HomeScreen />} /> */}
          <Route path="/" element={<Navigate to="/mdsa/ongoing" />} />
          <Route path="project/create" element={<CreateProjectScreen />} />
          <Route path="/sr/open" element={<ViewOpenSR />} />
          <Route path="/sr/completed" element={<ViewCompletedSRs />} />
          <Route path="/sr/:id/edit/info" element={<EditSeqRunInfo />} />
          <Route path="/projects" element={<ViewAllProjectsScreen />} />
          <Route path="/project/:id" element={<ViewProjectScreen />} />
          <Route path="/project/:id/edit" element={<EditProjectScreen />} />
          <Route path="/project/:id/edit/access" element={<EditProjectUserAccess />} />
          <Route path="/sr/:id/task/:taskId/linkFastq" element={<SRLinkFastq />} />
          <Route path="/sr/:id" element={<SequencingRunDashboard />} />
          <Route path="/sr/:id/task/:taskId/uploadMetadata" element={<SRUploadMetadata />} />
          <Route path="/sr/:id/task/:taskId/dada2" element={<SRDada2 />} />
          <Route path="/sr/:id/task/:taskId/result" element={<SRResult />} />
          <Route path="/mdsa/create" element={<CreateMdsa />} />
          <Route path="/mdsa/ongoing" element={<OngoingMdsa />} />
          <Route
            path="/mdsa/:id/task/:taskId/filterTableByTaxa"
            element={<MdsaFilterTableByTaxa />}
          />
          <Route path="/mdsa/:id" element={<MdsaDashboard />} />
          <Route path="/mdsa/:id/task/:taskId/mergeTablesSr" element={<MdsaMergeTables />} />
          <Route
            path="/mdsa/:id/task/:taskId/taxonomicClassification"
            element={<MdsaTaxonomicClassification />}
          />
          <Route path="/taxonomyClassifiers" element={<TaxonomyClassifiers />} />
          <Route path="/users" element={<ViewAllUsers />} />
          <Route path="/user/create" element={<CreateUser />} />
          <Route path="/mdsa/:id/task/:taskId/uploadMetadata" element={<MdsaUploadMetadata />} />
          <Route path="/mdsa/:id/task/:taskId/results" element={<MdsaResults />} />
          <Route path="/mdsa/:id/task/:taskId/taxaBarPlot" element={<MdsaTaxaBarPlot />} />
          <Route
            path="/mdsa/:id/task/:taskId/groupTableBarPlot"
            element={<MdsaGroupTableBarPlot />}
          />
          <Route
            path="/mdsa/:id/task/:taskId/phylogeneticDiversityAnalysis"
            element={<MdsaPhylogeneticDiversityAnalysis />}
          />
          <Route
            path="/mdsa/:id/task/:taskId/tableFilterFeatures"
            element={<MdsaTableFilterFeatures />}
          />
          <Route
            path="/mdsa/:id/task/:taskId/filterDistanceMatrix"
            element={<MdsaFilterDistanceMatrix />}
          />
          <Route path="/mdsa/:id/task/:taskId/customPcoaPlot" element={<MdsaCustomPcoaPlot />} />
          <Route path="/mdsa/:id/task/:taskId/ancom" element={<MdsaAncom />} />
          <Route path="/mdsa/:id/task/:taskId/betaDiversity" element={<MdsaBetaDiversity />} />
          <Route path="/mdsa/:id/task/:taskId/alphaDiversity" element={<MdsaAlphaDiversity />} />
          <Route path="/mdsa/completed" element={<CompletedMdsaScreen />} />
          <Route path="/settings/changePassword" element={<ChangePassword />} />
          <Route path="/settings/hmq" element={<HmqSettings />} />
          <Route path="/mdsa/:id/edit" element={<EditMdsaInfo />} />
          <Route path="/mdsa/:id/task/:taskId/picrust2" element={<MdsaPicrust2 />} />
          <Route path="/mdsa/:id/task/:taskId/addColumns" element={<UpdateMdsaMetadataScreen />} />
          <Route path="/mdsa/:id/task/:taskId/blast" element={<MdsaBlast />} />
          <Route path="/mdsa/:id/task/:taskId/lefse" element={<MdsaLefse />} />
          <Route path="/mdsa/:id/task/:taskId/cart" element={<MdsaCart />} />
          <Route path="/desktop/sr/create" element={<DesktopSrCreate />} />
          <Route path="/samples/hmq" element={<HmqSamplesLayout />}>
            <Route path="received" element={<SamplesReceived />} />
            <Route path="libprep" element={<SamplesLibraryPreparation />} />
            <Route path="sequencing" element={<SamplesSequencing />} />
          </Route>
        </Routes>
      </Router>
    </UserContext.Provider>
  );
};

export default App;
