import React from 'react';
import { Spin, Tabs } from 'antd';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { OngoingMdsaQuery } from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';

import OngoingMdsaTableComponent from '../components/OngoingMdsaTableComponent';

const ongoingMdsaQuery = loader('../graphql/queries/ongoingMdsaQuery.graphql');

/* React functional component */
const OngoingMdsa: React.FC = () => {
  /* Query to fetch ongoing mdsa data */
  const { data, loading: ongoingMdsaDataLoading, error: queryError } = useQuery<OngoingMdsaQuery>(
    ongoingMdsaQuery,
    { fetchPolicy: 'network-only' },
  );

  /* show loading fetching data */
  if (ongoingMdsaDataLoading) {
    return (
      <AppLayout screenTitle="Ongoing Downstream Analyses">
        <Spin />
      </AppLayout>
    );
  }

  /* if any error occurred while fetching data will show error message */
  if (queryError) {
    return (
      <AppLayout screenTitle="Ongoing Downstream Analyses">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  return (
    <AppLayout screenTitle="Ongoing Downstream Analyses">
      <Tabs>
        <Tabs.TabPane tab="List view" key="listView">
          <OngoingMdsaTableComponent mdsaTableData={data && data.mdsa} tabName="listView" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Root-child view" key="rootChildView">
          <OngoingMdsaTableComponent mdsaTableData={data && data.mdsa} tabName="rootChildView" />
        </Tabs.TabPane>
      </Tabs>
    </AppLayout>
  );
};

export default OngoingMdsa;
