/*
  To use this form only to edit the SR's info and not create the SR tasks, add the following query parameter to the URL:
    'onlyUpdateSr=true'
 */

import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { Input, Row, Col, Select, Button, message, Radio } from 'antd';
import { loader } from 'graphql.macro';
import AppLayout from '../components/AppLayout';
import EcFormItem from '../components/EcFormItem';
import {
  UpdateSrMutationVariables,
  UpdateSrMutation,
  Enum_Sequencing_Type_Enum,
  Sequencing_Run_Set_Input,
  UpdateSrBasicInfoFirstTimeMutation,
  UpdateSrBasicInfoFirstTimeMutationVariables,
  Enum_Sr_Status_Enum,
  ViewAllProjectsAndSrByPkQueryQueryVariables,
  ViewAllProjectsAndSrByPkQueryQuery,
} from '../graphql/graphql-types';
import { logger } from '../utils/helpers';
import { sequencingRunTasks } from '../utils/globals';

type EditSeqRunInfoFormType = Pick<
  Sequencing_Run_Set_Input,
  'description' | 'project_id' | 'title' | 'type' | 'status'
> & {
  // type definition for tags field
  tags: Array<string> | undefined;
};

// call to graphql files using loader
const viewAllProjectsAndSrByPkQuery = loader(
  '../graphql/queries/viewAllProjectsAndSrByPkQuery.graphql',
);
const updateSRMutation = loader('../graphql/mutations/updateSRMutation.graphql');
const updateSrBasicInfoFirstTimeMutation = loader(
  '../graphql/mutations/updateSrBasicInfoFirstTimeMutation.graphql',
);

// React functional component
const EditSeqRunInfo: React.FC = () => {
  // useState of loading indicator of submit button
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>(false);
  // fetch id from url param
  const { id } = useParams();
  // Get the query string from URL
  const { search } = useLocation();
  // useForm  declaration
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, control, errors, reset, setValue } = useForm<EditSeqRunInfoFormType>({
    defaultValues: {
      project_id: null,
      title: null,
      type: Enum_Sequencing_Type_Enum.Microbiome,
      description: null,
      tags: undefined,
    },
  });

  // useQuery for fetching data of all projects
  const { data: queryData } = useQuery<
    ViewAllProjectsAndSrByPkQueryQuery,
    ViewAllProjectsAndSrByPkQueryQueryVariables
  >(viewAllProjectsAndSrByPkQuery, {
    variables: { id: parseInt(id, 10) },
    onCompleted: (data) => {
      // stores the data of sr
      const srData = data.sequencing_run_by_pk;
      // sets values obtained from
      if (srData && srData.project) {
        setValue('project_id', srData.project.id);
      }
      if (srData && srData.title) {
        setValue('title', srData.title);
      }
      if (srData && srData.type) {
        setValue('type', srData.type);
      }
      if (srData && srData.description) {
        setValue('description', srData.description);
      }
      if (srData && srData.tags) {
        setValue('tags', srData.tags.split(','));
      }
    },
  });
  // useMutation to update sequencing run
  const [updateSR] = useMutation<UpdateSrMutation, UpdateSrMutationVariables>(updateSRMutation);
  // Mutation to update microbiome sequencing run
  const [updateSrBasicInfoFirstTime] = useMutation<
    UpdateSrBasicInfoFirstTimeMutation,
    UpdateSrBasicInfoFirstTimeMutationVariables
  >(updateSrBasicInfoFirstTimeMutation);

  const navigate = useNavigate();

  // Only update SR's info and do not create SR's tasks
  const handleUpdateSr = (srId: number, inputValues: Sequencing_Run_Set_Input) => {
    // call to update sequencing run mutation
    updateSR({
      variables: {
        id: srId,
        inputValues,
      },
    })
      .then(() => {
        setIsSubmitButtonLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Project successfully created!');
        reset();
        // navigate to sequencing run dashboard screen
        navigate(`/sr/${parseInt(id, 10)}`);
      })
      .catch((err: ApolloError) => {
        setIsSubmitButtonLoading(false);
        logger(err);
      });
  };

  return (
    <AppLayout screenTitle="Update Sequencing Run's basic info">
      <>
        <h3 style={{ marginBottom: 10 }}>SR{id}</h3>
        {/* when no there are no project for logged user */}
        {queryData && queryData.project.length === 0 ? (
          <h3 style={{ color: 'red', marginBottom: 20 }}>
            You have not created or have been added to any Project.
            <br />
            You should have access to atleast one project before starting analysis.
          </h3>
        ) : (
          <form
            onSubmit={handleSubmit((data) => {
              setIsSubmitButtonLoading(true);

              const sr_id = parseInt(id, 10);
              const srInputValues: Sequencing_Run_Set_Input = {
                project_id: data.project_id,
                description: data.description ? data.description : null,
                tags: data.tags ? data.tags.toString() : null,
                title: data.title,
                type: data.type,
              };

              // When user only want's to edit the SR's info we will pass 'onlyUpdateSr=true'. In that case we will only run the handleUpdateSr function
              if (search) {
                const urlParams = new URLSearchParams(search);
                const onlyUpdateSrParam = urlParams.get('onlyUpdateSr');
                if (onlyUpdateSrParam && onlyUpdateSrParam === 'true') {
                  handleUpdateSr(sr_id, srInputValues);
                  return;
                }
              }

              // For microbiome sequencing run
              if (data.type === Enum_Sequencing_Type_Enum.Microbiome) {
                updateSrBasicInfoFirstTime({
                  variables: {
                    id: sr_id,
                    inputValues: { ...srInputValues, status: Enum_Sr_Status_Enum.InfoSaved },
                    tasks: sequencingRunTasks().map((item) => ({
                      ...item,
                      sr_id,
                    })),
                  },
                })
                  .then(() => {
                    setIsSubmitButtonLoading(false);
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    message.success('Project successfully created!');
                    reset();
                    // navigate to sequencing run dashboard screen
                    navigate(`/sr/${parseInt(id, 10)}`);
                  })
                  .catch((err: ApolloError) => {
                    setIsSubmitButtonLoading(false);
                    logger(err);
                  });
              }
              // For other sequencing runs
              else {
                handleUpdateSr(sr_id, srInputValues);
              }
            })}
          >
            <Row gutter={[24, 20]}>
              <Col sm={24} lg={6}>
                <EcFormItem
                  label="Project"
                  name="project_id"
                  defaultValue={null}
                  rules={{
                    required: 'Please select project and try again',
                  }}
                  errors={errors}
                  control={control}
                  as={
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder="Select Project Title"
                      optionFilterProp="children"
                      filterOption={(input, option): boolean => {
                        const childrenOption = option?.children as string;
                        const valueOption = option?.value as number;
                        if (childrenOption && valueOption) {
                          return (
                            childrenOption.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            valueOption.toString().indexOf(input) >= 0
                          );
                        }
                        return false;
                      }}
                    >
                      {queryData && Array.isArray(queryData.project) && queryData.project.length > 0
                        ? queryData.project.map(
                            (option): JSX.Element => (
                              <Select.Option value={option.id} key={option.id}>
                                {option.title}
                              </Select.Option>
                            ),
                          )
                        : null}
                    </Select>
                  }
                />
              </Col>
              <Col sm={24} lg={6}>
                <EcFormItem
                  label="Title"
                  name="title"
                  rules={{ required: 'Please enter title and try again' }}
                  errors={errors}
                  control={control}
                  as={<Input allowClear />}
                />
              </Col>
              <Col sm={24} lg={10} style={{ marginBottom: 20 }}>
                <EcFormItem
                  label="Type of sequencing"
                  name="type"
                  control={control}
                  rules={{ required: 'Please select  type of sequencing and try again' }}
                  errors={errors}
                  render={({ onChange, value }): JSX.Element => (
                    <Radio.Group
                      onChange={(e) => onChange(e.target.value)}
                      value={value as string}
                      defaultValue="microbiome"
                    >
                      <Radio value="microbiome">Microbiome</Radio>
                      <Radio value="wgs">WGS</Radio>
                      <Radio value="rna_seq">RNA-seq</Radio>
                    </Radio.Group>
                  )}
                />
              </Col>
            </Row>

            <Row gutter={[24, 15]} style={{ alignItems: 'center' }}>
              <Col sm={24} lg={8}>
                <EcFormItem
                  label="Description"
                  name="description"
                  control={control}
                  as={<Input.TextArea rows={2} allowClear />}
                />
              </Col>
              <Col sm={24} lg={8}>
                <EcFormItem
                  label="Keywords/Tags"
                  name="tags"
                  control={control}
                  defaultValue={undefined}
                  render={({ onChange, value }) => (
                    <Select
                      mode="tags"
                      value={value as string}
                      style={{ width: '100%' }}
                      showArrow
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitButtonLoading}
              style={{ marginTop: 20 }}
            >
              Submit
            </Button>
          </form>
        )}
      </>
    </AppLayout>
  );
};

export default EditSeqRunInfo;
