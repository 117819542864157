/* eslint-disable no-param-reassign */
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { hmqSheetDateFormat } from '../../globals';

/* Type definition for column keys */
type ColumnKeysType = 'kitId' | 'dnaExtractionDate' | 'sampleValidity' | 'libPrepDate';

/* Array of objects that define the columns for the worksheet in the Excel file */
const sheetColumns: Array<Omit<Partial<ExcelJS.Column>, 'key'> & { key: ColumnKeysType }> = [
  { header: 'Kit ID', key: 'kitId' },
  { header: 'DNA extraction date', key: 'dnaExtractionDate', width: 20 },
  { header: 'Sample validity', key: 'sampleValidity', width: 15 },
  { header: 'Lib prep date', key: 'libPrepDate', width: 15 },
];

/**
 * @param {string} filename The name of the Excel file to be created.
 * @param {string[]} hmqKitIds An array of kit IDs to be added as rows in the worksheet.
 * Function to create an Excel file named 'output.xlsx' with a worksheet containing columns for
 * * 'Kit ID', 'DNA extraction date', 'Sample validity', and 'Lib prep date'.
 * It will add rows for each kit ID provided in the array.
 */
const hmqSamplesLibPrepExcelTemplate = async (
  filename: string,
  hmqKitIds: string[],
): Promise<void> => {
  /* Creating a new instance of the `Workbook` class */
  const workbook = new ExcelJS.Workbook();

  /* Adding worksheet */
  const worksheet = workbook.addWorksheet('HMQ samples');

  /* Adding columns to sheet */
  worksheet.columns = sheetColumns;

  /* Adding rows to sheet */
  const rows = hmqKitIds.map((val) => [val, '', '', '']);
  worksheet.addRows(rows);

  /* Making header row font 'bold' */
  worksheet.getRow(1).font = { bold: true };

  /**
   * Looping over all columns in sheet to implement following updates
   * Unlock the cells which needs to be editable by users
   * For column 'dnaExtractionDate' & 'libPrepDate' adding date format and data validation
   * For column 'sampleValidity' adding dropdown with list of values using data validation.
   */
  sheetColumns.forEach((column) => {
    if (column.key !== 'kitId') {
      worksheet.getColumn(column.key as string).eachCell((cell, rowNumber) => {
        if (rowNumber > 1) {
          cell.protection = { locked: false };

          if (column.key === 'dnaExtractionDate' || column.key === 'libPrepDate') {
            cell.numFmt = hmqSheetDateFormat;

            /* Reason for comment - https://bitbucket.org/eumentis-cloud/ecp29-web/pull-requests/204#comment-434996661 */
            // cell.dataValidation = {
            //   type: 'custom',
            //   formulae: [
            //     `=AND(TEXT(${cell.address}, "${dateFormat}")=${cell.address}, LEN(${cell.address})=10, MID(${cell.address},3,1)="-",MID(${cell.address},6,1)="-")`,
            //   ],
            //   errorTitle: 'Invalid date format',
            //   error: `Please enter a valid date in ${dateFormat} format.`,
            //   showErrorMessage: true,
            // };
          }

          if (column.key === 'sampleValidity') {
            cell.dataValidation = {
              type: 'list',
              formulae: ['"Valid,Invalid"'],
              allowBlank: false,
              errorTitle: 'Select one',
              error: 'Please select one of the value from dropdown',
              showErrorMessage: true,
            };
          }
        }
      });
    }
  });

  /* Added sheet protection */
  await worksheet.protect('Eumentis', {
    selectLockedCells: false,
    formatColumns: true,
    formatRows: true,
  });

  /* Downloading created excel sheet to user's device using 'filesaver' */
  // Created a buffer of created file
  const buffer = await workbook.xlsx.writeBuffer();

  // Created a blob from file buffer
  const blob = new Blob([buffer]);

  // Saving file to user's device using filename provided
  FileSaver.saveAs(blob, filename);
};

export default hmqSamplesLibPrepExcelTemplate;
