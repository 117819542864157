import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Spin, Table } from 'antd';
import { loader } from 'graphql.macro';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import {
  SrCheckFastqQuery,
  SrCheckFastqQueryVariables,
  SrCheckFastqOutput,
  SrLinkFastqMutation,
  SrLinkFastqMutationVariables,
  SrLinkFastqOutData,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import { logger } from '../utils/helpers';

const { Column } = Table;

const srCheckFastqQuery = loader('../graphql/queries/srCheckFastqQuery.graphql');
const srLinkFastqMutation = loader('../graphql/mutations/srLinkFastqMutation.graphql');

/* Link Fastq file sample type */
type LinkFastqFileSampleType = Pick<
  SrCheckFastqOutput,
  'sample_id' | 'reverseFastqFile' | 'forwardFastqFile' | '__typename'
>;

/* React functional component */
const SRLinkFastq: React.FC = () => {
  /* Extracting id, taskId from url */
  const { id, taskId } = useParams();
  const navigate = useNavigate();

  /* State to store started_at for srLinkFastqMutation */
  const [started_at] = useState(new Date());

  /* Loading state for confirm fastq files button */
  const [confirmFastqFilesLoading, setConfirmFastqFilesLoading] = useState<boolean>(false);
  /* Loading state for re-link fastq files button */
  const [reLinkFastqFilesLoading, setReLinkFastqFilesLoading] = useState<boolean>(false);

  /* State to store selected row key if user changes select/deselect any row */
  const [selectedTableRowKeys, setSelectedTableRowKeys] = useState<Array<string>>([]);

  /* State to store errored and correct samples of SrCheckFastqData */
  const [linkFastqSampleData, setLinkFastqSampleData] = useState<{
    erroredSamples: Array<LinkFastqFileSampleType>;
    correctSamples: Array<LinkFastqFileSampleType>;
  }>({
    erroredSamples: [],
    correctSamples: [],
  });

  /* Query to fetch data */
  const { loading: srCheckDataLoading, error: queryError, refetch, networkStatus } = useQuery<
    SrCheckFastqQuery,
    SrCheckFastqQueryVariables
  >(srCheckFastqQuery, {
    variables: { sr_id: parseInt(id, 10) },
    fetchPolicy: 'no-cache',
    onCompleted: (queryData) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      checkForErroredSamples(queryData);
    },
  });

  /* Mutation to confirm Link Fastq files */
  const [srLinkFastq] = useMutation<SrLinkFastqMutation, SrLinkFastqMutationVariables>(
    srLinkFastqMutation,
  );

  /* Check for Srcheck data contains error samples or not */
  const checkForErroredSamples = (queryData: SrCheckFastqQuery) => {
    if (queryData && Array.isArray(queryData.srCheckFastq) && queryData.srCheckFastq.length > 0) {
      /* Array to store errored samples in sr check data */
      const errorSamplesSrCheckDataa: Array<LinkFastqFileSampleType> = [];
      /* Array to store correct samples in sr check data */
      const correctSamplesSrCheckDataa: Array<LinkFastqFileSampleType> = [];
      /* Array to store keys of selected rows when there are no errored samples */
      const selectedRowKeys: Array<string> = [];
      queryData.srCheckFastq.forEach((val) => {
        if (val) {
          if (!val.forwardFastqFile || !val.reverseFastqFile) {
            errorSamplesSrCheckDataa.push(val);
          } else {
            correctSamplesSrCheckDataa.push(val);

            /* Storing sample ids for rows to be selected initially  */
            const sampleId = val?.sample_id;
            if (sampleId) {
              selectedRowKeys.push(sampleId);
            }
          }
        }
      });
      setSelectedTableRowKeys(selectedRowKeys);
      setLinkFastqSampleData({
        erroredSamples: errorSamplesSrCheckDataa,
        correctSamples: correctSamplesSrCheckDataa,
      });
    }
  };

  /* if any error occured while fetching data will show error message */
  if (queryError) {
    return (
      <AppLayout screenTitle="Sequencing Run analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  /* Loading indicator while fetching data */
  if (srCheckDataLoading || networkStatus === NetworkStatus.refetch) {
    return (
      <AppLayout screenTitle="Sequencing Run analysis">
        <>
          <h2>SR{id}</h2>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
            }}
          >
            <Spin size="large" />
          </div>
        </>
      </AppLayout>
    );
  }

  /* Confirming selected fastq files when there are no error samples */
  const handleConfirmFastqFiles = () => {
    setConfirmFastqFilesLoading(true);
    let mutationSampleData = linkFastqSampleData.correctSamples;
    if (
      Array.isArray(selectedTableRowKeys) &&
      selectedTableRowKeys.length !== linkFastqSampleData.correctSamples.length
    ) {
      mutationSampleData = linkFastqSampleData.correctSamples.filter((item) =>
        selectedTableRowKeys.includes(item.sample_id),
      );
    }

    /* Removing __typename property from each sample */
    mutationSampleData.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      delete item.__typename;
    });

    srLinkFastq({
      variables: {
        task_id: parseInt(taskId, 10),
        ended_at: new Date(),
        started_at,
        out_data: {
          samples: mutationSampleData,
        } as SrLinkFastqOutData,
        sr_id: parseInt(id, 10),
      },
    })
      .then(() => {
        setConfirmFastqFilesLoading(false);
        navigate(`/sr/${id}`);
      })
      .catch((err) => {
        setConfirmFastqFilesLoading(false);
        logger(err);
      });
  };

  /* Re linking samples which has errors */
  const handleReLinkingFastqFiles = () => {
    setReLinkFastqFilesLoading(true);
    /* Refetching srCheckFastqQuery */
    refetch()
      .then((refetchResponse) => {
        setReLinkFastqFilesLoading(false);
        checkForErroredSamples(refetchResponse.data);
      })
      .catch((refetchError) => {
        logger(refetchError);
        setReLinkFastqFilesLoading(false);
      });
  };

  return (
    <AppLayout screenTitle="Link FASTQ Files">
      <>
        <h2>SR{id}</h2>
        {/* if there are any error samples in the fastq sample data then following table will render with details of those errored samples with re-linking button */}
        {linkFastqSampleData.erroredSamples.length > 0 ? (
          <>
            <h4>There were errors in the following samples:</h4>
            <Table<LinkFastqFileSampleType>
              bordered
              dataSource={linkFastqSampleData.erroredSamples}
              rowKey="sample_id"
            >
              <Column title="Sample Id" dataIndex="sample_id" key="sample_id" />
              <Column
                title="Forward reads"
                dataIndex="forwardFastqFile"
                key="forwardFastqFile"
                render={(forwardFastq: string) => forwardFastq ?? <h4>File Not Found</h4>}
              />
              <Column
                title="Reverse reads"
                dataIndex="reverseFastqFile"
                key="reverseFastqFile"
                render={(reverseFastq: string) => reverseFastq ?? <h4>File Not Found</h4>}
              />
            </Table>
            <Button
              type="primary"
              onClick={handleReLinkingFastqFiles}
              loading={reLinkFastqFilesLoading}
              style={{ marginBottom: 25 }}
            >
              Re-link FASTQ Files
            </Button>
          </>
        ) : null}
        <h4>
          FASTQ Files for {linkFastqSampleData.correctSamples.length} samples were detected.{' '}
          {linkFastqSampleData.erroredSamples.length === 0
            ? 'Select the samples you want to use for DADA2'
            : null}
        </h4>

        <Table<LinkFastqFileSampleType>
          bordered
          dataSource={linkFastqSampleData.correctSamples}
          rowKey="sample_id"
          rowSelection={
            linkFastqSampleData.erroredSamples.length === 0
              ? {
                  selectedRowKeys: selectedTableRowKeys,
                  onChange: (updatedRowKeys) => {
                    setSelectedTableRowKeys(updatedRowKeys as Array<string>);
                  },
                }
              : undefined
          }
        >
          <Column title="Sample Id" dataIndex="sample_id" key="sample_id" />
          <Column title="Forward reads" dataIndex="forwardFastqFile" key="forwardFastqFile" />
          <Column title="Reverse reads" dataIndex="reverseFastqFile" key="reverseFastqFile" />
        </Table>

        {/* User can only confirm linking of fastq files when there are no errored samples */}
        {linkFastqSampleData.erroredSamples.length === 0 ? (
          <Button
            type="primary"
            onClick={handleConfirmFastqFiles}
            loading={confirmFastqFilesLoading}
          >
            Confirm FASTQ Files
          </Button>
        ) : null}
      </>
    </AppLayout>
  );
};

export default SRLinkFastq;
