import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin, Button, InputNumber } from 'antd';
import { useForm } from 'react-hook-form';
import Reference from 'yup/lib/Reference';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  MdsaTaskParamsMetadataColumnsQuery,
  MdsaTaskParamsMetadataColumnsQueryVariables,
  UpdateAndStartMdsaTaskMutation,
  UpdateAndStartMdsaTaskMutationVariables,
  MdsaInParam,
  Enum_Mdsa_Task_Key_Enum,
} from '../graphql/graphql-types';
import EcFormItem from '../components/EcFormItem';
import AppLayout from '../components/AppLayout';
import { logger } from '../utils/helpers';
import { positiveIntegerValidation, mdsaTasksInitialValues } from '../utils/globals';

const mdsaTaskParamsMetadataColumnsQuery = loader(
  '../graphql/queries/mdsaTaskParamsMetadataColumnsQuery.graphql',
);
const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);

/* Validation schema to min value fields */
const numberValidationSchemaForMinValFields = positiveIntegerValidation.moreThan(
  -1,
  'Only positive integer values are allowed.',
);
/* Validation schema to max value fields */
const numberValidationSchemaForMaxValFields = positiveIntegerValidation.moreThan(
  0,
  'Value should be greater than 0.',
);

const MdsaTableFilterFeatures: React.FC = () => {
  /* Extracting id and task id from url params */
  const { id, taskId } = useParams();

  const navigate = useNavigate();

  /* State to store started_at for updateAndStartMdsaTask */
  const [started_at] = useState(new Date());

  /* State to store start analysis buttons loading */
  const [startAnalysisBtnLoading, setStartAnalysisBtnLoading] = useState(false);

  /* Query to fetch mdsa data for mdsa table filter features */
  const { data, loading: mdsaTaskParamsMetadataColumnsLoading, error: queryError } = useQuery<
    MdsaTaskParamsMetadataColumnsQuery,
    MdsaTaskParamsMetadataColumnsQueryVariables
  >(mdsaTaskParamsMetadataColumnsQuery, {
    variables: {
      taskId: parseInt(taskId, 10),
    },
    fetchPolicy: 'network-only',
  });

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, control, watch, setValue } = useForm<{
    minFrequency: number | null;
    maxFrequency: number | null;
    minSamples: number | null;
    maxSamples: number | null;
  }>({
    resolver: yupResolver(
      yup.object().shape({
        minFrequency: numberValidationSchemaForMinValFields,
        maxFrequency: numberValidationSchemaForMaxValFields.moreThan(
          yup.ref('minFrequency') as Reference<number>,
          '--p-max-frequency should be greater than --p-min-frequency',
        ),
        minSamples: numberValidationSchemaForMinValFields,
        maxSamples: numberValidationSchemaForMaxValFields.moreThan(
          yup.ref('minSamples') as Reference<number>,
          '--p-max-samples should be greater than --p-min-samples',
        ),
      }),
    ),
    mode: 'onChange',
  });

  /* Mdsa task in params data */
  const mdsaTaskInParams = data?.task?.in_params as Array<MdsaInParam>;

  // fetched and set initial values if present
  useEffect(() => {
    /* Check whether initially values are present in in_params data */
    if (Array.isArray(mdsaTaskInParams) && mdsaTaskInParams.length > 0) {
      mdsaTaskInParams.forEach((item) => {
        if (item.key === '--p-min-frequency' && item.value) {
          setValue('minFrequency', item.rawValue as number);
        }
        if (item.key === '--p-max-frequency' && item.value) {
          setValue('maxFrequency', item.rawValue as number);
        }
        if (item.key === '--p-min-samples' && item.value) {
          setValue('minSamples', item.rawValue as number);
        }
        if (item.key === '--p-max-samples' && item.value) {
          setValue('maxSamples', item.rawValue as number);
        }
      });
    }
  }, [mdsaTaskInParams, setValue]);

  /* Mutation to update and start mdsa task */
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  /* Loading indicator while fetching data */
  if (mdsaTaskParamsMetadataColumnsLoading) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  /* if any error occurred while fetching data will show error message */
  if (queryError) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  /* Function to update and start mdsa task on click start analysis button */
  const handleUpdateAndStartMdsaTask = (inParamsData: Array<MdsaInParam>) => {
    setStartAnalysisBtnLoading(true);
    updateAndStartMdsaTask({
      variables: {
        taskId: parseInt(taskId, 10),
        taskUpdateInput: {
          started_at,
          in_params: inParamsData,
        },
      },
    })
      .then((res) => {
        const updatedMdsaTaskId = res.data?.update_mdsa_task_by_pk?.id;
        if (updatedMdsaTaskId) {
          setStartAnalysisBtnLoading(false);
          navigate(`/mdsa/${id}`);
        }
      })
      .catch((err) => {
        setStartAnalysisBtnLoading(false);
        logger(err);
      });
  };

  return (
    <AppLayout screenTitle="Microbiome downstream analysis">
      <>
        <h2>Filter features from table</h2>
        <EcFormItem
          label="--p-min-frequency"
          name="minFrequency"
          control={control}
          errors={errors}
          as={<InputNumber style={{ width: 200, marginTop: 5 }} min={0} />}
          helpText="The minimum total frequency that a feature must have to be retained"
        />
        <EcFormItem
          label="--p-max-frequency"
          name="maxFrequency"
          control={control}
          errors={errors}
          as={<InputNumber style={{ width: 200, marginTop: 5 }} min={1} />}
          helpText="The maximum total frequency that a feature must have to be retained"
          containerStyle={{ marginTop: 15 }}
        />
        <EcFormItem
          label="--p-min-samples"
          name="minSamples"
          control={control}
          errors={errors}
          as={<InputNumber style={{ width: 200, marginTop: 5 }} min={0} />}
          helpText="The minimum number of samples that a feature must be observed in to be retained"
          containerStyle={{ marginTop: 15 }}
        />
        <EcFormItem
          label="--p-max-samples"
          name="maxSamples"
          control={control}
          errors={errors}
          as={<InputNumber style={{ width: 200, marginTop: 5 }} min={1} />}
          helpText="The maximum number of samples that a feature can be observed in to be retained"
          containerStyle={{ marginTop: 15 }}
        />

        <Button
          onClick={handleSubmit(({ minFrequency, maxFrequency, minSamples, maxSamples }) => {
            // obtains the initial value of task table filter fetaures
            const defaultInParams = mdsaTasksInitialValues.find(
              (task) => task.key === Enum_Mdsa_Task_Key_Enum.TableFilterFeatures,
            )?.in_params as Array<MdsaInParam>;
            // const to store immutable copy of in_params
            const taskInParamImmutableConst = JSON.parse(JSON.stringify(defaultInParams)) as Array<
              MdsaInParam
            >;

            // Set the in_params value according to the inputs provided by the user
            /* eslint-disable no-param-reassign */
            taskInParamImmutableConst.forEach((item) => {
              if (item.key === '--p-min-frequency' && minFrequency) {
                item.enabled = true;
                item.rawValue = minFrequency;
                item.value = minFrequency.toString();
              }
              if (item.key === '--p-max-frequency' && maxFrequency) {
                item.enabled = true;
                item.rawValue = maxFrequency;
                item.value = maxFrequency.toString();
              }
              if (item.key === '--p-min-samples' && minSamples) {
                item.enabled = true;
                item.rawValue = minSamples;
                item.value = minSamples.toString();
              }
              if (item.key === '--p-max-samples' && maxSamples) {
                item.enabled = true;
                item.rawValue = maxSamples;
                item.value = maxSamples.toString();
              }
            });
            /* eslint-enable no-param-reassign */
            handleUpdateAndStartMdsaTask(taskInParamImmutableConst);
          })}
          type="primary"
          loading={startAnalysisBtnLoading}
          disabled={
            !watch('minSamples') &&
            !watch('maxSamples') &&
            !watch('minFrequency') &&
            !watch('maxFrequency')
          }
          style={{ marginTop: 20 }}
        >
          Start Analysis
        </Button>
      </>
    </AppLayout>
  );
};

export default MdsaTableFilterFeatures;
