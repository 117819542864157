import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SequencingDateRunOutputFormType, SuccessDataOfSamplesType } from '../utils/types';
import FinalSequencingRunSamplesDetails from './FinalSequencingRunSamplesDetails';
import ErrorSequencingRunSamplesDetails from './ErrorSequencingRunSamplesDetails';
import SequencingDateRunOutputForm from './SequencingDateRunOutputForm';

const DesktopSrCreate = (): JSX.Element => {
  // state to store details of all run samples success data for selected date
  const [sequencerRunSamplesSuccessDetails, setSequencerRunSamplesSuccessDetails] = useState<
    SuccessDataOfSamplesType | undefined
  >(undefined);

  // useForm declaration
  const sequencingDateRunOutputUseForm = useForm<SequencingDateRunOutputFormType>({
    defaultValues: {
      sequencingDate: null,
      sequencerRunOutDir: null,
    },
  });

  // stores form value of sequencerRunoutDir
  const sequencerRunOutDirValue = sequencingDateRunOutputUseForm.watch('sequencerRunOutDir');

  // renders valid final sequencing run samples details or sample details with errors
  const renderSequencingRunSamplesDetails = () => {
    // render nothing if sequencer run output directory is not provided or sequencer run samples are not present
    if (sequencerRunOutDirValue === null || !sequencerRunSamplesSuccessDetails) {
      return null;
    }

    // stores array of samples having missing fastq files
    const sequencerSamplesWithMissingFastqFiles = sequencerRunSamplesSuccessDetails.samples.filter(
      (sample) => !(sample.forwardFastqFile && sample.reverseFastqFile),
    );

    if (sequencerSamplesWithMissingFastqFiles.length > 0) {
      // executes if run sample details are missing forward or reverse fastQ file
      return (
        <ErrorSequencingRunSamplesDetails
          selectedSequencerRunOutputDir={sequencerRunOutDirValue}
          setSequencerRunSamplesSuccessDetails={setSequencerRunSamplesSuccessDetails}
          listOfRunSamplesWithError={sequencerSamplesWithMissingFastqFiles}
        />
      );
    }

    // executes if all run sample details are present
    return (
      <FinalSequencingRunSamplesDetails
        sequencerRunSuccessSamplesDetails={sequencerRunSamplesSuccessDetails}
      />
    );
  };

  return (
    <>
      <div style={{ display: sequencerRunSamplesSuccessDetails ? 'none' : 'block' }}>
        <SequencingDateRunOutputForm
          useForm={sequencingDateRunOutputUseForm}
          setSequencerRunSamplesSuccessDetails={setSequencerRunSamplesSuccessDetails}
        />
      </div>
      {renderSequencingRunSamplesDetails()}
    </>
  );
};

export default DesktopSrCreate;
