import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin, Button, Input, message } from 'antd';
import { useForm, useFieldArray, FieldError } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  MdsaTaskParamsMetadataColumnsQuery,
  MdsaTaskParamsMetadataColumnsQueryVariables,
  UpdateAndStartMdsaTaskMutation,
  UpdateAndStartMdsaTaskMutationVariables,
  MdsaInParam,
  Enum_Mdsa_Task_Key_Enum,
  UpdateMdsaTaskInParamsMutation,
  UpdateMdsaTaskInParamsMutationVariables,
} from '../graphql/graphql-types';
import EcFormItem from '../components/EcFormItem';
import AppLayout from '../components/AppLayout';
import { logger } from '../utils/helpers';
import { mdsaTasksInitialValues } from '../utils/globals';

const mdsaTaskParamsMetadataColumnsQuery = loader(
  '../graphql/queries/mdsaTaskParamsMetadataColumnsQuery.graphql',
);
const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);
const updateMdsaTaskInParamsMutation = loader(
  '../graphql/mutations/updateMdsaTaskInParamsMutation.graphql',
);

// The maximum no of feature ids (sequences) that can be blasted at once
const MAX_BLAST_INPUT_SEQS = 15;

// type definition for feature ids
type FeatureIds = {
  // indicates feature id
  featureId: null | string;
}[];

// type definition for formdata
type FormData = {
  // indicates feature id
  featureIds: FeatureIds;
};

// stores the form default values
const formDefaultValues: FormData = { featureIds: [] };

// sets the default value to null to each feature id
for (let i = 1; i <= MAX_BLAST_INPUT_SEQS; i++) {
  formDefaultValues.featureIds.push({ featureId: null });
}

const MdsaBlast: React.FC = () => {
  /* Extracting id and task id from url params */
  const { id, taskId } = useParams();

  const navigate = useNavigate();

  /* State to store started_at for updateAndStartMdsaTask */
  const [started_at] = useState<Date>(new Date());

  /* State to store start analysis button loading */
  const [startAnalysisBtnLoading, setStartAnalysisBtnLoading] = useState<boolean>(false);

  /* State to store save list button loading */
  const [saveListBtnLoading, setSaveListBtnLoading] = useState<boolean>(false);

  /* Query to fetch mdsa data for mdsa group table bar plot */
  const { data, loading: mdsaTaskParamsMetadataColumnsLoading, error: queryError } = useQuery<
    MdsaTaskParamsMetadataColumnsQuery,
    MdsaTaskParamsMetadataColumnsQueryVariables
  >(mdsaTaskParamsMetadataColumnsQuery, {
    variables: {
      taskId: parseInt(taskId, 10),
    },
    fetchPolicy: 'network-only',
  });

  /* useForm declaration with default values  */
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, control, setValue, clearErrors } = useForm<FormData>({
    resolver: yupResolver(
      yup.object().shape({
        featureIds: yup
          .array(
            yup.object().shape({
              featureId: yup
                .string()
                .matches(/^[a-zA-Z0-9]*$/, 'Only characters and numbers are allowed in feature id.')
                .nullable(),
            }),
          )
          .test({
            name: 'oneIdRequired',
            message: 'At least one value is required',
            test: (value, { createError }) => {
              if (Array.isArray(value) && value.length > 0) {
                // filters array with values present and checks if length is greater than 0 as atleast one id is required
                if (value.filter((item) => item.featureId).length === 0) {
                  return new yup.ValidationError(
                    createError({
                      path: `oneIdRequired`,
                      message: 'At least one value is required.',
                    }),
                  );
                }
              }
              return true;
            },
          })
          .test({
            name: 'unique',
            message: 'Duplicate feature id entered. Please enter unique feature id.',
            test: (value, { createError }) => {
              if (Array.isArray(value) && value.length > 0) {
                // stores the validation errors
                const validationErrors: yup.ValidationError[] = [];
                // stores the value of feature ids entered also converts each to lowercase
                const featureIds = value.map((item) => item.featureId?.toLowerCase());
                featureIds.forEach((item, index) => {
                  // checks for duplicate entry
                  if (item && featureIds.indexOf(item) !== index) {
                    // if duplicate value is found error is created
                    validationErrors.push(
                      createError({
                        path: `featureIds.${index}.featureId`,
                        message: 'Duplicate feature id entered. Please enter a new feature id.',
                      }),
                    );
                  }
                });
                // validates errors created
                if (validationErrors.length > 0) {
                  return new yup.ValidationError(validationErrors);
                }
              }
              return true;
            },
          }),
      }),
    ),
    mode: 'onChange',
    defaultValues: formDefaultValues,
  });

  /* Mdsa task in params data */
  const mdsaTaskInParams = data?.task?.in_params as Array<MdsaInParam>;

  // checks if default value is present if present sets it
  useEffect(() => {
    if (Array.isArray(mdsaTaskInParams) && mdsaTaskInParams.length > 0) {
      mdsaTaskInParams.forEach((item) => {
        if (item.key === 'featureIds' && Array.isArray(item.rawValue) && item.rawValue.length > 0) {
          item.rawValue.forEach((val: string | number, index: number) => {
            setValue(`featureIds.${index}.featureId`, val);
          });
        }
      });
    }
  }, [mdsaTaskInParams, setValue]);

  // created field array for feature ids
  const { fields } = useFieldArray({
    control,
    name: 'featureIds',
  });

  /* Mutation to update and start mdsa task */
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  /* Mutation to update mdsa task in params */
  const [updateMdsaTaskInParams] = useMutation<
    UpdateMdsaTaskInParamsMutation,
    UpdateMdsaTaskInParamsMutationVariables
  >(updateMdsaTaskInParamsMutation);

  /* Loading indicator while fetching data */
  if (mdsaTaskParamsMetadataColumnsLoading) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  /* if any error occurred while fetching data will show error message */
  if (queryError) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  // obtains the in_params value to set
  const getParamsValueToSet = (values: { featureIds: FeatureIds }) => {
    // obtains the initial value of task blast
    const defaultInParams = mdsaTasksInitialValues.find(
      (task) => task.key === Enum_Mdsa_Task_Key_Enum.Blast,
    )?.in_params as Array<MdsaInParam>;
    // const to store immutable copy of in_params
    const taskInParamImmutableConst = JSON.parse(JSON.stringify(defaultInParams)) as Array<
      MdsaInParam
    >;

    // obtaines the values to set
    const valuesToSet = values.featureIds
      .filter((item) => item.featureId)
      .map((item) => item.featureId);

    // Set the in_params value according to the inputs provided by the user
    /* eslint-disable no-param-reassign */
    taskInParamImmutableConst.forEach((item) => {
      if (item.key === 'featureIds') {
        item.rawValue = valuesToSet as string[];
      }
    });
    return taskInParamImmutableConst;
  };

  return (
    <AppLayout screenTitle="Microbiome downstream analysis">
      <>
        <h2>BLAST</h2>
        <h4 style={{ margin: 0, marginBottom: 2 }}>Create a list of Feature IDs to BLAST</h4>
        <div style={{ marginBottom: 10, fontSize: 13, fontStyle: 'italic' }}>
          Maximum of {MAX_BLAST_INPUT_SEQS} IDs allowed
        </div>

        {fields.map((item, index) => {
          return (
            <EcFormItem
              errors={errors}
              key={item.id}
              name={`featureIds.${index}.featureId`}
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Input
                    key={item.id}
                    onChange={(e) => {
                      clearErrors('oneIdRequired');
                      onChange(e.target.value);
                    }}
                    value={value as string}
                    style={{ width: 450, marginTop: 5 }}
                    allowClear
                  />
                );
              }}
            />
          );
        })}

        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {errors && errors.oneIdRequired ? (
          <span style={{ color: 'red' }}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {((errors.oneIdRequired as unknown) as FieldError).message}
          </span>
        ) : null}

        <div style={{ display: 'flex', marginTop: 20 }}>
          <Button
            type="primary"
            loading={saveListBtnLoading}
            onClick={handleSubmit((values) => {
              setSaveListBtnLoading(true);
              // stores the values of in param to set
              const inParams = getParamsValueToSet(values);
              updateMdsaTaskInParams({
                variables: {
                  taskId: parseInt(taskId, 10),
                  inParams,
                },
              })
                .then(() => {
                  setSaveListBtnLoading(false);
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  message.success('List saved successfully');
                })
                .catch((err) => {
                  setSaveListBtnLoading(false);
                  logger(err);
                });
            })}
          >
            Save list
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={startAnalysisBtnLoading}
            style={{ marginLeft: 20 }}
            onClick={handleSubmit((values) => {
              setStartAnalysisBtnLoading(true);
              // stores the values of in param to set
              const inParams = getParamsValueToSet(values);
              updateAndStartMdsaTask({
                variables: {
                  taskId: parseInt(taskId, 10),
                  taskUpdateInput: {
                    started_at,
                    in_params: inParams,
                  },
                },
              })
                .then((res) => {
                  const updatedMdsaTaskId = res.data?.update_mdsa_task_by_pk?.id;
                  if (updatedMdsaTaskId) {
                    setStartAnalysisBtnLoading(false);
                    navigate(`/mdsa/${id}`);
                  }
                })
                .catch((err) => {
                  setStartAnalysisBtnLoading(false);
                  logger(err);
                });
            })}
          >
            Start Analysis
          </Button>
        </div>
      </>
    </AppLayout>
  );
};

export default MdsaBlast;
