import React, { useState } from 'react';
import { Button, Upload, message } from 'antd';
import { useApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';
import papa from 'papaparse';
import FileSaver from 'file-saver';
import { InboxOutlined } from '@ant-design/icons';
import { GetFilesUrlQuery, GetFilesUrlQueryVariables } from '../graphql/graphql-types';
import { MdsaUploadMetadataScreenType, UploadFileDetailsType } from '../utils/types';
import { logger } from '../utils/helpers';

const { Dragger } = Upload;

type MdsaUploadMetadataFilePropType = {
  /* s3 keys of sequencing run metadata files */
  seqRunMetadataFilesS3Keys: Array<string>;
  /* sample ids of sequencing runs in mdsa upload metadata */
  sampleIds: Array<string>;
  /* current metadata file url when user wants to re-upload */
  currentMetadataFileUrl?: string | null;
  /* State to store selected file by user */
  uploadFileDetails: UploadFileDetailsType;
  /* set state function to updated selected file by user */
  updateUploadFileDetails: (currentFileDetails: UploadFileDetailsType) => void;
  /* setState to update current component */
  updateCurrentScreen: (currentScreen: MdsaUploadMetadataScreenType) => void;
  /* mdsa id */
  mdsaId: string;
};

const getFilesUrlQuery = loader('../graphql/queries/getFilesUrlQuery.graphql');

/* React functional component */
const MdsaMetadataUploadFile: React.FC<MdsaUploadMetadataFilePropType> = ({
  seqRunMetadataFilesS3Keys,
  currentMetadataFileUrl,
  sampleIds,
  uploadFileDetails,
  updateUploadFileDetails,
  updateCurrentScreen,
  mdsaId,
}) => {
  const apolloClient = useApolloClient();

  /* State to decide whether to show error of no file selected */
  const [showNoFileSelectedErr, setShowNoFileSelectedErr] = useState(false);
  /* Loading state of download metadata template button */
  const [downloadMetadataTemplateLoading, setDownloadMetadataTemplateLoading] = useState(false);

  /* Function to combine tsv files */
  const handleCombineTsvFiles = (downloadUrls: Array<string>) => {
    /* Parsing files using papaparse to fetch data from tsv files */
    Promise.all(
      downloadUrls.map(
        (downloadUrl) =>
          new Promise<papa.ParseResult<Record<string, string>>>((resolve, reject) =>
            papa.parse(downloadUrl, {
              download: true,
              header: true,
              complete: resolve,
              error: reject,
            }),
          ),
      ),
    )
      .then((results) => {
        /* Variable to store column headers of parsed all metadata files */
        const combinedColumnHeaders: Array<string> = [];
        /* Variable to store data of parsed of all metadata files */
        let combinedData: Array<Record<string, string>> = [];
        /* Variable to store data types of columns in all parsed metadata files */
        let combinedColumnDataTypes: Record<string, string> = {};

        results.forEach((result) => {
          const { meta, data } = result;
          if (Array.isArray(meta.fields)) {
            meta.fields.forEach((columnHeader) => {
              if (!combinedColumnHeaders.includes(columnHeader)) {
                combinedColumnHeaders.push(columnHeader);
              }
            });
          }
          combinedData = [...combinedData, ...data];
        });

        /* filtering rows from tsv files whose sample id is not present in sampleIds prop */
        const filteredCombinedFilesData = combinedData.filter((item: Record<string, string>) => {
          if (item['sample-id'] === '#q2:types') {
            combinedColumnDataTypes = { ...combinedColumnDataTypes, ...item };
            return false;
          }
          return sampleIds.includes(item['sample-id']);
        });

        /* Inserting columns data types at second position of files data for making it as second row in combined tsv file */
        filteredCombinedFilesData.unshift(combinedColumnDataTypes);

        /* Unparsing combined files data tsv */
        const unparsedFilesTsvData = papa.unparse(
          {
            fields: combinedColumnHeaders,
            data: filteredCombinedFilesData,
          },
          {
            delimiter: '\t',
          },
        );

        setDownloadMetadataTemplateLoading(false);
        /* File blob create for file saver to save file */
        const fileBlob = new Blob([unparsedFilesTsvData]);

        /* Saving file on user's device using saveAs */
        FileSaver.saveAs(fileBlob, `MDSA${mdsaId}_metadata_template.tsv`);
      })
      .catch((error) => {
        console.log('Catched error is', error);
        setDownloadMetadataTemplateLoading(false);
      });
  };

  /* Function to create tsv file template which user can download */
  const handleDownloadMetadataTemplate = () => {
    setDownloadMetadataTemplateLoading(true);
    /* Fetching file urls for all s3 keys in seqRunMetadataFilesS3Keys */
    apolloClient
      .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
        query: getFilesUrlQuery,
        variables: { keys: seqRunMetadataFilesS3Keys },
      })
      .then((response) => {
        const fileUrls = response.data.getFilesUrl;
        if (Array.isArray(fileUrls) && fileUrls.length > 0) {
          const fileDownloadUrls = [];
          for (let i = 0; i < fileUrls.length; i++) {
            const fileUrl = fileUrls[i]?.url;
            if (fileUrl) {
              fileDownloadUrls.push(fileUrl);
            }
          }

          if (fileDownloadUrls.length === 1) {
            /* Directly parsing file if fileDownloadUrls contains single file */
            papa.parse(fileDownloadUrls[0], {
              download: true,
              header: true,
              complete: (results: papa.ParseResult<Record<string, string>>) => {
                const { data, meta } = results;

                /* Filtering tsv data parsed from tsv file based on selected sample ids */
                const filteredTsvData = data.filter(
                  (item) =>
                    sampleIds.includes(item['sample-id']) || item['sample-id'] === '#q2:types',
                );

                /* Unparsing filtered data to create tsv file with this data */
                const unparsedTsvData = papa.unparse(
                  {
                    fields: meta.fields as string[],
                    data: filteredTsvData,
                  },
                  {
                    delimiter: '\t',
                  },
                );

                setDownloadMetadataTemplateLoading(false);
                /* File blob create for file saver to save file */
                const fileBlob = new Blob([unparsedTsvData]);

                /* Saving file on user's device using saveAs */
                FileSaver.saveAs(fileBlob, `MDSA${mdsaId}_metadata_template.tsv`);
              },
              error: (error) => {
                setDownloadMetadataTemplateLoading(false);
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.error(error.message);
              },
            });
          } else {
            /* Function to create one combined tsv file from all fetched files */
            handleCombineTsvFiles(fileDownloadUrls);
          }
        }
      })
      .catch((error) => {
        logger(error);
        setDownloadMetadataTemplateLoading(false);
      });
  };

  return (
    <>
      <Button
        type="primary"
        onClick={handleDownloadMetadataTemplate}
        loading={downloadMetadataTemplateLoading}
      >
        Download metadata template
      </Button>
      {currentMetadataFileUrl ? (
        <Button type="primary" href={currentMetadataFileUrl} style={{ marginLeft: 25 }}>
          Download current metadata file
        </Button>
      ) : null}

      <h3 style={{ marginTop: 30 }}>Upload metadata tsv file</h3>
      <div style={{ marginBottom: 15, marginTop: 15, width: 400 }}>
        <Dragger
          onChange={(info) => {
            let newFileList = [...info.fileList];
            newFileList = newFileList.slice(-1);
            const fileExtension = newFileList[0].name.split('.').pop();
            if (fileExtension === 'tsv') {
              updateUploadFileDetails({ file: info.file, fileList: newFileList });
            } else {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.error('Only .tsv files are allowed');
            }
            setShowNoFileSelectedErr(false);
          }}
          beforeUpload={() => {
            return false;
          }}
          onRemove={() => {
            updateUploadFileDetails({ file: null, fileList: [] });
            return false;
          }}
          fileList={uploadFileDetails.fileList}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Only TSV files are allowed</p>
        </Dragger>

        {showNoFileSelectedErr ? (
          <p style={{ color: 'red', marginTop: 15 }}>Please select a file and try again!</p>
        ) : null}
      </div>
      <Button
        type="primary"
        onClick={() => {
          /* if user do not select any file then following error will be shown */
          if (uploadFileDetails.file === null && uploadFileDetails.fileList.length === 0) {
            setShowNoFileSelectedErr(true);
          } else {
            setShowNoFileSelectedErr(false);
            updateCurrentScreen('mdsaMetadataConfirm');
          }
        }}
      >
        Upload
      </Button>
    </>
  );
};

export default MdsaMetadataUploadFile;
