import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, message, Spin } from 'antd';
import { useApolloClient, useMutation } from '@apollo/client';
import papa from 'papaparse';
import { loader } from 'graphql.macro';
import axios from 'axios';
import {
  MetadataColumn,
  MetadataColumnTypeEnum,
  SrSamples,
  SrFileUploadConfigQuery,
  SrFileUploadConfigQueryVariables,
  UpdateSrTaskMutation,
  UpdateSrTaskMutationVariables,
  SrUploadMetadataOutData,
} from '../graphql/graphql-types';
import { logger } from '../utils/helpers';
import { SRUploadMetadataScreenType } from '../utils/types';

const { Column } = Table;

/* Type for button text */
type ButtonTextType = 'Edit metadata columns' | 'Re-upload' | '';

/* Type for mismatched samples */
type MismatchedSamplesType = {
  'sample-id': string;
};

/* Type for table data when there are no errors */
type TableDataType = {
  tableColumns: Array<string>;
  tableDataSource: Array<Record<string, string>>;
};

/* Prop type of Srmetadata Verify upload */
type SrMetadataVerifyUploadPropType = {
  /* Sequencing run id */
  srId: string;
  /* Sequencing run task id */
  taskId: string;
  /* Prop to check for page is in create mode or edit mode */
  mode: 'edit' | 'create';
  /* State to store Metadata Columns of upload metadata */
  metadataColumns: Array<MetadataColumn> | undefined;
  /* setState to update current component */
  updateCurrentScreen: (currentScreen: SRUploadMetadataScreenType) => void;
  /* Selected file by user */
  uploadedFile: any;
  /* Link fastq out data samples */
  linkFastqSamples: Array<SrSamples>;
  /* Task started at timestamp */
  taskStartedAt: Date;
};

const srFileUploadConfigQuery = loader('../graphql/queries/srFileUploadConfigQuery.graphql');
const updateSrTaskMutation = loader('../graphql/mutations/updateSrTaskMutation.graphql');

/* React Functional component */
const SrMetadataVerifyUpload: React.FC<SrMetadataVerifyUploadPropType> = ({
  metadataColumns,
  updateCurrentScreen,
  uploadedFile,
  linkFastqSamples,
  srId,
  mode,
  taskId,
  taskStartedAt,
}) => {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  /* Mutation to update sr task */
  const [updateSrTask] = useMutation<UpdateSrTaskMutation, UpdateSrTaskMutationVariables>(
    updateSrTaskMutation,
  );

  /* State to store error title if there are any errors in samples and columns in metadata file */
  const [errorTitle, setErrorTitle] = useState<string>('');
  /* State to store button text if there are any errors in samples and columns in metadata file */
  const [btnText, setBtnText] = useState<ButtonTextType>('');

  /* Loading state of confirm metadata button */
  const [confirmMetadataLoading, setConfirmMetadataLoading] = useState(false);

  /* State to store mismatched columns between metadata file and form data */
  const [mismatchedColumns, setMismatchedColumns] = useState<Array<MetadataColumn>>([]);
  /* State to store mismatched samples between metadata file and form data */
  const [mismatchedSamples, setMismatchedSamples] = useState<Array<MismatchedSamplesType>>([]);
  /* State to store table data when there are no errors(as number of columns will be dynamically decided based on metadata file) */
  const [tableData, setTableData] = useState<TableDataType>({
    tableColumns: [],
    tableDataSource: [],
  });
  /* State to store error messages while parsing file using papaparse(row specific messages) */
  const [fileParsingErrMsgs, setFileParsingErrMsgs] = useState<Array<string>>([]);

  useEffect(() => {
    /* Parsing uploaded metadata file by user using papaparse */
    papa.parse(uploadedFile, {
      header: true,
      delimiter: '\t',
      skipEmptyLines: true,
      complete: (results) => {
        if (Array.isArray(results.errors) && results.errors.length > 0) {
          /* If there are errors while parsing the uploaded tsv file by user */
          const errMsgsArr: string[] = [];
          results.errors.forEach((item) => {
            if (item.type === 'FieldMismatch' && item.code === 'TooFewFields') {
              const rowColsCount = item.message.split('parsed')[1].split(' ')[1];
              const expectedColCount = item.message.split('expected')[1].split(' ')[1];
              const msg = `Row no. ${
                item.row + 2
              } has only ${rowColsCount} columns defined instead of ${expectedColCount}.`;
              errMsgsArr.push(msg);
            } else {
              errMsgsArr.push(item.message);
            }
          });
          setFileParsingErrMsgs(errMsgsArr);
          setErrorTitle('We have found the following errors in the metadata file:');
          setBtnText('Re-upload');
          return;
        }

        const metaFields = results.meta.fields;
        if (metadataColumns && Array.isArray(metaFields)) {
          if (metadataColumns.length > metaFields.length - 1) {
            /* If there are more columns in the form data than selected file by user */
            const mismatchedColumnsInFormData: Array<MetadataColumn> = [];

            metadataColumns.forEach((item) => {
              if (!metaFields.includes(item.column)) {
                mismatchedColumnsInFormData.push(item);
              }
            });

            setMismatchedColumns(mismatchedColumnsInFormData);
            setErrorTitle(
              'Some columns are missing in the metadata file. Please add the following columns along with their data and re-upload the metadata file',
            );
            setBtnText('Re-upload');
          } else if (metadataColumns.length < metaFields.length - 1) {
            /* If there are more columns in the file selected by the user */
            const extraColumnsInMetadataFile: Array<string> = [];
            const mismatchedColumnsInMetadataFile: Array<MetadataColumn> = [];
            const typeFromMetadataFile = results.data[0] as Record<string, string>;

            metaFields.forEach((item) => {
              let isColumnPresent = false;
              if (item !== 'sample-id') {
                for (let i = 0; i < metadataColumns.length; i++) {
                  if (item === metadataColumns[i].column) {
                    isColumnPresent = true;
                    break;
                  }
                }
                if (!isColumnPresent) extraColumnsInMetadataFile.push(item);
              }
            });

            if (typeFromMetadataFile) {
              extraColumnsInMetadataFile.forEach((item) => {
                mismatchedColumnsInMetadataFile.push({
                  column: item,
                  type: typeFromMetadataFile[item] as MetadataColumnTypeEnum,
                });
              });
            }

            setMismatchedColumns(mismatchedColumnsInMetadataFile);
            setErrorTitle(
              'Additional columns were detected in the metadata file. Please add these columns in the ‘Metadata Columns’ form',
            );
            setBtnText('Edit metadata columns');
          } else if (linkFastqSamples.length > results.data.length - 1) {
            /* If the sample id’s in the uploaded file are less than the sample ids in the linkfastq sample data */
            const extraSamplesInLinkFastqData = linkFastqSamples
              .filter((item) => {
                return !results.data.some((metadataItem) => {
                  const element = metadataItem as Record<string, string>;
                  return item.sample_id === element['sample-id'];
                });
              })
              .map((extraSample) => {
                return { 'sample-id': extraSample.sample_id };
              });

            setMismatchedSamples(extraSamplesInLinkFastqData);
            setErrorTitle(
              'Metadata for the following samples was not found in the selected file. Please add the data and re-upload the metadata file',
            );
            setBtnText('Re-upload');
          } else if (linkFastqSamples.length < results.data.length - 1) {
            /* If additional sample id’s are found in the metadata file */
            const extraSamplesInMetadataFile = results.data
              .filter((item) => {
                const element = item as Record<string, string>;
                if (element['sample-id'] === '#q2:types') return null;
                return !linkFastqSamples.some((linkFastqSample) => {
                  return element['sample-id'] === linkFastqSample.sample_id;
                });
              })
              .map((extraSample) => {
                const element = extraSample as Record<string, string>;
                return { 'sample-id': element['sample-id'] };
              });

            setMismatchedSamples(extraSamplesInMetadataFile);
            setErrorTitle(
              'Additional samples were found in the metadata file which were not selected in ‘Link FASTQ Files’ step. Please remove the following samples from metadata file and re-upload the file',
            );
            setBtnText('Re-upload');
          } else {
            /* if there are no errors */
            setTableData({
              tableColumns: metaFields,
              tableDataSource: results.data as Array<Record<string, string>>,
            });
            setBtnText('Re-upload');
          }
        }
      },
      error: (error) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadataColumns, linkFastqSamples, uploadedFile]);

  /* Confirming uploaded metadata */
  const handleConfirmMetadata = () => {
    setConfirmMetadataLoading(true);
    /* Query to get info/config for uploading to S3 */
    apolloClient
      .query<SrFileUploadConfigQuery, SrFileUploadConfigQueryVariables>({
        query: srFileUploadConfigQuery,
        fetchPolicy: 'no-cache',
        variables: {
          files: [
            {
              fileName: 'metadata.tsv',
              contentType: 'text/tab-separated-values',
            },
          ],
          sr_id: parseInt(srId, 10),
        },
      })
      .then(({ data }) => {
        if (data && Array.isArray(data.s3Config) && data.s3Config[0]) {
          const {
            algorithm,
            contentDisposition,
            contentType,
            credential,
            date,
            key,
            policy,
            signature,
            url,
            fileName,
          } = data.s3Config[0];
          /* Creating formdata from query response */
          const formDataForS3: FormData = new FormData();
          formDataForS3.append('key', key);
          formDataForS3.append('policy', policy);
          formDataForS3.append('x-amz-algorithm', algorithm);
          formDataForS3.append('x-amz-credential', credential);
          formDataForS3.append('x-amz-date', date);
          formDataForS3.append('x-amz-signature', signature);
          formDataForS3.append('Content-Type', contentType);
          formDataForS3.append('Content-Disposition', contentDisposition);
          formDataForS3.append('file', uploadedFile);

          /* Axios post request to upload file on server using config response from srFileUploadConfigQuery */
          axios
            .post(url, formDataForS3)
            .then(() => {
              /* After successful file upload */
              /* Updating sr task */
              updateSrTask(
                mode === 'create'
                  ? {
                      /* Create mode */
                      variables: {
                        id: parseInt(taskId, 10),
                        setInput: {
                          started_at: taskStartedAt,
                          ended_at: new Date(),
                          out_data: {
                            metadata_columns: metadataColumns,
                          } as SrUploadMetadataOutData,
                          out_files: [
                            {
                              name: fileName,
                              s3_key: key,
                              show_in_dashboard_table: true,
                            },
                          ],
                        },
                      },
                    }
                  : {
                      /* Edit mode */
                      variables: {
                        id: parseInt(taskId, 10),
                        setInput: {
                          out_data: {
                            metadata_columns: metadataColumns,
                          } as SrUploadMetadataOutData,
                        },
                      },
                    },
              )
                .then(() => {
                  navigate(`/sr/${srId}`);
                })
                .catch((err) => {
                  logger(err);
                });

              setConfirmMetadataLoading(false);
            })
            .catch((error) => {
              logger(error);
              setConfirmMetadataLoading(false);
            });
        }
      })
      .catch((err) => {
        logger(err);
        setConfirmMetadataLoading(false);
      });
  };

  const renderTable = () => {
    /* Rendered when there are mismatched columns in file and metadata columns */
    if (mismatchedColumns.length > 0) {
      return (
        <Table
          style={{ width: 250 }}
          pagination={false}
          bordered
          dataSource={mismatchedColumns}
          rowKey="column"
        >
          <Column key="column" dataIndex="column" title="Column" />
          <Column key="type" dataIndex="type" title="Type" />
        </Table>
      );
    }

    /* Rendered when there are mismatched samples in file and link fastq samples */
    if (mismatchedSamples.length > 0) {
      return (
        <Table
          style={{ width: 150 }}
          pagination={false}
          bordered
          dataSource={mismatchedSamples}
          rowKey="sample-id"
        >
          <Column key="sample-id" dataIndex="sample-id" title="Sample Id" />
        </Table>
      );
    }

    /* Rendered when there are no errors */
    if (tableData.tableColumns.length > 0 && tableData.tableDataSource) {
      return (
        <Table
          size="small"
          bordered
          dataSource={tableData.tableDataSource}
          className="qiimeMetadataTable"
          scroll={{ x: 'max-content' }}
        >
          {tableData.tableColumns.map((item) => (
            <Column
              title={item}
              dataIndex={item}
              key={item}
              fixed={item === 'sample-id' ? 'left' : undefined}
            />
          ))}
        </Table>
      );
    }

    /* Rendered if there are file parsing errors */
    if (fileParsingErrMsgs.length > 0) {
      return (
        <ul>
          {fileParsingErrMsgs.map((item, index) => {
            return <li key={index.toString()}>{item}</li>;
          })}
        </ul>
      );
    }

    /* Loading indicator while conditions are checked */
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        <Spin size="large" />
      </div>
    );
  };

  return (
    <>
      <h3>Confirm uploaded metadata</h3>
      <p>{errorTitle}</p>

      {renderTable()}

      <Button
        type="primary"
        onClick={() => {
          if (btnText === 'Re-upload') {
            updateCurrentScreen('metadataUploadFile');
          } else {
            updateCurrentScreen('metadataColumns');
          }
        }}
        style={{ marginTop: 30 }}
      >
        {btnText}
      </Button>

      {tableData.tableColumns.length > 0 && tableData.tableDataSource ? (
        <Button
          type="primary"
          style={{ marginTop: 30, marginLeft: 30 }}
          onClick={handleConfirmMetadata}
          loading={confirmMetadataLoading}
        >
          Confirm Metadata
        </Button>
      ) : null}
    </>
  );
};

export default SrMetadataVerifyUpload;
