import React, { useState } from 'react';
import { Table, Popconfirm, message, Button } from 'antd';
import { useQuery, useMutation, Reference } from '@apollo/client';
import { Link } from 'react-router-dom';
import { loader } from 'graphql.macro';
import moment from 'moment';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import {
  OpenSRsQuery,
  DeleteSrMutation,
  DeleteSrMutationVariables,
  Enum_Sr_Status_Enum,
  Maybe,
  Sequencing_Run,
  User,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import { logger } from '../utils/helpers';
import { colors } from '../utils/globals';
import ColumnsSearchInput from '../components/ColumnsSearchInput';

const { Column } = Table;

const openSRsQuery = loader('../graphql/queries/openSRsQuery.graphql');
const deleteSRMutation = loader('../graphql/mutations/deleteSRMutation.graphql');

// Sequence run type
type SequencingRunType = Pick<Sequencing_Run, 'id' | 'created_at' | 'status'> & {
  created_by?: Maybe<Pick<User, 'first_name' | 'last_name'>>;
};

// labels for current status of sequencing
const statusLabels: Record<Enum_Sr_Status_Enum, string> = {
  [Enum_Sr_Status_Enum.Open]: 'Open',
  [Enum_Sr_Status_Enum.InfoSaved]: 'Ready for analysis',
  [Enum_Sr_Status_Enum.AnalysisStarted]: 'Analysis Incomplete',
  [Enum_Sr_Status_Enum.AnalysisCompleted]: 'Analysis Done',
};

// React functional component
const ViewOpenSR: React.FC = () => {
  // Query to fetch open SR's data
  const { data, loading: openSrDataLoading, error: queryError } = useQuery<OpenSRsQuery>(
    openSRsQuery,
    { fetchPolicy: 'network-only' },
  );
  // Mutation for delete SR
  const [deleteSR] = useMutation<DeleteSrMutation, DeleteSrMutationVariables>(deleteSRMutation);

  // useState to set deleted sr id for setting button loading
  const [deletedSrId, setDeletedSrId] = useState<number | null>(null);

  // if any error occurred while fetching data will show error message
  if (queryError) {
    return (
      <AppLayout screenTitle="Ongoing Sequencing Runs">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  // delete sequencing run
  const handleDeleteSR = (id: number) => {
    deleteSR({
      variables: { id },
      // Update cache on delete
      update: (cache, { data: deletedData }) => {
        cache.modify({
          fields: {
            sequencing_run(existingRefs: Array<Reference>, { readField }) {
              if (
                deletedData &&
                deletedData.delete_sequencing_run_by_pk &&
                deletedData.delete_sequencing_run_by_pk.id
              ) {
                const idToFilter = deletedData.delete_sequencing_run_by_pk.id;
                return existingRefs.filter((ref) => idToFilter !== readField('id', ref));
              }
              return existingRefs;
            },
          },
        });
      },
    })
      .then((mutationResponse) => {
        if (
          mutationResponse &&
          mutationResponse.data &&
          mutationResponse.data.delete_sequencing_run_by_pk
        ) {
          setDeletedSrId(null);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Deleted successfully');
        }
      })
      .catch((mutationError) => {
        setDeletedSrId(null);
        logger(mutationError);
      });
  };

  return (
    <AppLayout screenTitle="Ongoing Sequencing Runs">
      <Table<SequencingRunType>
        bordered
        dataSource={data && Array.isArray(data.sequencing_run) ? data.sequencing_run : []}
        loading={openSrDataLoading}
        rowKey="id"
      >
        <Column<SequencingRunType>
          title="ID"
          dataIndex="id"
          key="id"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsSearchInput
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="id"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? colors.tintColor : undefined }} />
          )}
          onFilter={(value, record) =>
            `SR${record.id}`.toLowerCase().includes(value.toString().toLowerCase())
          }
          render={(srId: string, record) => {
            if (record.status === Enum_Sr_Status_Enum.Open) {
              return <Link to={`/sr/${srId}/edit/info`}>SR{srId}</Link>;
            }
            return <Link to={`/sr/${srId}`}>SR{srId}</Link>;
          }}
        />

        <Column<SequencingRunType> title="Title" dataIndex="title" key="title" />

        <Column<SequencingRunType>
          title="Created On"
          dataIndex="created_at"
          key="created_at"
          sorter={(a, b) => moment(a.created_at).unix() - moment(b.created_at).unix()}
          render={(created_at: string) => {
            return moment(created_at).format('D MMM, YYYY');
          }}
        />

        {/* <Column<SequencingRunType>
          title="Created By"
          dataIndex="created_by"
          key="created_by"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsSearchInput
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="created by"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? colors.tintColor : undefined }} />
          )}
          onFilter={(value, record) => {
            if (record.created_by) {
              const fullName = record.created_by.first_name.concat(
                ' ',
                record.created_by.last_name,
              );
              return fullName.toLowerCase().includes(value.toString().toLowerCase());
            }
            return false;
          }}
          render={(created_by: Pick<User, 'first_name' | 'last_name'>) =>
            created_by ? created_by.first_name.concat(' ', created_by.last_name) : 'N/A'
          }
        /> */}

        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(status: Enum_Sr_Status_Enum) => statusLabels[status]}
        />

        <Column<SequencingRunType>
          title="Actions"
          key="actions"
          render={(value, record) => {
            return (
              <>
                {record.status !== Enum_Sr_Status_Enum.Open ? (
                  <Button type="default" style={{ marginRight: 20 }}>
                    {/* only to edit the SR's info and not create the SR tasks */}
                    <Link to={`/sr/${record.id}/edit/info?onlyUpdateSr=true`}>Edit</Link>
                  </Button>
                ) : null}
                <Popconfirm
                  title="Are you sure delete this run?"
                  onConfirm={() => {
                    setDeletedSrId(record.id);
                    handleDeleteSR(record.id);
                  }}
                  okText="Yes"
                  cancelText="No"
                  icon={<DeleteOutlined style={{ color: 'red' }} />}
                >
                  <Button type="default" loading={record.id === deletedSrId}>
                    Delete
                  </Button>
                </Popconfirm>
              </>
            );
          }}
        />
      </Table>
    </AppLayout>
  );
};

export default ViewOpenSR;
