import React, { useCallback, useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Alert, Button, Col, DatePicker, Radio, Row, Spin } from 'antd';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import AppLayout from '../components/AppLayout';
import EcFormItem from '../components/EcFormItem';
import { logger } from '../utils/helpers';
import { SequencingDateRunOutputFormType, SuccessDataOfSamplesType } from '../utils/types';
import {
  CloudwatchLogsMutation,
  CloudwatchLogsMutationVariables,
  PlatformType,
} from '../graphql/graphql-types';
import { logGroupNameEcp29apps, logStreamNameSrPlanning } from '../utils/globals';

// type definition for SequencingDateRunOutputForm prop type
type SequencingDateRunOutputFormPropType = {
  // useForm definition
  useForm: UseFormMethods<SequencingDateRunOutputFormType>;
  // type definition to set sequencer run sample success details
  setSequencerRunSamplesSuccessDetails: React.Dispatch<
    React.SetStateAction<SuccessDataOfSamplesType | undefined>
  >;
};

const cloudwatchLogsMutation = loader('../graphql/mutations/cloudwatchLogs.graphql');

// Filter run output directories by the selected date
const filterOutputRunDirByDate = (directories: string[], date: moment.Moment) =>
  directories.filter((dir) => dir.substring(0, 6) === date.format('YYMMDD'));

const SequencingDateRunOutputForm = ({
  useForm,
  setSequencerRunSamplesSuccessDetails,
}: SequencingDateRunOutputFormPropType): JSX.Element => {
  // mutation to store the logs
  const [cloudwatchLogs] = useMutation<CloudwatchLogsMutation, CloudwatchLogsMutationVariables>(
    cloudwatchLogsMutation,
  );

  // useState to store allOutputRunDirectories fetched from getDirectoriesFromMiSeqOutDirectory() from preload file defined in ecp29Desktop
  const [allOutputRunDirectories, setAllOutputRunDirectories] = useState<string[]>([]);

  // state to show loading indicator till all output run directories are fetched
  const [allOutputRunDirsLoading, setAllOutputRunDirsLoading] = useState<boolean>(false);

  // state to show loading indicator on submit button
  const [submitBtnLoading, setSubmitBtnLoading] = useState<boolean>(false);

  // state to show closable error box with error message
  const [showClosableBoxWithErrorInfo, setShowClosableBoxWithErrorInfo] = useState<
    string | undefined
  >(undefined);

  // useForm declaration
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, reset, watch, handleSubmit } = useForm;

  // useEffect is used to fetch the list of all output run directories present in MiSeqRunOut folder uploaded by user.
  useEffect(() => {
    const fetchAllOutputRunDirectories = async () => {
      setAllOutputRunDirsLoading(true);
      if (window.ecp29Desktop) {
        setAllOutputRunDirectories(await window.ecp29Desktop.getDirectoriesFromMiSeqOutDirectory());
        setAllOutputRunDirsLoading(false);
      }
    };
    // calling fetchAllOutputRunDirectories()
    fetchAllOutputRunDirectories().catch((err) => {
      setAllOutputRunDirsLoading(false);
      logger(err as Error);
    });
  }, []);

  const sequencingDateValue = watch('sequencingDate');

  // function to render the radio options to select run output directory or information message based on below condition
  const renderRunOutDirSelect = useCallback(() => {
    // Render nothing if date is not selected
    if (sequencingDateValue === null) {
      return null;
    }

    // output run dirs filtered by selected date
    const filteredOutputRunDirs = filterOutputRunDirByDate(
      allOutputRunDirectories,
      sequencingDateValue,
    );

    // No output run directories for the selected date
    if (filteredOutputRunDirs.length === 0) {
      return <p>No MiSeq output runs found for the selected date. Please select a new date.</p>;
    }

    return (
      <Col span={24}>
        <EcFormItem
          label="Sequencer run output directory"
          key={sequencingDateValue.toString()}
          name="sequencerRunOutDir"
          control={control}
          render={({ onChange, value }): JSX.Element => (
            <Radio.Group
              onChange={(e) => {
                setShowClosableBoxWithErrorInfo(undefined);
                onChange(e.target.value);
              }}
              value={value as string}
            >
              {filteredOutputRunDirs.map((item) => (
                <Col span={24} style={{ padding: '5px' }}>
                  <Radio key={item} value={item}>
                    {item}
                  </Radio>
                </Col>
              ))}
            </Radio.Group>
          )}
        />
      </Col>
    );
  }, [allOutputRunDirectories, control, sequencingDateValue]);

  /* Loading indicator while fetching all outputRun directories */
  if (allOutputRunDirsLoading) {
    return (
      <AppLayout screenTitle="Create Sequencing Run(s)">
        <>
          <h2>Choose sequencer run</h2>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
            }}
          >
            <Spin size="large" />
          </div>
        </>
      </AppLayout>
    );
  }

  return (
    <AppLayout screenTitle="Create Sequencing Run(s)">
      <>
        <h2>Choose sequencer run</h2>
        <form
          onSubmit={handleSubmit(async (formData: SequencingDateRunOutputFormType) => {
            setSubmitBtnLoading(true);

            try {
              // mutation to log console to AWS cloudwatch
              await cloudwatchLogs({
                variables: {
                  args: {
                    logGroupName: logGroupNameEcp29apps,
                    logStreamName: logStreamNameSrPlanning,
                    logData: [
                      {
                        sourcecode_file_name: 'SequencingDateRunOutputForm',
                        sourcecode_function_name: 'onFormSubmit',
                        platform: PlatformType.WebApp,
                        message: 'Selected date of sequencing and sequencer run output directory',
                        data: JSON.stringify({
                          selectedDate: formData.sequencingDate?.toString(),
                          selectedSequencerRunOutputDir: formData.sequencerRunOutDir,
                        }),
                        event: 'onSubmitButtonClick',
                        timestamp: new Date().toISOString(),
                      },
                    ],
                  },
                },
              });
            } catch (err) {
              logger(err as Error);
            }

            //  sequencer run samples details for the selected date
            window.ecp29Desktop
              .getSequencerRunSamplesInfo(formData.sequencerRunOutDir)
              .then((res) => {
                if (!res) {
                  setSubmitBtnLoading(false);
                  return;
                }
                if (res.status === 'FAILED') {
                  setShowClosableBoxWithErrorInfo(res.errorMessage);
                } else {
                  setSequencerRunSamplesSuccessDetails(res.successData);
                }
                setSubmitBtnLoading(false);
              })
              .catch((err: Error) => {
                setSubmitBtnLoading(false);
                logger(err);
              });
          })}
        >
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <EcFormItem
                label="Date of sequencing"
                name="sequencingDate"
                control={control}
                render={({ onChange, value }): JSX.Element => (
                  <DatePicker
                    disabledDate={(selectedDate: moment.Moment) =>
                      selectedDate.toDate() > new Date()
                    }
                    onChange={(date): void => {
                      onChange(date);
                      setShowClosableBoxWithErrorInfo(undefined);

                      if (date) {
                        reset({
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          sequencingDate: date,
                          sequencerRunOutDir:
                            filterOutputRunDirByDate(allOutputRunDirectories, date)[0] || null,
                        });
                      } else {
                        reset({
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          sequencingDate: date,
                          sequencerRunOutDir: null,
                        });
                      }
                    }}
                    value={value as moment.Moment}
                    format="Do MMM YYYY"
                    placeholder="Select date"
                    allowClear
                    style={{ width: 180 }}
                  />
                )}
              />
            </Col>
            {/* renders appropriate output based on condition mentioned in renderRunOutDirSelect function */}
            {renderRunOutDirSelect()}
            <Col span={24}>
              {showClosableBoxWithErrorInfo ? (
                <Alert
                  style={{ marginBottom: '20px', padding: '10px' }}
                  message={showClosableBoxWithErrorInfo}
                  type="error"
                  closable
                  onClose={() => {
                    setShowClosableBoxWithErrorInfo(undefined);
                  }}
                />
              ) : null}
              <Button
                type="primary"
                htmlType="submit"
                disabled={!(sequencingDateValue && watch('sequencerRunOutDir'))}
                loading={submitBtnLoading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      </>
    </AppLayout>
  );
};

export default SequencingDateRunOutputForm;
