import React, { useEffect, useState } from 'react';
import { Button, Table, message } from 'antd';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { TablePaginationConfig } from 'antd/lib/table';
import Column from 'antd/lib/table/Column';
import moment from 'moment';
import { fetchKitInfoForSequencing } from '../utils/apis/kit';
import { logger } from '../utils/helpers';
import {
  CloudwatchLogsMutation,
  CloudwatchLogsMutationVariables,
  PlatformType,
} from '../graphql/graphql-types';
import { logGroupNameEcp29apps, logStreamNameSampleManagement } from '../utils/globals';

// type definition for table data
type TableDataType = {
  // type definition for kitID
  kitID: string;
  // type definition for libPrepDateTime
  libPrepDateTime: string;
  // type definition for sampleSheetID
  sampleSheetID: number;
};

const cloudwatchLogsMutation = loader('../graphql/mutations/cloudwatchLogs.graphql');

/* React functional component */
const SamplesSequencing = (): JSX.Element => {
  // mutation to store the logs
  const [cloudwatchLogs] = useMutation<CloudwatchLogsMutation, CloudwatchLogsMutationVariables>(
    cloudwatchLogsMutation,
  );

  /* state to store data to render in table after fetching it from fetchKitInfoForSequencing API */
  const [tableData, setTableData] = useState<TableDataType[] | undefined>(undefined);

  /* state to check whether table data is loaded or not */
  const [isTableDataLoading, setIsTableDataLoading] = useState<boolean>(false);

  /* state to check whether copy samples button is loading or not */
  const [isCopySamplesBtnLoading, setIsCopySamplesBtnLoading] = useState<boolean>(false);

  /* State to store sample sheet ids and kit ids selected to copy samples for sequencing */
  const [
    sampleSheetAndKitIdsOfCopySamplesForSeq,
    setSampleSheetAndKitIdsOfCopySamplesForSeq,
  ] = useState<Array<Pick<TableDataType, 'kitID' | 'sampleSheetID'>>>([]);

  /* state to store pagination details of table.
   we explicitly need to provide current page number and pageSize. PageSize is by-default 10 but as we want it to be 284
   then changed it to 284 by default.
  */
  const [paginationDetails, setPaginationDetails] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 284,
  });

  // calling useEffect to fetch kit information data on every page change in table
  useEffect(() => {
    // if no current page is selected for table then return
    if (!(paginationDetails && paginationDetails.current)) {
      return;
    }

    setIsTableDataLoading(true);

    fetchKitInfoForSequencing(paginationDetails.current)
      .then((res) => {
        // stores required table data from response data
        const reqTableData = res.data.map((data) => {
          return {
            kitID: data.kitID,
            libPrepDateTime: moment(data.libPrepDateTime).format('D MMM YYYY'),
            sampleSheetID: data.sampleSheetID,
          };
        });

        setTableData(reqTableData);
        setIsTableDataLoading(false);
        setPaginationDetails({ ...paginationDetails, total: res.totalRecords });
      })
      .catch((err) => {
        logger(err as Error);
        setIsTableDataLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationDetails.current]);

  return (
    <div>
      <h4>Copy samples for sequencing (to paste in Illumina Experiment Manager)</h4>

      <Button
        type="primary"
        disabled={sampleSheetAndKitIdsOfCopySamplesForSeq.length === 0}
        loading={isCopySamplesBtnLoading}
        onClick={async () => {
          setIsCopySamplesBtnLoading(true);

          /* Storing string that will be copied to clipboard */
          const clipboardDataOfSelectedSampleSheetAndKitIds = sampleSheetAndKitIdsOfCopySamplesForSeq
            .map((item) => `${item.kitID}\tHMQ-KIT-${item.sampleSheetID}`)
            .join('\n');

          try {
            // mutation to log console to AWS cloudwatch
            await cloudwatchLogs({
              variables: {
                args: {
                  logGroupName: logGroupNameEcp29apps,
                  logStreamName: logStreamNameSampleManagement,
                  logData: [
                    {
                      sourcecode_file_name: 'SamplesSequencing',
                      sourcecode_function_name: 'onClickOfCopySamples',
                      platform: PlatformType.WebApp,
                      message: 'Details of copied samples',
                      data: JSON.stringify({
                        copiedSampleDetails: clipboardDataOfSelectedSampleSheetAndKitIds,
                      }),
                      event: 'copySamplesOnClick',
                      timestamp: new Date().toISOString(),
                    },
                  ],
                },
              },
            });
          } catch (err) {
            logger(err as Error);
          }

          try {
            // clipboard API to write the text data to clipboard so that we will be able to paste it
            await navigator.clipboard.writeText(clipboardDataOfSelectedSampleSheetAndKitIds);
            setIsCopySamplesBtnLoading(false);
            setSampleSheetAndKitIdsOfCopySamplesForSeq([]);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('Data copied to clipboard. Paste into Excel.');
          } catch (err) {
            setIsCopySamplesBtnLoading(false);
            setSampleSheetAndKitIdsOfCopySamplesForSeq([]);
            logger(err as Error);
          }
        }}
        style={{ margin: '15px 0px' }}
      >
        Copy samples
      </Button>

      <Table<TableDataType>
        rowKey={(record) => record.sampleSheetID}
        rowSelection={{
          preserveSelectedRowKeys: true,
          selectedRowKeys: sampleSheetAndKitIdsOfCopySamplesForSeq.map(
            (item) => item.sampleSheetID,
          ),
          onChange: (_, selectedRows) => {
            /* Variable to store selected sample sheet IDs and kit IDs */
            const sampleSheetAndKitIds = selectedRows.map((item) => ({
              kitID: item.kitID,
              sampleSheetID: item.sampleSheetID,
            }));

            setSampleSheetAndKitIdsOfCopySamplesForSeq(sampleSheetAndKitIds);
          },
        }}
        bordered
        dataSource={tableData}
        pagination={paginationDetails}
        loading={isTableDataLoading}
        style={{ width: '80%' }}
        size="small"
        onChange={(pagination) => {
          setPaginationDetails({
            ...paginationDetails,
            current: pagination.current,
          });
        }}
      >
        <Column<TableDataType>
          title="Sample sheet ID"
          dataIndex="sampleSheetID"
          key="sampleSheetID"
          render={(sampleSheetId: string) => `HMQ-KIT-${sampleSheetId}`}
        />
        <Column<TableDataType> title="Kit ID" dataIndex="kitID" key="kitID" />
        <Column<TableDataType>
          title="Lib. prep date"
          dataIndex="libPrepDateTime"
          key="libPrepDateTime"
        />
      </Table>
    </div>
  );
};

export default SamplesSequencing;
