import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  GetSrUploadMetadataQuery,
  GetSrUploadMetadataQueryVariables,
  MetadataColumn,
  MetadataColumnTypeEnum,
  SrLinkFastqOutData,
  SrSamples,
  SrUploadMetadataOutData,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import SrMetadataColumns from '../components/SrMetadataColumns';
import SrMetadataUploadFile from '../components/SrMetadataUploadFile';
import SrMetadataVerifyUpload from '../components/SrMetadataVerifyUpload';
import { SRUploadMetadataScreenType, UploadFileDetailsType } from '../utils/types';

/* Type for state expected from sequencing run dashboard */
type RouteStateType = {
  downloadUrl: string;
};

const getSRUploadMetadataQuery = loader('../graphql/queries/getSRUploadMetadataQuery.graphql');

/* React Functional component */
const SRUploadMetadata: React.FC = () => {
  /* fetch sr id and task id from url param */
  const { id, taskId } = useParams();

  const location = useLocation();

  /* State to store started_at */
  const [started_at] = useState(new Date());

  /* State to store key of the current screen */
  const [currentScreen, setCurrentScreen] = useState<SRUploadMetadataScreenType>('metadataColumns');
  /* Submitted metadataColumn from SrMetadatacolumn component */
  const [metadataColumns, setMetadataColumns] = useState<Array<MetadataColumn>>([]);

  /* State to store selected file details */
  const [uploadFileDetails, setUploadFileDetails] = useState<UploadFileDetailsType>({
    file: null,
    fileList: [],
  });

  /* Query to fetch sequencing run data */
  const { data, loading: srUploadMetadataLoading, error: queryError } = useQuery<
    GetSrUploadMetadataQuery,
    GetSrUploadMetadataQueryVariables
  >(getSRUploadMetadataQuery, {
    variables: { sr_id: parseInt(id, 10) },
  });

  /* if any error occured while fetching data will show error message */
  if (queryError) {
    return (
      <AppLayout screenTitle="Upload Metadata file">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  /* Loading indicator while fetching data */
  if (srUploadMetadataLoading) {
    return (
      <AppLayout screenTitle="Upload Metadata file">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  /* Storing project metadata columns of sequencing run */
  const projectMetadataColumns = data?.sequencing_run_by_pk?.project
    ?.qiime_metadata_columns as Array<MetadataColumn>;

  /* Storing tasks data of sequencing run */
  const tasksData = data?.sequencing_run_by_pk?.tasks;

  /* Storing link fastq samples of sequencing run */
  let linkFastqSamplesData: Array<SrSamples> = [];
  if (Array.isArray(tasksData) && tasksData.length > 0) {
    for (let i = 0; i < tasksData.length; i++) {
      if (tasksData[i].key === 'linkFastq') {
        const outData = tasksData[i].out_data as SrLinkFastqOutData;
        if (outData) {
          linkFastqSamplesData = outData.samples;
        }
      }
    }
  }

  /* Storing upload metadata's out data metadata columns of sequencing run */
  let uploadeMetadataOutDataMetadataColumns: Array<MetadataColumn> = [];
  if (Array.isArray(tasksData) && tasksData.length > 0) {
    for (let i = 0; i < tasksData.length; i++) {
      if (tasksData[i].key === 'uploadMetadata') {
        const outData = tasksData[i].out_data as SrUploadMetadataOutData;
        if (outData) {
          uploadeMetadataOutDataMetadataColumns = outData.metadata_columns;
        }
      }
    }
  }

  /* Function returns screen based on currentScreen state */
  const renderScreen = () => {
    const routeState = location.state as RouteStateType;
    /* render SrMetadataColumns component */
    if (currentScreen === 'metadataColumns') {
      return (
        <SrMetadataColumns
          srId={id}
          mode={
            uploadeMetadataOutDataMetadataColumns && routeState && routeState.downloadUrl
              ? 'edit'
              : 'create'
          }
          updateCurrentScreen={setCurrentScreen}
          projectMetadataColumns={
            Array.isArray(projectMetadataColumns) && projectMetadataColumns.length > 0
              ? projectMetadataColumns
              : [
                  {
                    column: '',
                    type: MetadataColumnTypeEnum.Categorical,
                  },
                ]
          }
          initialMetadataColumns={
            Array.isArray(uploadeMetadataOutDataMetadataColumns) &&
            uploadeMetadataOutDataMetadataColumns.length > 0
              ? uploadeMetadataOutDataMetadataColumns
              : []
          }
          metadataColumns={metadataColumns}
          updateMetadataColumns={setMetadataColumns}
          linkFastqSamples={
            Array.isArray(linkFastqSamplesData) && linkFastqSamplesData.length > 0
              ? linkFastqSamplesData
              : []
          }
          currentMetadataFileUrl={routeState ? routeState.downloadUrl : null}
        />
      );
    }

    /* render SrMetadataUploadFile component */
    if (currentScreen === 'metadataUploadFile') {
      return (
        <SrMetadataUploadFile
          updateCurrentScreen={setCurrentScreen}
          updateUploadFileDetails={setUploadFileDetails}
          uploadFileDetails={uploadFileDetails}
        />
      );
    }

    /* render SrMetadataVerifyUpload */
    if (currentScreen === 'metadataVerifyUpload') {
      return (
        <SrMetadataVerifyUpload
          srId={id}
          taskId={taskId}
          metadataColumns={metadataColumns}
          updateCurrentScreen={setCurrentScreen}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          uploadedFile={uploadFileDetails.file}
          linkFastqSamples={
            Array.isArray(linkFastqSamplesData) && linkFastqSamplesData.length > 0
              ? linkFastqSamplesData
              : []
          }
          mode={
            uploadeMetadataOutDataMetadataColumns && routeState && routeState.downloadUrl
              ? 'edit'
              : 'create'
          }
          taskStartedAt={started_at}
        />
      );
    }
    return null;
  };

  return (
    <AppLayout screenTitle="Upload Metadata file">
      <>
        <h2>SR{id}</h2>
        {renderScreen()}
      </>
    </AppLayout>
  );
};

export default SRUploadMetadata;
