import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin, Button, Select } from 'antd';
import { useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { OptionProps } from 'antd/es/select';
import {
  MdsaTaskParamsMetadataColumnsQuery,
  MdsaTaskParamsMetadataColumnsQueryVariables,
  UpdateAndStartMdsaTaskMutation,
  UpdateAndStartMdsaTaskMutationVariables,
  MetadataColumn,
  MdsaInParam,
  SkipMdsaTaskMutation,
  SkipMdsaTaskMutationVariables,
  Enum_Mdsa_Task_Key_Enum,
} from '../graphql/graphql-types';
import EcFormItem from '../components/EcFormItem';
import AppLayout from '../components/AppLayout';
import {
  distanceMatrixOptions,
  colors,
  filterSelectOptions,
  mdsaTasksInitialValues,
} from '../utils/globals';
import { logger } from '../utils/helpers';

const mdsaTaskParamsMetadataColumnsQuery = loader(
  '../graphql/queries/mdsaTaskParamsMetadataColumnsQuery.graphql',
);
const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);
const skipMdsaTaskMutation = loader('../graphql/mutations/skipMdsaTaskMutation.graphql');

const MdsaCustomPcoaPlot: React.FC = () => {
  /* Extracting id and task id from url params */
  const { id, taskId } = useParams();

  const navigate = useNavigate();

  /* State to store started_at for updateAndStartMdsaTask */
  const [started_at] = useState(new Date());

  /* State to store update tasks status buttons loading (Start analysis or skip task & continue based on condition) */
  const [updateTasksStatusBtnLoading, setUpdateTasksStatusBtnLoading] = useState(false);

  /* useForm declaration with default values  */
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, control, setValue } = useForm<{
    pcoaResults: string | null;
    customAxes: string[] | null;
  }>({
    mode: 'onSubmit',
    defaultValues: {
      pcoaResults: null,
      customAxes: [],
    },
  });

  /* Query to fetch mdsa data for mdsa group table bar plot */
  const { data, loading: mdsaTaskParamsMetadataColumnsLoading, error: queryError } = useQuery<
    MdsaTaskParamsMetadataColumnsQuery,
    MdsaTaskParamsMetadataColumnsQueryVariables
  >(mdsaTaskParamsMetadataColumnsQuery, {
    variables: {
      taskId: parseInt(taskId, 10),
    },
    fetchPolicy: 'network-only',
  });

  /* Mutation to update and start mdsa task */
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  /* Mutation to skip mdsa task */
  const [skipMdsaTask] = useMutation<SkipMdsaTaskMutation, SkipMdsaTaskMutationVariables>(
    skipMdsaTaskMutation,
  );

  /* Function to update and start mdsa task on click start analysis button */
  const handleUpdateAndStartMdsaTask = (inParamsData: Array<MdsaInParam>) => {
    setUpdateTasksStatusBtnLoading(true);
    updateAndStartMdsaTask({
      variables: {
        taskId: parseInt(taskId, 10),
        taskUpdateInput: {
          started_at,
          in_params: inParamsData,
        },
      },
    })
      .then((res) => {
        const updatedMdsaTaskId = res.data?.update_mdsa_task_by_pk?.id;
        if (updatedMdsaTaskId) {
          setUpdateTasksStatusBtnLoading(false);
          navigate(`/mdsa/${id}`);
        }
      })
      .catch((err) => {
        setUpdateTasksStatusBtnLoading(false);
        logger(err);
      });
  };

  /* Mdsa task in params data */
  const mdsaTaskInParams = data?.task?.in_params as Array<MdsaInParam>;

  /* Mdsa metadata columns data */
  const mdsaMetadataColumnsData = data?.task?.mdsa.metadata_columns as MetadataColumn[];

  /* Variable to store mdsa metadata columns whose type is numeric */
  let numericMdsaMetadataColumns: MetadataColumn[] = [];

  /* Filtering mdsaMetadataColumnsData where type of metadata column is numeric */
  if (Array.isArray(mdsaMetadataColumnsData) && mdsaMetadataColumnsData.length > 0) {
    numericMdsaMetadataColumns = mdsaMetadataColumnsData.filter((item) => item.type === 'numeric');
  }

  // checks if default value is present if present sets it accordingly
  useEffect(() => {
    if (Array.isArray(mdsaTaskInParams) && mdsaTaskInParams.length > 0) {
      mdsaTaskInParams.forEach((item) => {
        if (item.key === '--i-pcoa' && item.value) {
          setValue('pcoaResults', item.value as string);
        }
        if (
          item.key === '--p-custom-axes' &&
          Array.isArray(item.rawValue) &&
          item.rawValue.length > 0
        ) {
          setValue('customAxes', item.rawValue as string[]);
        }
      });
    }
  }, [mdsaTaskInParams, setValue]);

  /* Loading indicator while fetching data */
  if (mdsaTaskParamsMetadataColumnsLoading) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  /* if any error occurred while fetching data will show error message */
  if (queryError) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  return (
    <AppLayout screenTitle="Microbiome downstream analysis">
      <>
        <h2>Custom PCoA plot</h2>
        {numericMdsaMetadataColumns.length > 0 ? (
          <>
            <EcFormItem
              label="PCoA Results (--i-pcoa)"
              name="pcoaResults"
              control={control}
              rules={{ required: 'Please select PCoA Result and try again' }}
              errors={errors}
              as={
                <Select placeholder="Select a matrix" style={{ width: 300, marginTop: 5 }}>
                  {Array.isArray(distanceMatrixOptions) && distanceMatrixOptions.length > 0
                    ? distanceMatrixOptions.map((item) => (
                        <Select.Option value={item.value} key={item.value}>
                          {item.name}
                        </Select.Option>
                      ))
                    : null}
                </Select>
              }
            />
            <div style={{ marginTop: 10 }}>
              <EcFormItem
                helpText="Only numeric columns are allowed in emperor plot"
                label="Custom axes (--p-custom-axes)"
                name="customAxes"
                control={control}
                rules={{ required: 'Please select Custom axes and try again' }}
                errors={errors}
                as={
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Select a metadata column"
                    style={{ width: 300, marginTop: 5 }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      filterSelectOptions(input, option as OptionProps)
                    }
                  >
                    {numericMdsaMetadataColumns.map((item) => {
                      return (
                        <Select.Option value={item.column} key={item.column}>
                          {item.column}
                        </Select.Option>
                      );
                    })}
                  </Select>
                }
              />
            </div>
          </>
        ) : (
          <h3 style={{ color: colors.tintColor }}>
            You have no "numeric" metadata column. Emperor plot can only be created with numeric
            axes
          </h3>
        )}

        <Button
          onClick={() => {
            /* When numeric metadata columns are present then mdsa task will be started and if
            there are no numeric type metadata columns task will be skipped */
            if (numericMdsaMetadataColumns.length > 0) {
              /* Start the task with following updated values when there are numeric type metadata columns  */
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              handleSubmit((pcoaData) => {
                // obtains the initial value of task CustomPcoaPlot
                const defaultInParams = mdsaTasksInitialValues.find(
                  (task) => task.key === Enum_Mdsa_Task_Key_Enum.CustomPcoaPlot,
                )?.in_params as Array<MdsaInParam>;

                // const to store immutable copy of in_params
                const taskInParamImmutableConst = JSON.parse(
                  JSON.stringify(defaultInParams),
                ) as Array<MdsaInParam>;

                /* Updating in_params data based on user input */
                /* eslint-disable no-param-reassign */
                taskInParamImmutableConst.forEach((item) => {
                  if (item.key === '--i-pcoa' && pcoaData.pcoaResults) {
                    item.enabled = true;
                    item.value = pcoaData.pcoaResults;
                    item.rawValue = pcoaData.pcoaResults;
                  }
                  if (item.key === '--p-custom-axes') {
                    item.enabled = true;
                    item.value = null;
                    item.rawValue = pcoaData.customAxes;
                  }
                });

                handleUpdateAndStartMdsaTask(taskInParamImmutableConst);
              })();
            } else {
              /* Skip the task when there are no numeric type metadata columns */
              setUpdateTasksStatusBtnLoading(true);
              skipMdsaTask({
                variables: {
                  id: parseInt(taskId, 10),
                },
              })
                .then(() => {
                  setUpdateTasksStatusBtnLoading(false);
                  navigate(`/mdsa/${id}`);
                })
                .catch((err) => {
                  setUpdateTasksStatusBtnLoading(false);
                  logger(err);
                });
            }
          }}
          type="primary"
          loading={updateTasksStatusBtnLoading}
          style={{ marginTop: 30 }}
        >
          {numericMdsaMetadataColumns.length > 0 ? 'Start Analysis' : 'Skip task & continue'}
        </Button>
      </>
    </AppLayout>
  );
};

export default MdsaCustomPcoaPlot;
