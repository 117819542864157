import React, { useContext, useState } from 'react';
import { Table, Popconfirm, Button, Space, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, Reference } from '@apollo/client';
import {
  ViewAllProjectsQuery,
  ViewAllProjectsQueryVariables,
  DeleteProjectMutation,
  DeleteProjectMutationVariables,
  Project,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import { logger } from '../utils/helpers';
import { UserContext } from '../contexts/UserContext';

const { Column } = Table;

// Project type
type ProjectType = Pick<Project, 'id' | 'title' | 'species' | 'start_date' | 'created_by_id'>;

const viewAllProjectsQuery = loader('../graphql/queries/viewAllProjectsQuery.graphql');
const deleteProjectMutation = loader('../graphql/mutations/deleteProjectMutation.graphql');

// React functional component
const ViewAllProjectsScreen: React.FC = () => {
  // Query to fetch projects data
  const { data, loading: allProjectsLoading, error: queryError } = useQuery<
    ViewAllProjectsQuery,
    ViewAllProjectsQueryVariables
  >(viewAllProjectsQuery, { fetchPolicy: 'network-only' });
  // userContext to get data of logged in user
  const { user } = useContext(UserContext);

  // Mutation for delete project
  const [deleteProject] = useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(
    deleteProjectMutation,
  );
  // useState to set deleted project id for setting button loading
  const [deletedProjectId, setDeletedProjectId] = useState<number | null>(null);

  // if any error occured while fetching data will show error message
  if (queryError) {
    return (
      <AppLayout screenTitle="View all Projects">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }
  // delete project
  const handleDeleteProject = (id: number): void => {
    deleteProject({
      variables: { id },
      update: (cache, { data: deletedData }) => {
        cache.modify({
          fields: {
            project(existingRefs: Array<Reference>, { readField }) {
              const idToFilter = deletedData?.delete_project_by_pk?.id;
              if (idToFilter) {
                return existingRefs.filter((ref) => idToFilter !== readField('id', ref));
              }
              return existingRefs;
            },
          },
        });
      },
    })
      .then((mutationResponse) => {
        const response = mutationResponse?.data?.delete_project_by_pk;
        if (response) {
          setDeletedProjectId(null);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Deleted successfully');
        }
      })
      .catch((mutationError) => {
        setDeletedProjectId(null);
        logger(mutationError);
      });
  };

  return (
    <AppLayout screenTitle="View all Projects" hideBackBtn>
      <Table<ProjectType>
        bordered
        loading={allProjectsLoading}
        dataSource={data && Array.isArray(data.project) ? data.project : []}
        rowKey="id"
      >
        <Column<ProjectType>
          title="Title"
          dataIndex="title"
          key="title"
          render={(title: string, record) => <Link to={`/project/${record.id}`}>{title}</Link>}
        />

        <Column title="Species" dataIndex="species" key="species" />

        <Column
          title="Start Date"
          dataIndex="start_date"
          key="start_date"
          render={(start_date: string) => {
            return start_date ? moment(start_date).format('D MMM YYYY') : 'N/A';
          }}
        />

        <Column<ProjectType>
          title="Actions"
          key="actions"
          render={(value, record) => {
            return (
              <Space size="small">
                <Button type="default">
                  <Link to={`/project/${record.id}/edit`}>Edit</Link>
                </Button>

                <Button type="default" disabled={user && record.created_by_id !== user.id}>
                  <Link to={`/project/${record.id}/edit/access`}>User Access</Link>
                </Button>

                <Popconfirm
                  title="Are you sure delete this run?"
                  onConfirm={() => {
                    setDeletedProjectId(record.id);
                    handleDeleteProject(record.id);
                  }}
                  okText="Yes"
                  cancelText="No"
                  icon={<DeleteOutlined style={{ color: 'red' }} />}
                >
                  <Button type="default" loading={record.id === deletedProjectId}>
                    Delete
                  </Button>
                </Popconfirm>
              </Space>
            );
          }}
        />
      </Table>
    </AppLayout>
  );
};

export default ViewAllProjectsScreen;
