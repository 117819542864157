import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Table, Button, Select, message } from 'antd';
import AppLayout from '../components/AppLayout';
import {
  ProjectUserAccessQuery,
  ProjectUserAccessQueryVariables,
  AddUsersToProjectMutation,
  AddUsersToProjectMutationVariables,
  RemoveProjectUserAccessMutation,
  RemoveProjectUserAccessMutationVariables,
  User,
  AllUsersQuery,
} from '../graphql/graphql-types';
import { UserContext } from '../contexts/UserContext';
import { logger } from '../utils/helpers';

// call to graphql files using loader
const projectUserAccessQuery = loader('../graphql/queries/projectUserAccessQuery.graphql');
const allUsersQuery = loader('../graphql/queries/allUsersQuery.graphql');
const removeProjectUserAccessMutation = loader(
  '../graphql/mutations/removeProjectUserAccessMutation.graphql',
);
const addUsersToProjectMutation = loader('../graphql/mutations/addUserToProjectMutation.graphql');

// type for user who have access to project
type UserType = { user: Pick<User, 'id' | 'first_name' | 'last_name' | 'email'> };

const { Column } = Table;

// React functional component
const EditProjectUserAccess: React.FC = () => {
  // extracted id from url param
  const { id } = useParams();

  // useState of loading indicatior for remove user access mutation
  const [idOfUserForRemoveAccess, setIdOfUserForRemoveAccess] = useState<number | null>(null);

  // useState of loading indicatior to give user access to project
  const [giveAccessButtonLoading, setGiveAccessButtonLoading] = useState<boolean>(false);

  // useState for storing  the ids of new users to whom access will be provided.
  const [newUserIdsToProvideAccess, setNewUserIdsToProvideAccess] = useState<Array<number>>([]);

  // use query for fetching data of User who have access to project
  const { data, loading, error } = useQuery<
    ProjectUserAccessQuery,
    ProjectUserAccessQueryVariables
  >(projectUserAccessQuery, {
    variables: { project_id: parseInt(id, 10) },
    fetchPolicy: 'network-only',
  });

  // useQuery for fetching data of all user
  const { data: allUserData } = useQuery<AllUsersQuery>(allUsersQuery);

  // mutation to remove user access from particular project
  const [removeProjectUserAccess] = useMutation<
    RemoveProjectUserAccessMutation,
    RemoveProjectUserAccessMutationVariables
  >(removeProjectUserAccessMutation);

  // useMutation for add User to Project
  const [addUsersToProject] = useMutation<
    AddUsersToProjectMutation,
    AddUsersToProjectMutationVariables
  >(addUsersToProjectMutation);

  // userContext to get data of logged in user
  const { user } = useContext(UserContext);

  if (error) {
    return (
      <AppLayout screenTitle="Project User Access">
        <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
      </AppLayout>
    );
  }
  // array which will store logged user id and id of user who already have access to project
  const arrayOfUserWhoAlreadyHaveAccess: Array<number> = [];
  if (user) {
    arrayOfUserWhoAlreadyHaveAccess.push(user.id);
  }
  if (data && data.project_user) {
    data.project_user.forEach((e) => {
      arrayOfUserWhoAlreadyHaveAccess.push(e.user.id);
    });
  }

  return (
    <AppLayout screenTitle="Project User Access">
      <div style={{ paddingRight: 80 }}>
        <Table<UserType>
          bordered
          loading={loading}
          dataSource={data && Array.isArray(data.project_user) ? data.project_user : []}
          rowKey={(record) => record.user.id}
          size="small"
        >
          <Column<UserType>
            title="Name"
            render={(text: UserType) => {
              return text.user.first_name.concat(' ', text.user.last_name);
            }}
          />

          <Column<UserType> title="Email" dataIndex={['user', 'email']} />
          <Column<UserType>
            title="Actions"
            render={(record: UserType) => {
              return (
                <Button
                  type="default"
                  onClick={() => {
                    setIdOfUserForRemoveAccess(record.user.id);
                    removeProjectUserAccess({
                      variables: {
                        project_id: parseInt(id, 10),
                        user_id: record.user.id,
                      },
                      refetchQueries: [
                        {
                          query: projectUserAccessQuery,
                          variables: { project_id: parseInt(id, 10) },
                        },
                      ],
                    })
                      .then(() => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        message.success('Access removed successfully');
                        setIdOfUserForRemoveAccess(null);
                      })
                      .catch((err) => {
                        setIdOfUserForRemoveAccess(null);
                        logger(err);
                      });
                  }}
                  loading={record.user.id === idOfUserForRemoveAccess}
                >
                  Remove Access
                </Button>
              );
            }}
            width={200}
          />
        </Table>
        <h2>Add user(s) to project</h2>
        <Select
          mode="multiple"
          style={{ width: '250px' }}
          onChange={(e: Array<number>) => {
            setNewUserIdsToProvideAccess(e);
          }}
          value={newUserIdsToProvideAccess}
          placeholder="Select User(s)"
        >
          {allUserData && Array.isArray(allUserData.users) && allUserData.users.length > 0
            ? allUserData.users
                .filter((item) => {
                  return !arrayOfUserWhoAlreadyHaveAccess.includes(item.id);
                })
                .map(
                  (item): JSX.Element => (
                    <Select.Option value={item.id} key={item.id}>
                      {`${item.first_name} ${item.last_name}`}
                    </Select.Option>
                  ),
                )
            : null}
        </Select>
        <div>
          <Button
            type="primary"
            style={{ marginTop: 20 }}
            onClick={() => {
              const projectId: number = parseInt(id, 10);
              if (newUserIdsToProvideAccess.length > 0 && projectId) {
                setGiveAccessButtonLoading(true);
                // call to muattion to add new user to project
                addUsersToProject({
                  variables: {
                    // mapping to create object that will be pass as argument to addUserToProjectMutation
                    objects: newUserIdsToProvideAccess.map((element) => ({
                      user_id: element,
                      project_id: projectId,
                    })),
                  },
                  refetchQueries: [
                    {
                      query: projectUserAccessQuery,
                      variables: { project_id: parseInt(id, 10) },
                    },
                  ],
                })
                  .then(() => {
                    setGiveAccessButtonLoading(false);
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    message.success('User added to project successfully!');
                    setNewUserIdsToProvideAccess([]);
                  })
                  .catch((err: ApolloError) => {
                    setGiveAccessButtonLoading(false);
                    logger(err);
                  });
              }
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              else message.error('Please select user(s) and try again!');
            }}
            loading={giveAccessButtonLoading}
          >
            Give access
          </Button>
        </div>
      </div>
    </AppLayout>
  );
};

export default EditProjectUserAccess;
