import React, { useState } from 'react';
import { Button, Upload, message, Space, Input, Table, Popconfirm } from 'antd';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { useQuery, useMutation, ApolloError, useApolloClient } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import {
  TaxonomyClassifierUploadInfoQueryVariables,
  TaxonomyClassifierUploadInfoQuery,
  NewTaxonomyClassifierMutation,
  NewTaxonomyClassifierMutationVariables,
  AllTaxonomyClassifiersQueryVariables,
  AllTaxonomyClassifiersQuery,
  GetFilesUrlQueryVariables,
  GetFilesUrlQuery,
  Taxonomy_Classifier,
  DeleteTaxonomyClassifierMutationVariables,
  DeleteTaxonomyClassifierMutation,
  GetFilesUrlOutput,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import InfoBox from '../components/InfoBox';
import { UploadFileDetailsType } from '../utils/types';
import { logger } from '../utils/helpers';
import { qiimeVersion } from '../utils/globals';
import EcFormItem from '../components/EcFormItem';

// table data type
type TaxonomyDataType = Pick<Taxonomy_Classifier, 'id' | 'name' | 's3_key'>;

/* Taxonomy classifier name regex */
const taxonomyClassifiersNameRegex = /^[a-zA-Z][a-zA-Z\d-_]*[a-zA-Z\d]$/;

// call to graphql files
const taxonomyClassifierUploadInfoQuery = loader(
  '../graphql/queries/taxonomyClassifierUploadInfoQuery.graphql',
);
const newTaxonomyClassifierMutation = loader(
  '../graphql/mutations/newTaxonomyClassifierMutation.graphql',
);
const allTaxonomyClassifiersQuery = loader(
  '../graphql/queries/allTaxonomyClassifiersQuery.graphql',
);
const getFilesUrlQuery = loader('../graphql/queries/getFilesUrlQuery.graphql');
const deleteTaxonomyClassifierMutation = loader(
  '../graphql/mutations/deleteTaxonomyClassifierMutation.graphql',
);

// react functional component
const TaxonomyClassifiers: React.FC = () => {
  // useState to decide whether to show loading on submit button or not
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>(false);
  // useState to store download url of files
  const [downloadUrls, setDownloadUrls] = useState<Array<GetFilesUrlOutput>>([]);
  // useState to set taxonomy classifier id which user has selected for delete so that we can set loading on delete button
  const [
    taxonomyClassifierIdSelectedForDelete,
    setTaxonomyClassifierIdSelectedForDelete,
  ] = useState<number | null>(null);

  /* use form declaration with default values */
  const { handleSubmit, errors, control, reset } = useForm<{
    classifierName: string;
    fileDetails: UploadFileDetailsType;
  }>({
    defaultValues: {
      classifierName: '',
      fileDetails: {
        file: undefined,
        fileList: [],
      },
    },
    mode: 'onSubmit',
  });

  // use Mutation for creating new taxonomy classifier
  const [newTaxonomyClassifier] = useMutation<
    NewTaxonomyClassifierMutation,
    NewTaxonomyClassifierMutationVariables
  >(newTaxonomyClassifierMutation);

  // use Mutation to delete taxonomy mutation
  const [deleteTaxonomyClassifier] = useMutation<
    DeleteTaxonomyClassifierMutation,
    DeleteTaxonomyClassifierMutationVariables
  >(deleteTaxonomyClassifierMutation);

  // apollo Client
  const apolloClient = useApolloClient();

  // use query for fetching data of taxonomy classifier
  const {
    data: allTaxonomyClassifiersData,
    loading: allTaxonomyClassifiersLoading,
    error: allTaxonomyClassifiersError,
  } = useQuery<AllTaxonomyClassifiersQuery, AllTaxonomyClassifiersQueryVariables>(
    allTaxonomyClassifiersQuery,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (queryData) => {
        // call to query to fetch urls based on s3key
        apolloClient
          .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
            query: getFilesUrlQuery,
            variables: {
              keys: queryData.taxonomy_classifier.map((e) => e.s3_key),
            },
          })
          .then(({ data }) => {
            const { getFilesUrl } = data;
            // array to store generated url respective to s3key which will then pass to setDownloadURl
            const newArray: Array<GetFilesUrlOutput> = [];
            if (getFilesUrl) {
              getFilesUrl.forEach((e) => {
                const key = e?.key;
                const url = e?.url;
                if (url && key) {
                  newArray.push({ key, url });
                }
              });
              setDownloadUrls(newArray);
            }
          })
          .catch((err: ApolloError) => logger(err));
      },
    },
  );

  const { Column } = Table;
  const { Dragger } = Upload;

  return (
    <AppLayout screenTitle="Taxonomy Classifiers">
      <>
        <h2>Upload new classifier</h2>
        <InfoBox
          message={`Platform uses QIIME ${qiimeVersion} for analysis. Please ensure the classifier you upload is trained using QIIME ${qiimeVersion}`}
        />
        <Space size="large" style={{ alignItems: 'flex-start', marginBottom: 30, marginTop: 15 }}>
          <div style={{ maxWidth: 250 }}>
            <EcFormItem
              label="Classifier Name"
              name="classifierName"
              rules={{
                required: 'Please enter classifier name and try again',
                pattern: {
                  value: taxonomyClassifiersNameRegex,
                  message: 'Incorrect name format',
                },
              }}
              helpText="Use only letters, digits, hyphen (-) and underscore (_)"
              isFieldRequired
              control={control}
              errors={errors}
              as={<Input placeholder="Classifier Name" />}
            />
          </div>
          <div>
            {/* <p style={{ marginBottom: 1 }}>Classifier qza file*</p> */}
            <div style={{ height: 90, width: 300 }}>
              <EcFormItem
                label="Classifier qza file"
                name="fileDetails"
                control={control}
                errors={errors}
                rules={{
                  required: true,
                  validate: (value: UploadFileDetailsType) =>
                    value.fileList.length > 0 || 'Please select file and try again',
                }}
                render={({ onChange, value }) => {
                  const fieldValue = value as UploadFileDetailsType;
                  return (
                    <Dragger
                      onChange={(info) => {
                        let newFileList = [...info.fileList];
                        newFileList = newFileList.slice(-1);
                        const fileExtension = newFileList[0].name.split('.').pop();
                        if (fileExtension === 'qza') {
                          onChange({ file: info.file, fileList: newFileList });
                        } else {
                          onChange({ file: null, fileList: [] });
                          // eslint-disable-next-line @typescript-eslint/no-floating-promises
                          message.error('Only .qza files are allowed');
                        }
                      }}
                      beforeUpload={() => {
                        return false;
                      }}
                      onRemove={() => {
                        onChange({ file: null, fileList: [] });
                        return false;
                      }}
                      fileList={fieldValue.fileList}
                    >
                      <p style={{ margin: -10, paddingLeft: 10, paddingRight: 10 }}>
                        <InboxOutlined style={{ fontSize: 30, color: 'blue' }} />
                        <p style={{ fontSize: 12, padding: 2 }}>
                          Click or drag file to this area to upload
                        </p>
                        <p style={{ fontSize: 12 }}>Only QZA files are allowed</p>
                      </p>
                    </Dragger>
                  );
                }}
              />
            </div>
          </div>
          <Popconfirm
            title={
              <span>
                Is the classifier trained with <strong>QIIME {qiimeVersion}</strong>?
              </span>
            }
            onConfirm={handleSubmit((classifierData) => {
              setIsSubmitButtonLoading(true);
              // call to query to get s3Key value of uploaded file
              apolloClient
                .query<
                  TaxonomyClassifierUploadInfoQuery,
                  TaxonomyClassifierUploadInfoQueryVariables
                >({
                  query: taxonomyClassifierUploadInfoQuery,
                  variables: {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                    fileName: classifierData.fileDetails.file.name,
                  },
                })
                .then(({ data }) => {
                  if (data && data.fileUploadS3Config && data.fileUploadS3Config[0]) {
                    const {
                      algorithm,
                      contentDisposition,
                      contentType,
                      credential,
                      date,
                      key,
                      policy,
                      signature,
                      url,
                    } = data.fileUploadS3Config[0];
                    const formData: FormData = new FormData();
                    formData.append('key', key);
                    formData.append('policy', policy);
                    formData.append('x-amz-algorithm', algorithm);
                    formData.append('x-amz-credential', credential);
                    formData.append('x-amz-date', date);
                    formData.append('x-amz-signature', signature);
                    formData.append('Content-Type', contentType);
                    formData.append('Content-Disposition', contentDisposition);
                    formData.append('file', classifierData.fileDetails.file);
                    // Upload the file to the server
                    axios
                      .post(url, formData)
                      .then(() => {
                        // After successful file upload, update value s3_key in mutation
                        newTaxonomyClassifier({
                          variables: { name: classifierData.classifierName, s3_key: key },
                          refetchQueries: [
                            {
                              query: allTaxonomyClassifiersQuery,
                            },
                          ],
                        })
                          .then(() => {
                            setIsSubmitButtonLoading(false);
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            message.success('New Taxonomy Classifier added successfully');
                            reset({
                              classifierName: '',
                              fileDetails: { file: undefined, fileList: [] },
                            });
                          })
                          .catch((err: ApolloError) => logger(err));
                      })
                      .catch((formDataError: AxiosError) => {
                        setIsSubmitButtonLoading(false);
                        logger(formDataError);
                      });
                  }
                })
                .catch((err: ApolloError) => {
                  setIsSubmitButtonLoading(false);
                  logger(err);
                });
            })}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" style={{ marginTop: 22 }} loading={isSubmitButtonLoading}>
              Submit
            </Button>
          </Popconfirm>
        </Space>
        {/* logic to display list of taxonomy classifier in table */}
        <h2>View all classifiers</h2>
        {allTaxonomyClassifiersData &&
        Array.isArray(allTaxonomyClassifiersData.taxonomy_classifier) &&
        allTaxonomyClassifiersData.taxonomy_classifier.length > 0 &&
        !allTaxonomyClassifiersError ? (
          <Table<TaxonomyDataType>
            bordered
            loading={allTaxonomyClassifiersLoading}
            dataSource={allTaxonomyClassifiersData.taxonomy_classifier}
            rowKey={(record) => record.id}
            size="small"
            style={{ width: 530 }}
          >
            <Column<TaxonomyDataType> title="Name" dataIndex="name" />
            <Column<TaxonomyDataType>
              title="Actions"
              render={(record: TaxonomyDataType) => {
                // const to store url who's s3Key matches with clicked item s3Key
                const fileUrl: string = downloadUrls.filter((item) => item.key === record.s3_key)[0]
                  ?.url;
                return (
                  <div>
                    <Button
                      type="default"
                      icon={<DownloadOutlined />}
                      href={fileUrl ?? '#'}
                      style={{ marginRight: 20 }}
                    >
                      Download
                    </Button>
                    <Popconfirm
                      title={
                        <span>
                          Are you sure delete this classifier? <br />
                          <strong>It may result in loss of information in MDSA analysis</strong>
                        </span>
                      }
                      onConfirm={() => {
                        setTaxonomyClassifierIdSelectedForDelete(record.id);
                        // call to delete mutation
                        deleteTaxonomyClassifier({
                          variables: { id: record.id },
                          refetchQueries: [
                            {
                              query: allTaxonomyClassifiersQuery,
                            },
                          ],
                        })
                          .then(() => {
                            setTaxonomyClassifierIdSelectedForDelete(null);
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            message.success('Classifier deleted successfully');
                          })
                          .catch((err: ApolloError) => {
                            setTaxonomyClassifierIdSelectedForDelete(null);
                            logger(err);
                          });
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="default"
                        loading={record.id === taxonomyClassifierIdSelectedForDelete}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </div>
                );
              }}
              width={250}
            />
          </Table>
        ) : (
          <p style={{ color: 'red', textAlign: 'center' }}>
            {allTaxonomyClassifiersError ? allTaxonomyClassifiersError.message : null}
          </p>
        )}
      </>
    </AppLayout>
  );
};

export default TaxonomyClassifiers;
