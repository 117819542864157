import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Spin, Row, Col, InputNumber, Button, message } from 'antd';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import AppLayout from '../components/AppLayout';
import {
  SrTaskInParamsQueryVariables,
  SrTaskInParamsQuery,
  SrTaskInputParams,
  UpdateSrTaskIntParamsMutationVariables,
  UpdateSrTaskIntParamsMutation,
  StartSrQiimeAnalysisMutation,
  StartSrQiimeAnalysisMutationVariables,
} from '../graphql/graphql-types';
import EcFormItem from '../components/EcFormItem';
import { logger } from '../utils/helpers';

// call to graphql files
const srTaskParamsQuery = loader('../graphql/queries/srTaskInParamsQuery.graphql');
const updateSrTaskIntParamsMutation = loader(
  '../graphql/mutations/updateSrTaskIntParamsMutation.graphql',
);
const startSrQiimeAnalysisMutation = loader(
  '../graphql/mutations/startSrQiimeAnalysisMutation.graphql',
);

// react functional component
const SRDada2: React.FC = () => {
  // useState of loading indicator for start analysis button
  const [isStartAnalysisButtonLoading, setIsStartAnalysisLoading] = useState<boolean>(false);

  // fetching taskId from useParams
  const { taskId } = useParams();

  // call to query to get sr task inParams
  const { data, loading, error } = useQuery<SrTaskInParamsQuery, SrTaskInParamsQueryVariables>(
    srTaskParamsQuery,
    {
      variables: { id: parseInt(taskId, 10) },
    },
  );
  // useForm declaration
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, control, errors, getValues, clearErrors, setError } = useForm({
    mode: 'onChange',
  });

  // call to mutation to update sr task params
  const [updateSrTaskIntParams] = useMutation<
    UpdateSrTaskIntParamsMutation,
    UpdateSrTaskIntParamsMutationVariables
  >(updateSrTaskIntParamsMutation);

  // mutation for startSrQiime analysis
  const [startSrQiimeAnalysis] = useMutation<
    StartSrQiimeAnalysisMutation,
    StartSrQiimeAnalysisMutationVariables
  >(startSrQiimeAnalysisMutation);

  const navigate = useNavigate();

  // if error in fetching query
  if (error) {
    return (
      <AppLayout screenTitle="DADA2">
        <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
      </AppLayout>
    );
  }

  // while loading query data
  if (loading) {
    return (
      <AppLayout screenTitle="DADA2">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }
  // const to store sr task in params data which is fetched from query
  const inParamsData = data?.sr_task_by_pk?.in_params as SrTaskInputParams;

  // const to store sr task in params data re ordered according to requirements
  const reOrderedSrInParamsData: SrTaskInputParams = [];

  if (Array.isArray(inParamsData) && inParamsData.length > 0) {
    /* Re arranging inParams data */
    inParamsData.forEach((item) => {
      if (item.key === '--p-trim-left-f') {
        reOrderedSrInParamsData[0] = item;
      }
      if (item.key === '--p-trunc-len-f') {
        reOrderedSrInParamsData[1] = item;
      }
      if (item.key === '--p-trim-left-r') {
        reOrderedSrInParamsData[2] = item;
      }
      if (item.key === '--p-trunc-len-r') {
        reOrderedSrInParamsData[3] = item;
      }
    });
  }

  /* Function to validate in params key data based on following conditions */
  const handleValidation = (value: string, key: string) => {
    /* Validation for '--p-trunc-len-f' input */
    if (key === '--p-trunc-len-f') {
      if (getValues('--p-trim-left-f') < getValues('--p-trunc-len-f')) {
        clearErrors(['--p-trim-left-f']);
      } else {
        setError('--p-trim-left-f', {
          type: 'manual',
          message: 'The value of --p-trim-left-f should be smaller than --p-trunc-len-f',
        });
      }
    }

    /* Validation for '--p-trim-left-f' input */
    if (key === '--p-trim-left-f') {
      if (getValues('--p-trim-left-f') >= getValues('--p-trunc-len-f')) {
        return 'The value of --p-trim-left-f should be smaller than --p-trunc-len-f';
      }
    }

    /* Validation for '--p-trunc-len-r' input */
    if (key === '--p-trunc-len-r') {
      if (getValues('--p-trim-left-r') < getValues('--p-trunc-len-r')) {
        clearErrors(['--p-trim-left-r']);
      } else {
        setError('--p-trim-left-r', {
          type: 'manual',
          message: 'The value of --p-trim-left-r should be smaller than --p-trunc-len-r',
        });
      }
    }

    /* Validation for '--p-trim-left-r' input */
    if (key === '--p-trim-left-r') {
      if (getValues('--p-trim-left-r') >= getValues('--p-trunc-len-r')) {
        return 'The value of --p-trim-left-r should be smaller than --p-trunc-len-r';
      }
    }
    return value;
  };

  return (
    <AppLayout screenTitle="DADA2">
      <form
        onSubmit={handleSubmit((formData) => {
          if (inParamsData) {
            setIsStartAnalysisLoading(true);
            updateSrTaskIntParams({
              variables: {
                id: parseInt(taskId, 10),
                in_params: inParamsData.map((e) => {
                  const formDataKeyname = e.key;
                  return { key: e.key, value: formData[formDataKeyname] as number };
                }) as SrTaskInputParams,
              },
            })
              .then(() => {
                startSrQiimeAnalysis({
                  variables: {
                    taskId: parseInt(taskId, 10),
                  },
                })
                  .then(() => {
                    setIsStartAnalysisLoading(false);
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    message.success('Sr task params updated successfully');
                    navigate(-1);
                  })
                  .catch((err) => {
                    setIsStartAnalysisLoading(false);
                    logger(err);
                  });
              })
              .catch((err) => {
                setIsStartAnalysisLoading(false);
                logger(err);
              });
          }
        })}
      >
        <Row gutter={[0, 15]}>
          {reOrderedSrInParamsData &&
          Array.isArray(reOrderedSrInParamsData) &&
          reOrderedSrInParamsData.length > 0
            ? reOrderedSrInParamsData.map((item) => {
                return (
                  <Col lg={24} sm={24} key={item.key}>
                    <EcFormItem
                      label={item.key}
                      name={item.key}
                      control={control}
                      defaultValue={item.value}
                      rules={{
                        required: `Please enter ${item.key} and try again`,
                        min: {
                          value: 1,
                          message: 'Please enter valid number ',
                        },
                        validate: (value) => handleValidation(value, item.key),
                      }}
                      errors={errors}
                      as={<InputNumber style={{ width: 100 }} />}
                    />
                  </Col>
                );
              })
            : null}
        </Row>
        <div>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: 20 }}
            loading={isStartAnalysisButtonLoading}
          >
            Start Analysis
          </Button>
        </div>
      </form>
    </AppLayout>
  );
};

export default SRDada2;
