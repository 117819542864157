import React, { useState } from 'react';
import { Button, Table, notification } from 'antd';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';

import {
  MdsaFileUploadConfigQuery,
  MdsaFileUploadConfigQueryVariables,
  MetadataColumn,
  UpdateMdsaMutation,
  UpdateMdsaMutationVariables,
} from '../../graphql/graphql-types';
import apolloClient from '../../graphql/apolloClient';
import { logger } from '../../utils/helpers';
import { UpdateMetadataNewColumnType } from '../../utils/types';

const mdsaFileUploadConfigQuery = loader('../../graphql/queries/mdsaFileUploadConfigQuery.graphql');
const mdsaTaskResultQuery = loader('../../graphql/queries/mdsaTaskResultQuery.graphql');
const updateMdsaMutation = loader('../../graphql/mutations/updateMdsaMutation.graphql');

//  type to store prop types for UpdatedMdsaMetadataConfirmScreen
type UpdatedMdsaMetadataConfirmScreenPropType = {
  // prop to store uploaded file object
  uploadedFile: any;
  // const to store newly added columns details to metadata
  newlyAddedColumnsData: Array<UpdateMetadataNewColumnType>;
  // prop to set the state of metadataScreenToVisible
  setMetadataScreenToRender: React.Dispatch<React.SetStateAction<'upload' | 'confirm' | 'form'>>;
  // prop use to store parsed file data which we will pass to table.
  tableDataToConfirm:
    | {
        tableColumns: Array<string>;
        tableDataSource: Array<Record<string, string>>;
      }
    | undefined;
};

// extracting column from table
const { Column } = Table;

// react functional component
const UpdatedMdsaMetadataConfirmScreen: React.FC<UpdatedMdsaMetadataConfirmScreenPropType> = ({
  uploadedFile,
  newlyAddedColumnsData,
  setMetadataScreenToRender,
  tableDataToConfirm,
}) => {
  // extract id and task id from useParam
  const { id, taskId } = useParams();

  // Mutation to update mdsa metadata
  const [updateMdsa] = useMutation<UpdateMdsaMutation, UpdateMdsaMutationVariables>(
    updateMdsaMutation,
  );

  // state to handle confirm btn loading indicator
  const [confirmMetadataLoading, setConfirmMetadataLoading] = useState<boolean>(false);

  // creating instance from useNavigation
  const navigate = useNavigate();

  // function to handle confirm btn on click
  const handleConfirmMetadata = () => {
    setConfirmMetadataLoading(true);
    /* Query to fetch info/config for uploading file to S3 */
    apolloClient
      .query<MdsaFileUploadConfigQuery, MdsaFileUploadConfigQueryVariables>({
        query: mdsaFileUploadConfigQuery,
        variables: {
          files: [
            {
              fileName: 'metadata.tsv',
              contentType: 'text/tab-separated-values',
            },
          ],
          mdsa_id: parseInt(id, 10),
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        if (data && Array.isArray(data.mdsaFileUploadConfig) && data.mdsaFileUploadConfig[0]) {
          const {
            algorithm,
            contentDisposition,
            contentType,
            credential,
            date,
            key,
            policy,
            signature,
            url,
          } = data.mdsaFileUploadConfig[0];

          /* Creating formData from query response */
          const formData: FormData = new FormData();
          formData.append('key', key);
          formData.append('policy', policy);
          formData.append('x-amz-algorithm', algorithm);
          formData.append('x-amz-credential', credential);
          formData.append('x-amz-date', date);
          formData.append('x-amz-signature', signature);
          formData.append('Content-Type', contentType);
          formData.append('Content-Disposition', contentDisposition);
          formData.append('file', uploadedFile);

          // const to store newly added columns plus old columns of the metadata
          const allMetadataColumns = tableDataToConfirm
            ? tableDataToConfirm.tableColumns.map((ele) => {
                return { column: ele, type: tableDataToConfirm.tableDataSource[0][`${ele}`] };
              })
            : [];
          /* Axios request to upload file to server */
          axios
            .post(url, formData)
            .then(() => {
              /* Updating mdsa metadata using mutation after successful file upload */
              updateMdsa({
                variables: {
                  id: parseInt(id, 10),
                  mdsaSetInput: {
                    metadata_columns: allMetadataColumns as MetadataColumn[],
                  },
                },
                refetchQueries: [
                  {
                    query: mdsaTaskResultQuery,
                    variables: { id: parseInt(taskId, 10) },
                  },
                ],
              })
                .then(() => {
                  notification.success({
                    message: 'Success!',
                    description: 'New columns were appended to the metadata file',
                  });
                  navigate(`/mdsa/${id}`);
                  setConfirmMetadataLoading(false);
                })
                .catch((err) => {
                  logger(err);
                  setConfirmMetadataLoading(false);
                });
            })
            .catch((error) => {
              logger(error);
              setConfirmMetadataLoading(false);
            });
        }
      })
      .catch((error) => {
        logger(error);
        setConfirmMetadataLoading(false);
      });
  };

  return (
    <>
      <p>
        Check the new column(s) and their values and provide confirmation before updating the
        metadata file
      </p>
      {tableDataToConfirm ? (
        <>
          <Table
            size="small"
            bordered
            dataSource={tableDataToConfirm.tableDataSource}
            rowKey="sample-id"
          >
            {/* filtering because we want to show first column and  newly added columns of metadata in a confirmation table */}
            {tableDataToConfirm.tableColumns
              .filter((ele, index) => {
                if (index === 0) {
                  return true;
                }
                return newlyAddedColumnsData.map((item) => item.column).includes(ele);
              })
              .map((item) => (
                <Column title={item} dataIndex={item} key={item} />
              ))}
          </Table>
          <Button type="primary" onClick={() => setMetadataScreenToRender('upload')}>
            Re-Upload
          </Button>
          <Button
            loading={confirmMetadataLoading}
            type="primary"
            style={{ marginLeft: 25 }}
            onClick={() => {
              handleConfirmMetadata();
            }}
          >
            Confirm metadata
          </Button>
        </>
      ) : (
        <p>No data</p>
      )}
    </>
  );
};

export default UpdatedMdsaMetadataConfirmScreen;
