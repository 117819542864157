import React from 'react';
import { Table } from 'antd';
import { BlastOutData } from '../../graphql/graphql-types';

// type definition for props
type BlastMappedResultsPropsType = {
  // indicates out data of blast task
  outData: Array<BlastOutData>;
};

const BlastMappedResults: React.FC<BlastMappedResultsPropsType> = ({ outData }) => {
  return (
    <Table
      columns={[
        { title: 'Feature ID', key: 'featureId', dataIndex: 'featureId' },
        {
          title: 'Accession',
          key: 'saccver',
          dataIndex: 'saccver',
          render: (value) => (
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://www.ncbi.nlm.nih.gov/nucleotide/${value as string}`}
            >
              {value}
            </a>
          ),
        },
        { title: 'Scientific Name', key: 'ssciname', dataIndex: 'ssciname' },
        {
          title: 'Taxonomy Id',
          key: 'staxid',
          dataIndex: 'staxid',
          render: (value) => (
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?id=${
                value as string
              }`}
            >
              {value}
            </a>
          ),
        },
      ]}
      bordered
      dataSource={outData}
      style={{ marginTop: 20 }}
    />
  );
};

export default BlastMappedResults;
