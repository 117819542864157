import React, { useState } from 'react';
import { Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

/* ColumnSearchInput prop type */
type ColumnsSearchInputPropType = {
  /* setState function to set selectedKeys in table for search */
  setSelectedKeys: (selectedKeys: React.Key[]) => void;
  /* State to store selectedKeys in table for search */
  selectedKeys: React.Key[];
  /* Function to confirm column search  */
  confirm: () => void;
  /* Function to reset search/filters in column search */
  clearFilters: (() => void) | undefined;
  /* Data index of a column whose search functionality i s being used */
  dataIndex: string;
};

/* React functional component */
const ColumnsSearchInput: React.FC<ColumnsSearchInputPropType> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataIndex,
}) => {
  /* State to store search text for columns search options */
  const [searchText, setSearchText] = useState('');

  /* Function to handle column search functionality */
  const handleSearch = () => {
    confirm();
    setSearchText(selectedKeys[0] as string);
  };

  /* Function to reset column search functionality */
  const handleReset = () => {
    if (clearFilters) clearFilters();
    setSearchText('');
  };
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch()}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset()} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );
};

export default ColumnsSearchInput;
