import { message } from 'antd';
import { ApolloError } from '@apollo/client';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';

// this function will be use to log errors
export const logger = (error: Error | AxiosError | ApolloError): void => {
  Sentry.captureException(error);
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  message.error(error.message);
  console.log('message', error.message);
  console.log('error name', error.name);
  // if axios error
  if ((error as AxiosError).isAxiosError) {
    const axiosError = error as AxiosError;
    console.log('Errored request', axiosError.request);
    console.log('Errored response', axiosError.response);
  }
  // If ApolloError
  else if ((error as ApolloError).graphQLErrors) {
    const apolloError = error as ApolloError;
    console.log('ApolloError.extraInfo', apolloError.extraInfo);
    console.log('ApolloError.graphQLErrors', apolloError.graphQLErrors);
  }
  // any other type of error
  else {
    console.log(error.stack);
    console.log(error);
  }
};

export default null;
