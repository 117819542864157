import React, { useState } from 'react';
import { InboxOutlined, WarningFilled } from '@ant-design/icons';
import { Alert, Button, message, Spin, Upload } from 'antd';
import FileSaver from 'file-saver';
import Papa from 'papaparse';
import { useParams } from 'react-router';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { UpdateMetadataNewColumnType, UploadFileDetailsType } from '../../utils/types';
import apolloClient from '../../graphql/apolloClient';
import {
  MdsaTaskResultQuery,
  MdsaTaskResultQueryVariables,
  TaskOutFiles,
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
} from '../../graphql/graphql-types';
import { logger } from '../../utils/helpers';

const mdsaTaskResultQuery = loader('../../graphql/queries/mdsaTaskResultQuery.graphql');
const getFilesUrlQuery = loader('../../graphql/queries/getfilesUrlQuery.graphql');

// extracting dragger from upload
const { Dragger } = Upload;

// type to store prop type of UpdatedMdsaUploadOrConfirmScreen
type UpdatedMdsaUploadOrConfirmScreenPropType = {
  // prop use to store (formData) newly added columns and there basic details like alterTableBy, combinedColumns, type.
  newlyAddedColumnsData: Array<UpdateMetadataNewColumnType>;
  // prop to set the state of metadataScreenToVisible
  setMetadataScreenToRender: React.Dispatch<React.SetStateAction<'upload' | 'confirm' | 'form'>>;
  // prop to set the state of uploadFileDetails
  setUploadFileDetails: React.Dispatch<React.SetStateAction<UploadFileDetailsType>>;
  // prop to store uploaded file object
  uploadFileDetails: UploadFileDetailsType;
  // prop use to store state which then use to set the file parsed data in setTableDataToConfirm
  setTableDataToConfirm: React.Dispatch<
    React.SetStateAction<
      | {
          tableColumns: Array<string>;
          tableDataSource: Array<Record<string, string>>;
        }
      | undefined
    >
  >;
};

// react functional component
const UpdatedMdsaMetadataUploadScreen: React.FC<UpdatedMdsaUploadOrConfirmScreenPropType> = ({
  newlyAddedColumnsData,
  setMetadataScreenToRender,
  setUploadFileDetails,
  uploadFileDetails,
  setTableDataToConfirm,
}) => {
  /* Extracting id, task id from url param */
  const { id, taskId } = useParams();

  // State to decide whether to show error of no file selected
  const [showNoFileSelectedErr, setShowNoFileSelectedErr] = useState(false);

  // state to set the loading indicator for upload button
  const [isUploadBtnLoading, setIsUploadBtnLoading] = useState<boolean>(false);

  // state to set the loading indicator for download button
  const [isDownloadBtnLoading, setIsDownloadBtnLoading] = useState<boolean>(false);

  // state to store previously uploaded metaData. will store data we got by parsing file which we download as template
  const [oldParsedMetadata, setOldParsedMetadata] = useState<Array<string>[]>([]);

  // state use to store error object which we got when uploaded file has some values which are modified as compared to download template file
  const [dataMismatchError, setDataMismatchError] = useState<
    Array<{ columnName: string; sample: string }>
  >([]);

  // useQuery instance to fetch outFile details for selected mdsa task
  const { data: mdsaTaskQueryData, loading, error } = useQuery<
    MdsaTaskResultQuery,
    MdsaTaskResultQueryVariables
  >(mdsaTaskResultQuery, {
    variables: { id: parseInt(taskId, 10) },
    fetchPolicy: 'no-cache',
    onCompleted: (res: MdsaTaskResultQuery) => {
      // const to store out file  object of the mdsa task
      const mdsaTaskOutFilesObject =
        res.mdsa_task_by_pk && (res.mdsa_task_by_pk.out_files as TaskOutFiles);

      // const to store metadata.tsv outFile of mdsa task
      const metaDataFileObject =
        mdsaTaskOutFilesObject && mdsaTaskOutFilesObject.find((ele) => ele.name === 'metadata.tsv');

      // const to store mdsa task metadata.tsv file s3key
      const metaDataFileS3Key = metaDataFileObject ? metaDataFileObject.s3_key : null;

      if (metaDataFileS3Key) {
        // query to get metaDataFile download url
        apolloClient
          .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
            query: getFilesUrlQuery,
            variables: {
              keys: [metaDataFileS3Key],
            },
          })
          .then((result) => {
            // const to store url of the file
            const downloadUrlForMetadataFile =
              result.data.getFilesUrl &&
              result.data.getFilesUrl[0] &&
              result.data.getFilesUrl[0].url;

            if (downloadUrlForMetadataFile) {
              // function to parse remote url using papa parse to get file data in array format
              Papa.parse(downloadUrlForMetadataFile, {
                skipEmptyLines: true,
                download: true,
                complete: ({ data }) => {
                  setOldParsedMetadata(data as Array<Array<string>>);
                },
              });
            }
          })
          .catch((err) => {
            logger(err);
          });
      }
    },
  });

  // function to download template file
  const handleDownloadTemplateFunc = () => {
    setIsDownloadBtnLoading(true);
    // const to store  updated data of metadata file. This will include parsed file data plus newly added columns data.
    const updatedParsedData = oldParsedMetadata.map((item, index) => {
      // as first element of parsed data contain heading of columns so appending newly added columns name to it
      if (index === 0) {
        return [
          ...item,
          ...newlyAddedColumnsData.map((ele) => {
            return ele.column;
          }),
        ];
      }
      // as second element of parsed data contain type of columns so appending newly added columns type to it
      if (index === 1) {
        return [
          ...item,
          ...newlyAddedColumnsData.map((ele) => {
            return ele.type;
          }),
        ];
      }
      // appending rest data to rest all rows, If newly added column alterType is "combined" then will pass
      //  combined data from mentioned columns which we want to combine else will pass undefined
      return [
        ...item,
        ...newlyAddedColumnsData.map((ele) => {
          if (ele.alterTableBy === 'new_column') {
            return null;
          }
          // const to store combined value for the column
          let combinedColumnValue = '';
          // iterating over all combined columns to append there old value in combinedColumnValue variable
          ele.combinedColumnsKey.forEach((column) => {
            // const to store index of column which we want to combine
            const indexOfColumnToCombine = oldParsedMetadata[0].findIndex(
              (element) => element === column,
            );
            if (combinedColumnValue) {
              combinedColumnValue = `${combinedColumnValue}_${item[indexOfColumnToCombine]}`;
            } else {
              combinedColumnValue = item[indexOfColumnToCombine];
            }
          });
          return combinedColumnValue;
        }),
      ];
    });

    // Unparsing updated data to create tsv file using papa parse
    const unparsedTsvData = Papa.unparse(
      {
        fields: updatedParsedData[0],
        data: updatedParsedData.filter((ele, index) => index !== 0),
      },
      {
        delimiter: '\t',
      },
    );

    // File blob create for file saver to save file
    const fileBlob = new Blob([unparsedTsvData]);

    // Saving file on user's device using saveAs
    FileSaver.saveAs(fileBlob, `MDSA${id}_metadata_new-columns_template.tsv`);
    setIsDownloadBtnLoading(false);
  };

  // function to handle upload functionality of new metadata file
  const handleFileUploadFunction = () => {
    // if user does not select any file then following error will be shown
    if (uploadFileDetails.file === null && uploadFileDetails.fileList.length === 0) {
      setShowNoFileSelectedErr(true);
    } else {
      setShowNoFileSelectedErr(false);
      setIsUploadBtnLoading(true);
      // const to parse uploaded file so that we can compare uploaded, file data with old file data
      Papa.parse(uploadFileDetails.file, {
        download: true,
        delimiter: '\t',
        skipEmptyLines: true,
        complete: ({ data }) => {
          // array to store uploaded file parsed data
          const fileParsedData = data as Array<string[]>;
          // array to store index of newly added column in uploaded parsed data
          const indexOfNewlyAddedColumns: Array<number> = [];

          // const to get old data from newly uploaded file so that we can compare all old column value
          const oldExtractedData = fileParsedData.map((ele, colIndex) => {
            if (colIndex === 0) {
              ele.forEach((item, index) => {
                if (
                  newlyAddedColumnsData &&
                  newlyAddedColumnsData.map((obj) => obj.column).includes(item)
                ) {
                  indexOfNewlyAddedColumns.push(index);
                }
              });
            }
            // filtering out newly added column value from all rows
            return ele.filter((item, columnIndex) => {
              return !indexOfNewlyAddedColumns.includes(columnIndex);
            });
          });

          // const to store array of object which store info related to sample number and column name whose value id different as compared to old file values
          const errorObject: Array<{ columnName: string; sample: string }> = [];
          // logic to compare newly uploaded data with old data
          oldExtractedData.forEach((ele, sampleIndex) => {
            ele.forEach((item, index) => {
              const sampleIndexRowData = oldParsedMetadata[sampleIndex];
              if (item !== sampleIndexRowData[index]) {
                errorObject.push({
                  columnName:
                    oldParsedMetadata[0] && oldParsedMetadata[0][index]
                      ? oldParsedMetadata[0][index]
                      : 'Number of columns are different',
                  sample: ele[0] ? ele[0] : 'Extra sample added',
                });
              }
            });
          });

          if (errorObject.length > 0) {
            setDataMismatchError(errorObject);
          } else {
            // const to store array of object by manipulating fileParsedData which will use to set the tableDataSource value.
            const tableDataSource = fileParsedData.reduce(
              (acc: Array<Record<string, string>>, current, index) => {
                let rowObject = {};
                if (index !== 0) {
                  fileParsedData[0].forEach((item, nestedIndex) => {
                    rowObject = { ...rowObject, [item]: current[nestedIndex] };
                  });
                  return [...acc, rowObject];
                }
                return acc;
              },
              [],
            );
            setMetadataScreenToRender('confirm');
            setTableDataToConfirm({
              tableColumns: fileParsedData[0],
              tableDataSource,
            });
          }
          setIsUploadBtnLoading(false);
        },
      });
    }
  };

  // if query loading
  if (loading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin />
      </div>
    );
  }
  // if query error
  if (error) {
    <div>
      <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
    </div>;
  }

  return (
    <>
      <Alert
        message="Steps:"
        description={
          <ol style={{ margin: 0, marginLeft: -20 }}>
            <li>Download the metadata template file.</li>
            <li>
              Enter values for the newly created columns for all samples (the columns will be at the
              end).
            </li>
            <li>Upload the updated metadata tsv file.</li>
          </ol>
        }
        style={{ maxWidth: '700px', marginBottom: 20 }}
        type="info"
        showIcon
      />
      <Alert
        message={
          <span style={{ fontSize: '1.05rem' }}>
            Do not modify the values of existing metadata columns
          </span>
        }
        type="warning"
        style={{ maxWidth: '500px', marginBottom: 20 }}
        icon={<WarningFilled style={{ fontSize: '1.8rem' }} />}
        showIcon
      />
      <h3 style={{ marginTop: 30 }}>Download template metadata tsv file</h3>
      <Button
        type="primary"
        onClick={() => {
          handleDownloadTemplateFunc();
        }}
        loading={isDownloadBtnLoading}
      >
        Download metadata template
      </Button>

      <h3 style={{ marginTop: 30 }}>Upload metadata tsv file</h3>
      <div style={{ marginBottom: 15, marginTop: 15, width: 400 }}>
        <Dragger
          onChange={(info) => {
            let newFileList = [...info.fileList];
            newFileList = newFileList.slice(-1);
            const fileExtension = newFileList[0].name.split('.').pop();
            if (fileExtension === 'tsv') {
              setUploadFileDetails({ file: info.file, fileList: newFileList });
            } else {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.error('Only .tsv files are allowed');
            }
            setShowNoFileSelectedErr(false);
            setDataMismatchError([]);
          }}
          beforeUpload={() => {
            return false;
          }}
          onRemove={() => {
            setDataMismatchError([]);
            setUploadFileDetails({ file: null, fileList: [] });
            return false;
          }}
          fileList={uploadFileDetails.fileList}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Only TSV files are allowed</p>
        </Dragger>
      </div>

      {showNoFileSelectedErr ? (
        <p style={{ color: 'red', marginTop: 15 }}>Please select a file and try again!</p>
      ) : null}

      {dataMismatchError && dataMismatchError.length > 0 ? (
        <div style={{ color: 'red' }}>
          <p style={{ marginBottom: 5, fontSize: '16px' }}>
            <b>Error!</b> The following data values from the existing metadata columns were
            modified:
          </p>
          {dataMismatchError.map((ele) => {
            return (
              <ul style={{ paddingLeft: 20, marginBottom: 5 }}>
                <li>
                  {ele.sample}: {ele.columnName}
                </li>
              </ul>
            );
          })}
          <p style={{ fontSize: '15px' }}>Please revert the values and upload the file again!</p>
        </div>
      ) : null}
      <Button
        type="primary"
        onClick={() => {
          handleFileUploadFunction();
        }}
        loading={isUploadBtnLoading}
      >
        Upload
      </Button>
    </>
  );
};

export default UpdatedMdsaMetadataUploadScreen;
