import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin, Button, InputNumber } from 'antd';
import { useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import {
  MdsaTaskInParamsWithPrimaryTableNameQuery,
  MdsaTaskInParamsWithPrimaryTableNameQueryVariables,
  UpdateAndStartMdsaTaskMutation,
  UpdateAndStartMdsaTaskMutationVariables,
  MdsaInParam,
  Enum_Mdsa_Task_Key_Enum,
} from '../graphql/graphql-types';
import EcFormItem from '../components/EcFormItem';
import AppLayout from '../components/AppLayout';

import { logger } from '../utils/helpers';
import { mdsaTasksInitialValues } from '../utils/globals';

const mdsaTaskInParamsWithPrimaryTableNameQuery = loader(
  '../graphql/queries/mdsaTaskInParamsWithPrimaryTableNameQuery.graphql',
);
const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);

const MdsaPhylogeneticDiversityAnalysis: React.FC = () => {
  /* Extracting id and task id from url params */
  const { id, taskId } = useParams();

  const navigate = useNavigate();

  /* State to store started_at for updateAndStartMdsaTask */
  const [started_at] = useState(new Date());

  /* State to store start analysis buttons loading */
  const [startAnalysisBtnLoading, setStartAnalysisBtnLoading] = useState(false);

  /* useForm declaration with default values  */
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, control, setValue } = useForm<{ samplingDepth: number }>({
    mode: 'onSubmit',
  });

  /* Query to fetch mdsa data for mdsa phylogenetic diversity analysis data */
  const { data, loading, error: queryError } = useQuery<
    MdsaTaskInParamsWithPrimaryTableNameQuery,
    MdsaTaskInParamsWithPrimaryTableNameQueryVariables
  >(mdsaTaskInParamsWithPrimaryTableNameQuery, {
    variables: {
      taskId: parseInt(taskId, 10),
    },
    fetchPolicy: 'network-only',
  });

  /* Mutation to update and start mdsa task */
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  /* Mdsa task in params data */
  const mdsaTaskInParams = data?.task?.in_params as Array<MdsaInParam>;

  useEffect(() => {
    /* Check whether initially values are present in in_params data */
    if (Array.isArray(mdsaTaskInParams) && mdsaTaskInParams.length > 0) {
      mdsaTaskInParams.forEach((item) => {
        if (item.key === '--p-sampling-depth' && item.rawValue) {
          setValue('samplingDepth', item.rawValue as number);
        }
      });
    }
  }, [mdsaTaskInParams, setValue]);

  /* Loading indicator while fetching data */
  if (loading) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  /* if any error occurred while fetching data will show error message */
  if (queryError) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  /* Primary feature table file name from mdsa data */
  const primaryFeatureTableFileName = data?.task?.mdsa.primary_feature_table_file_name;

  /* Function to update and start mdsa task on click start analysis button */
  const handleUpdateAndStartMdsaTask = (inParamsData: Array<MdsaInParam>) => {
    setStartAnalysisBtnLoading(true);
    updateAndStartMdsaTask({
      variables: {
        taskId: parseInt(taskId, 10),
        taskUpdateInput: {
          started_at,
          in_params: inParamsData,
        },
      },
    })
      .then((res) => {
        const updatedMdsaTaskId = res.data?.update_mdsa_task_by_pk?.id;
        if (updatedMdsaTaskId) {
          setStartAnalysisBtnLoading(false);
          navigate(`/mdsa/${id}`);
        }
      })
      .catch((err) => {
        setStartAnalysisBtnLoading(false);
        logger(err);
      });
  };

  return (
    <AppLayout screenTitle="Microbiome downstream analysis">
      <>
        <h2>Phylogenetic Diversity Analysis</h2>
        <p style={{ fontStyle: 'italic' }}>
          Feature table: <strong>{primaryFeatureTableFileName}</strong> will be used for analysis.
        </p>

        <EcFormItem
          label="Sampling Depth"
          name="samplingDepth"
          control={control}
          rules={{
            required: 'Please enter Sampling Depth and try again.',
            pattern: {
              value: /^[0-9]*$/,
              message: 'The value of Sampling Depth should be a positive integer.',
            },
          }}
          defaultValue={undefined}
          errors={errors}
          as={<InputNumber min={0} style={{ width: 150 }} />}
        />

        <div style={{ marginTop: 20 }}>
          <Button
            type="primary"
            loading={startAnalysisBtnLoading}
            onClick={handleSubmit(({ samplingDepth }) => {
              // obtains the initial value of task PhylogeneticDiversityAnalysis
              const defaultInParams = mdsaTasksInitialValues.find(
                (task) => task.key === Enum_Mdsa_Task_Key_Enum.PhylogeneticDiversityAnalysis,
              )?.in_params as Array<MdsaInParam>;

              // const to store immutable copy of in_params
              const taskInParamImmutableConst = JSON.parse(
                JSON.stringify(defaultInParams),
              ) as Array<MdsaInParam>;

              /* Updating in_params data based on user input */
              /* eslint-disable no-param-reassign */
              taskInParamImmutableConst.forEach((item) => {
                if (item.key === '--p-sampling-depth' && samplingDepth) {
                  item.enabled = true;
                  item.value = samplingDepth.toString();
                  item.rawValue = samplingDepth;
                }
              });
              /* eslint-enable no-param-reassign */
              handleUpdateAndStartMdsaTask(taskInParamImmutableConst);
            })}
          >
            Start Analysis
          </Button>
        </div>
      </>
    </AppLayout>
  );
};

export default MdsaPhylogeneticDiversityAnalysis;
