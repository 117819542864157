import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin, Select, Checkbox, Button, Radio } from 'antd';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import {
  MdsaTaskParamsMetadataColumnsQuery,
  MdsaTaskParamsMetadataColumnsQueryVariables,
  MetadataColumn,
  UpdateAndStartMdsaTaskMutation,
  UpdateAndStartMdsaTaskMutationVariables,
  MdsaInParam,
  Enum_Mdsa_Task_Key_Enum,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import { taxaLevels, mdsaTasksInitialValues } from '../utils/globals';
import { logger } from '../utils/helpers';
import EcFormItem from '../components/EcFormItem';
import styles from './ScreenStyles.module.scss';

const mdsaTaskParamsMetadataColumnsQuery = loader(
  '../graphql/queries/mdsaTaskParamsMetadataColumnsQuery.graphql',
);
const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);

/* type of all possible pmode values used in mdsa grouptablebarplot */
type PModeType = 'mean-ceiling' | 'median-ceiling' | 'sum';

// stores the options to be displayed for pMode (feature-table group) radio group
const pModeOptions: Array<{ label: PModeType; value: PModeType }> = [
  { label: 'mean-ceiling', value: 'mean-ceiling' },
  { label: 'median-ceiling', value: 'median-ceiling' },
  { label: 'sum', value: 'sum' },
];

/* React functional component */
const MdsaGroupTableBarPlot: React.FC = () => {
  /* Extracting task id from url param */
  const { id, taskId } = useParams();

  const navigate = useNavigate();

  /* State to store started_at for updateAndStartMdsaTask */
  const [started_at] = useState(new Date());

  /* State to store start analysis buttons loading */
  const [startAnalysisBtnLoading, setStartAnalysisBtnLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, control, watch, setValue, clearErrors } = useForm<{
    metadataColumns: string[];
    taxaLevel: string[] | undefined;
    abundanceFileCheckbox: boolean;
    pMode: PModeType;
  }>({
    resolver: yupResolver(
      yup.object().shape({
        metadataColumn: yup.array().min(1, 'Please select a value and try again'),
        pMode: yup.string().required('Please select a value and try again'),
        taxaLevel: yup
          .array()
          .nullable()
          .when('abundanceFileCheckbox', {
            is: true,
            then: yup.array().nullable().required('Please select a value and try again'),
          }),
      }),
    ),
    mode: 'onSubmit',
  });

  /* Query to fetch mdsa data for mdsa group table bar plot */
  const { data, loading: mdsaTaskParamsMetadataColumnsLoading, error: queryError } = useQuery<
    MdsaTaskParamsMetadataColumnsQuery,
    MdsaTaskParamsMetadataColumnsQueryVariables
  >(mdsaTaskParamsMetadataColumnsQuery, {
    variables: {
      taskId: parseInt(taskId, 10),
    },
    fetchPolicy: 'network-only',
  });

  /* Mutation to update and start mdsa task */
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  /* Mdsa task in params data */
  const mdsaTaskInParams = data?.task?.in_params as Array<MdsaInParam>;

  useEffect(() => {
    /* Check whether initially values are present in in_params data */
    if (Array.isArray(mdsaTaskInParams) && mdsaTaskInParams.length > 0) {
      mdsaTaskInParams.forEach((item) => {
        if (item.key === '--m-metadata-column') {
          if (Array.isArray(item.rawValue)) {
            setValue('metadataColumns', item.rawValue as string[]);
          } else if (item.rawValue) {
            setValue('metadataColumns', [item.rawValue] as string[]);
          }
        }
        if (item.key === '--p-level' && Array.isArray(item.rawValue) && item.rawValue.length > 0) {
          setValue('abundanceFileCheckbox', true);
          setValue('taxaLevel', item.rawValue as string[]);
        }
        if (item.key === '--p-mode' && item.value) {
          setValue('pMode', item.value as string);
        }
      });
    }
  }, [mdsaTaskInParams, setValue]);

  /* Loading indicator while fetching data */
  if (mdsaTaskParamsMetadataColumnsLoading) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  /* if any error occurred while fetching data will show error message */
  if (queryError) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  /* Function to update and start mdsa task on click start analysis button */
  const handleUpdateAndStartMdsaTask = (inParamsData: Array<MdsaInParam>) => {
    setStartAnalysisBtnLoading(true);
    updateAndStartMdsaTask({
      variables: {
        taskId: parseInt(taskId, 10),
        taskUpdateInput: {
          started_at,
          in_params: inParamsData,
        },
      },
    })
      .then((res) => {
        const updatedMdsaTaskId = res.data?.update_mdsa_task_by_pk?.id;
        if (updatedMdsaTaskId) {
          setStartAnalysisBtnLoading(false);
          navigate(`/mdsa/${id}`);
        }
      })
      .catch((err) => {
        setStartAnalysisBtnLoading(false);
        logger(err);
      });
  };

  /* Mdsa metadata columns data */
  const mdsaMetadataColumnsData = data?.task?.mdsa.metadata_columns as MetadataColumn[];

  return (
    <AppLayout screenTitle="Microbiome downstream analysis">
      <>
        <h2>Group table by metadata column & bar plot</h2>

        <form
          onSubmit={handleSubmit((formData) => {
            // obtains the initial value of task group table bar plot
            const defaultInParams = mdsaTasksInitialValues.find(
              (task) => task.key === Enum_Mdsa_Task_Key_Enum.GroupTableBarPlot,
            )?.in_params as Array<MdsaInParam>;

            // const to store immutable copy of in_params
            const taskInParamImmutableConst = JSON.parse(JSON.stringify(defaultInParams)) as Array<
              MdsaInParam
            >;

            // Set the in_params value according to the inputs provided by the user
            /* eslint-disable no-param-reassign */
            taskInParamImmutableConst.forEach((item) => {
              if (item.key === '--m-metadata-column' && formData.metadataColumns) {
                item.enabled = true;
                item.rawValue = formData.metadataColumns;
                item.value = null;
              }
              if (item.key === '--p-level' && formData.taxaLevel) {
                item.enabled = true;
                item.rawValue = formData.taxaLevel;
                item.value = null;
              }
              if (item.key === '--p-mode') {
                item.rawValue = formData.pMode as string;
                item.value = formData.pMode as string;
              }
            });

            // Run the mutation to update task
            handleUpdateAndStartMdsaTask(taskInParamImmutableConst);
          })}
        >
          <EcFormItem
            label="Metadata column(s) to group by"
            name="metadataColumns"
            control={control}
            errors={errors}
            isFieldRequired
            defaultValue={[]}
            as={
              <Select
                mode="multiple"
                style={{ width: 400, marginTop: 5 }}
                placeholder="Select metadata column(s)"
              >
                {Array.isArray(mdsaMetadataColumnsData) && mdsaMetadataColumnsData.length > 0
                  ? mdsaMetadataColumnsData.map((item) => {
                      return (
                        <Select.Option key={item.column} value={item.column}>
                          {item.column}
                        </Select.Option>
                      );
                    })
                  : null}
              </Select>
            }
          />
          <EcFormItem
            label="--p-mode (feature-table group)"
            name="pMode"
            control={control}
            errors={errors}
            defaultValue="sum"
            render={({ onChange, value }) => (
              <Radio.Group
                value={value as Array<string>}
                onChange={(e) => onChange(e.target.value)}
                className={styles.input}
                key={value as string}
              >
                {pModeOptions.map((item) => (
                  <Radio key={item.value as string} value={item.value as string}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            )}
            containerStyle={{ marginTop: 25 }}
          />

          <EcFormItem
            name="abundanceFileCheckbox"
            control={control}
            defaultValue={false}
            render={({ onChange, value }): JSX.Element => {
              return (
                <Checkbox
                  checked={value as boolean}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    if (!e.target.checked) {
                      setValue('taxaLevel', undefined);
                      clearErrors(['taxaLevel']);
                    }
                  }}
                  style={{ fontSize: 16 }}
                >
                  Create Relative Abundance file?
                </Checkbox>
              );
            }}
            containerStyle={{ marginTop: 25 }}
          />

          <EcFormItem
            label="Taxa collapse level"
            name="taxaLevel"
            control={control}
            errors={errors}
            isFieldRequired
            defaultValue={undefined}
            mode="multiple"
            as={
              <Select placeholder="Select level" style={{ width: 400, marginTop: 5 }}>
                {Array.isArray(taxaLevels)
                  ? taxaLevels.map((item) => {
                      return (
                        <Select.Option key={item.level} value={item.level}>
                          {item.name}
                        </Select.Option>
                      );
                    })
                  : null}
              </Select>
            }
            containerStyle={{
              display: watch('abundanceFileCheckbox') ? 'block' : 'none',
              marginTop: 15,
            }}
          />

          <Button
            type="primary"
            htmlType="submit"
            loading={startAnalysisBtnLoading}
            style={{ marginTop: 30 }}
          >
            Start Analysis
          </Button>
        </form>
      </>
    </AppLayout>
  );
};

export default MdsaGroupTableBarPlot;
