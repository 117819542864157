import React from 'react';
import { Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import moment from 'moment';
import {
  CompletedSRsQueryVariables,
  CompletedSRsQuery,
  Sequencing_Run,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import { colors } from '../utils/globals';
import ColumnsSearchInput from '../components/ColumnsSearchInput';

// call to graphql files
const completedSRsQuery = loader('../graphql/queries/completedSRsQuery.graphql');

// completedSr table type
type completedSrType = Pick<
  Sequencing_Run,
  'id' | 'description' | 'tags' | 'title' | 'analysis_started_at' | 'analysis_completed_at'
>;

// react functional component
const ViewCompletedSRs: React.FC = () => {
  // query to fetch data of
  const { data, loading: queryLoading, error: queryError } = useQuery<
    CompletedSRsQuery,
    CompletedSRsQueryVariables
  >(completedSRsQuery, { fetchPolicy: 'network-only' });

  // if any error occurred while fetching query data
  if (queryError) {
    return (
      <AppLayout screenTitle="Completed Sequencing Runs">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  const { Column } = Table;

  // start date and end date display format
  const dataTimeFormat = (date: string) => {
    return moment(date).format('D MMM YYYY [at] LT');
  };

  return (
    <AppLayout screenTitle="Completed Sequencing Runs">
      <Table<completedSrType>
        dataSource={
          data && Array.isArray(data.sequencing_run) && data.sequencing_run.length > 0
            ? data.sequencing_run
            : []
        }
        bordered
        loading={queryLoading}
        rowKey="id"
      >
        <Column<completedSrType>
          title="Id"
          dataIndex="id"
          key="id"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsSearchInput
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="id"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? colors.tintColor : undefined }} />
          )}
          onFilter={(value, record) =>
            `SR${record.id}`.toLowerCase().includes(value.toString().toLowerCase())
          }
          render={(id: number) => {
            return <Link to={`/sr/${id}`}>SR{id}</Link>;
          }}
        />

        <Column<completedSrType>
          title="Title"
          dataIndex="title"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsSearchInput
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="title"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? colors.tintColor : undefined }} />
          )}
          onFilter={(value, record) =>
            record.title
              ? record.title.toLowerCase().includes(value.toString().toLowerCase())
              : false
          }
        />

        <Column<completedSrType>
          title="Description"
          dataIndex="description"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsSearchInput
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="description"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? colors.tintColor : undefined }} />
          )}
          onFilter={(value, record) =>
            record.description
              ? record.description.toLowerCase().includes(value.toString().toLowerCase())
              : false
          }
          render={(desc: string) => desc ?? 'N/A'}
        />

        <Column<completedSrType>
          title="Tags"
          dataIndex="tags"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsSearchInput
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="tags"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? colors.tintColor : undefined }} />
          )}
          onFilter={(value, record) =>
            record.tags ? record.tags.toLowerCase().includes(value.toString().toLowerCase()) : false
          }
          render={(tags: string) => tags ?? 'N/A'}
        />

        <Column<completedSrType>
          title="Started At"
          dataIndex="analysis_started_at"
          sorter={(a, b) =>
            moment(a.analysis_started_at).unix() - moment(b.analysis_started_at).unix()
          }
          render={(started_at: string) => dataTimeFormat(started_at)}
        />

        <Column<completedSrType>
          title="Ended At"
          dataIndex="analysis_completed_at"
          sorter={(a, b) =>
            moment(a.analysis_completed_at).unix() - moment(b.analysis_completed_at).unix()
          }
          render={(ended_at: string) => dataTimeFormat(ended_at)}
        />
      </Table>
    </AppLayout>
  );
};

export default ViewCompletedSRs;
