import React, { useState, useContext } from 'react';
import { Table, Popconfirm, Button, message } from 'antd';
import { loader } from 'graphql.macro';
import { useQuery, useMutation, Reference } from '@apollo/client';
import { DeleteOutlined } from '@ant-design/icons';
import {
  AllUsersQuery,
  User,
  DeleteUserMutation,
  DeleteUserMutationVariables,
} from '../graphql/graphql-types';
import { UserContext } from '../contexts/UserContext';
import AppLayout from '../components/AppLayout';
import { logger } from '../utils/helpers';

const { Column } = Table;

/* Type for user */
type UserType = Pick<User, 'email' | 'first_name' | 'id' | 'last_name'>;

const allUsersQuery = loader('../graphql/queries/allUsersQuery.graphql');
const deleteUserMutation = loader('../graphql/mutations/deleteUserMutation.graphql');

const ViewAllUsers: React.FC = () => {
  /* Query to fetch all users data */
  const { data, loading: allUsersLoading, error: queryError } = useQuery<AllUsersQuery>(
    allUsersQuery,
  );

  /* Mutation to delete user */
  const [deleteUser] = useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    deleteUserMutation,
  );

  /* userContext to get data of logged in user */
  const { user } = useContext(UserContext);

  /* State to store deleted user id for delete button loading */
  const [deletedUserId, setDeletedUserId] = useState<number | null>(null);

  /* if any error occured while fetching data will show error message */
  if (queryError) {
    return (
      <AppLayout screenTitle="View all Users">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  /* Delete user function */
  const handleDeleteUser = (id: number) => {
    deleteUser({
      variables: {
        id,
      },
      update: (cache, { data: deletedData }) => {
        /* Updating cache after user deleted successfully */
        cache.modify({
          fields: {
            user(existingRefs: Array<Reference>, { readField }) {
              if (
                deletedData &&
                deletedData.delete_user_by_pk &&
                deletedData.delete_user_by_pk.id
              ) {
                const idToFilter = deletedData.delete_user_by_pk.id;
                return existingRefs.filter((ref) => idToFilter !== readField('id', ref));
              }
              return existingRefs;
            },
          },
        });
      },
    })
      .then((response) => {
        if (response.data && response.data.delete_user_by_pk) {
          setDeletedUserId(null);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('User Deleted successfully');
        }
      })
      .catch((error) => {
        logger(error);
        setDeletedUserId(null);
      });
  };

  return (
    <AppLayout screenTitle="View all Users" hideBackBtn>
      <Table<UserType>
        bordered
        loading={allUsersLoading}
        dataSource={data && Array.isArray(data.users) ? data.users : []}
        rowKey="id"
      >
        <Column<UserType>
          title="Name"
          dataIndex="name"
          key="name"
          render={(text: string, record) => record.first_name.concat(' ', record.last_name)}
        />
        <Column title="Email" dataIndex="email" key="email" />
        <Column<UserType>
          title="Actions"
          key="actions"
          render={(value, record) => {
            return (
              <Popconfirm
                disabled={user && user.id ? record.id === user.id : false}
                title="Are you sure delete this run?"
                onConfirm={() => {
                  setDeletedUserId(record.id);
                  handleDeleteUser(record.id);
                }}
                okText="Yes"
                cancelText="No"
                icon={<DeleteOutlined style={{ color: 'red' }} />}
              >
                <Button
                  type="default"
                  disabled={user && user.id ? record.id === user.id : false}
                  loading={record.id === deletedUserId}
                >
                  Delete
                </Button>
              </Popconfirm>
            );
          }}
        />
      </Table>
    </AppLayout>
  );
};

export default ViewAllUsers;
