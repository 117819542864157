import React, { useState } from 'react';
import { GroupBase, MultiValue, OptionsOrGroups, PropsValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { ReactSelectOptionType } from '../utils/types';
import { searchKitIds } from '../utils/apis/kit';
import { logger } from '../utils/helpers';
import { colors } from '../utils/globals';

/* Props type */
type HmqKitsSelectPropsType = {
  // type definition for placeholder
  placeholder: string;
  // type definition for value
  value?: PropsValue<ReactSelectOptionType>;
  // type definition for onChange
  onChange: (...event: any[]) => void;
};

/* React component */
const HmqKitsSelect = ({ placeholder, value, onChange }: HmqKitsSelectPropsType): JSX.Element => {
  // stores the kit options fetched by API on each call. Also, used to display loading and no options messages based on
  // whether at least 3 characters are entered or not.
  const [kitOptionsFetchedByApiCall, setKitOptionsFetchedByApiCall] = useState<
    OptionsOrGroups<ReactSelectOptionType, GroupBase<ReactSelectOptionType>>
  >([]);

  return (
    <AsyncSelect
      isMulti
      placeholder={placeholder}
      defaultOptions={kitOptionsFetchedByApiCall}
      value={value}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          border: '1px solid #d9d9d9',
          borderRadius: '2px',
          cursor: 'text',
          ':hover': {
            border: '1px solid #1890ff',
          },
          ':focus': {
            boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
          },
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          color: '#bfbfbf',
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: colors.menuOptionsInHmqKitsSelectBgColor,
        }),
        multiValue: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: colors.menuOptionsInHmqKitsSelectBgColor,
        }),
        multiValueRemove: (baseStyles) => ({
          ...baseStyles,
          color: 'rgba(0, 0, 0, 0.45)',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: 'transparent',
          },
        }),
        clearIndicator: (baseStyles) => ({
          ...baseStyles,
          color: colors.indicatorsInHmqKitsSelectColor,
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          color: colors.indicatorsInHmqKitsSelectColor,
        }),
      }}
      loadOptions={(searchText, callback) => {
        // calling API only after at least 3 characters are entered
        if (searchText.length >= 3) {
          searchKitIds(searchText)
            .then((data) => {
              setKitOptionsFetchedByApiCall(data);
              callback(data);
            })
            .catch((err) => {
              logger(err as Error);
              callback(kitOptionsFetchedByApiCall);
            });
          return;
        }

        // When less than 3 characters in search text
        callback(kitOptionsFetchedByApiCall);
      }}
      onChange={(updatedOptions: MultiValue<ReactSelectOptionType>) => {
        // stores array of options onChange
        onChange(updatedOptions);
      }}
      isClearable
      noOptionsMessage={({ inputValue }) =>
        inputValue.length >= 3 ? (
          <span>No kits found</span>
        ) : (
          <span>Please enter at least 3 characters</span>
        )
      }
    />
  );
};

// default props
HmqKitsSelect.defaultProps = {
  value: undefined,
};

export default HmqKitsSelect;
