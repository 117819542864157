import { OptionProps } from 'antd/es/select/index';
import * as yup from 'yup';
import {
  Enum_Mdsa_Task_Key_Enum,
  Mdsa_Task_Insert_Input,
  MdsaTaskDependentOn,
  MdsaInParam,
  Enum_Sr_Task_Key_Enum,
  SrTaskDependentOn,
  SrTaskInputParams,
  Sr_Task_Insert_Input,
  SrSamples,
  Dada2InParamsKey,
} from '../graphql/graphql-types';

export const graphqlEndpoint =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://hasura.ecp29.eumentis.com/v1/graphql'
    : 'https://hasura.dev.cloud.eumentis.com/ecp29/v1/graphql';
// export const graphqlEndpoint = 'http://localhost:2980/v1/graphql';

// UI Colors used
export const colors = {
  tintColor: '#ce0e2d',
  menuOptionsInHmqKitsSelectBgColor: '#f5f5f5',
  indicatorsInHmqKitsSelectColor: 'rgba(0, 0, 0, 0.25)',
};

// mdsa task key names
export const mdsaTaskKeyNameMap: Record<Enum_Mdsa_Task_Key_Enum, string> = {
  [Enum_Mdsa_Task_Key_Enum.MergeTablesSr]: 'Merge tables from SR(s)',
  [Enum_Mdsa_Task_Key_Enum.UploadMetadata]: 'Metadata for merged samples',
  [Enum_Mdsa_Task_Key_Enum.MergeTablesSummary]: 'Merge tables summary',
  [Enum_Mdsa_Task_Key_Enum.TaxonomicClassification]: 'Taxonomic Classification',
  [Enum_Mdsa_Task_Key_Enum.FilterTableByTaxa]: 'Filter table by taxa',
  [Enum_Mdsa_Task_Key_Enum.TaxaBarPlot]: 'Taxa bar plot',
  [Enum_Mdsa_Task_Key_Enum.GroupTableBarPlot]: 'Group table by metadata column & bar plot',
  [Enum_Mdsa_Task_Key_Enum.CoreMicrobiomeAnalysis]: 'Core Microbiome Analysis',
  [Enum_Mdsa_Task_Key_Enum.PhylogeneticDiversityAnalysis]: 'Phylogenetic Diversity Analysis',
  [Enum_Mdsa_Task_Key_Enum.FilterDistanceMatrix]: 'Filter distance matrix',
  [Enum_Mdsa_Task_Key_Enum.CustomPcoaPlot]: 'Custom PCoA plot',
  [Enum_Mdsa_Task_Key_Enum.Ancom]: 'ANCOM',
  [Enum_Mdsa_Task_Key_Enum.AlphaGroupSignificance]: 'Alpha Group Significance',
  [Enum_Mdsa_Task_Key_Enum.BetaGroupSignificance]: 'Beta Group Significance',
  [Enum_Mdsa_Task_Key_Enum.TableFilterFeatures]: 'Filter features from table',
  [Enum_Mdsa_Task_Key_Enum.Picrust2]: 'PICRUSt2',
  [Enum_Mdsa_Task_Key_Enum.Blast]: 'BLAST',
  [Enum_Mdsa_Task_Key_Enum.Lefse]: 'LEfSe',
  [Enum_Mdsa_Task_Key_Enum.Cart]: 'CART',
  [Enum_Mdsa_Task_Key_Enum.HmqReport]: 'Horse MQ report',
};

/* Qiime version */
export const qiimeVersion = '2023.5';

/* Mdsa skippable task keys */
export const mdsaSkippableTaskKeys = [
  Enum_Mdsa_Task_Key_Enum.FilterTableByTaxa,
  Enum_Mdsa_Task_Key_Enum.GroupTableBarPlot,
  Enum_Mdsa_Task_Key_Enum.CoreMicrobiomeAnalysis,
  Enum_Mdsa_Task_Key_Enum.FilterDistanceMatrix,
  Enum_Mdsa_Task_Key_Enum.CustomPcoaPlot,
  Enum_Mdsa_Task_Key_Enum.Ancom,
  Enum_Mdsa_Task_Key_Enum.AlphaGroupSignificance,
  Enum_Mdsa_Task_Key_Enum.BetaGroupSignificance,
  Enum_Mdsa_Task_Key_Enum.TableFilterFeatures,
  Enum_Mdsa_Task_Key_Enum.Picrust2,
  Enum_Mdsa_Task_Key_Enum.Blast,
  Enum_Mdsa_Task_Key_Enum.Lefse,
  Enum_Mdsa_Task_Key_Enum.Cart,
  Enum_Mdsa_Task_Key_Enum.HmqReport,
];

/* Taxa levels */
export const taxaLevels = [
  { level: '1', name: 'Kingdom' },
  { level: '2', name: 'Phylum' },
  { level: '3', name: 'Class' },
  { level: '4', name: 'Order' },
  { level: '5', name: 'Family' },
  { level: '6', name: 'Genus' },
  { level: '7', name: 'Species' },
];

// const to store array of distance matrix options
export const distanceMatrixOptions = [
  { name: 'Unweighted UniFrac', value: 'unweighted_unifrac' },
  { name: 'Weighted UniFrac', value: 'weighted_unifrac' },
  { name: 'Bray-Curtis', value: 'bray_curtis' },
  { name: 'Jaccard', value: 'jaccard' },
];

/*
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * Whenever changes are made to mdsaTasksInitialValues, also update the same const in globals of ecp29-backend
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

// The initial values for MDSA tasks
export const mdsaTasksInitialValues: Array<Mdsa_Task_Insert_Input> = [
  {
    key: Enum_Mdsa_Task_Key_Enum.MergeTablesSr,
    position: 1,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.UploadMetadata,
    position: 2,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.MergeTablesSr] as MdsaTaskDependentOn,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.MergeTablesSummary,
    position: 3,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.UploadMetadata] as MdsaTaskDependentOn,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.TaxonomicClassification,
    position: 4,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.MergeTablesSummary] as MdsaTaskDependentOn,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.TableFilterFeatures,
    position: 5,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.TaxonomicClassification] as MdsaTaskDependentOn,
    in_params: [
      {
        key: '--p-min-frequency',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime feature-table filter-features'],
        enabled: false,
      },
      {
        key: '--p-max-frequency',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime feature-table filter-features'],
        enabled: false,
      },
      {
        key: '--p-min-samples',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime feature-table filter-features'],
        enabled: false,
      },
      {
        key: '--p-max-samples',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime feature-table filter-features'],
        enabled: false,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.FilterTableByTaxa,
    position: 6,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.TaxonomicClassification] as MdsaTaskDependentOn,
    in_params: [
      {
        key: '--p-include',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime taxa filter-table', 'qiime taxa filter-seqs'],
        enabled: false,
      },
      {
        key: '--p-exclude-ids',
        value: null,
        rawValue: [],
        qiimeCommands: ['qiime feature-table filter-samples'],
        enabled: false,
      },
      {
        key: '--p-exclude',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime taxa filter-table', 'qiime taxa filter-seqs'],
        enabled: false,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.TaxaBarPlot,
    position: 7,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.FilterTableByTaxa] as MdsaTaskDependentOn,
    in_params: [
      {
        key: 'taxaCollapseLevels',
        value: null,
        rawValue: [],
        qiimeCommands: ['qiime taxa collapse'],
        // This will indicate whether to create the relative abundance file or not
        enabled: false,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.GroupTableBarPlot,
    position: 8,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.TaxaBarPlot] as MdsaTaskDependentOn,
    in_params: [
      {
        key: '--m-metadata-column',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime feature-table group'],
        enabled: true,
      },
      {
        key: '--p-mode',
        value: 'sum',
        rawValue: 'sum',
        qiimeCommands: ['qiime feature-table group'],
        enabled: true,
      },
      {
        key: '--p-level',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime taxa collapse'],
        // This will indicate whether to create the relative abundance file or not
        enabled: false,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.CoreMicrobiomeAnalysis,
    position: 9,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.TaxaBarPlot] as MdsaTaskDependentOn,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.PhylogeneticDiversityAnalysis,
    position: 10,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.TaxaBarPlot] as MdsaTaskDependentOn,
    in_params: [
      {
        key: '--p-sampling-depth',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime diversity core-metrics-phylogenetic'],
        enabled: false,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.FilterDistanceMatrix,
    position: 11,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.PhylogeneticDiversityAnalysis] as MdsaTaskDependentOn,
    in_params: [
      {
        key: '--i-distance-matrix',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime diversity filter-distance-matrix'],
        enabled: false,
      },
      {
        key: '--p-exclude-ids',
        // This will always be null
        value: null,
        // Array of sample ids to exclude
        rawValue: [],
        qiimeCommands: ['qiime diversity filter-distance-matrix'],
        enabled: false,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.CustomPcoaPlot,
    position: 12,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.PhylogeneticDiversityAnalysis] as MdsaTaskDependentOn,
    in_params: [
      {
        key: '--i-pcoa',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime emperor plot'],
        enabled: false,
      },
      {
        key: '--p-custom-axes',
        value: null,
        rawValue: [],
        qiimeCommands: ['qiime emperor plot'],
        enabled: false,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.Ancom,
    position: 13,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.PhylogeneticDiversityAnalysis] as MdsaTaskDependentOn,
    in_params: [
      {
        key: '--p-min-frequency',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime feature-table filter-features'],
        enabled: false,
      },
      {
        key: '--p-max-frequency',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime feature-table filter-features'],
        enabled: false,
      },
      {
        key: '--p-level',
        value: null,
        rawValue: null,
        qiimeCommands: ['qiime taxa collapse'],
        enabled: false,
      },
      {
        key: 'metadataColumns',
        value: null,
        rawValue: [],
        qiimeCommands: ['qiime composition ancom'],
        enabled: true,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.AlphaGroupSignificance,
    position: 14,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.PhylogeneticDiversityAnalysis] as MdsaTaskDependentOn,
    in_params: [
      {
        key: 'diversityMetrics',
        // This will always be null
        value: null,
        // Array<'shannon' | 'observed_features' | 'faith_pd' | 'evenness'>
        rawValue: [],
        qiimeCommands: ['qiime diversity alpha-group-significance'],
        enabled: true,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.BetaGroupSignificance,
    position: 15,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.PhylogeneticDiversityAnalysis] as MdsaTaskDependentOn,
    in_params: [
      {
        key: 'distanceMatrices',
        value: null,
        // Array of names of distance matrix
        rawValue: [],
        qiimeCommands: ['qiime diversity beta-group-significance'],
        enabled: true,
      },
      {
        key: 'metadataColumns',
        // This will always be null
        value: null,
        // This will be an array of metadata column names
        rawValue: [],
        qiimeCommands: ['qiime diversity beta-group-significance'],
        enabled: true,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.Picrust2,
    position: 16,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.TaxonomicClassification] as MdsaTaskDependentOn,
    in_params: [
      {
        key: '--placement_tool',
        value: 'epa-ng',
        rawValue: 'epa-ng',
        qiimeCommands: [],
        enabled: true,
      },
      {
        key: '--in_traits',
        value: 'EC,KO',
        rawValue: ['EC', 'KO'],
        qiimeCommands: [],
        enabled: true,
      },
      {
        key: '--stratified',
        value: null,
        rawValue: null,
        qiimeCommands: [],
        enabled: false,
      },
      {
        key: '--per_sequence_contrib',
        value: null,
        rawValue: null,
        qiimeCommands: [],
        enabled: false,
      },
      {
        key: '--max_nsti',
        value: null,
        rawValue: null,
        qiimeCommands: [],
        enabled: false,
      },
      {
        key: '--min_reads',
        value: null,
        rawValue: null,
        qiimeCommands: [],
        enabled: false,
      },
      {
        key: '--min_samples',
        value: null,
        rawValue: null,
        qiimeCommands: [],
        enabled: false,
      },
      {
        key: '--hsp_method',
        value: 'mp',
        rawValue: 'mp',
        qiimeCommands: [],
        enabled: true,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.Blast,
    position: 17,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.TaxonomicClassification] as MdsaTaskDependentOn,
    in_params: [
      {
        key: 'featureIds',
        // This will always be null
        value: null,
        // Array of feature/out/asu ids
        rawValue: [],
        qiimeCommands: [],
        enabled: true,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.Lefse,
    position: 18,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.TaxonomicClassification] as MdsaTaskDependentOn,
    in_params: [
      {
        key: 'sampleIds',
        // this will always be null
        value: null,
        // Array of sample ids to include
        rawValue: [],
        qiimeCommands: [],
        enabled: true,
      },
      {
        key: 'groupMetadataColumn',
        value: null,
        rawValue: null,
        qiimeCommands: [],
        enabled: true,
      },
      {
        key: 'taxaRank',
        value: 'Genus',
        rawValue: 'Genus',
        qiimeCommands: [],
        enabled: true,
      },
      {
        key: '--alpha',
        value: 0.05,
        rawValue: 0.05,
        qiimeCommands: [],
        enabled: true,
      },
      {
        key: '--title',
        value: 'Differentially Abundant Markers',
        rawValue: 'Differentially Abundant Markers',
        qiimeCommands: [],
        enabled: true,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.Cart,
    position: 19,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.TaxonomicClassification] as MdsaTaskDependentOn,
    in_params: [
      // Array of metadata columns to be filtered/included in CART analysis
      {
        key: '--variables',
        // this will alway be null
        value: null,
        // Array of metadata column which we want to include for analysis
        rawValue: [],
        qiimeCommands: [],
        enabled: true,
      },
      {
        key: '--pertaxa',
        value: 0.8,
        rawValue: 0.8,
        qiimeCommands: [],
        enabled: true,
      },
    ] as Array<MdsaInParam>,
  },
  {
    key: Enum_Mdsa_Task_Key_Enum.HmqReport,
    position: 20,
    dependent_on: [Enum_Mdsa_Task_Key_Enum.TaxonomicClassification] as MdsaTaskDependentOn,
    in_params: [
      {
        key: '--taxa.rank',
        value: 'Family',
        rawValue: 'Family',
        qiimeCommands: [],
        enabled: true,
      },
      {
        key: '--num_taxa',
        value: 10,
        rawValue: 10,
        qiimeCommands: [],
        enabled: true,
      },
    ] as Array<MdsaInParam>,
  },
];

// alpha diversity metrics const
export const alphaDiversityMetrics = [
  { name: 'Shannon’s diversity index', value: 'shannon' },
  { name: 'Observed Features', value: 'observed_features' },
  { name: 'Faith’s Phylogenetic Diversity', value: 'faith_pd' },
  { name: 'Evenness', value: 'evenness' },
];

/* Array to store column names which are not allowed use to use while adding new columns in sr metadata */
export const notAllowedColumnNames = ['id', 'sampleid', 'sample id', 'sample-id'];

/* Function to filter options in select input */
export const filterSelectOptions = (input: string, option: OptionProps): boolean => {
  const childrenOptions = option.children as string;
  return childrenOptions.toLowerCase().includes(input.toLowerCase());
};

/* Validation schema to validate only positive integers */
export const positiveIntegerValidation = yup
  .number()
  .typeError('Only positive integer values are allowed.')
  .nullable()
  .notRequired()
  .positive('Only positive integer values are allowed.')
  .integer('Only positive integer values are allowed.');

/* Variable to store format in which date is stored in HMQ excel sheet */
export const hmqSheetDateFormat = 'mm-dd-yyyy';

// The fixed values to be used as in_params for DADA2 for HMQ SRs
const hmqDada2InParamsValues: Record<Dada2InParamsKey, number> = {
  '--p-trim-left-f': 17,
  '--p-trunc-len-f': 260,
  '--p-trim-left-r': 21,
  '--p-trunc-len-r': 240,
};

/* Array to store all the tasks required in sequencing run */
export const sequencingRunTasks = (
  // For HMQ SRs the in_params for linkFastq task
  sequencedSamples?: Array<SrSamples>,
  // Are the tasks for HMQ SR?
  isHmqSr = false,
): Sr_Task_Insert_Input[] => [
  {
    key: Enum_Sr_Task_Key_Enum.LinkFastq,
    position_sr: 1,
    dependent_on: null,
    started_at: isHmqSr ? new Date() : null,
    in_params:
      Array.isArray(sequencedSamples) && sequencedSamples.length > 0
        ? [
            {
              key: 'sequencedSamples',
              value: sequencedSamples,
            },
          ]
        : null,
  },
  {
    key: Enum_Sr_Task_Key_Enum.UploadMetadata,
    position_sr: 2,
    dependent_on: [Enum_Sr_Task_Key_Enum.LinkFastq] as SrTaskDependentOn,
    in_params: null,
  },
  {
    key: Enum_Sr_Task_Key_Enum.QcSummary,
    position_sr: 3,
    dependent_on: [Enum_Sr_Task_Key_Enum.UploadMetadata] as SrTaskDependentOn,
    in_params: null,
  },
  {
    key: Enum_Sr_Task_Key_Enum.Dada2,
    position_sr: 4,
    dependent_on: [Enum_Sr_Task_Key_Enum.QcSummary] as SrTaskDependentOn,
    in_params: [
      { key: '--p-trim-left-f', value: isHmqSr ? hmqDada2InParamsValues['--p-trim-left-f'] : null },
      { key: '--p-trunc-len-f', value: isHmqSr ? hmqDada2InParamsValues['--p-trunc-len-f'] : null },
      { key: '--p-trim-left-r', value: isHmqSr ? hmqDada2InParamsValues['--p-trim-left-r'] : null },
      { key: '--p-trunc-len-r', value: isHmqSr ? hmqDada2InParamsValues['--p-trunc-len-r'] : null },
    ] as SrTaskInputParams,
    is_last_position: true,
  },
];

// constants for cloudwatch log group name and log stream name
export const logGroupNameEcp29apps = 'ecp29-apps';
export const logGroupNameEcp29SrAutomation = 'ecp29-sr-automation';
export const logStreamNameSampleManagement = 'sample-management';
export const logStreamNameSrPlanning = 'sr-planning';

export default null;
