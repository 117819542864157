import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { Alert, Button, Col, Popconfirm, Row, Spin, Table, message } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import AppLayout from '../components/AppLayout';
import { SrUploadFastqFilePropsType, SuccessDataOfSamplesType } from '../utils/types';
import {
  CloudwatchLogsMutation,
  CloudwatchLogsMutationVariables,
  CustomJsonb,
  Enum_Sequencing_Type_Enum,
  Enum_Sr_Status_Enum,
  Enum_Sr_Task_Key_Enum,
  GetHmqProjectIdQuery,
  GetHmqProjectIdQueryVariables,
  GetHmqUserIdQuery,
  GetHmqUserIdQueryVariables,
  InsertSequencingRunMutationMutation,
  InsertSequencingRunMutationMutationVariables,
  LogData,
  NewMicrobiomeSequencingRunMutation,
  NewMicrobiomeSequencingRunMutationVariables,
  PlatformType,
  SrSamples,
  SrTaskInputParams,
  Sr_Task_Insert_Input,
  UpdateHmqSrsCreatedByIdMutation,
  UpdateHmqSrsCreatedByIdMutationVariables,
  UpdateInparamsOfLinkFastqMutationMutation,
  UpdateInparamsOfLinkFastqMutationMutationVariables,
} from '../graphql/graphql-types';
import { logger } from '../utils/helpers';
import {
  logGroupNameEcp29SrAutomation,
  logGroupNameEcp29apps,
  logStreamNameSrPlanning,
  sequencingRunTasks,
} from '../utils/globals';

// type definition for FinalSequencingRunSamplesDetails props type
type FinalSequencingRunSamplesDetailsPropsType = {
  // type definition for sequencer run sample details
  sequencerRunSuccessSamplesDetails: SuccessDataOfSamplesType;
};

// Importing new microbiome sequence mutation
const newMicrobiomeSequencingRunMutation = loader(
  '../graphql/mutations/newMicrobiomeSequencingRunMutation.graphql',
);

// Importing mutation to insert sequencing run
const insertSequencingRunMutation = loader(
  '../graphql/mutations/insertSequencingRunMutation.graphql',
);

// Importing mutation to update in_params of linkFastq sr task
const updateInparamsOfLinkFastqMutation = loader(
  '../graphql/mutations/updateInparamsOfLinkFastqMutation.graphql',
);

// Importing query to fetch latest HMQ project id
const getHmqProjectIdQuery = loader('../graphql/queries/getHmqProjectIdQuery.graphql');

// Importing query to fetch hmq-user-id
const getHmqUserIdQuery = loader('../graphql/queries/getHmqUserIdQuery.graphql');

// Importing mutation to update the HMQ SRs created_by_id
const updateHmqSrsCreatedByIdMutation = loader(
  '../graphql/mutations/updateHmqSrsCreatedById.graphql',
);

const cloudwatchLogsMutation = loader('../graphql/mutations/cloudwatchLogs.graphql');

// extracting Column from Antd Table
const { Column } = Table;

const FinalSequencingRunSamplesDetails = ({
  sequencerRunSuccessSamplesDetails,
}: FinalSequencingRunSamplesDetailsPropsType): JSX.Element => {
  // stores useNavigate function
  const navigate = useNavigate();

  // new microbiome run mutation declaration
  const [newMicrobiomeSequencingRun] = useMutation<
    NewMicrobiomeSequencingRunMutation,
    NewMicrobiomeSequencingRunMutationVariables
  >(newMicrobiomeSequencingRunMutation);

  // mutation to insert sequencing run
  const [insertSequencingRun] = useMutation<
    InsertSequencingRunMutationMutation,
    InsertSequencingRunMutationMutationVariables
  >(insertSequencingRunMutation);

  // mutation to update HMQ SRs created_by_id
  const [updateHmqSrsCreatedById] = useMutation<
    UpdateHmqSrsCreatedByIdMutation,
    UpdateHmqSrsCreatedByIdMutationVariables
  >(updateHmqSrsCreatedByIdMutation);

  // mutation to update in_params of linkFastq sr task
  const [updateInparamsOfLinkFastq] = useMutation<
    UpdateInparamsOfLinkFastqMutationMutation,
    UpdateInparamsOfLinkFastqMutationMutationVariables
  >(updateInparamsOfLinkFastqMutation);

  // query to fetch data of hmq project id
  const {
    data: hmqProjectIdQueryData,
    loading: hmqProjectIdQueryLoading,
    error: hmqProjectIdQueryError,
  } = useQuery<GetHmqProjectIdQuery, GetHmqProjectIdQueryVariables>(getHmqProjectIdQuery, {
    fetchPolicy: 'network-only',
  });

  // query to fetch data of hmq user id
  const {
    data: hmqUserIdQueryData,
    loading: hmqUserIdQueryLoading,
    error: hmqUserIdQueryError,
  } = useQuery<GetHmqUserIdQuery, GetHmqUserIdQueryVariables>(getHmqUserIdQuery, {
    fetchPolicy: 'network-only',
  });

  // mutation to store the logs
  const [cloudwatchLogs] = useMutation<CloudwatchLogsMutation, CloudwatchLogsMutationVariables>(
    cloudwatchLogsMutation,
  );

  // state to show loading indicator on create SR(s) button
  const [createSRsBtnLoading, setCreateSRsBtnLoading] = useState<boolean>(false);

  // const to store the newly created HMQ srId
  let newlyCreatedHMQSrId: number | undefined;

  // destructuring the samples
  const { samples } = sequencerRunSuccessSamplesDetails;

  // useMemo to store the samples count
  const samplesCount = useMemo(() => {
    // stores the number of HMQ samples from sequencer Run
    let hmqSamplesCount = 0;
    // stores the number of non-HMQ samples from sequencer Run
    let nonHmqSamplesCount = 0;

    samples.forEach((sample) => {
      if (sample.sample_id.includes('HMQ')) {
        hmqSamplesCount += 1;
      } else {
        nonHmqSamplesCount += 1;
      }
    });

    return {
      hmqSamplesCount,
      nonHmqSamplesCount,
    };
  }, [samples]);

  if (hmqProjectIdQueryLoading && hmqUserIdQueryLoading) {
    // if loading then loading indicator will display
    return (
      <AppLayout screenTitle="Create Sequencing Run(s)">
        <>
          <h2>Finalize SR(s)</h2>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
            }}
          >
            <Spin size="large" />
          </div>
        </>
      </AppLayout>
    );
  }

  /* if error in fetching query or if project id fetched from settings is NULL */
  if (
    hmqProjectIdQueryError ||
    !(hmqProjectIdQueryData && hmqProjectIdQueryData.settings[0].value) ||
    hmqUserIdQueryError
  ) {
    return (
      <AppLayout screenTitle="Create Sequencing Run(s)">
        <>
          <h2>Finalize SR(s)</h2>
          <p style={{ color: 'red', textAlign: 'center' }}>
            {hmqProjectIdQueryError
              ? hmqProjectIdQueryError.message
              : 'HMQ project has not been defined. Please set a HMQ project.'}
          </p>
        </>
      </AppLayout>
    );
  }

  // rendering info box with samples count details
  const renderAlertInfoBox = () => {
    const totalSamplesCount = samplesCount.hmqSamplesCount + samplesCount.nonHmqSamplesCount;
    if (samplesCount.hmqSamplesCount > 0 && samplesCount.nonHmqSamplesCount > 0) {
      return `Both Horse MQ and non-Horse MQ SRs will be created will all ${totalSamplesCount} samples in both the SRs.`;
    }
    if (samplesCount.hmqSamplesCount > 0) {
      return `Only Horse MQ SR will be created with ${totalSamplesCount} samples`;
    }
    return `Only non-Horse MQ SR will be created with ${totalSamplesCount} samples`;
  };

  // function to execute on clicking create SR(s) button
  const onCreateSRsButtonClicked = async () => {
    setCreateSRsBtnLoading(true);

    // stores properties required to create SR{id} folder and upload their respective fastq files in it
    const uploadFastqFilesToSrFolder: SrUploadFastqFilePropsType[] = [];

    // variable to store the sequencingRun tasks for HMQ sample
    let hmqSequencingRunTasks: Array<Sr_Task_Insert_Input>;

    // variable to store the HMQ sample sr's linkFastq task id
    let hmqSamplesSrLinkFastqTaskId: number | undefined;

    // This is the only log that we send to the 'sr-planning' log stream so we are uploading it right away and not collecting multiple logs
    try {
      // mutation to log console to AWS cloudwatch
      await cloudwatchLogs({
        variables: {
          args: {
            logGroupName: logGroupNameEcp29apps,
            logStreamName: logStreamNameSrPlanning,
            logData: [
              {
                sourcecode_file_name: 'FinalSequencingRunSamplesDetails',
                sourcecode_function_name: 'onCreateSRsButtonClicked',
                platform: PlatformType.WebApp,
                message: renderAlertInfoBox(),
                data: JSON.stringify(samplesCount),
                event: 'Create SR(s) from desktop app - started',
                timestamp: new Date().toISOString(),
              },
            ],
          },
        },
      });
    } catch (err) {
      logger(err as Error);
    }

    const hmqSrCloudwatchLogs: LogData[] = [];

    try {
      // executes if non-hmq samples are present
      if (samplesCount.nonHmqSamplesCount > 0) {
        // mutation response of newly created sequencing run of non-hmq samples
        const createNonHmqSrResponse = await newMicrobiomeSequencingRun();

        uploadFastqFilesToSrFolder.push({
          srId: createNonHmqSrResponse.data?.insert_sequencing_run_one?.id as number,
          fastqFilesSourceDirPath: sequencerRunSuccessSamplesDetails.fastqFilesSourceDirPath,
          sampleIds: samples.map((id) => id.sample_id),
        });
      }

      // executes if hmq samples are present
      if (samplesCount.hmqSamplesCount > 0) {
        // storing the hmq SR task details
        hmqSequencingRunTasks = sequencingRunTasks(samples, true);

        // mutation response of newly created sequencing run of hmq samples
        const createHmqSrResponse = await insertSequencingRun({
          variables: {
            objects: [
              {
                project_id:
                  hmqProjectIdQueryData &&
                  Array.isArray(hmqProjectIdQueryData.settings) &&
                  hmqProjectIdQueryData.settings.length > 0 &&
                  hmqProjectIdQueryData.settings[0]
                    ? hmqProjectIdQueryData.settings[0].value
                    : undefined,
                status: Enum_Sr_Status_Enum.InfoSaved,
                tags: 'HMQ',
                title: `HMQ SR ${moment().format('YYYY-MM-DD')}`,
                type: Enum_Sequencing_Type_Enum.Microbiome,
                tasks: {
                  data: hmqSequencingRunTasks,
                },
              },
            ],
          },
        });

        // assigning newly created HMQ sr id
        newlyCreatedHMQSrId = createHmqSrResponse.data?.insert_sequencing_run?.returning[0].id;

        if (newlyCreatedHMQSrId === undefined) {
          throw new Error(
            'Hasura returned 200 response for insertSequencingRun but did not return the new SR id',
          );
        }

        // storing HMQ Sample's linkFastq task id
        hmqSamplesSrLinkFastqTaskId =
          createHmqSrResponse.data?.insert_sequencing_run?.returning[0].tasks[0].id;

        // mutation to update the HMQ SRs created_by_id if hmq_user_id is present
        if (
          hmqUserIdQueryData &&
          Array.isArray(hmqUserIdQueryData.settings) &&
          hmqUserIdQueryData.settings[0].value
        ) {
          await updateHmqSrsCreatedById({
            variables: {
              updates: [
                {
                  sr_id: newlyCreatedHMQSrId,
                  created_by_id: hmqUserIdQueryData.settings[0].value,
                },
              ],
            },
          });
        }

        hmqSrCloudwatchLogs.push({
          sourcecode_file_name: 'FinalSequencingRunSamplesDetails',
          sourcecode_function_name: 'onCreateSRsButtonClicked',
          platform: PlatformType.WebApp,
          message: 'New HMQ SR created successfully',
          data: JSON.stringify({
            srId: newlyCreatedHMQSrId,
          }),
          event: 'New SR',
          timestamp: new Date().toISOString(),
        });

        uploadFastqFilesToSrFolder.push({
          srId: newlyCreatedHMQSrId,
          fastqFilesSourceDirPath: sequencerRunSuccessSamplesDetails.fastqFilesSourceDirPath,
          sampleIds: samples.map((id) => id.sample_id),
        });
      }

      /* the srUploadFastqFile function from electron desktop will be called. Then srUploadFastqFile function will,
        1. creates SR{id} folder with their respective fastq files uploaded in it. It will copy these files from miseq_out_dir and
        move to respective SR{id} folder in dataSync_dir
        2. After successful copying of files, it will automatically start the AWS dataSync job.
       */
      if (window.ecp29Desktop) {
        hmqSrCloudwatchLogs.push({
          sourcecode_file_name: 'FinalSequencingRunSamplesDetails',
          sourcecode_function_name: 'onCreateSRsButtonClicked',
          platform: PlatformType.WebApp,
          message:
            'Started copying fastq files to DataSync directory and creating new DataSync job after copying',
          event: 'ecp29Desktop.srUploadFastqFile function called',
          timestamp: new Date().toISOString(),
        });

        window.ecp29Desktop
          .srUploadFastqFile(uploadFastqFilesToSrFolder)
          .then((taskExecutionArnResponse) => {
            // executes if hmqSamplesSrLinkFastqTaskId is present and task execution arn is fetched correctly
            if (hmqSamplesSrLinkFastqTaskId && taskExecutionArnResponse) {
              // Upload HMQ SR logs if the SR was created
              if (newlyCreatedHMQSrId !== undefined) {
                cloudwatchLogs({
                  variables: {
                    args: {
                      logGroupName: logGroupNameEcp29SrAutomation,
                      logStreamName: newlyCreatedHMQSrId?.toString(),
                      logData: [
                        {
                          timestamp: new Date().toISOString(),
                          sourcecode_file_name: 'FinalSequencingRunSamplesDetails',
                          sourcecode_function_name: 'onCreateSRsButtonClicked',
                          platform: PlatformType.WebApp,
                          event: 'ecp29Desktop.srUploadFastqFile function completed',
                          message: 'DataSync task execution was created to upload fastq files',
                          data: JSON.stringify({
                            srId: newlyCreatedHMQSrId,
                            datasyncTaskExecutionArn: taskExecutionArnResponse,
                          }),
                        },
                      ],
                    },
                  },
                }).catch((err) => {
                  logger(err as Error);
                });
              }

              // const to store the linkFastqTaskDetails
              const linkFastqTaskDetails = hmqSequencingRunTasks.find(
                (task) => task.key === Enum_Sr_Task_Key_Enum.LinkFastq,
              );

              // const to store the in_params of linkFastq task
              const linkFastqTaskParams =
                linkFastqTaskDetails && (linkFastqTaskDetails.in_params as SrTaskInputParams);

              if (Array.isArray(linkFastqTaskParams) && linkFastqTaskParams.length > 0) {
                // adding the datasyncTaskExecutionArn key value to in_params
                linkFastqTaskParams.push({
                  key: 'datasyncTaskExecutionArn',
                  value: taskExecutionArnResponse,
                });
              }

              // mutation to update the in_params of linkFastq sr task for HMQ sr
              updateInparamsOfLinkFastq({
                variables: {
                  id: hmqSamplesSrLinkFastqTaskId,
                  in_params: linkFastqTaskParams as CustomJsonb,
                },
              }).catch((err) => {
                logger(err as Error);
              });
            }
          })
          .catch((err) => {
            logger(err as Error);
          });
      }

      // Upload HMQ SR logs if the SR was created
      if (newlyCreatedHMQSrId !== undefined) {
        cloudwatchLogs({
          variables: {
            args: {
              logGroupName: logGroupNameEcp29SrAutomation,
              logStreamName: newlyCreatedHMQSrId?.toString(),
              logData: hmqSrCloudwatchLogs,
            },
          },
        }).catch((err) => {
          logger(err as Error);
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.success('SR(s) successfully created!');

      setCreateSRsBtnLoading(false);
      // navigate to sequencing run dashboard screen
      navigate('/sr/open');
    } catch (err) {
      setCreateSRsBtnLoading(false);
      logger(err as Error);

      // Upload HMQ SR logs if the SR was created
      if (newlyCreatedHMQSrId !== undefined) {
        cloudwatchLogs({
          variables: {
            args: {
              logGroupName: logGroupNameEcp29SrAutomation,
              logStreamName: newlyCreatedHMQSrId?.toString(),
              logData: [
                ...hmqSrCloudwatchLogs,
                {
                  timestamp: new Date().toISOString(),
                  sourcecode_file_name: 'FinalSequencingRunSamplesDetails',
                  sourcecode_function_name: 'onCreateSRsButtonClicked',
                  platform: PlatformType.WebApp,
                  event: 'ERROR',
                  message: 'Check error in Sentry',
                },
              ],
            },
          },
        }).catch((nestedErr) => {
          logger(nestedErr as Error);
        });
      }
      // If HMQ SR was not created then send logs to 'sr-planning' log stream
      else {
        cloudwatchLogs({
          variables: {
            args: {
              logGroupName: logGroupNameEcp29SrAutomation,
              logStreamName: logStreamNameSrPlanning,
              logData: [
                {
                  timestamp: new Date().toISOString(),
                  sourcecode_file_name: 'FinalSequencingRunSamplesDetails',
                  sourcecode_function_name: 'onCreateSRsButtonClicked',
                  platform: PlatformType.WebApp,
                  event: 'ERROR',
                  message: 'Check error in Sentry',
                },
              ],
            },
          },
        }).catch((nestedErr) => {
          logger(nestedErr as Error);
        });
      }
    }
  };

  return (
    <AppLayout screenTitle="Create Sequencing Run(s)">
      <>
        <h2>Finalize SR(s)</h2>
        <Row>
          <Col span={12}>
            <p>
              <span style={{ fontWeight: 'bold' }}>No. of samples: </span>
              {samples.length}
            </p>
            <Alert
              style={{ width: 450, marginTop: 40 }}
              message={`${
                samplesCount.hmqSamplesCount > 0 && samplesCount.nonHmqSamplesCount > 0 ? '2' : '1'
              } SR(s) will be created`}
              description={renderAlertInfoBox()}
              type="info"
              showIcon
            />

            <Popconfirm
              placement="topRight"
              title="Have you checked all samples and read the description in the info box? Press Yes to create the SR(s)"
              onConfirm={onCreateSRsButtonClicked}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                loading={createSRsBtnLoading}
                style={{ marginTop: '40px', marginBottom: '20px' }}
              >
                Create SR(s)
              </Button>
            </Popconfirm>
          </Col>
          <Col span={12}>
            <Table<SrSamples>
              style={{ width: 250 }}
              size="small"
              bordered
              dataSource={samples}
              rowKey="sample_id"
              pagination={{
                defaultPageSize: 50,
              }}
            >
              <Column<SrSamples>
                key="sample_id"
                title="Sample ids"
                dataIndex="sample_id"
                align="center"
              />
            </Table>
          </Col>
        </Row>
      </>
    </AppLayout>
  );
};

export default FinalSequencingRunSamplesDetails;
