import React from 'react';
import { Controller } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { ErrorMessage } from '@hookform/error-message';

// prop type
type ECFormItemProps = {
  // prop to pass label of input/select field
  label?: string | JSX.Element;
  // prop to pass error
  errors?: Record<string, unknown>;
  /* Prop to pass help text */
  helpText?: string;
  /* Prop to check field is required  */
  isFieldRequired?: boolean;
  // Style override for wrapper/container div
  containerStyle?: React.CSSProperties;
  // Assign class to the label
  labelClassName?: string;
} & ControllerProps<JSX.Element>;

// react functional component which is use as react-hook form controller in form
const EcFormItem: React.FC<ECFormItemProps> = ({
  label,
  errors,
  helpText,
  isFieldRequired,
  containerStyle,
  labelClassName,
  ...controllerProps
}) => {
  return (
    <div style={containerStyle}>
      <p style={{ marginBottom: 3 }} className={labelClassName}>
        {label}
        {(label && controllerProps.rules?.required) || isFieldRequired ? <span>*</span> : null}
      </p>
      <p
        style={{ fontSize: 12, fontStyle: 'italic', color: '#999999', margin: 0, marginBottom: 3 }}
      >
        {helpText}
      </p>
      <Controller {...controllerProps} />
      {errors ? (
        <ErrorMessage
          errors={errors}
          name={controllerProps.name}
          render={({ message }: { message: string }): JSX.Element => (
            <div style={{ color: 'red' }}>{message}</div>
          )}
        />
      ) : null}
    </div>
  );
};

export default EcFormItem;
