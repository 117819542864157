import React from 'react';
import { Alert } from 'antd';

/* React functional component */
const AlertBox: React.FC = () => {
  return (
    <Alert
      style={{ width: 650, marginBottom: 20 }}
      message={
        <ul style={{ paddingInlineStart: 15, marginBlockEnd: 0 }}>
          <li>Do not add “Sample ID” metadata column. It's automatically added by the platform</li>
          <li>In the column name, only lower case letters, hyphen (-) and numbers are allowed</li>
        </ul>
      }
      type="info"
    />
  );
};

export default AlertBox;
