import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import {
  MdsaBasicInfoQuery,
  MdsaBasicInfoQueryVariables,
  Mdsa_Insert_Input,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import MdsaInfoForm from '../forms/MdsaInfoForm';

// intialData prop type declaration
type InitialDataType = Pick<Mdsa_Insert_Input, 'title' | 'tags' | 'description'>;

// call to graphql file
const mdsaBasicInfoQuery = loader('../graphql/queries/mdsaBasicInfoQuery.graphql');

// react functional component
const EditMdsaInfo: React.FC = () => {
  const { id } = useParams();
  // query to fetch initial data of selected mdsa
  const { data, loading, error } = useQuery<MdsaBasicInfoQuery, MdsaBasicInfoQueryVariables>(
    mdsaBasicInfoQuery,
    {
      variables: { id: parseInt(id, 10) },
    },
  );

  // if error occur while fetching data from query
  if (error) {
    return (
      <AppLayout screenTitle="Edit MDSA Basic Info">
        <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
      </AppLayout>
    );
  }
  // const to store initial data of selected mdsa
  const individualMdsaData = data?.mdsa_by_pk;

  // when its not loading and individual mdsa data is present below code will execute
  if (individualMdsaData && !loading) {
    const { title, tags, description } = individualMdsaData;
    const initialData: InitialDataType = {
      title,
      description,
      tags,
    };
    return (
      <AppLayout screenTitle="Edit MDSA Basic Info">
        <MdsaInfoForm mode="edit" initialData={initialData} />
      </AppLayout>
    );
  }

  return (
    <AppLayout screenTitle="Edit MDSA Basic Info">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        <Spin size="large" />
      </div>
    </AppLayout>
  );
};

export default EditMdsaInfo;
