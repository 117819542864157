import React from 'react';
import { Tabs } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router';
import AppLayout from './AppLayout';

/* React functional component */
const HmqSamplesLayout = (): JSX.Element => {
  /* useLocation declaration */
  const location = useLocation();

  /* useNavigate declaration */
  const navigate = useNavigate();

  return (
    <AppLayout screenTitle="Horse MQ Samples">
      <Tabs
        activeKey={location.pathname}
        onChange={(activeKey) => {
          navigate(activeKey);
        }}
      >
        <Tabs.TabPane tab="Received in lab" key="/samples/hmq/received">
          <Outlet />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Library Preparation" key="/samples/hmq/libprep">
          <Outlet />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Sequencing" key="/samples/hmq/sequencing">
          <Outlet />
        </Tabs.TabPane>
      </Tabs>
    </AppLayout>
  );
};

export default HmqSamplesLayout;
