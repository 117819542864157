import React, { useState, useContext } from 'react';
import { Input, Row, Col, DatePicker, Select, Button, Radio, message, Upload } from 'antd';
import { useQuery, useMutation, ApolloError, useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import axios, { AxiosError } from 'axios';
import { useForm, useFieldArray } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { UserContext } from '../contexts/UserContext';
import EcFormItem from '../components/EcFormItem';
import {
  AllUsersQuery,
  NewProjectMutation,
  NewProjectMutationVariables,
  AddUsersToProjectMutation,
  AddUsersToProjectMutationVariables,
  ProtocolUploadInfoQuery,
  ProtocolUploadInfoQueryVariables,
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
  Project_Set_Input,
  DeleteFileMutationVariables,
  DeleteFileMutation,
  Project,
  MetadataColumn,
  Maybe,
} from '../graphql/graphql-types';
import { logger } from '../utils/helpers';
import { UploadFileDetailsType } from '../utils/types';
import { notAllowedColumnNames } from '../utils/globals';
import AlertBox from '../components/AlertBox';

// type for create new project fields
type ProjectFormType = Pick<
  NewProjectMutationVariables,
  'title' | 'business_unit' | 'description' | 'species'
> & {
  tags: Array<string> | null | undefined;
  start_date: moment.Moment | null;
  end_date: moment.Moment | null;
} & { qiime_metadata_columns?: Maybe<Array<MetadataColumn>> };
// type of qiime_metadata_columns
type MetadataColumnType = Pick<NewProjectMutationVariables, 'qiime_metadata_columns'>;
// initialValues type
type InitialDataType = Pick<
  Project,
  | 'business_unit'
  | 'description'
  | 'end_date'
  | 'id'
  | 'protocol_s3_key'
  | 'species'
  | 'start_date'
  | 'tags'
  | 'title'
> & { qiime_metadata_columns?: Maybe<Array<MetadataColumn>> };
// project form prop type
type ProjectFormPropType = {
  // mode which will use to know whether create is called or edit
  mode: 'create' | 'edit';
  // project data is prop which contain data of project for particular id its use as initialValues in edit
  initialData?: InitialDataType;
  // contain url of file which is use to download file
  fileUrl?: string | null;
};

// call to graphql files using loader which will use in creating projects
const allUsersQuery = loader('../graphql/queries/allUsersQuery.graphql');
const protocolUploadInfoQuery = loader('../graphql/queries/protocolUploadInfoQuery.graphql');
const newProjectMutation = loader('../graphql/mutations/newProjectMutation.graphql');
const addUsersToProjectMutation = loader('../graphql/mutations/addUserToProjectMutation.graphql');
// call to graphql files using loader which will use in editing projects
const updateProjectMutation = loader('../graphql/mutations/updateProjectMutation.graphql');
const deleteFileMutation = loader('../graphql/mutations/deleteFileMutation.graphql');

//  React functional Component
const ProjectForm: React.FC<ProjectFormPropType> = ({ mode, initialData, fileUrl }) => {
  // useState for storing ids of user who have access to project
  const [accessUserIds, setAccessUserIds] = useState<Array<number>>([]);
  // useState of loading indicator for newProject mutation
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>(false);
  // useState for setting  file details of uploaded file
  const [fileDetails, setFileDetails] = useState<UploadFileDetailsType>({
    file: null,
    fileList: [],
  });

  // useState of loading indicator for delete file mutation
  const [deleteFileMutationLoading, setDeleteFileMutationLoading] = useState<boolean>(false);
  // State to store the protocol s3 key of file uploaded in project, use only in edit mode
  const [protocolS3Key, setProtocolS3Key] = useState<string | null>(
    initialData && initialData.protocol_s3_key ? initialData.protocol_s3_key : null,
  );

  // apollo Client
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  // useMutation for creating new project
  const [newProject] = useMutation<NewProjectMutation, NewProjectMutationVariables>(
    newProjectMutation,
  );
  // useMutation for add User to Project
  const [addUsersToProject] = useMutation<
    AddUsersToProjectMutation,
    AddUsersToProjectMutationVariables
  >(addUsersToProjectMutation);
  // mutation to update projects details
  const [updateProject] = useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    updateProjectMutation,
  );
  // mutation to delete uploaded file
  const [deleteFile] = useMutation<DeleteFileMutation, DeleteFileMutationVariables>(
    deleteFileMutation,
  );
  // useQuery for fetching data of user who have access
  const { data: allUserData } = useQuery<AllUsersQuery>(allUsersQuery);

  // useForm declaration
  const { handleSubmit, control, errors, reset } = useForm<ProjectFormType>(
    initialData
      ? {
          defaultValues: {
            title: initialData.title,
            species: initialData.species,
            description: initialData.description,
            start_date: moment(initialData.start_date),
            end_date: moment(initialData.end_date),
            business_unit: initialData.business_unit,
            tags: initialData.tags ? initialData.tags.split(',') : null,
            qiime_metadata_columns:
              initialData.qiime_metadata_columns === null
                ? ([] as Array<MetadataColumn>)
                : (initialData.qiime_metadata_columns as Array<MetadataColumn>),
          },
        }
      : {
          defaultValues: {
            title: '',
            species: '',
            description: null,
            start_date: null,
            end_date: null,
            business_unit: null,
            tags: null,
            qiime_metadata_columns: [],
          },
        },
  );
  // useField array for metadata column
  const { fields, append, remove } = useFieldArray<MetadataColumnType>({
    control,
    name: 'qiime_metadata_columns',
  });

  // userContext to get data of logged in user
  const { user } = useContext(UserContext);
  // function to create new project and add user to project
  const createNewProjectAndAddUserToProjectFunc = (mutationValues: NewProjectMutationVariables) => {
    // call to create new Project mutation
    newProject({
      variables: mutationValues,
    })
      .then((res): void => {
        const projectId: number | undefined = res?.data?.insert_project_one?.id;
        // addUserToProjectMutation will call only when accessUSerID present
        if (accessUserIds.length > 0 && projectId) {
          // call to mutation to add new user to project
          addUsersToProject({
            variables: {
              // mapping to create object that will be pass as argument to addUserToProjectMutation
              objects: accessUserIds.map((element) => ({
                user_id: element,
                project_id: projectId,
              })),
            },
          })
            .then(() => {
              setIsSubmitButtonLoading(false);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.success('Project successfully created!');
              reset();
              setAccessUserIds([]);
              setFileDetails({ file: null, fileList: [] });
            })
            .catch((addUsersToProjectError: ApolloError) => {
              setIsSubmitButtonLoading(false);
              logger(addUsersToProjectError);
            });
        } else {
          setIsSubmitButtonLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Project successfully created!');
          reset();
          setAccessUserIds([]);
          setFileDetails({ file: null, fileList: [] });
        }
      })
      .catch((newProjectError: ApolloError) => {
        setIsSubmitButtonLoading(false);
        logger(newProjectError);
      });
  };

  // function to edit project
  const editProjectFunc = (mutationValues: Project_Set_Input) => {
    if (initialData) {
      updateProject({
        variables: {
          id: initialData.id,
          inputValues: mutationValues,
        },
      })
        .then(() => {
          setIsSubmitButtonLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Project successfully updated!');
          navigate('/projects');
        })
        .catch((err: ApolloError) => {
          setIsSubmitButtonLoading(false);
          logger(err);
        });
    }
  };

  // function to handle uploading of file
  const handleFileUpload = (
    fileData: UploadFileDetailsType,
    values: NewProjectMutationVariables,
  ) => {
    if (fileData.file) {
      apolloClient
        .query<ProtocolUploadInfoQuery, ProtocolUploadInfoQueryVariables>({
          query: protocolUploadInfoQuery,
          variables: {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            contentType: fileData.file.type,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            fileName: fileData.file.name,
          },
        })
        .then(({ data }) => {
          if (data && data.fileUploadS3Config && data.fileUploadS3Config[0]) {
            const {
              algorithm,
              contentDisposition,
              contentType,
              credential,
              date,
              key,
              policy,
              signature,
              url,
            } = data.fileUploadS3Config[0];
            const formData: FormData = new FormData();
            formData.append('key', key);
            formData.append('policy', policy);
            formData.append('x-amz-algorithm', algorithm);
            formData.append('x-amz-credential', credential);
            formData.append('x-amz-date', date);
            formData.append('x-amz-signature', signature);
            formData.append('Content-Type', contentType);
            formData.append('Content-Disposition', contentDisposition);
            formData.append('file', fileData.file);
            // Upload the file to the server
            axios
              .post(url, formData)
              .then(() => {
                // After successful file upload, update value of protocol_key in mutation
                setIsSubmitButtonLoading(false);
                const mutationValues: NewProjectMutationVariables = {
                  ...values,
                  protocol_s3_key: key,
                };
                if (mode === 'edit') {
                  setProtocolS3Key(key);
                  editProjectFunc(mutationValues);
                } else {
                  createNewProjectAndAddUserToProjectFunc(mutationValues);
                }
              })
              .catch((formDataError: AxiosError) => {
                setIsSubmitButtonLoading(false);
                logger(formDataError);
              });
          }
        })
        .catch((err: ApolloError) => {
          setIsSubmitButtonLoading(false);
          logger(err);
        });
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit((data) => {
          setIsSubmitButtonLoading(true);
          // const for mutation value without protocol_s3_key
          const values: NewProjectMutationVariables = {
            business_unit: data.business_unit ? data.business_unit : null,
            description: data.description ? data.description : null,
            end_date: data.end_date ? data.end_date.format('YYYY-MM-DD') : null,
            qiime_metadata_columns: data.qiime_metadata_columns
              ? data.qiime_metadata_columns
              : null,
            species: data.species,
            start_date: data.start_date ? data.start_date.format('YYYY-MM-DD') : null,
            tags: data.tags ? data.tags.join(', ') : null,
            title: data.title,
          };

          // logic if mode is create
          if (mode === 'create') {
            // when file to be upload is selected
            if (fileDetails.file) {
              handleFileUpload(fileDetails, values);
            }
            // when file to be upload is not selected
            else {
              const mutationValues: NewProjectMutationVariables = {
                ...values,
                protocol_s3_key: null,
              };
              createNewProjectAndAddUserToProjectFunc(mutationValues);
            }
          }
          // logic if mode is edit
          if (mode === 'edit') {
            if (fileDetails.file && protocolS3Key === null) {
              handleFileUpload(fileDetails, values);
            } else {
              const mutationValues: Project_Set_Input = {
                ...values,
                protocol_s3_key: protocolS3Key,
              };
              editProjectFunc(mutationValues);
            }
          }
        })}
      >
        <Row gutter={[24, 10]}>
          <Col sm={24} lg={8}>
            <EcFormItem
              label="Title"
              name="title"
              control={control}
              rules={{ required: 'Please enter title and try again' }}
              errors={errors}
              as={<Input allowClear />}
            />
          </Col>
          <Col sm={24} lg={8}>
            <EcFormItem
              label="Description"
              name="description"
              control={control}
              as={<Input.TextArea rows={2} allowClear />}
            />
          </Col>
        </Row>
        <Row gutter={[24, 15]}>
          <Col sm={24} lg={8}>
            <EcFormItem
              label="Species"
              name="species"
              control={control}
              rules={{ required: 'Please enter species and try again' }}
              errors={errors}
              as={<Input allowClear />}
            />
          </Col>
          <Col sm={24} lg={8}>
            <EcFormItem
              label="Business Unit"
              name="business_unit"
              control={control}
              as={<Input allowClear />}
            />
          </Col>
        </Row>
        <Row gutter={[24, 15]}>
          <Col sm={24} lg={8}>
            <EcFormItem
              label="Tags"
              name="tags"
              defaultValue={[]}
              control={control}
              render={({ onChange, value }) => (
                <Select
                  mode="tags"
                  value={value as string}
                  style={{ width: '100%' }}
                  showArrow
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
            />
          </Col>
          <Col sm={24} lg={4}>
            <EcFormItem
              label="Start Date"
              name="start_date"
              control={control}
              render={({ onChange, value }): JSX.Element => (
                <DatePicker
                  onChange={(date): void => {
                    onChange(date);
                  }}
                  value={value as moment.Moment}
                  format="Do MMM YYYY"
                  placeholder="Select date"
                  allowClear
                  style={{ width: 180 }}
                />
              )}
            />
          </Col>
          <Col sm={24} lg={4}>
            <EcFormItem
              label="End Date"
              name="end_date"
              control={control}
              render={({ onChange, value }): JSX.Element => (
                <DatePicker
                  onChange={(date): void => {
                    onChange(date);
                  }}
                  value={value as moment.Moment}
                  format="Do MMM YYYY"
                  placeholder="Select date"
                  allowClear
                  style={{ width: 180 }}
                />
              )}
            />
          </Col>
        </Row>
        <Row gutter={[24, 15]}>
          <Col sm={24} lg={8}>
            <p style={{ marginBottom: 2 }}>Experiment Protocol</p>
            {mode === 'edit' && protocolS3Key && fileUrl ? (
              <div style={{ flex: 1, flexDirection: 'row' }}>
                <Button
                  icon={<DownloadOutlined />}
                  size="small"
                  style={{ marginRight: 20, marginBottom: 5 }}
                  href={fileUrl ?? '#'}
                >
                  Download File
                </Button>
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  loading={deleteFileMutationLoading}
                  onClick={() => {
                    setDeleteFileMutationLoading(true);
                    if (initialData && initialData.protocol_s3_key)
                      deleteFile({
                        variables: {
                          key: initialData.protocol_s3_key,
                        },
                      })
                        .then(() => {
                          setDeleteFileMutationLoading(false);
                          setProtocolS3Key(null);
                          setFileDetails({ file: null, fileList: [] });
                        })
                        .catch((err: ApolloError) => {
                          setDeleteFileMutationLoading(false);
                          logger(err);
                        });
                  }}
                >
                  Delete & upload file
                </Button>
              </div>
            ) : (
              <Upload
                onChange={(info) => {
                  let newFileList = [...info.fileList];
                  newFileList = newFileList.slice(-1);
                  setFileDetails({ file: info.file, fileList: newFileList });
                }}
                beforeUpload={() => {
                  return false;
                }}
                onRemove={() => {
                  setFileDetails({ file: null, fileList: [] });
                  return false;
                }}
                fileList={fileDetails.fileList}
              >
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
            )}
          </Col>
          {mode === 'create' ? (
            <Col sm={24} lg={8}>
              <p style={{ marginBottom: 1 }}>Allow access to user</p>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                showArrow
                onChange={(e: Array<number>) => {
                  setAccessUserIds(e);
                }}
                value={accessUserIds}
              >
                {allUserData && Array.isArray(allUserData.users) && allUserData.users.length > 0
                  ? allUserData.users
                      .filter((item) => !user || item.id !== user.id)
                      .map(
                        (item): JSX.Element => (
                          <Select.Option value={item.id} key={item.id}>
                            {`${item.first_name} ${item.last_name}`}
                          </Select.Option>
                        ),
                      )
                  : null}
              </Select>
            </Col>
          ) : null}
        </Row>

        <p>Default metadata columns for qiime</p>

        <AlertBox />

        {fields.map(
          (item, index): JSX.Element => {
            return (
              <Row gutter={[24, 15]} key={item.id}>
                <Col sm={24} lg={4}>
                  <EcFormItem
                    name={`qiime_metadata_columns[${index}].column`}
                    control={control}
                    defaultValue={
                      mode === 'edit' && initialData && initialData.qiime_metadata_columns
                        ? initialData.qiime_metadata_columns[index].column
                        : null
                    }
                    rules={{
                      required: 'Please enter column name and try again',
                      pattern: {
                        value: /^[a-z][a-z\d-]*[a-z\d]$/,
                        message: 'Only lower case alphabets, hyphen (-) and numbers are allowed',
                      },
                      validate: (value) => {
                        if (notAllowedColumnNames.includes(value)) {
                          return 'You cannot use ‘id’, ‘sampleid’, ‘sample id’, ‘sample-id’ as column names';
                        }
                        return true;
                      },
                    }}
                    errors={errors}
                    as={<Input placeholder="Column Name" style={{ width: 200 }} allowClear />}
                  />
                </Col>

                <Col sm={24} lg={5} style={{ alignSelf: 'center', padding: 5, marginBottom: 2 }}>
                  <EcFormItem
                    name={`qiime_metadata_columns[${index}].type`}
                    control={control}
                    defaultValue={
                      mode === 'edit' && initialData && initialData.qiime_metadata_columns
                        ? initialData.qiime_metadata_columns[index].type
                        : 'categorical'
                    }
                    render={({ onChange, value }): JSX.Element => (
                      <Radio.Group
                        onChange={(e) => onChange(e.target.value)}
                        value={value as string}
                        defaultValue="categorical"
                      >
                        <Radio value="categorical">Categorical</Radio>
                        <Radio value="numeric">Numeric</Radio>
                      </Radio.Group>
                    )}
                  />
                </Col>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    style={{
                      marginLeft: 3,
                      alignSelf: 'center',
                      marginBottom: 2,
                      color: 'grey',
                    }}
                    onClick={(): void => {
                      remove(index);
                    }}
                  />
                ) : null}
              </Row>
            );
          },
        )}
        <Button
          style={{ marginBottom: 10 }}
          icon={<PlusOutlined style={{ color: 'grey' }} />}
          onClick={(): void => {
            append({});
          }}
        >
          New Column
        </Button>
        <div>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitButtonLoading}
            style={{ marginTop: 20 }}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};
export default ProjectForm;
