import React, { useState } from 'react';
import { Menu, Button, Modal, message, Dropdown } from 'antd';
import { PlusOutlined, ArrowLeftOutlined, DownOutlined } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import { useApolloClient, useMutation, Reference, ApolloError } from '@apollo/client';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  NewMicrobiomeSequencingRunMutation,
  NewMicrobiomeSequencingRunMutationVariables,
  DataSyncStartTaskExecutionMutation,
  DataSyncStartTaskExecutionMutationVariables,
} from '../graphql/graphql-types';
import Logo from '../images/purina_logo.svg';
import styles from './AppLayout.module.scss';
import { colors } from '../utils/globals';
import { firebaseAuth } from '../utils/firebase';
import { logger } from '../utils/helpers';
import FileUploadInProgressModal from './FileUploadInProgressModal';
import { useUser } from '../contexts/UserContext';

const { SubMenu } = Menu;

// app layout prop type
type AppLayoutPropsType = {
  // prop which will decide to show back button on screen or not
  hideBackBtn?: boolean;
  // prop to go back to specific url when mention otherwise it will go back to previous page
  backUrl?: string | null;
  // prop to display the name of screen
  screenTitle: string;
  // prop will pass whole jsx component of screen
  children: JSX.Element;
};

// Importing new microbiome sequence mutation
const newMicrobiomeSequencingRunMutation = loader(
  '../graphql/mutations/newMicrobiomeSequencingRunMutation.graphql',
);

const dataSyncStartTaskExecutionMutation = loader(
  '../graphql/mutations/dataSyncStartTaskExecutionMutation.graphql',
);

// react functional component
const AppLayout: React.FC<AppLayoutPropsType> = ({
  hideBackBtn,
  backUrl,
  screenTitle,
  children,
}) => {
  const apolloClient = useApolloClient();
  const location = useLocation();
  const navigate = useNavigate();

  // user context to fetch user details
  const { user } = useUser();

  // useState for new sequence create loading
  const [newSequencingLoading, setNewSequencingLoading] = useState<boolean>(false);
  // useState for modal visibility
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  // useState to set current sequence id
  const [newSequenceId, setNewSequenceId] = useState<number | null>(null);
  /* State used to show loading indicator on start data sync btn when mutation is running */
  const [startDataSyncLoading, setStartDataSyncLoading] = useState<boolean>(false);
  /* State to store executed tasks ARN */
  const [taskExecARN, setTaskExecArn] = useState<string | null>(null);

  // new microbiome run mutation declaration
  const [newMicrobiomeSequencingRun] = useMutation<
    NewMicrobiomeSequencingRunMutation,
    NewMicrobiomeSequencingRunMutationVariables
  >(newMicrobiomeSequencingRunMutation);

  /* Mutation to start data sync task */
  const [dataSyncStartTaskExecution] = useMutation<
    DataSyncStartTaskExecutionMutation,
    DataSyncStartTaskExecutionMutationVariables
  >(dataSyncStartTaskExecutionMutation);

  // Handling modal close button
  const handleClose = (): void => {
    setModalVisibility(false);
    setNewSequenceId(null);
  };

  // Handling modal Open SR button
  const handleOpenSR = (): void => {
    handleClose();
    if (newSequenceId) {
      navigate(`/sr/${newSequenceId}/edit/info`);
    }
  };

  // Handling new sequencing run button
  const handleNewSequencingRun = (): void => {
    setNewSequencingLoading(true);
    // update cache to reflect changes instantly in view open sr screen.
    newMicrobiomeSequencingRun({
      update: (cache, { data: newData }): void => {
        const newlyAddedSrData = newData?.insert_sequencing_run_one;
        cache.modify({
          fields: {
            sequencing_run(sequencingRunRef: Array<Reference>) {
              return [...sequencingRunRef, newlyAddedSrData];
            },
          },
        });
      },
    })
      .then((res) => {
        setNewSequencingLoading(false);
        if (res && res.data && res.data.insert_sequencing_run_one) {
          setNewSequenceId(res.data.insert_sequencing_run_one.id);
          setModalVisibility(true);
        }
      })
      .catch((error) => {
        if (error) {
          setNewSequencingLoading(false);
          logger(error);
        }
      });
  };

  /* Function to handle start data sync task using dataSyncStartTaskExecution mutation */
  const handleStartDataSync = () => {
    setStartDataSyncLoading(true);
    dataSyncStartTaskExecution()
      .then((res) => {
        if (res && res.data && res.data.dataSyncStartTaskExecution) {
          setTaskExecArn(res.data.dataSyncStartTaskExecution.taskExecutionArn);
          setStartDataSyncLoading(false);
        }
      })
      .catch((error: ApolloError) => {
        if (error) {
          setStartDataSyncLoading(false);
          if (error.message === 'SyncTaskAlreadyRunning') {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error('An upload task is already running. Please wait for it to finish');
          } else {
            logger(error);
          }
        }
      });
  };

  return (
    <>
      {/* Create New Sequencing Run Modal */}
      <Modal visible={modalVisibility} centered closable={false} footer={null}>
        <p style={{ textAlign: 'center' }}>New sequencing run was created!</p>
        <h2 style={{ textAlign: 'center' }}>SR{newSequenceId}</h2>
        <p>Next Steps:</p>
        <ol>
          <li>
            Create a directory "SR{newSequenceId}" in the data sync directory on the PC attached to
            the sequencer
          </li>
          <li>Paste all the fastq files of this run inside the directory and upload</li>
          <li>Open the SR on the platform and detect/sync files</li>
        </ol>
        <Button type="primary" style={{ marginTop: 10 }} onClick={handleOpenSR}>
          Open SR
        </Button>
        <Button type="primary" style={{ marginLeft: 15 }} onClick={handleClose}>
          Close
        </Button>
      </Modal>

      {/* File upload in progress modal */}
      {taskExecARN ? (
        <FileUploadInProgressModal taskExecArn={taskExecARN} setTaskExecArn={setTaskExecArn} />
      ) : null}

      <div>
        {/* header part */}
        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            height: 50,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: colors.tintColor,
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <img src={Logo} alt="img" className={styles.logo} />
            <Button
              className={styles.navigationButton}
              icon={<PlusOutlined />}
              onClick={
                //  this is to check if user is clicking the button from desktop app or browser.
                //  if it is clicked from desktop app then navigate to DesktopSrCreate screen else execute handleNewSequencingRun function
                window.ecp29Desktop && window.ecp29Desktop.isDesktopApp
                  ? () => {
                      navigate('/desktop/sr/create');
                    }
                  : handleNewSequencingRun
              }
              loading={newSequencingLoading}
            >
              New Sequencing Run
            </Button>

            <Button
              className={styles.navigationButton}
              href="https://view.qiime2.org/"
              target="_blank"
              rel="noreferrer"
            >
              Qiime Viewer
            </Button>

            <Button
              className={styles.navigationButton}
              loading={startDataSyncLoading}
              onClick={handleStartDataSync}
            >
              Start DataSync
            </Button>

            <Button
              className={styles.navigationButton}
              href="https://rstudio.lol-mq.com/"
              target="_blank"
              rel="noreferrer"
            >
              RStudio Server
            </Button>
          </div>

          <Dropdown
            className={styles.navigationButton}
            trigger={['click']}
            overlay={
              <Menu style={{ padding: '3px 5px' }}>
                <Menu.Item
                  onClick={() =>
                    firebaseAuth
                      .signOut()
                      .then(() =>
                        // to clear apollo client store data
                        apolloClient.clearStore(),
                      )
                      .catch((err) => message.error(err))
                  }
                >
                  Logout
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
          >
            <Button>
              {user ? (
                <>
                  <span>{`${user.first_name} ${user.last_name}`}</span>
                  <DownOutlined style={{ fontSize: '11px', marginLeft: '10px' }} />
                </>
              ) : null}
            </Button>
          </Dropdown>
        </div>
        {/* header part end */}
        <div
          style={{
            minHeight: 'calc(100vh - 50px)',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#DDDDDD',
          }}
        >
          {/* sider part */}
          <div style={{ width: '300px' }}>
            <Menu
              mode="inline"
              defaultOpenKeys={['sub1', 'sub2', 'sub3']}
              selectedKeys={[location.pathname]}
              className={styles.individual}
            >
              <SubMenu key="sub1" title="Projects">
                <Menu.Item key="/project/create" className={styles.individual}>
                  <Link to="/project/create">Create New</Link>
                </Menu.Item>
                <Menu.Item key="/projects" className={styles.individual}>
                  <Link to="/projects">View All</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub2" title="Sequencing Runs">
                <Menu.Item key="/sr/open" className={styles.individual}>
                  <Link to="/sr/open">View Open</Link>
                </Menu.Item>
                <Menu.Item key="/sr/completed" className={styles.individual}>
                  <Link to="/sr/completed"> View Completed</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub3" title="Microbiome Downstream Analysis">
                <Menu.Item key="/mdsa/create" className={styles.individual}>
                  <Link to="/mdsa/create"> Create New</Link>
                </Menu.Item>
                <Menu.Item key="/mdsa/ongoing" className={styles.individual}>
                  <Link to="/mdsa/ongoing"> View Ongoing</Link>
                </Menu.Item>
                <Menu.Item key="/mdsa/completed" className={styles.individual}>
                  <Link to="/mdsa/completed"> View Completed</Link>
                </Menu.Item>
                <Menu.Item key="/taxonomyClassifiers" className={styles.individual}>
                  <Link to="/taxonomyClassifiers">Taxonomy Classifiers</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub4" title="HMQ Samples">
                <Menu.Item key="/samples/hmq/received" className={styles.individual}>
                  <Link to="/samples/hmq/received">Received in lab</Link>
                </Menu.Item>
                <Menu.Item key="/samples/hmq/libprep" className={styles.individual}>
                  <Link to="/samples/hmq/libprep">DNA & Library preparation</Link>
                </Menu.Item>
                <Menu.Item key="/samples/hmq/sequencing" className={styles.individual}>
                  <Link to="/samples/hmq/sequencing">Sequencing</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub5" title="User Management">
                <Menu.Item key="/user/create" className={styles.individual}>
                  <Link to="/user/create"> Create New</Link>
                </Menu.Item>
                <Menu.Item key="/users" className={styles.individual}>
                  <Link to="/users">View All</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub6" title="Settings">
                <Menu.Item key="/settings/changePassword" className={styles.individual}>
                  <Link to="/settings/changePassword">Change Password</Link>
                </Menu.Item>
                <Menu.Item key="/settings/hmq" className={styles.individual}>
                  <Link to="/settings/hmq">HMQ</Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </div>
          {/* content part */}
          <div
            style={{ flex: 1, backgroundColor: '#fff', padding: 20, width: 'calc(100vw - 300px)' }}
          >
            {hideBackBtn ? null : (
              <Button
                icon={<ArrowLeftOutlined />}
                style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                onClick={() => (backUrl ? navigate(backUrl) : navigate(-1))}
              >
                Go Back
              </Button>
            )}
            <h1 style={{ marginBottom: 20 }}>{screenTitle}</h1>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
export default AppLayout;
