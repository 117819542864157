import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Table, Upload, message } from 'antd';
import ExcelJs, { ValueType } from 'exceljs';
import moment from 'moment';
import Column from 'antd/lib/table/Column';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload/interface';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
  hmqSheetDateFormat,
  logGroupNameEcp29apps,
  logStreamNameSampleManagement,
} from '../utils/globals';
import { UpdateKitDnaPrepLibPrepBody, UploadFileDetailsType } from '../utils/types';
import {
  fetchKitInfoWithFilters,
  updateKitDnaPrepLibPrepDetails,
  updateKitDnaPrepLibPrepDetailsURL,
} from '../utils/apis/kit';
import { logger } from '../utils/helpers';
import hmqSamplesLibPrepExcelTemplate from '../utils/helpers/hmq-samples/hmqSamplesLibPrepExcelTemplate';
import {
  CloudwatchLogsMutation,
  CloudwatchLogsMutationVariables,
  PlatformType,
} from '../graphql/graphql-types';

/* Type definition for HMQ sheet cell's error type */
type SheetCellErrorType = Array<{
  /* Cell Address */
  address: string;
  /* Error message for that particular cell */
  error: string;
}>;

// type definition for table data
type TableDataType = {
  // type definition for kitID
  kitID: string;
  // type definition for recievedDate
  recievedDate: string;
  // type definition for summaryComplete
  summaryComplete: boolean | null;
};

const cloudwatchLogsMutation = loader('../graphql/mutations/cloudwatchLogs.graphql');

/* React functional component */
const SamplesLibraryPreparation = (): JSX.Element => {
  // mutation to store the logs
  const [cloudwatchLogs] = useMutation<CloudwatchLogsMutation, CloudwatchLogsMutationVariables>(
    cloudwatchLogsMutation,
  );

  /* State to store hmq excel sheet file details uploaded using antd component */
  const [hmqFileDetails, setHmqFileDetails] = useState<UploadFileDetailsType>({
    file: null,
    fileList: [],
  });

  /* State to store sheet errors while reading HMQ sheet */
  const [hmqSheetCellErrors, setHmqSheetCellErrors] = useState<SheetCellErrorType>([]);

  /* state to store data with summary completed to render in table after fetching it from fetchKitInfo API */
  const [completeSummaryKitsTableData, setCompleteSummaryKitsTableData] = useState<
    TableDataType[] | undefined
  >(undefined);

  /* state to store data with summary in-complete to render in table after fetching it from fetchKitInfo API */
  const [incompleteSummaryKitsTableData, setIncompleteSummaryKitsTableData] = useState<
    TableDataType[] | undefined
  >(undefined);

  /* state to check whether table with data are loaded or not */
  const [isTableDataLoading, setIsTableDataLoading] = useState<boolean>(false);

  /* state to store kit ids which are selected to download in a HMQ excel sheet */
  const [kitIdsToDownloadInExcel, setKitIdsToDownloadInExcel] = useState<string[]>([]);

  /* store array of kit dna prep lib details to provide as a body data to update in a bulk with an update API */
  const [kitDnaPrepLibPrepDetails, setKitDnaPrepLibPrepDetails] = useState<
    UpdateKitDnaPrepLibPrepBody[]
  >([]);

  /* state to check whether download template button is loading or not */
  const [isDownloadTemplateBtnLoading, setIsDownloadTemplateBtnLoading] = useState<boolean>(false);

  /* state to check whether upload button is loading or not */
  const [isUploadButtonLoading, setIsUploadButtonLoading] = useState<boolean>(false);

  /**
   * Function is responsible for validating the content of an Excel sheet file.
   * It checks for errors in the sheet, such as empty cells, incorrect date format, and invalid cell values.
   * If there are no errors, it updates the state with the file details.
   * @param {RcFile} file The file object containing the Excel sheet to be validated.
   */
  const validateSheetContent = (file: RcFile) => {
    /* Creating new instance of the ExcelJs.Workbook class. */
    const workbook = new ExcelJs.Workbook();

    /* Creating new instance of the FileReader class. */
    const fileReader = new FileReader();

    /* Event handler that is triggered when the file has been successfully loaded by the `FileReader` object */
    fileReader.onload = async () => {
      /* Storing content of the file */
      const content = fileReader.result;

      /* Loading the content of the file into the workbook */
      await workbook.xlsx.load(content as Buffer);

      /* Storing first worksheet in the workbook which is HMQ sheet */
      const hmqWorksheet = workbook.getWorksheet(1);

      /* Variable to store any errors found in the sheet */
      const sheetErrors: SheetCellErrorType = [];

      /**
       * Iterating over each column in the worksheet.
       * Here index is started from 1 as 0th column in 'kitId' which has pre defined values so we
       * don't need to validate that column.
       */
      for (let index = 1; index < hmqWorksheet.columns.length; index++) {
        /* Storing individual column data */
        const column = hmqWorksheet.columns[index];

        if (column.eachCell) {
          /* Iterate over each cell in the column, skipping the first row. (Because first row is header row so we don't need validation in first row) */
          column.eachCell((cell, rowNumber) => {
            if (rowNumber !== 1) {
              /* If cell do not have any value. For all input columns  */
              if (!cell.value) {
                sheetErrors.push({ address: cell.address, error: 'Cell cannot be empty' });
                /* If date format is invalid. Column - dnaExtractionDate, libPrepDate */
              } else if (
                (index === 1 || index === 3) &&
                // Check for valid date
                !(
                  cell.value instanceof Date &&
                  !Number.isNaN(cell.value.getTime()) &&
                  cell.type === ValueType.Date
                )
              ) {
                sheetErrors.push({
                  address: cell.address,
                  error: `Cell should be a date (${hmqSheetDateFormat})`,
                });
                /* If input values are invalid. Column - sampleValidity */
              } else if (index === 2 && !(cell.value === 'Invalid' || cell.value === 'Valid')) {
                sheetErrors.push({
                  address: cell.address,
                  error: 'Cell can have one of the following two value: Invalid or Valid',
                });
              }
            }
          });
        }
      }

      // if there are no errors in sheet
      if (sheetErrors.length === 0) {
        // stores kits details to update in bulk
        const kitDetailsToUpdateInBulk: UpdateKitDnaPrepLibPrepBody[] = [];

        // starting the loop from index 2 as it has headers on index 1
        for (let index = 2; index <= hmqWorksheet.rowCount; index++) {
          // stores rowDetails
          const rowDetails: string[] = [];

          // updating rowDetails array with all the required data in single row
          hmqWorksheet.getRow(index).eachCell((cell) => {
            rowDetails.push(cell.value as string);
          });

          // pushing the each kit details in an array
          kitDetailsToUpdateInBulk.push({
            kitID: rowDetails[0],
            extractionDateTime: moment(rowDetails[1]).toISOString(),
            sampleValid: rowDetails[2] === 'Valid' ? 'true' : 'false',
            libPrepDateTime: moment(rowDetails[3]).toISOString(),
          });
        }

        setKitDnaPrepLibPrepDetails(kitDetailsToUpdateInBulk);
      }

      setHmqSheetCellErrors(sheetErrors);
    };

    /**
     * Method to read the contents of the specified `file` as an array buffer.
     * This method triggers the `onload` event handler when the file has been successfully loaded.
     */
    fileReader.readAsArrayBuffer(file);
  };

  // calling useEffect to fetch kit information data on every page change in table
  useEffect(() => {
    setIsTableDataLoading(true);

    fetchKitInfoWithFilters()
      .then((res) => {
        // stores required table data from response data
        const reqTableData = res.data.map((data) => {
          return {
            kitID: data.kitID,
            recievedDate: moment(data.recievedDate).format('D MMM YYYY'),
            summaryComplete: data.summaryComplete,
          };
        });

        /** array to store the data with summary complete */
        const tableWithSummaryCompleteData: TableDataType[] = [];

        /** array to store the data with summary in-complete */
        const tableWithSummaryInCompleteData: TableDataType[] = [];

        reqTableData.forEach((data) => {
          if (data.summaryComplete) {
            tableWithSummaryCompleteData.push(data);
          } else {
            tableWithSummaryInCompleteData.push(data);
          }
        });

        /** setting all the states for summary complete data */
        setCompleteSummaryKitsTableData(tableWithSummaryCompleteData);

        /** setting all the states for summary in-complete data */
        setIncompleteSummaryKitsTableData(tableWithSummaryInCompleteData);

        setIsTableDataLoading(false);
      })
      .catch((err) => {
        logger(err as Error);
        setIsTableDataLoading(false);
      });
  }, []);

  return (
    <div>
      <p style={{ fontStyle: 'italic' }}>
        Select kits, download excel template, fill DNA extraction, validity and library preparation
        info and upload the filled excel file to update information in database
      </p>

      <Row style={{ marginTop: 15 }}>
        <Col span={12} style={{ padding: '0px 10px', borderRight: '1px solid black' }}>
          <h2>Download excel template</h2>

          <Button
            type="primary"
            disabled={kitIdsToDownloadInExcel.length === 0}
            loading={isDownloadTemplateBtnLoading}
            onClick={async () => {
              setIsDownloadTemplateBtnLoading(true);

              try {
                // mutation to log console to AWS cloudwatch
                await cloudwatchLogs({
                  variables: {
                    args: {
                      logGroupName: logGroupNameEcp29apps,
                      logStreamName: logStreamNameSampleManagement,
                      logData: [
                        {
                          sourcecode_file_name: 'SamplesLibraryPreparation',
                          sourcecode_function_name: 'onClickOfDownloadExcelTemplate',
                          platform: PlatformType.WebApp,
                          message: 'Details of kitIds to download in excel sheet',
                          data: JSON.stringify({
                            kitIdsDownloadedInExcel: kitIdsToDownloadInExcel,
                          }),
                          event: 'onDownloadExcelTemplate',
                          timestamp: new Date().toISOString(),
                        },
                      ],
                    },
                  },
                });
              } catch (err) {
                logger(err as Error);
              }

              // calling helper function to create and download the excel sheet with selected kit IDs and relevant fields
              hmqSamplesLibPrepExcelTemplate(
                `MQE_dna-extraction-validity-prep_${moment().format('YYYY-MM-DD')}.xlsx`,
                kitIdsToDownloadInExcel,
              )
                .then((res) => {
                  setIsDownloadTemplateBtnLoading(false);
                  setKitIdsToDownloadInExcel([]);
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  message.success('Excel sheet downloaded successfully!');
                })
                .catch((err) => {
                  setKitIdsToDownloadInExcel([]);
                  setIsDownloadTemplateBtnLoading(false);
                  logger(err as Error);
                });
            }}
            style={{ marginBottom: '20px' }}
          >
            Download template
          </Button>

          <Table<TableDataType>
            rowKey={(record) => record.kitID}
            rowSelection={{
              preserveSelectedRowKeys: true,
              selectedRowKeys: kitIdsToDownloadInExcel,
              onChange: (selectedRowKeys) => {
                setKitIdsToDownloadInExcel(selectedRowKeys as string[]);
              },
            }}
            bordered
            dataSource={completeSummaryKitsTableData}
            pagination={{
              defaultPageSize: 50,
              defaultCurrent: 1,
            }}
            loading={isTableDataLoading}
            size="small"
          >
            <Column<TableDataType> title="Kit ID" dataIndex="kitID" key="kitID" />
            <Column<TableDataType>
              title="Received date"
              dataIndex="recievedDate"
              key="recievedDate"
            />
          </Table>
        </Col>

        <Col span={12} style={{ padding: '0px 20px' }}>
          <h2>Upload filled excel</h2>

          <Upload
            style={{ marginTop: 25 }}
            accept=".xlsx, .xls"
            maxCount={1}
            onChange={(info) => {
              setHmqFileDetails(info);
            }}
            beforeUpload={(file) => {
              validateSheetContent(file);
              return false;
            }}
            onRemove={() => {
              setHmqFileDetails({ file: null, fileList: [] });
              setHmqSheetCellErrors([]);
              return false;
            }}
            fileList={hmqFileDetails.fileList}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>

          {hmqSheetCellErrors.length > 0 && (
            <ul style={{ marginTop: 10 }}>
              {hmqSheetCellErrors.map((item) => (
                <li key={item.address}>
                  {item.address} - {item.error}
                </li>
              ))}
            </ul>
          )}

          <Button
            type="primary"
            style={{ marginTop: hmqSheetCellErrors.length > 0 ? 10 : 20, marginBottom: 15 }}
            disabled={hmqFileDetails.file ? hmqSheetCellErrors.length > 0 : true}
            loading={isUploadButtonLoading}
            onClick={async () => {
              setIsUploadButtonLoading(true);

              try {
                // mutation to log console to AWS cloudwatch
                await cloudwatchLogs({
                  variables: {
                    args: {
                      logGroupName: logGroupNameEcp29apps,
                      logStreamName: logStreamNameSampleManagement,
                      logData: [
                        {
                          sourcecode_file_name: 'SamplesLibraryPreparation',
                          sourcecode_function_name: 'onClickOfUploadButton',
                          platform: PlatformType.WebApp,
                          message: 'Request data to update kit DNA library preparation details',
                          data: JSON.stringify({
                            requestData: kitDnaPrepLibPrepDetails,
                            endpoint: updateKitDnaPrepLibPrepDetailsURL,
                            method: 'PUT',
                          }),
                          event: 'onClickOfUploadButton',
                          timestamp: new Date().toISOString(),
                        },
                      ],
                    },
                  },
                });
              } catch (err) {
                logger(err as Error);
              }

              /*  API to update kit details like extractionDateTime, libPrepDateTime, sampleValid fields using their kitID
                for multiple kits in bulk */
              updateKitDnaPrepLibPrepDetails(kitDnaPrepLibPrepDetails)
                .then((res) => {
                  setIsUploadButtonLoading(false);
                  // resetting file details to null on successful upload.
                  setHmqFileDetails({ file: null, fileList: [] });
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  message.success('Kits updated successfully!');
                })
                .catch((err) => {
                  setIsUploadButtonLoading(false);
                  logger(err as Error);
                });
            }}
          >
            Upload
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: 25 }}>
        <Col span={12} style={{ padding: '0px 10px' }}>
          <h2>Kits without summary data (metadata)</h2>
          <Table<TableDataType>
            rowKey={(record) => record.kitID}
            bordered
            dataSource={incompleteSummaryKitsTableData}
            pagination={{
              defaultPageSize: 50,
              defaultCurrent: 1,
            }}
            loading={isTableDataLoading}
            size="small"
          >
            <Column<TableDataType> title="Kit ID" dataIndex="kitID" key="kitID" />
            <Column<TableDataType>
              title="Received date"
              dataIndex="recievedDate"
              key="recievedDate"
            />
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default SamplesLibraryPreparation;
