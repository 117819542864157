import React, { useEffect, useState } from 'react';
import { Select, Button, message, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/client';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AppLayout from '../components/AppLayout';
import EcFormItem from '../components/EcFormItem';
import {
  AllTaxonomyClassifiersQuery,
  AllTaxonomyClassifiersQueryVariables,
  GetHmqTaxonomyClassifierS3KeyQuery,
  GetHmqTaxonomyClassifierS3KeyQueryVariables,
  UpdateTaxonomyClassifierS3KeyMutation,
  UpdateTaxonomyClassifierS3KeyMutationVariables,
} from '../graphql/graphql-types';
import { logger } from '../utils/helpers';

/* All taxonomy classifiers fetch query */
const allTaxonomyClassifiersQuery = loader(
  '../graphql/queries/allTaxonomyClassifiersQuery.graphql',
);

/* HMQ taxonomy classifier's s3 key fetch query */
const getHmqTaxonomyClassifierS3KeyQuery = loader(
  '../graphql/queries/getHmqTaxonomyClassifierS3KeyQuery.graphql',
);

/* Mutation to update taxonomy classifiers setting */
const updateTaxonomyClassifierS3KeyMutation = loader(
  '../graphql/mutations/updateTaxonomyClassifierS3Key.graphql',
);

/* Type definition for HMQ settings form */
type HmqSettingsFormType = {
  /* Taxonomy classifier's S3 Key */
  taxonomyClassifierS3Key: string | null;
};

/* Form validation schema */
const validationSchema = yup.object().shape({
  taxonomyClassifierS3Key: yup
    .string()
    .required('Please select classifier and try again')
    .nullable(),
});

/* React functional component */
const HmqSettings = (): JSX.Element => {
  /* State used to decide whether to show/hide loading indicator on 'Save settings' btn */
  const [saveSettingsBtnLoading, setSaveSettingsBtnLoading] = useState<boolean>(false);

  /* Query to fetch all taxonomy classifier's data */
  const { data, loading, error } = useQuery<
    AllTaxonomyClassifiersQuery,
    AllTaxonomyClassifiersQueryVariables
  >(allTaxonomyClassifiersQuery);

  /* Query to fetch all taxonomy classifier's data */
  const { data: hmqSettingsData, loading: hmqSettingsLoading, error: hmqSettingsError } = useQuery<
    GetHmqTaxonomyClassifierS3KeyQuery,
    GetHmqTaxonomyClassifierS3KeyQueryVariables
  >(getHmqTaxonomyClassifierS3KeyQuery, { fetchPolicy: 'network-only' });

  /* Mutation to update HMQ taxonomy classifier S3 key setting */
  const [updateTaxonomyClassifierS3Key] = useMutation<
    UpdateTaxonomyClassifierS3KeyMutation,
    UpdateTaxonomyClassifierS3KeyMutationVariables
  >(updateTaxonomyClassifierS3KeyMutation);

  /* useForm declaration */
  const { control, handleSubmit, errors, reset } = useForm<HmqSettingsFormType>({
    defaultValues: { taxonomyClassifierS3Key: null },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  /* useEffect to add default value to the select if HMQ settings are already set by user previously  */
  useEffect(() => {
    if (
      hmqSettingsData &&
      Array.isArray(hmqSettingsData.settings) &&
      hmqSettingsData.settings.length > 0 &&
      hmqSettingsData.settings[0]
    ) {
      reset({ taxonomyClassifierS3Key: hmqSettingsData.settings[0].value || null });
    }
  }, [hmqSettingsData, reset]);

  /* Showing loading indicator while fetching the data */
  if (hmqSettingsLoading) {
    return (
      <AppLayout screenTitle="HMQ Settings">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  /* Showing error if any occurs while fetching data */
  if (error || hmqSettingsError) {
    /* Variable to store query error */
    let queryError = error;

    /* If hmq settings error is present then we will show that error */
    if (hmqSettingsError) {
      queryError = hmqSettingsError;
    }

    return (
      <AppLayout screenTitle="HMQ Settings">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError?.message}</p>
      </AppLayout>
    );
  }

  return (
    <AppLayout screenTitle="HMQ Settings">
      <form
        onSubmit={handleSubmit((formData) => {
          if (formData.taxonomyClassifierS3Key) {
            setSaveSettingsBtnLoading(true);

            updateTaxonomyClassifierS3Key({
              variables: {
                hmq_taxonomy_classifier_s3_key: formData.taxonomyClassifierS3Key,
              },
            })
              .then(() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.success('HMQ settings updated successfully.');
                setSaveSettingsBtnLoading(false);
              })
              .catch((err) => {
                logger(err);
                setSaveSettingsBtnLoading(false);
              });
          }
        })}
      >
        <EcFormItem
          helpText="The taxonomy classifier to use for automated MDSA analysis of Horse MQ samples"
          label="Taxonomy classifier"
          name="taxonomyClassifierS3Key"
          isFieldRequired
          control={control}
          errors={errors}
          as={
            <Select
              loading={loading}
              placeholder="Select classifier"
              style={{ width: 350 }}
              options={
                data &&
                Array.isArray(data.taxonomy_classifier) &&
                data.taxonomy_classifier.length > 0
                  ? data.taxonomy_classifier.map((item) => ({
                      label: item.name,
                      value: item.s3_key,
                    }))
                  : []
              }
            />
          }
        />
        <Button
          style={{ marginTop: 35 }}
          type="primary"
          htmlType="submit"
          loading={saveSettingsBtnLoading}
        >
          Save settings
        </Button>
      </form>
    </AppLayout>
  );
};

export default HmqSettings;
