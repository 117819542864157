import React, { useState } from 'react';
import { Button, Table } from 'antd';
import AppLayout from '../components/AppLayout';
import { logger } from '../utils/helpers';
import { SrSamples } from '../graphql/graphql-types';
import { SuccessDataOfSamplesType } from '../utils/types';

// type definition for ErrorSequencingRunSamplesDetails props
type ErrorSequencingRunSamplesDetailsPropsType = {
  // type definition for selected sequencer run output directory
  selectedSequencerRunOutputDir: string;
  // type definition to set sequencer run success sample details
  setSequencerRunSamplesSuccessDetails: React.Dispatch<
    React.SetStateAction<SuccessDataOfSamplesType | undefined>
  >;
  // type definition for list of run samples having error (either forward or reverse fastq file missing)
  listOfRunSamplesWithError: Array<SrSamples>;
};

// type definition for a single sample from run output data
type SampleFromRunOutputDataType = Array<SrSamples>[0];

// extracting Column from Antd Table
const { Column } = Table;

const ErrorSequencingRunSamplesDetails = ({
  selectedSequencerRunOutputDir,
  setSequencerRunSamplesSuccessDetails,
  listOfRunSamplesWithError,
}: ErrorSequencingRunSamplesDetailsPropsType): JSX.Element => {
  // state to show loading indicator on reload fastq button
  const [reloadFastqBtnLoading, setReloadFastqBtnLoading] = useState<boolean>(false);

  return (
    <AppLayout screenTitle="Create Sequencing Run(s)">
      <>
        <h2>Issues in fastq files</h2>
        <p>There were errors in following samples:</p>
        <Table<SampleFromRunOutputDataType>
          style={{ width: 550, marginBottom: '20px' }}
          pagination={false}
          bordered
          dataSource={listOfRunSamplesWithError}
          rowKey="sample_id"
        >
          <Column<SampleFromRunOutputDataType>
            key="sample_id"
            dataIndex="sample_id"
            title="Sample Id"
          />
          <Column<SampleFromRunOutputDataType>
            key="forwardFastqFile"
            dataIndex="forwardFastqFile"
            render={(value: string) =>
              value || <span style={{ fontWeight: 'bold' }}>File not found</span>
            }
            title="Forward reads"
          />
          <Column<SampleFromRunOutputDataType>
            key="reverseFastqFile"
            dataIndex="reverseFastqFile"
            render={(value: string) =>
              value || <span style={{ fontWeight: 'bold' }}>File not found</span>
            }
            title="Reverse reads"
          />
        </Table>
        <p>
          Please add the files in the {selectedSequencerRunOutputDir} sequencer run's fast directory
        </p>
        <Button
          type="primary"
          loading={reloadFastqBtnLoading}
          style={{ marginTop: '10px' }}
          onClick={() => {
            setReloadFastqBtnLoading(true);
            if (window.ecp29Desktop) {
              //  sequencer run samples details for the selected date.
              window.ecp29Desktop
                .getSequencerRunSamplesInfo(selectedSequencerRunOutputDir)
                .then((res) => {
                  if (!res) {
                    setReloadFastqBtnLoading(false);
                    return;
                  }
                  setSequencerRunSamplesSuccessDetails(res.successData);
                  setReloadFastqBtnLoading(false);
                })
                .catch((err: Error) => {
                  setReloadFastqBtnLoading(false);
                  logger(err);
                });
            }
          }}
        >
          Reload FASTQ files
        </Button>
      </>
    </AppLayout>
  );
};

export default ErrorSequencingRunSamplesDetails;
