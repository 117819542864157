import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Button, Table, Spin } from 'antd';
import {
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
  UpdateMdsaTaskOutDataMutation,
  UpdateMdsaTaskOutDataMutationVariables,
} from '../../graphql/graphql-types';
import { logger } from '../../utils/helpers';

// call to graphql files using loader
const getFilesUrlQuery = loader('../../graphql/queries/getFilesUrlQuery.graphql');
const updateMdsaTaskOutDataMutation = loader(
  '../../graphql/mutations/updateMdsaTaskOutDataMutation.graphql',
);

// type definition for BlastJsonResultEntry
type BlastJsonResultEntryType = {
  qseqid: string;
  saccver: string;
  stitle: string;
  ssciname: string;
  evalue: number;
  bitscore: number;
  pident: number;
  length: number;
  staxid: string;
};

// type definition for BlastResultData
interface BlastResultDataType extends BlastJsonResultEntryType {
  // indicates key
  key: string;
}
// definition for props
type BlastFeatureMappingPropsType = {
  // indicates s3key of file
  s3Key: string;
  // indicates task id
  taskId: string;
};

const BlastFeatureMapping: React.FC<BlastFeatureMappingPropsType> = ({ s3Key, taskId }) => {
  // stores the data to be displayed in table
  const [blastResultData, setBlastResultData] = useState<
    { key: string; data: BlastResultDataType[] }[]
  >([]);

  // stores the value of rows selected
  const [selectedRows, setSelectedRows] = useState<
    { featureId: string; data: BlastJsonResultEntryType }[]
  >([]);

  // indicates whether to show loading button
  const [showButtonLoading, setShowButtonLoading] = useState<boolean>(false);

  // calls getFilesUrlQuery
  const { loading, error } = useQuery<GetFilesUrlQuery, GetFilesUrlQueryVariables>(
    getFilesUrlQuery,
    {
      variables: { keys: [s3Key] },
      onCompleted: (data) => {
        // stores the value of file url obtained
        const url =
          data && data.getFilesUrl && data.getFilesUrl[0] && data.getFilesUrl[0].url
            ? data.getFilesUrl[0].url
            : undefined;
        if (url) {
          // fetches data from file
          fetch(url)
            .then((result) => result.json())
            .then((output: { [key: string]: BlastJsonResultEntryType[] }) => {
              // stores the data in key data format
              const blastResultDataToSet = Object.keys(output).map((item) => {
                return {
                  key: item,
                  // added the key  which is used for unique identification in row selection of table
                  data: output[item].map((element, index) => {
                    return { ...element, key: `${element.saccver}_${index}` };
                  }),
                };
              });
              setBlastResultData(blastResultDataToSet);
            })
            .catch((err) => {
              logger(err);
            });
        }
      },
    },
  );

  // mutation to update mdsa task out data
  const [updateMdsaTaskOutData] = useMutation<
    UpdateMdsaTaskOutDataMutation,
    UpdateMdsaTaskOutDataMutationVariables
  >(updateMdsaTaskOutDataMutation);

  // displayed when data is loading
  if (loading) {
    return <Spin />;
  }
  // if error in fetching query
  if (error) {
    <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>;
  }

  return (
    <>
      {blastResultData.map((item) => {
        return (
          <div key={item.key}>
            <p>
              <strong>Feature ID: </strong>
              {item.key}
            </p>
            <Table
              rowSelection={{
                type: 'radio',
                onChange: (updatedRowKeys, selectedRow) => {
                  // removes the data if already present for specific feature id
                  const filteredRows = selectedRows.filter((row) => row.featureId !== item.key);
                  // updates the state with selected row
                  if (Array.isArray(filteredRows) && filteredRows.length > 0) {
                    setSelectedRows([
                      ...filteredRows,
                      { featureId: item.key, data: selectedRow[0] },
                    ]);
                  } else {
                    setSelectedRows([{ featureId: item.key, data: selectedRow[0] }]);
                  }
                },
              }}
              rowKey="key"
              size="small"
              key={item.key}
              columns={[
                {
                  title: 'Accession',
                  key: 'saccver',
                  dataIndex: 'saccver',
                  render: (value) => (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={`https://www.ncbi.nlm.nih.gov/nucleotide/${value as string}`}
                    >
                      {value}
                    </a>
                  ),
                  width: 120,
                },
                { title: 'Description', key: 'stitle', dataIndex: 'stitle' },
                { title: 'Scientific Name', key: 'ssciname', dataIndex: 'ssciname' },
                { title: 'E value', key: 'evalue', dataIndex: 'evalue' },
                { title: 'Score', key: 'bitscore', dataIndex: 'bitscore', width: 50 },
                { title: '% identity', key: 'pident', dataIndex: 'pident', width: 75 },
                { title: 'Alignment Length', key: 'length', dataIndex: 'length', width: 70 },
              ]}
              bordered
              dataSource={item.data}
            />
          </div>
        );
      })}
      <Button
        type="primary"
        loading={showButtonLoading}
        onClick={() => {
          setShowButtonLoading(true);
          updateMdsaTaskOutData({
            variables: {
              taskId: parseInt(taskId, 10),
              outData: selectedRows.map((item) => {
                return {
                  featureId: item.featureId,
                  saccver: item.data.saccver,
                  ssciname: item.data.ssciname,
                  staxid: item.data.staxid,
                };
              }),
            },
          })
            .then(() => {
              setShowButtonLoading(false);
            })
            .catch(() => {
              setShowButtonLoading(false);
            });
        }}
        // atleast one row should be selected for each feature id until row is selected for each id button is kept disabled
        disabled={selectedRows.length !== blastResultData.length}
      >
        Assign
      </Button>
    </>
  );
};

export default BlastFeatureMapping;
