import React from 'react';
import ProjectForm from '../forms/ProjectForm';
import AppLayout from '../components/AppLayout';
import '../utils/index.css';

const CreateProjectScreen: React.FC = () => {
  return (
    <AppLayout screenTitle="Create New Project">
      <ProjectForm mode="create" />
    </AppLayout>
  );
};
export default CreateProjectScreen;
