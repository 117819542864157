import React, { useState } from 'react';
import { Button, Select, Spin, message } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useNavigate, useParams, Link } from 'react-router-dom';
import {
  AllTaxonomyClassifiersQueryVariables,
  AllTaxonomyClassifiersQuery,
  UpdateAndStartMdsaTaskMutation,
  UpdateAndStartMdsaTaskMutationVariables,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import { logger } from '../utils/helpers';

// call to graphql files
const allTaxonomyClassifiersQuery = loader(
  '../graphql/queries/allTaxonomyClassifiersQuery.graphql',
);

const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);

// react functional component
const MdsaTaxonomicClassification: React.FC = () => {
  // useState to set selected taxonomy classifier id
  const [selectedTaxonomyClassifierId, setSelectedTaxonomyClassifierId] = useState<number | null>(
    null,
  );
  // useState to set started_at value for startMdsaTaxonomicClassificationMutation variable
  const [started_at] = useState<Date>(new Date());
  // useState to decide whether to display select box required error or not
  const [requiredError, setRequiredError] = useState<boolean>(false);
  // useState to set loading on start analysis button
  const [isStartAnalysisButtonLoading, setIsStartAnalysisLoading] = useState<boolean>(false);

  // use query for fetching data of taxonomy classifier
  const { data, loading, error } = useQuery<
    AllTaxonomyClassifiersQuery,
    AllTaxonomyClassifiersQueryVariables
  >(allTaxonomyClassifiersQuery, { fetchPolicy: 'network-only' });

  /* Mutation to update and start mdsa task */
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  // extracting id, task id from url
  const { id, taskId } = useParams();

  const navigate = useNavigate();

  // while loading query data
  if (loading) {
    return (
      <AppLayout screenTitle="Taxonomic Classification">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }
  // if error in fetching query data
  if (error) {
    return (
      <AppLayout screenTitle="Taxonomic Classification">
        <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
      </AppLayout>
    );
  }

  // The list of taxonomy classifiers for the select options
  const taxonomyClassifiers = data?.taxonomy_classifier;

  // When no classifier is available show a message to the user
  if (!Array.isArray(taxonomyClassifiers) || taxonomyClassifiers.length === 0) {
    return (
      <AppLayout screenTitle="Taxonomic Classification">
        <>
          <h3 style={{ color: 'red' }}>
            No classifier available. Please upload a classifier to continue with the analysis.
          </h3>
          <Button type="default">
            <Link to="/taxonomyClassifiers">Upload Classifier</Link>
          </Button>
        </>
      </AppLayout>
    );
  }

  return (
    <AppLayout screenTitle="Taxonomic Classification">
      <>
        <p>Classifier*</p>
        <Select
          style={{ width: '250px' }}
          showSearch
          onChange={(e: number) => {
            setSelectedTaxonomyClassifierId(e);
            setRequiredError(false);
          }}
          placeholder="Select Classifier"
          optionFilterProp="children"
          filterOption={(input, option): boolean => {
            const childrenOption = option?.children as string;
            const valueOption = option?.value as number;
            if (childrenOption && valueOption) {
              return (
                childrenOption.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                valueOption.toString().indexOf(input) >= 0
              );
            }
            return false;
          }}
        >
          {taxonomyClassifiers.map(
            (item): JSX.Element => (
              <Select.Option value={item.id} key={item.id}>
                {item.name}
              </Select.Option>
            ),
          )}
        </Select>
        {requiredError ? (
          <p style={{ fontSize: 12, color: 'red' }}>
            Please select taxonomy classifier and try again
          </p>
        ) : null}
        <div>
          <Button
            type="primary"
            style={{ marginTop: 20 }}
            onClick={() => {
              if (selectedTaxonomyClassifierId !== null) {
                setIsStartAnalysisLoading(true);
                if (Array.isArray(taxonomyClassifiers) && taxonomyClassifiers.length > 0) {
                  /* S3 key of selected taxonomy classifier */
                  const selectedTaxonomyClassifierS3Key = taxonomyClassifiers.find(
                    (item) => item.id === selectedTaxonomyClassifierId,
                  )?.s3_key;
                  if (selectedTaxonomyClassifierS3Key) {
                    /* Call to mutation */
                    updateAndStartMdsaTask({
                      variables: {
                        taskId: parseInt(taskId, 10),
                        taskUpdateInput: {
                          started_at,
                          in_files: [
                            {
                              name: 'classifier.qza',
                              s3_key: selectedTaxonomyClassifierS3Key,
                              param_key: '--i-classifier',
                            },
                          ],
                        },
                      },
                    })
                      .then(() => {
                        setIsStartAnalysisLoading(false);
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        message.success('Taxonomy classification analysis started');
                        navigate(`/mdsa/${parseInt(id, 10)}`);
                      })
                      .catch((err) => {
                        setIsStartAnalysisLoading(false);
                        logger(err);
                      });
                  }
                }
              } else {
                setRequiredError(true);
              }
            }}
            loading={isStartAnalysisButtonLoading}
          >
            Start Analysis
          </Button>
        </div>
      </>
    </AppLayout>
  );
};

export default MdsaTaxonomicClassification;
