import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Input, Radio, Select, Table, notification } from 'antd';
import { useFieldArray, useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { loader } from 'graphql.macro';
import {
  GetMdsaMetadataColumnsByPkQuery,
  GetMdsaMetadataColumnsByPkQueryVariables,
  MetadataColumn,
  MetadataColumnTypeEnum,
} from '../graphql/graphql-types';
import EcFormItem from '../components/EcFormItem';
import AppLayout from '../components/AppLayout';

import { UpdateMetadataNewColumnType } from '../utils/types';
import { notAllowedColumnNames } from '../utils/globals';

const getMdsaMetadataColumnsByPk = loader(
  '../graphql/queries/getMdsaMetadataColumnsByPkQuery.graphql',
);

// addColumnsToMdsaMetadataForm  type declaration
type AddColumnsToMdsaMetadataFormType = {
  newColumns: Array<UpdateMetadataNewColumnType>;
};

// form prop type
type AddColumnsToMdsaMetadataFormPropType = {
  // type to set the state which will determine which screen to open
  setMetadataScreenToRender: React.Dispatch<React.SetStateAction<'upload' | 'confirm' | 'form'>>;
  // type to set state which store formData
  setFormData: React.Dispatch<React.SetStateAction<UpdateMetadataNewColumnType[]>>;
};

// extracting columns from antd table
const { Column } = Table;

// yup schema for addColumnsToMdsaMetadataForm
const schema = yup.object({
  newColumns: yup.array(
    yup.object({
      alterTableBy: yup.string().oneOf(['new_column', 'combine_columns']),
      column: yup
        .string()
        .matches(/^[a-z][a-z\d-]*[a-z\d]$/, {
          excludeEmptyString: true,
          message: 'Only lower case alphabets, hyphen (-) and numbers are allowed',
        })
        .notOneOf(
          notAllowedColumnNames,
          "You cannot use ‘id’, ‘sampleid’, ‘sample id’, ‘sample-id’ as column names'",
        )
        .test(
          'uniqueColumnName',
          'The column name should not be same as any of the existing columns',
          // function to check entered column name value already exist or not
          function test(value) {
            // function to store existing column names
            const existingColumnName = this.options.context?.existingMetadataColumns as string[];
            return existingColumnName && value ? !existingColumnName.includes(value) : false;
          },
        )
        .required('Please enter column name and try again'),
      combinedColumnsKey: yup.array().when('alterTableBy', {
        is: (val: 'new_column' | 'combine_columns') => val === 'combine_columns',
        then: () =>
          yup
            .array()
            .min(2, 'Please select at least two columns to combine')
            .max(3, 'Maximum of three columns can be combined'),
      }),
    }),
  ),
});

//  react functional component
const AddColumnsToMetadataForm: React.FC<AddColumnsToMdsaMetadataFormPropType> = ({
  setFormData,
  setMetadataScreenToRender,
}) => {
  // Extracting id, task id from url param
  const { id } = useParams();

  // Query to fetch mdsa data for current analysis
  const {
    data: mdsaMetadataColumnsQueryData,
    loading: mdsaMetadataColumnsQueryLoading,
    error: mdsaMetadataColumnsQueryError,
  } = useQuery<GetMdsaMetadataColumnsByPkQuery, GetMdsaMetadataColumnsByPkQueryVariables>(
    getMdsaMetadataColumnsByPk,
    {
      variables: {
        id: parseInt(id, 10),
      },
      fetchPolicy: 'network-only',
    },
  );
  // const to store mdsa metadata columns which will get from query data
  const existingMetadataColumns = mdsaMetadataColumnsQueryData?.mdsa_by_pk
    ?.metadata_columns as Array<MetadataColumn>;

  // declaring useForm instance to add new columns to the mdsa metadata
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, handleSubmit, errors, watch, clearErrors, setValue } = useForm<
    AddColumnsToMdsaMetadataFormType
  >({
    defaultValues: {
      newColumns: [
        {
          column: '',
          alterTableBy: 'new_column',
          type: MetadataColumnTypeEnum.Categorical,
          combinedColumnsKey: [],
        },
      ],
    },
    resolver: yupResolver(schema),
    context: {
      existingMetadataColumns:
        existingMetadataColumns && existingMetadataColumns.map((ele) => ele.column),
    },
  });

  // declaring instance useField array
  const { fields, append, remove } = useFieldArray<UpdateMetadataNewColumnType>({
    control,
    name: 'newColumns',
  });

  // func will be called on click of enter values btn which set formData state and make mdsa metadata upload screen visible by setting isMdsaMetadataVisible state
  const onSubmit = (formSubmitData: AddColumnsToMdsaMetadataFormType) => {
    setFormData(formSubmitData.newColumns);
    setMetadataScreenToRender('upload');
  };

  // if error in fetching query
  if (mdsaMetadataColumnsQueryError) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{mdsaMetadataColumnsQueryError.message}</p>
      </AppLayout>
    );
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, () => {
        notification.error({
          message: 'Errors in form',
          description: 'Some errors were detected in the form. Please resolve them and try again.',
        });
      })}
    >
      <p>
        Provide the details of all the new columns that you wish to add in the existing metadata
        file. You can combine existing columns or create a new column altogether.
      </p>
      <Alert
        message="When combining columns we will use '_' to combine the values of all the columns selected"
        type="info"
        showIcon
        style={{ width: '55%' }}
      />
      <Table
        style={{ marginTop: 20, marginBottom: 20 }}
        pagination={false}
        bordered
        dataSource={fields}
        rowKey="id"
      >
        {/* alter table by column */}
        <Column
          key="alterTableBy"
          dataIndex="alterTableBy"
          title="Alter table by"
          render={(text, record, index) => {
            return (
              <EcFormItem
                name={`newColumns[${index}].alterTableBy`}
                control={control}
                defaultValue="new_column"
                render={({ onChange, value }): JSX.Element => (
                  <Radio.Group
                    onChange={(e) => {
                      if (e.target.value === 'new_column') {
                        clearErrors(`newColumns[${index}].combinedColumnsKey`);
                        setValue(`newColumns[${index}].combinedColumnsKey`, []);
                      }
                      //  condition because we want type of combinedColumns to be categorical
                      if (e.target.value === 'combine_columns') {
                        setValue(`newColumns[${index}].type`, MetadataColumnTypeEnum.Categorical);
                      }
                      onChange(e.target.value);
                    }}
                    value={value as string}
                  >
                    <Radio value="new_column">New Column</Radio>
                    <Radio value="combine_columns">Combine columns</Radio>
                  </Radio.Group>
                )}
              />
            );
          }}
        />

        {/* type column */}
        <Column
          key="type"
          dataIndex="type"
          title="Type"
          render={(text, record, index) => {
            return (
              <EcFormItem
                name={`newColumns[${index}].type`}
                control={control}
                defaultValue={MetadataColumnTypeEnum.Categorical}
                render={({ onChange, value }): JSX.Element => (
                  <Radio.Group onChange={(e) => onChange(e.target.value)} value={value as string}>
                    <Radio value={MetadataColumnTypeEnum.Categorical}>Categorical</Radio>
                    <Radio
                      value={MetadataColumnTypeEnum.Numeric}
                      disabled={
                        watch('newColumns')[index] &&
                        watch('newColumns')[index].alterTableBy === 'combine_columns'
                      }
                    >
                      Numeric
                    </Radio>
                  </Radio.Group>
                )}
              />
            );
          }}
        />

        {/* Column name column */}
        <Column
          key="column"
          dataIndex="column"
          title="Column name"
          width={300}
          render={(text, record, index) => {
            return (
              <EcFormItem
                name={`newColumns[${index}].column`}
                control={control}
                errors={errors}
                defaultValue=""
                as={<Input placeholder="Column Name" allowClear />}
              />
            );
          }}
        />

        {/* Columns to combine column */}
        <Column
          key="combinedColumnsKey"
          dataIndex="combinedColumnsKey"
          title="Columns To Combine"
          render={(text, record, index) => {
            return (
              <EcFormItem
                name={`newColumns[${index}].combinedColumnsKey`}
                control={control}
                errors={errors}
                defaultValue={[]}
                as={
                  <Select
                    placeholder="Select mdsa metadata columns"
                    style={{ width: 250 }}
                    showArrow
                    mode="multiple"
                    disabled={
                      watch('newColumns')[index] &&
                      watch('newColumns')[index].alterTableBy !== 'combine_columns'
                    }
                    loading={mdsaMetadataColumnsQueryLoading}
                  >
                    {existingMetadataColumns && existingMetadataColumns.length > 0
                      ? existingMetadataColumns.map((ele) => {
                          return (
                            <Select.Option value={ele.column} key={ele.column}>
                              {ele.column}
                            </Select.Option>
                          );
                        })
                      : []}
                  </Select>
                }
              />
            );
          }}
        />

        {/* action column */}
        <Column
          key="actions"
          dataIndex="actions"
          title="Actions"
          render={(text, record, index) => {
            return (
              <Button
                onClick={(): void => {
                  remove(index);
                }}
              >
                Delete
              </Button>
            );
          }}
        />
      </Table>
      {/* button to append new column */}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          style={{ marginBottom: 20 }}
          icon={<PlusOutlined style={{ color: 'grey' }} />}
          onClick={(): void => {
            append({
              column: '',
              alterTableBy: 'new_column',
              type: MetadataColumnTypeEnum.Categorical,
              combinedColumnsKey: [],
            });
          }}
        >
          New column
        </Button>
      </div>

      {/* button to make table visible */}
      <Button type="primary" htmlType="submit" style={{ marginTop: 10 }}>
        Enter values
      </Button>
    </form>
  );
};

export default AddColumnsToMetadataForm;
