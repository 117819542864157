import React, { useState } from 'react';
import { Button, Spin, Table, message, Popconfirm, Space, Col, Row } from 'antd';
import { EditFilled, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useQuery, useMutation, Reference, useApolloClient } from '@apollo/client';
import {
  ViewProjectQuery,
  ViewProjectQueryVariables,
  DeleteProjectMutation,
  DeleteProjectMutationVariables,
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
  MetadataColumn,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import { logger } from '../utils/helpers';
import styles from './ScreenStyles.module.scss';

const { Column } = Table;

const viewProjectQuery = loader('../graphql/queries/viewProjectQuery.graphql');
const getFilesUrlQuery = loader('../graphql/queries/getFilesUrlQuery.graphql');
const deleteProjectMutation = loader('../graphql/mutations/deleteProjectMutation.graphql');

// React functional component
const ViewProjectScreen: React.FC = () => {
  // extracted id from url param
  const { id } = useParams();
  const navigate = useNavigate();
  const apolloClient = useApolloClient();

  // State to store the download url of project protocol
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

  // Query to fetch project data
  const { data, loading: queryDataLoading, error: queryError } = useQuery<
    ViewProjectQuery,
    ViewProjectQueryVariables
  >(viewProjectQuery, {
    variables: { id: parseInt(id, 10) },
    onCompleted: (projectQueryData) => {
      /* Query for fetching download url of protocol file */
      const s3Key = projectQueryData.project_by_pk?.protocol_s3_key;
      if (s3Key) {
        apolloClient
          .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
            query: getFilesUrlQuery,
            variables: { keys: [s3Key] },
          })
          .then((fileUrlResponse) => {
            const downloadUrlResponse = fileUrlResponse.data.getFilesUrl;
            if (
              downloadUrlResponse &&
              Array.isArray(downloadUrlResponse) &&
              downloadUrlResponse.length > 0
            ) {
              /* File download url */
              const url = downloadUrlResponse[0]?.url;
              if (url) {
                setDownloadUrl(url);
              }
            }
          })
          .catch((fileUrlError) => {
            logger(fileUrlError);
          });
      }
    },
  });

  // Mutation for delete project
  const [deleteProject] = useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(
    deleteProjectMutation,
  );

  // if any error occured while fetching data will show error message
  if (queryError) {
    return (
      <AppLayout screenTitle="View all Projects">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  // delete project
  const handleDeleteProject = (projectId: number): void => {
    deleteProject({
      variables: { id: projectId },
      // update cache on delete
      update: (cache, { data: deletedData }) => {
        cache.modify({
          fields: {
            project(existingRefs: Array<Reference>, { readField }) {
              const idToFilter = deletedData?.delete_project_by_pk?.id;
              if (idToFilter) {
                return existingRefs.filter((ref) => idToFilter !== readField('id', ref));
              }
              return existingRefs;
            },
          },
        });
      },
    })
      .then((mutationResponse) => {
        const response = mutationResponse?.data?.delete_project_by_pk;
        if (response) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Deleted successfully');
          navigate('/projects');
        }
      })
      .catch((mutationError) => {
        logger(mutationError);
      });
  };

  // Rendering first row which is static while fetching data
  const renderProjectIdRow = (): JSX.Element => {
    return (
      <Space align="center" size="large">
        <p style={{ margin: 0, padding: 0 }}>
          <strong>Project ID: </strong>
          {id}
        </p>
        <Button
          icon={<EditFilled />}
          type="primary"
          onClick={() => {
            navigate(`/project/${id}/edit`);
          }}
        >
          Edit
        </Button>
        <Popconfirm
          title="Are you sure delete this run?"
          onConfirm={() => {
            handleDeleteProject(parseInt(id, 10));
          }}
          okText="Yes"
          cancelText="No"
          icon={<DeleteOutlined style={{ color: 'red' }} />}
        >
          <Button icon={<DeleteOutlined />}>Delete</Button>
        </Popconfirm>
      </Space>
    );
  };

  // Storing fetched project data
  const projectData = data?.project_by_pk;

  /* if statement used to check whether data is loaded successfully and return to show fetched data */
  if (projectData && !queryDataLoading) {
    // destructuring of all data
    const {
      created_at,
      created_by,
      description,
      start_date,
      end_date,
      species,
      business_unit,
      tags,
      title,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      qiime_metadata_columns,
      protocol_s3_key,
    } = projectData;

    return (
      <AppLayout screenTitle="View Project">
        <>
          {renderProjectIdRow()}
          <div style={{ paddingTop: 20 }}>
            <Row gutter={[3, 15]}>
              <Col sm={24} lg={4} className={styles.viewProjectScreenLabel}>
                Created On:
              </Col>
              <Col sm={24} lg={12}>
                {moment(created_at).format('DD MMM YYYY')} at {moment(created_at).format('HH:mm A')}
              </Col>
            </Row>
            <Row gutter={[3, 15]}>
              <Col sm={24} lg={4} className={styles.viewProjectScreenLabel}>
                Created by:
              </Col>
              <Col sm={24} lg={12}>
                {created_by ? created_by.first_name.concat(' ', created_by.last_name) : 'N/A'}
              </Col>
            </Row>
            <Row gutter={[3, 15]}>
              <Col sm={24} lg={4} className={styles.viewProjectScreenLabel}>
                Title
              </Col>
              <Col sm={24} lg={12}>
                {title}
              </Col>
            </Row>
            <Row gutter={[3, 15]}>
              <Col sm={24} lg={4} className={styles.viewProjectScreenLabel}>
                Description:
              </Col>
              <Col sm={24} lg={12}>
                {description ?? 'N/A'}
              </Col>
            </Row>
            <Row gutter={[3, 15]}>
              <Col sm={24} lg={4} className={styles.viewProjectScreenLabel}>
                Start Date:
              </Col>
              <Col sm={24} lg={12}>
                {start_date ? moment(start_date).format('DD MMM YYYY') : 'N/A'}
              </Col>
            </Row>
            <Row gutter={[3, 15]}>
              <Col sm={24} lg={4} className={styles.viewProjectScreenLabel}>
                End Date:
              </Col>
              <Col sm={24} lg={12}>
                {end_date ? moment(end_date).format('DD MMM YYYY') : 'N/A'}
              </Col>
            </Row>
            <Row gutter={[3, 15]}>
              <Col sm={24} lg={4} className={styles.viewProjectScreenLabel}>
                Species:
              </Col>
              <Col sm={24} lg={12}>
                {species}
              </Col>
            </Row>
            <Row gutter={[3, 15]}>
              <Col sm={24} lg={4} className={styles.viewProjectScreenLabel}>
                Business Unit:
              </Col>
              <Col sm={24} lg={12}>
                {business_unit ?? 'N/A'}
              </Col>
            </Row>
            <Row gutter={[3, 15]}>
              <Col sm={24} lg={4} className={styles.viewProjectScreenLabel}>
                Tags/Keywords:
              </Col>
              <Col sm={24} lg={12}>
                {tags && tags !== '' ? tags : 'N/A'}
              </Col>
            </Row>
            <Row gutter={[3, 15]}>
              <Col sm={24} lg={4} className={styles.viewProjectScreenLabel}>
                Protocol:
              </Col>
              <Col sm={24} lg={12}>
                {protocol_s3_key ? (
                  <Button icon={<DownloadOutlined />} href={downloadUrl ?? '#'}>
                    Download File
                  </Button>
                ) : (
                  'N/A'
                )}
              </Col>
            </Row>
            <Row gutter={[3, 15]}>
              <Col sm={24} lg={4} className={styles.viewProjectScreenLabel}>
                Metadata columns:
              </Col>
              <Col sm={24} lg={12}>
                {qiime_metadata_columns ? (
                  <Table<MetadataColumn>
                    dataSource={
                      Array.isArray(qiime_metadata_columns)
                        ? (qiime_metadata_columns as Array<MetadataColumn>)
                        : []
                    }
                    pagination={false}
                    bordered
                    size="small"
                    rowKey="Column"
                    style={{ width: 220 }}
                  >
                    <Column
                      title="Column"
                      key="column"
                      dataIndex="column"
                      render={(column: string) => column ?? 'N/A'}
                    />
                    <Column title="Type" key="type" dataIndex="type" />
                  </Table>
                ) : (
                  'N/A'
                )}
              </Col>
            </Row>
          </div>
        </>
      </AppLayout>
    );
  }
  /* return while query data fetching is in progress (loading indicator) */
  return (
    <AppLayout screenTitle="View Project">
      <>
        {renderProjectIdRow()}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 25,
          }}
        >
          <Spin size="large" />
        </div>
      </>
    </AppLayout>
  );
};

export default ViewProjectScreen;
