/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { graphqlEndpoint } from '../utils/globals';
import { firebaseAuth } from '../utils/firebase';

const authLink = setContext(async (_, { headers }) => {
  // return the headers to the context so httpLink can read them
  const newHeaders = { ...headers };

  if (firebaseAuth && firebaseAuth.currentUser) {
    const token = await firebaseAuth.currentUser.getIdToken();
    newHeaders.authorization = `Bearer ${token}`;
  }

  return {
    headers: newHeaders,
  };
});

const httpLink = createHttpLink({
  uri: graphqlEndpoint,
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      mdsa_sequencing_run: {
        keyFields: ['mdsa_id', 'sr_id'],
      },
    },
  }),
  link: authLink.concat(httpLink),
});

export default client;
