import React, { useState } from 'react';
import { Table, Popconfirm, message, Button, Tooltip, Alert, Tag } from 'antd';
import { DeleteOutlined, SearchOutlined, DeleteFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useMutation, Reference } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  Mdsa,
  Mdsa_Task,
  DeleteMdsaMutation,
  DeleteMdsaMutationVariables,
  AwsBatchJobType,
  Mdsa_Sequencing_Run,
} from '../graphql/graphql-types';
import { mdsaTaskKeyNameMap, colors } from '../utils/globals';
import { logger } from '../utils/helpers';
import ColumnsSearchInput from './ColumnsSearchInput';

const deleteMdsaMutation = loader('../graphql/mutations/deleteMdsaMutation.graphql');

const { Column } = Table;

/* Ongoing mdsa type */
type OngoingMdsaType = Pick<
  Mdsa,
  'id' | 'title' | 'created_at' | 'source_root_mdsa_id' | 'source_mdsa_id'
> & {
  tasks: Array<
    { __typename?: 'mdsa_task' } & Pick<
      Mdsa_Task,
      'id' | 'key' | 'started_at' | 'ended_at' | 'aws_batch_job' | 'skipped'
    >
  >;
} & {
  mdsa_sequencing_runs: Array<
    { __typename?: 'mdsa_sequencing_run' } & Pick<Mdsa_Sequencing_Run, 'mdsa_id' | 'sr_id'>
  >;
};

// type to store prop type of the screen
type OngoingMdsaTableComponentPropType = {
  // prop to store name of the currently active tab
  tabName: 'listView' | 'rootChildView';
  // prop use to store mdsa data which will then pass to table
  mdsaTableData: Array<OngoingMdsaType> | undefined;
};

/* React functional component */
const OngoingMdsaTableComponent: React.FC<OngoingMdsaTableComponentPropType> = ({
  mdsaTableData,
  tabName,
}) => {
  /* Mutation to delete mdsa */
  const [deleteMdsa] = useMutation<DeleteMdsaMutation, DeleteMdsaMutationVariables>(
    deleteMdsaMutation,
  );

  /* State to store selected mdsa id for deletion(for delete button loading) */
  const [selectedMdsaIdForDeletion, setSelectedMdsaIdForDeletion] = useState<number | null>(null);

  /* delete ongoing mdsa */
  const handleDeleteMdsa = (mdsaId: number) => {
    deleteMdsa({
      variables: { id: mdsaId },
      // Update cache on delete
      update: (cache, { data: deletedData }) => {
        cache.modify({
          fields: {
            mdsa(existingRefs: Array<Reference>, { readField }) {
              if (
                deletedData &&
                deletedData.delete_mdsa_by_pk &&
                deletedData.delete_mdsa_by_pk.id
              ) {
                const idToFilter = deletedData.delete_mdsa_by_pk.id;
                return existingRefs.filter((ref) => idToFilter !== readField('id', ref));
              }
              return existingRefs;
            },
          },
        });
      },
    })
      .then((mutationResponse) => {
        if (mutationResponse && mutationResponse.data && mutationResponse.data.delete_mdsa_by_pk) {
          setSelectedMdsaIdForDeletion(null);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Deleted successfully');
        }
      })
      .catch((mutationError) => {
        setSelectedMdsaIdForDeletion(null);
        logger(mutationError);
      });
  };

  // const to store object containing mdsa's whose sourRootMdsaId are same and the key for that element will be sourceRootMdsaId
  const mdsaDataGrpBySourceRootMdsaId = mdsaTableData
    ? mdsaTableData.reduce((accumulator: Record<string, Array<OngoingMdsaType>>, currentValue) => {
        // variable to store object key
        let key = '';
        if (currentValue.source_root_mdsa_id) {
          key = `MDSA${currentValue.source_root_mdsa_id}`;
        } else {
          key = `MDSA${currentValue.id}`;
        }
        // const to store array of element whose source root is equal to key
        const currentGroup = key && accumulator[key] ? accumulator[key] : [];
        return { ...accumulator, [key]: [...currentGroup, currentValue] };
      }, {})
    : {};

  // const will store array which we get by converting object array into a single array.
  // sorting is use as we want to place root mdsa as first element irrespective of its actual position but want to maintain other element sequence as it is
  const mdsaDataForRootChildView = Object.values(mdsaDataGrpBySourceRootMdsaId)
    .map((ele) => {
      return ele.sort((item) => (item.source_root_mdsa_id === null ? -1 : 0));
    })
    .flat(1);

  // function to check whether the particular mdsa is root mdsa or not
  const isRootMdsaFunc = (mdsaId: number): boolean => {
    return Object.keys(mdsaDataGrpBySourceRootMdsaId).includes(`MDSA${mdsaId.toString()}`);
  };

  return (
    <>
      {tabName === 'rootChildView' ? (
        <Alert
          message={
            <span>
              <i>Light grey background row</i> indicates root MDSA
            </span>
          }
          type="info"
          showIcon
          style={{ width: '35%', marginBottom: 10 }}
        />
      ) : null}
      <Table<OngoingMdsaType>
        bordered
        dataSource={
          mdsaTableData && tabName === 'listView' ? mdsaTableData : mdsaDataForRootChildView
        }
        className="ongoingMdsaTableStyle"
        rowKey="id"
        rowClassName={(record) => {
          // running include condition because we want to check if current MDSA is the root MDSA or not”
          if (tabName === 'rootChildView' && isRootMdsaFunc(record.id)) {
            return 'rootRowStyle';
          }
          return '';
        }}
      >
        <Column<OngoingMdsaType>
          title="ID"
          dataIndex="id"
          key="id"
          width={150}
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsSearchInput
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="id"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? colors.tintColor : undefined }} />
          )}
          onFilter={(value, record) =>
            `MDSA-${record.id}`.toLowerCase().includes(value.toString().toLowerCase())
          }
          render={(mdsaId: string, record) => {
            const style: React.CSSProperties = {};
            if (tabName === 'rootChildView') {
              if (isRootMdsaFunc(record.id)) {
                style.fontWeight = 'bold';
              } else {
                style.paddingLeft = 30;
              }
            }
            return (
              <Link to={`/mdsa/${record.id}`}>
                <span style={style}>MDSA-{mdsaId}</span>
              </Link>
            );
          }}
        />

        <Column<OngoingMdsaType>
          title="Title"
          dataIndex="title"
          key="title"
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsSearchInput
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="title"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? colors.tintColor : undefined }} />
          )}
          onFilter={(value, record) =>
            record.title
              ? record.title.toLowerCase().includes(value.toString().toLowerCase())
              : false
          }
        />
        <Column<OngoingMdsaType>
          title="Started On"
          dataIndex="created_at"
          key="startedOn"
          render={(value) => moment(value).format('DD MMM YYYY')}
          width={120}
        />

        <Column<OngoingMdsaType>
          title="Source"
          dataIndex="source"
          key="source"
          width={180}
          filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <ColumnsSearchInput
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="source"
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined style={{ color: filtered ? colors.tintColor : undefined }} />
          )}
          onFilter={(value, record) => {
            if (
              record.source_root_mdsa_id &&
              `MDSA-${record.source_root_mdsa_id}`
                .toLowerCase()
                .includes(value.toString().toLowerCase())
            ) {
              return true;
            }
            if (
              record.source_mdsa_id &&
              `MDSA-${record.source_mdsa_id}`.toLowerCase().includes(value.toString().toLowerCase())
            ) {
              return true;
            }
            return false;
          }}
          render={(value, record) => {
            return (
              <>
                {record.mdsa_sequencing_runs && record.mdsa_sequencing_runs.length > 0 ? (
                  <p style={{ margin: 0, marginBottom: 2 }}>
                    Source SRs:
                    {record.mdsa_sequencing_runs.map((ele, index) => {
                      return (
                        <>
                          <Link to={`/sr/${ele.sr_id}`} style={{ marginLeft: 5 }}>
                            SR-{ele.sr_id}
                          </Link>
                          {record.mdsa_sequencing_runs.length - 1 !== index ? ',' : null}
                        </>
                      );
                    })}
                  </p>
                ) : null}
                {record.source_mdsa_id || record.source_root_mdsa_id ? (
                  <>
                    {record.source_root_mdsa_id ? (
                      <p style={{ margin: 0, marginBottom: 2 }}>
                        Root:
                        <Link to={`/mdsa/${record.source_root_mdsa_id}`} style={{ marginLeft: 5 }}>
                          MDSA-{record.source_root_mdsa_id}
                        </Link>
                      </p>
                    ) : (
                      '-'
                    )}

                    {record.source_mdsa_id ? (
                      <p style={{ margin: 0 }}>
                        Parent:
                        <Link to={`/mdsa/${record.source_mdsa_id}`} style={{ marginLeft: 5 }}>
                          MDSA-{record.source_mdsa_id}
                        </Link>
                      </p>
                    ) : (
                      '-'
                    )}
                  </>
                ) : null}
              </>
            );
          }}
        />

        <Column<OngoingMdsaType>
          title="Latest analysis"
          dataIndex="lastUpdatedAnalysis"
          key="lastUpdatedAnalysis"
          render={(value, record) => {
            const { tasks } = record;
            // variable to store task status
            let taskStatus = 'Ready';
            // variable to store antd tag color string according to task status
            let taskStatusColor = 'default';
            if (Array.isArray(tasks) && tasks.length > 0) {
              const awsBatchJob = tasks[0].aws_batch_job as AwsBatchJobType;
              // logic to determine task status
              if (awsBatchJob && awsBatchJob.status === 'FAILED') {
                taskStatus = 'Failed';
                taskStatusColor = 'error';
              } else if (tasks[0].skipped) {
                taskStatus = 'Skipped';
                taskStatusColor = 'default';
              } else if (tasks[0].started_at !== null && tasks[0].ended_at === null) {
                taskStatus = 'In Progress';
                taskStatusColor = 'processing';
              } else if (tasks[0].ended_at !== null) {
                taskStatus = 'Completed';
                taskStatusColor = 'success';
              }
              /* Last updated analysis derived from the first element in the tasks array */
              return (
                <div>
                  <p style={{ marginBottom: 3 }}>{mdsaTaskKeyNameMap[tasks[0].key]}</p>
                  <Tag color={taskStatusColor}>{taskStatus}</Tag>
                </div>
              );
            }
            return <span style={{ fontStyle: 'italic' }}>Analysis not started</span>;
          }}
        />
        <Column<OngoingMdsaType> title="Remarks" dataIndex="remarks" key="remarks" />
        <Column<OngoingMdsaType>
          title=""
          key="actions"
          width={60}
          render={(value, record) => {
            return (
              // <Space size="small">
              //   <Button type="default">
              //     <Link to={`/mdsa/${record.id}/edit`}>Edit Info</Link>
              //   </Button>
              //   <Popconfirm
              //     title="Are you sure delete this mdsa?"
              //     onConfirm={() => {
              //       setSelectedMdsaIdForDeletion(record.id);
              //       handleDeleteMdsa(record.id);
              //     }}
              //     okText="Yes"
              //     cancelText="No"
              //     icon={<DeleteOutlined style={{ color: 'red' }} />}
              //   >
              //     <Button loading={record.id === selectedMdsaIdForDeletion} type="default">
              //       Delete
              //     </Button>
              //   </Popconfirm>
              // </Space>
              <Popconfirm
                title="Are you sure delete this mdsa?"
                onConfirm={() => {
                  setSelectedMdsaIdForDeletion(record.id);
                  handleDeleteMdsa(record.id);
                }}
                okText="Yes"
                cancelText="No"
                icon={<DeleteOutlined style={{ color: 'red' }} />}
              >
                <Tooltip title="Delete MDSA">
                  <Button
                    loading={record.id === selectedMdsaIdForDeletion}
                    type="default"
                    danger
                    icon={<DeleteFilled />}
                  />
                </Tooltip>
              </Popconfirm>
            );
          }}
        />
      </Table>
    </>
  );
};

export default OngoingMdsaTableComponent;
