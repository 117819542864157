import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Button, Col, DatePicker, Row, message } from 'antd';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EcFormItem from '../components/EcFormItem';
import HmqKitsSelect from '../components/HmqKitsSelect';
import { ReactSelectOptionType } from '../utils/types';
import { updateKitsByIdsUrl, updateReceivedDateTimeOfKitsByIds } from '../utils/apis/kit';
import { logger } from '../utils/helpers';
import {
  CloudwatchLogsMutation,
  CloudwatchLogsMutationVariables,
  PlatformType,
} from '../graphql/graphql-types';
import { logGroupNameEcp29apps, logStreamNameSampleManagement } from '../utils/globals';

/* Form type */
type FormType = {
  // type definition for kits
  kits: Array<ReactSelectOptionType>;
  // type definition for received date
  receivedDateTime: moment.Moment | null;
};

// schema validation
const validationSchema = yup.object().shape({
  kits: yup
    .array()
    .min(1, 'Please select at least one kitId and try again')
    .required('Please select at least one kitId and try again')
    .nullable(),
  receivedDateTime: yup
    .date()
    .test('receivedDateTime', 'Please select a date and try again', (val) => !!val)
    .nullable(),
});

const cloudwatchLogsMutation = loader('../graphql/mutations/cloudwatchLogs.graphql');

/* React functional component */
const SamplesReceived = (): JSX.Element => {
  // state to check whether submit button is loading or not
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState<boolean>(false);

  // mutation to store the logs
  const [cloudwatchLogs] = useMutation<CloudwatchLogsMutation, CloudwatchLogsMutationVariables>(
    cloudwatchLogsMutation,
  );

  /* useForm declaration */
  const { control, errors, handleSubmit, reset } = useForm<FormType>({
    defaultValues: {
      kits: [],
      receivedDateTime: null,
    },
    resolver: yupResolver(validationSchema),
  });

  // functionality that will be executed when mutation gets executed
  const functionalityToExecuteOnMutationExecution = () => {
    reset();
    setIsSubmitBtnLoading(false);
  };

  return (
    <>
      <p>Mark samples as received in lab</p>
      <form
        onSubmit={handleSubmit(async (formData: FormType) => {
          setIsSubmitBtnLoading(true);

          if (!formData.receivedDateTime) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error('Please select a date and try again');
            return;
          }

          // stores body data to be given as an input to the request header body in update API call
          const bodyData = {
            receivedDateTime: formData.receivedDateTime.toISOString(),
            kitID: formData.kits.map((kit) => kit.value),
          };

          try {
            // mutation to log console to AWS cloudwatch
            await cloudwatchLogs({
              variables: {
                args: {
                  logGroupName: logGroupNameEcp29apps,
                  logStreamName: logStreamNameSampleManagement,
                  logData: [
                    {
                      sourcecode_file_name: 'SamplesReceived',
                      sourcecode_function_name: 'OnSubmit',
                      platform: PlatformType.WebApp,
                      message: 'Request body to update receivedDateTime of selected KitsByIds',
                      data: JSON.stringify({
                        requestData: bodyData,
                        endpoint: updateKitsByIdsUrl,
                        method: 'PUT',
                      }),
                      event: 'sampleReceivedFormSubmission',
                      timestamp: new Date().toISOString(),
                    },
                  ],
                },
              },
            });
          } catch (err) {
            logger(err as Error);
          }

          // stores response of API call to update receivedDateTime of kits whose ids are passed to update
          updateReceivedDateTimeOfKitsByIds(bodyData)
            .then((res) => {
              functionalityToExecuteOnMutationExecution();
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.success('Kits updated successfully!');
            })
            .catch((err) => {
              functionalityToExecuteOnMutationExecution();
              logger(err as Error);
            });
        })}
      >
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <EcFormItem
              label="Kit(s)"
              name="kits"
              errors={errors}
              control={control}
              isFieldRequired
              render={({ onChange, value }): JSX.Element => (
                <HmqKitsSelect
                  placeholder="Select kit(s)"
                  onChange={onChange}
                  value={value as ReactSelectOptionType[]}
                />
              )}
            />
          </Col>
          <Col span={24}>
            <EcFormItem
              label="Received date"
              name="receivedDateTime"
              errors={errors}
              control={control}
              isFieldRequired
              render={({ onChange, value }): JSX.Element => (
                <DatePicker
                  disabledDate={(selectedDate: moment.Moment) => selectedDate.toDate() > new Date()}
                  onChange={(date): void => {
                    onChange(date);
                  }}
                  value={value as moment.Moment}
                  format="Do MMM YYYY"
                  placeholder="Select date"
                  allowClear
                  style={{ width: 180 }}
                />
              )}
            />
          </Col>
          <Col span={24}>
            <Button type="primary" htmlType="submit" loading={isSubmitBtnLoading}>
              Submit
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default SamplesReceived;
