import React, { useEffect, useState } from 'react';
import { Button, Input, InputNumber, Select, Spin } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { OptionProps } from 'antd/lib/select';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AppLayout from '../components/AppLayout';
import {
  Enum_Mdsa_Task_Key_Enum,
  MdsaInParam,
  MdsaLefseQuery,
  MdsaLefseQueryVariables,
  MetadataColumn,
  UpdateAndStartMdsaTaskMutation,
  UpdateAndStartMdsaTaskMutationVariables,
} from '../graphql/graphql-types';
import EcFormItem from '../components/EcFormItem';
import { filterSelectOptions, mdsaTasksInitialValues } from '../utils/globals';
import QiimeMetadataTable from '../components/QiimeMetadataTable';
import { logger } from '../utils/helpers';

const mdsaLefseQuery = loader('../graphql/queries/mdsaLefseQuery.graphql');
const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);

// input param form type
type FormType = {
  // type for field group
  group: string | null;
  // type for field taxa rank
  taxaRank: string | null;
  // type for field filtered samples
  sampleIds: Array<string> | null;
  // type to store P-value cut-off for significant taxa
  alpha: number | null;
  // type to store title for lefse plot
  title: string | null;
};

// const to store option array for taxa rank field
const taxaRankOptions = [
  'Kingdom',
  'Phylum',
  'Class',
  'Order',
  'Family',
  'Genus',
  'Species',
  'none',
  'all',
];

// schema use to pass to yup resolver for form validation
const schema = yup.object().shape({
  group: yup.string().required('Please select at least one group and try again').nullable(),
  taxaRank: yup.string().required('Please select at least one taxa rank and try again'),
});

// const to stroe initial values fir task lefse
const lefseInitialValues = mdsaTasksInitialValues.find(
  (item) => item.key === Enum_Mdsa_Task_Key_Enum.Lefse,
);

// const use to store form default values for task lefse
const lefseFormDefaultValue: FormType = {
  group: null,
  taxaRank: null,
  alpha: null,
  title: null,
  sampleIds: null,
};

// if logic to set form default values to actual default values passed in in_param for that task.
if (
  lefseInitialValues &&
  Array.isArray(lefseInitialValues.in_params) &&
  lefseInitialValues.in_params.length > 0
) {
  (lefseInitialValues.in_params as MdsaInParam[]).forEach((item) => {
    if (item.key === 'sampleIds' && item.rawValue) {
      lefseFormDefaultValue.sampleIds = item.rawValue as string[];
    }
    if (item.key === 'groupMetadataColumn' && item.rawValue) {
      lefseFormDefaultValue.group = item.rawValue as string;
    }
    if (item.key === 'taxaRank' && item.rawValue) {
      lefseFormDefaultValue.taxaRank = item.rawValue as string;
    }
    if (item.key === '--alpha' && item.rawValue) {
      lefseFormDefaultValue.alpha = item.rawValue as number;
    }
    if (item.key === '--title' && item.rawValue) {
      lefseFormDefaultValue.title = item.rawValue as string;
    }
  });
}

// react functional component
const MdsaLefse: React.FC = () => {
  // Extracting id and task id from url params
  const { id, taskId } = useParams();

  // creating navigate instance
  const navigate = useNavigate();

  // state use to determine loading indicator on start analysis button
  const [startAnalysisButtonLoading, setStartAnalysisButtonLoading] = useState<boolean>(false);

  // state to set started_at value to a time when user open the start analysis screen in update and start mdsa task mutation
  const [started_at] = useState(new Date());

  // query use to fetch details of task metadata column and metadata file url
  const { data, loading: queryLoading, error: queryError } = useQuery<
    MdsaLefseQuery,
    MdsaLefseQueryVariables
  >(mdsaLefseQuery, {
    variables: {
      task_id: parseInt(taskId, 10),
      mdsa_id: parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  // Mutation to update and start mdsa task
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  // useForm declarations
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, control, setValue } = useForm<FormType>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: lefseFormDefaultValue,
  });

  // Const to store mdsa metadata
  const mdsaMetadataColumnsData = data?.task?.mdsa.metadata_columns as MetadataColumn[];

  // const use to store primary feature table file name
  const primaryFeatureTableFileName = data?.task?.mdsa.primary_feature_table_file_name;

  // const to store in_params for mdsa task fetched from query
  const mdsaTaskInParams = data?.task?.in_params as MdsaInParam[];

  // useEffect to et initial values of params  to the existing one when page is reload
  useEffect(() => {
    if (Array.isArray(mdsaTaskInParams) && mdsaTaskInParams.length > 0)
      mdsaTaskInParams.forEach((item) => {
        if (item.key === 'sampleIds' && item.rawValue) {
          setValue('sampleIds', item.rawValue as string[]);
        }
        if (item.key === 'groupMetadataColumn' && item.rawValue) {
          setValue('group', item.rawValue as string);
        }
        if (item.key === 'taxaRank' && item.rawValue) {
          setValue('taxaRank', item.rawValue as string);
        }
        if (item.key === '--alpha') {
          setValue('alpha', item.rawValue as number);
          lefseFormDefaultValue.alpha = item.rawValue as number;
        }
        if (item.key === '--title') {
          setValue('title', item.rawValue as string);
        }
      });
  }, [mdsaTaskInParams, setValue]);

  /* Loading indicator while fetching data */
  if (queryLoading) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  // if any error occurred while fetching data will show error message
  if (queryError) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  // function use to handle functionality when start analysis button i s clicked
  const onSubmit = (formData: FormType) => {
    setStartAnalysisButtonLoading(true);

    // obtains the initial value of task lefse
    const defaultInParams = mdsaTasksInitialValues.find(
      (task) => task.key === Enum_Mdsa_Task_Key_Enum.Lefse,
    )?.in_params as Array<MdsaInParam>;

    // const to store immutable copy of in_params
    const taskInParamImmutableConst = JSON.parse(JSON.stringify(defaultInParams)) as Array<
      MdsaInParam
    >;

    /* eslint-disable no-param-reassign */
    taskInParamImmutableConst.forEach((item) => {
      if (item.key === 'sampleIds') {
        item.rawValue = formData.sampleIds;
      }
      if (item.key === 'groupMetadataColumn') {
        item.rawValue = formData.group;
        item.value = formData.group;
      }
      if (item.key === 'taxaRank') {
        item.rawValue = formData.taxaRank;
        item.value = formData.taxaRank;
      }
      if (item.key === '--alpha') {
        item.rawValue = formData.alpha || null;
        item.value = formData.alpha || null;
        item.enabled = !!formData.alpha;
      }
      if (item.key === '--title') {
        item.rawValue = formData.title || null;
        item.value = formData.title || null;
        item.enabled = !!formData.title;
      }
    });
    /* eslint-enable no-param-reassign */

    // call to mutation
    updateAndStartMdsaTask({
      variables: {
        taskId: parseInt(taskId, 10),
        taskUpdateInput: {
          started_at,
          in_params: taskInParamImmutableConst,
        },
      },
    })
      .then(() => {
        setStartAnalysisButtonLoading(false);
        navigate(`/mdsa/${parseInt(id, 10)}`);
      })
      .catch((err) => {
        setStartAnalysisButtonLoading(false);
        logger(err);
      });
  };

  return (
    <AppLayout screenTitle="Microbiome downstream analysis">
      <>
        <h2 style={{ marginBottom: 20 }}>LEfSe</h2>
        <p style={{ fontStyle: 'italic' }}>
          Feature table: <strong>{primaryFeatureTableFileName}</strong> will be used for analysis.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          {/* Group field */}
          <EcFormItem
            label="Group"
            name="group"
            helpText="Select the metadata column"
            control={control}
            errors={errors}
            isFieldRequired
            render={({ onChange, value }) => {
              return (
                <Select
                  showSearch
                  value={value as string}
                  onChange={onChange}
                  placeholder="Select a group"
                  style={{ width: 300, marginTop: 5, marginBottom: 20 }}
                  optionFilterProp="children"
                  loading={queryLoading}
                  filterOption={(input, option) =>
                    filterSelectOptions(input, option as OptionProps)
                  }
                >
                  {Array.isArray(mdsaMetadataColumnsData) && mdsaMetadataColumnsData.length > 0
                    ? mdsaMetadataColumnsData.map((item) => (
                        <Select.Option value={item.column} key={item.column}>
                          {item.column}
                        </Select.Option>
                      ))
                    : null}
                </Select>
              );
            }}
          />
          {/* Taxa rank field */}
          <EcFormItem
            label="Taxa rank"
            name="taxaRank"
            control={control}
            errors={errors}
            isFieldRequired
            render={({ onChange, value }) => {
              return (
                <Select
                  value={value as string}
                  onChange={onChange}
                  placeholder="Select a taxa rank"
                  style={{ width: 300, marginTop: 5, marginBottom: 20 }}
                >
                  {taxaRankOptions.map((item) => (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              );
            }}
          />

          <EcFormItem
            label="P-value cut-off for significant taxa"
            name="alpha"
            control={control}
            errors={errors}
            render={({ onChange, value }) => {
              return (
                <InputNumber
                  value={value as number}
                  onChange={onChange}
                  step={0.01}
                  style={{ width: 300, marginTop: 5, marginBottom: 20 }}
                  placeholder="Please enter p-value cut-off for significant taxa"
                />
              );
            }}
          />

          <EcFormItem
            label="Title for lefse plot"
            name="title"
            control={control}
            errors={errors}
            render={({ onChange, value }) => {
              return (
                <Input
                  allowClear
                  value={value as string}
                  onChange={onChange}
                  style={{ width: 300, marginTop: 5, marginBottom: 20 }}
                  placeholder="Please enter title for lefse plot"
                />
              );
            }}
          />
          {/* Filtered samples fields */}
          <EcFormItem
            name="sampleIds"
            label="Select samples to include in the analysis"
            helpText="If you do not select any sample, then all samples will be used for the analysis"
            errors={errors}
            control={control}
            containerStyle={{ width: '100%' }}
            render={({ onChange, value }): JSX.Element => {
              if (data && data.metadataFile && data.metadataFile.url) {
                return (
                  // call to qiimeMetadata table component to display table which contain data obtain by parsing remote file url
                  <QiimeMetadataTable
                    metadataFileUrl={data && data.metadataFile && data.metadataFile.url}
                    selectedRowKeys={value as Array<string>}
                    setSelectedRowKeys={(selectedSamplesId) => {
                      onChange(selectedSamplesId);
                    }}
                  />
                );
              }
              return <div />;
            }}
          />
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: 20 }}
            loading={startAnalysisButtonLoading}
          >
            Start Analysis
          </Button>
        </form>
      </>
    </AppLayout>
  );
};

export default MdsaLefse;
