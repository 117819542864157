import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { Table, message } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';

// Qiime metadataTable prop types
type QiimeMetadataPropsType = {
  // store file url which will parse using papaparse
  metadataFileUrl: string;
  // store sample ids of samples which are selected in table.
  selectedRowKeys: Array<string>;
  // optional prop to store data of all selected samples from table
  setSelectedRows?: (newSelectedRow: Array<Record<string, string>>) => void;
  // optional prop to store keys of seleted samples from table
  setSelectedRowKeys?: (selectedKeys: Array<string>) => void;
};

// tableData Type
type TableDataType = {
  // this will contain all column name of table
  columns: Array<string>;
  // this will contain data to be display in table
  dataSource: Array<Record<string, string>>;
};

// react function component
const QiimeMetadataTable: React.FC<QiimeMetadataPropsType> = ({
  metadataFileUrl,
  selectedRowKeys,
  setSelectedRows,
  setSelectedRowKeys,
}) => {
  // state to store data which will then dispay in table
  const [tableData, setTableData] = useState<TableDataType>({ columns: [], dataSource: [] });
  // useState to set  table loading indicator until data display in table
  const [tableLoadingIndicator, setTableLoadingIndicator] = useState<boolean>(true);

  // logic to parse file and store file data
  useEffect(() => {
    if (metadataFileUrl)
      // papaparse call to parse  metadata file
      Papa.parse(metadataFileUrl, {
        download: true,
        header: true,
        skipEmptyLines: true,
        complete: (res) => {
          if (res.errors.length === 0 && res.data && res.meta && res.meta.fields) {
            setTableLoadingIndicator(false);
            setTableData({
              columns: res.meta.fields,
              dataSource: res.data as Array<Record<string, string>>,
            });
          }
        },
        error: (error) => {
          setTableLoadingIndicator(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
        },
      });
  }, [metadataFileUrl]);

  const { Column } = Table;

  // Data source for table. Remove the 2nd row from metadata table.
  const tableDataSource = tableData.dataSource.filter((e) => e['sample-id'] !== '#q2:types');

  return (
    <Table<Record<string, string>>
      bordered
      className="qiimeMetadataTable"
      scroll={{ x: 'max-content' }}
      rowKey={tableData.columns[0]}
      rowSelection={{
        selectedRowKeys,
        onChange: (selectedRowKey, selectedRow) => {
          if (setSelectedRows) {
            setSelectedRows(selectedRow);
          }
          if (setSelectedRowKeys) {
            setSelectedRowKeys(selectedRowKey as string[]);
          }
        },
        selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
      }}
      size="small"
      dataSource={tableDataSource}
      loading={tableLoadingIndicator}
    >
      {tableData.columns.map((column) => {
        // logic to get unique value in filter options start

        // It stores the unique values of a column in all rows.
        const colValues: string[] = [];

        const filtersArray: ColumnFilterItem[] = [];

        // Calculate unique values of a column
        tableDataSource.forEach((entry) => {
          if (!colValues.includes(entry[column])) {
            colValues.push(entry[column]);
            filtersArray.push({
              text: entry[column],
              value: entry[column],
            });
          }
        });
        // logic end

        return (
          <Column<Record<string, string>>
            title={column}
            dataIndex={column}
            key={column}
            filters={column !== 'sample-id' ? filtersArray : undefined}
            fixed={column === 'sample-id' ? 'left' : undefined}
            onFilter={(value, record) => {
              return record[column].indexOf(value as string) === 0;
            }}
          />
        );
      })}
    </Table>
  );
};

export default QiimeMetadataTable;
