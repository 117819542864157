import React, { useState } from 'react';
import { Button, Input, message } from 'antd';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import firebase, { firebaseAuth } from '../utils/firebase';
import AppLayout from '../components/AppLayout';
import EcFormItem from '../components/EcFormItem';
import { logger } from '../utils/helpers';

/* Update password form type */
type UpdatePasswordFormType = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

/* React functional component */
const HomeScreen: React.FC = () => {
  /* State to store change password buttons loading */
  const [changePasswordBtnLoading, setChangePasswordBtnLoading] = useState<boolean>(false);

  // Get the query parameters from URL
  const urlParams = new URLSearchParams(useLocation().search);
  const currentPasswordParam = urlParams.get('currentPassword');

  /* useForm declaration with default values and validation */
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, control, reset, setError } = useForm<UpdatePasswordFormType>({
    defaultValues: {
      currentPassword: currentPasswordParam || '',
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        currentPassword: yup.string().required('Please enter password and try again'),
        newPassword: yup
          .string()
          .required('Please enter new password and try again')
          .min(6, 'Password must be at least 6 characters long'),
        confirmNewPassword: yup
          .string()
          .oneOf(
            [yup.ref('newPassword'), null],
            'The value of "New Password" and "Confirm New Password" should be the same',
          )
          .required('Please enter confirm new password and try again'),
      }),
    ),
    mode: 'onChange',
  });

  /* Function to update user's password */
  const handleUpdatePassword = (updatePasswordData: UpdatePasswordFormType) => {
    setChangePasswordBtnLoading(true);
    const { currentUser } = firebaseAuth;
    if (currentUser && currentUser.email) {
      /* Credentials variable with current user's email and current password entered by user for reauthenticateWithCredential method */
      const credentials = firebase.auth.EmailAuthProvider.credential(
        currentUser.email,
        updatePasswordData.currentPassword,
      );

      /* Check whether user entered current password is correct or not */
      currentUser
        .reauthenticateWithCredential(credentials)
        .then(() => {
          /* If current password is correct then update user's password */
          currentUser
            .updatePassword(updatePasswordData.newPassword)
            .then(() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.success('Password has been successfully updated.');
              setChangePasswordBtnLoading(false);
              /* Reset from after password updated successfully */
              reset({
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: '',
              });
            })
            .catch((err) => {
              logger(err);
              setChangePasswordBtnLoading(false);
            });
        })
        .catch((error) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (error.code === 'auth/wrong-password') {
            setError('currentPassword', {
              type: 'manual',
              message: 'The Current Password you have entered is incorrect',
            });
          } else {
            logger(error);
          }
          setChangePasswordBtnLoading(false);
        });
    }
  };
  return (
    <AppLayout screenTitle="Change Password">
      <form
        style={{ width: 300 }}
        onSubmit={handleSubmit((data) => {
          handleUpdatePassword(data);
        })}
      >
        <EcFormItem
          label="Current Password"
          name="currentPassword"
          isFieldRequired
          control={control}
          errors={errors}
          as={
            <Input.Password placeholder="Please enter Password" readOnly={!!currentPasswordParam} />
          }
        />
        <div style={{ marginTop: 15 }}>
          <EcFormItem
            label="New Password"
            name="newPassword"
            isFieldRequired
            control={control}
            errors={errors}
            as={<Input.Password placeholder="Please enter New Password" />}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <EcFormItem
            label="Confirm New Password"
            name="confirmNewPassword"
            isFieldRequired
            control={control}
            errors={errors}
            as={<Input.Password placeholder="Please enter Confirm New Password" />}
          />
        </div>
        <Button
          style={{ marginTop: 35 }}
          type="primary"
          htmlType="submit"
          loading={changePasswordBtnLoading}
        >
          Change password
        </Button>
      </form>
    </AppLayout>
  );
};

export default HomeScreen;
