import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin, Select, Checkbox, Button } from 'antd';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import {
  MdsaTaskInParamsQuery,
  MdsaTaskInParamsQueryVariables,
  UpdateAndStartMdsaTaskMutation,
  UpdateAndStartMdsaTaskMutationVariables,
  MdsaInParam,
  Enum_Mdsa_Task_Key_Enum,
} from '../graphql/graphql-types';
import AppLayout from '../components/AppLayout';
import { taxaLevels, mdsaTasksInitialValues, mdsaTaskKeyNameMap } from '../utils/globals';
import { logger } from '../utils/helpers';
import EcFormItem from '../components/EcFormItem';

const mdsaTaskInParamsQuery = loader('../graphql/queries/mdsaTaskInParamsQuery.graphql');
const updateAndStartMdsaTaskMutation = loader(
  '../graphql/mutations/updateAndStartMdsaTaskMutation.graphql',
);

/* React functional component */
const MdsaTaxaBarPlot: React.FC = () => {
  /* Extracting task id from url param */
  const { id, taskId } = useParams();

  const navigate = useNavigate();

  /* State to store started_at for updateAndStartMdsaTask */
  const [started_at] = useState(new Date());

  /* State to store start analysis buttons loading */
  const [startAnalysisBtnLoading, setStartAnalysisBtnLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, control, watch, setValue, clearErrors } = useForm<{
    taxaLevel: string[] | undefined;
    abundanceFileCheckbox: boolean;
  }>({
    resolver: yupResolver(
      yup.object().shape({
        taxaLevel: yup
          .array()
          .nullable()
          .when('abundanceFileCheckbox', {
            is: true,
            then: yup.array().nullable().required('Please select a value and try again'),
          }),
      }),
    ),
    mode: 'onSubmit',
  });

  /* Query to fetch mdsa data for mdsa group table bar plot */
  const { data, loading: queryLoading, error: queryError } = useQuery<
    MdsaTaskInParamsQuery,
    MdsaTaskInParamsQueryVariables
  >(mdsaTaskInParamsQuery, {
    variables: {
      id: parseInt(taskId, 10),
    },
    fetchPolicy: 'network-only',
  });

  /* Mutation to update and start mdsa task */
  const [updateAndStartMdsaTask] = useMutation<
    UpdateAndStartMdsaTaskMutation,
    UpdateAndStartMdsaTaskMutationVariables
  >(updateAndStartMdsaTaskMutation);

  /* Mdsa task in params data */
  const mdsaTaskInParams = data?.task?.in_params as Array<MdsaInParam>;

  useEffect(() => {
    /* Check whether initially values are present in in_params data */
    if (Array.isArray(mdsaTaskInParams) && mdsaTaskInParams.length > 0) {
      mdsaTaskInParams.forEach((item) => {
        if (
          item.key === 'taxaCollapseLevels' &&
          Array.isArray(item.rawValue) &&
          item.rawValue.length > 0
        ) {
          setValue('abundanceFileCheckbox', true);
          setValue('taxaLevel', item.rawValue as string[]);
        }
      });
    }
  }, [mdsaTaskInParams, setValue]);

  /* Loading indicator while fetching data */
  if (queryLoading) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Spin size="large" />
        </div>
      </AppLayout>
    );
  }

  /* if any error occurred while fetching data will show error message */
  if (queryError) {
    return (
      <AppLayout screenTitle="Microbiome Downstream Analysis">
        <p style={{ color: 'red', textAlign: 'center' }}>{queryError.message}</p>
      </AppLayout>
    );
  }

  /* Function to update and start mdsa task on click start analysis button */
  const handleUpdateAndStartMdsaTask = (inParamsData: Array<MdsaInParam>) => {
    setStartAnalysisBtnLoading(true);
    updateAndStartMdsaTask({
      variables: {
        taskId: parseInt(taskId, 10),
        taskUpdateInput: {
          started_at,
          in_params: inParamsData,
        },
      },
    })
      .then((res) => {
        const updatedMdsaTaskId = res.data?.update_mdsa_task_by_pk?.id;
        if (updatedMdsaTaskId) {
          setStartAnalysisBtnLoading(false);
          navigate(`/mdsa/${id}`);
        }
      })
      .catch((err) => {
        setStartAnalysisBtnLoading(false);
        logger(err);
      });
  };

  return (
    <AppLayout screenTitle="Microbiome downstream analysis">
      <>
        <h2>{mdsaTaskKeyNameMap[Enum_Mdsa_Task_Key_Enum.TaxaBarPlot]}</h2>

        <form
          onSubmit={handleSubmit((formData) => {
            // obtains the initial value of task group table bar plot
            const defaultInParams = mdsaTasksInitialValues.find(
              (task) => task.key === Enum_Mdsa_Task_Key_Enum.TaxaBarPlot,
            )?.in_params as Array<MdsaInParam>;

            // const to store immutable copy of in_params
            const taskInParamImmutableConst = JSON.parse(JSON.stringify(defaultInParams)) as Array<
              MdsaInParam
            >;

            // Set the in_params value according to the inputs provided by the user
            /* eslint-disable no-param-reassign */
            taskInParamImmutableConst.forEach((item) => {
              if (item.key === 'taxaCollapseLevels' && formData.taxaLevel) {
                item.enabled = true;
                item.rawValue = formData.taxaLevel;
                item.value = null;
              }
            });

            // Run the mutation to update task
            handleUpdateAndStartMdsaTask(taskInParamImmutableConst);
          })}
        >
          <EcFormItem
            name="abundanceFileCheckbox"
            control={control}
            defaultValue={false}
            render={({ onChange, value }): JSX.Element => {
              return (
                <Checkbox
                  checked={value as boolean}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    if (!e.target.checked) {
                      setValue('taxaLevel', undefined);
                      clearErrors(['taxaLevel']);
                    }
                  }}
                  style={{ fontSize: 16 }}
                >
                  Create Relative Abundance file?
                </Checkbox>
              );
            }}
            containerStyle={{ marginTop: 25 }}
          />

          <EcFormItem
            label="Taxa collapse level"
            name="taxaLevel"
            control={control}
            errors={errors}
            isFieldRequired
            defaultValue={undefined}
            mode="multiple"
            as={
              <Select placeholder="Select level" style={{ width: 400, marginTop: 5 }}>
                {Array.isArray(taxaLevels)
                  ? taxaLevels.map((item) => {
                      return (
                        <Select.Option key={item.level} value={item.level}>
                          {item.name}
                        </Select.Option>
                      );
                    })
                  : null}
              </Select>
            }
            containerStyle={{
              display: watch('abundanceFileCheckbox') ? 'block' : 'none',
              marginTop: 15,
            }}
          />

          <Button
            type="primary"
            htmlType="submit"
            loading={startAnalysisBtnLoading}
            style={{ marginTop: 30 }}
          >
            Start Analysis
          </Button>
        </form>
      </>
    </AppLayout>
  );
};

export default MdsaTaxaBarPlot;
