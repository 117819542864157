import React from 'react';
import AppLayout from '../components/AppLayout';
import MdsaInfoForm from '../forms/MdsaInfoForm';

// react functional component
const CreateMdsa: React.FC = () => {
  return (
    <AppLayout screenTitle="Create New Downstream Analysis" hideBackBtn>
      <MdsaInfoForm mode="create" />
    </AppLayout>
  );
};

export default CreateMdsa;
