import React from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import {
  DataSyncDescribeTaskExecutionQueryVariables,
  DataSyncDescribeTaskExecutionQuery,
} from '../graphql/graphql-types';

const dataSyncDescribeTaskExecutionQuery = loader(
  '../graphql/queries/dataSyncDescribeTaskExecutionQuery.graphql',
);

const DataSyncStatus: React.FC<{
  datasyncTaskExecutionArn: string;
}> = ({ datasyncTaskExecutionArn }) => {
  const { data } = useQuery<
    DataSyncDescribeTaskExecutionQuery,
    DataSyncDescribeTaskExecutionQueryVariables
  >(dataSyncDescribeTaskExecutionQuery, {
    variables: {
      taskExecutionArn: datasyncTaskExecutionArn,
    },
    fetchPolicy: 'network-only',
    pollInterval: 10000,
  });

  if (data && data.dataSyncDescribeTaskExecution) {
    return (
      <div>
        <p style={{ marginBottom: 0 }}>
          <strong>DataSync status: </strong>
          {data.dataSyncDescribeTaskExecution.status}
        </p>
        <p style={{ margin: 0 }}>
          <strong>Files uploaded: </strong>
          {data.dataSyncDescribeTaskExecution.filesTransferred} /{' '}
          {data.dataSyncDescribeTaskExecution.estimatedFilesToTransfer}
        </p>
      </div>
    );
  }
  return <span>Uploading fastq files</span>;
};

/* React functional component */
const SrLinkFastqStatus: React.FC<{
  datasyncTaskExecutionArn?: string;
}> = ({ datasyncTaskExecutionArn }) => {
  if (!datasyncTaskExecutionArn) {
    return <span>Copying fastq files</span>;
  }
  return <DataSyncStatus datasyncTaskExecutionArn={datasyncTaskExecutionArn} />;
};

export default SrLinkFastqStatus;
