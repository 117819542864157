export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: Date | string;
  jsonb: any;
  timestamptz: Date | string;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CloudwatchLogsInput = {
  logData: Array<LogData>;
  logGroupName: Scalars['String'];
  logStreamName: Scalars['String'];
};

export type DeleteFileOutput = {
  __typename?: 'DeleteFileOutput';
  status: Scalars['Boolean'];
};

export type DescribeTaskExecutionOutput = {
  __typename?: 'DescribeTaskExecutionOutput';
  estimatedFilesToTransfer?: Maybe<Scalars['Int']>;
  filesTransferred?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  status: TaskExecutionStatus;
};

export type FileUploadS3ConfigInput = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  keyName?: Maybe<Scalars['String']>;
  keyPrefix?: Maybe<Scalars['String']>;
};

export type FileUploadS3ConfigOutput = {
  __typename?: 'FileUploadS3ConfigOutput';
  algorithm: Scalars['String'];
  contentDisposition: Scalars['String'];
  contentType: Scalars['String'];
  credential: Scalars['String'];
  date: Scalars['String'];
  fileName: Scalars['String'];
  key: Scalars['String'];
  policy: Scalars['String'];
  signature: Scalars['String'];
  url: Scalars['String'];
};

export type GetFilesUrlOutput = {
  __typename?: 'GetFilesUrlOutput';
  key: Scalars['String'];
  url: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type LogData = {
  data?: Maybe<Scalars['String']>;
  event: Scalars['String'];
  message: Scalars['String'];
  platform: PlatformType;
  sourcecode_file_name: Scalars['String'];
  sourcecode_function_name: Scalars['String'];
  timestamp: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['String']>;
};

export type MdsaOutFileUrlOutput = {
  __typename?: 'MdsaOutFileUrlOutput';
  s3_key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MeOutput = {
  __typename?: 'MeOutput';
  user?: Maybe<User>;
  user_id: Scalars['Int'];
};

export type NewHmqMdsaOutput = {
  __typename?: 'NewHmqMdsaOutput';
  mdsa_id: Scalars['Int'];
};

export enum PlatformType {
  Analysis = 'analysis',
  Backend = 'backend',
  DesktopApp = 'desktop_app',
  WebApp = 'web_app'
}

export type ResetMdsaUptoTaskOutput = {
  __typename?: 'ResetMdsaUptoTaskOutput';
  status: Scalars['Boolean'];
};

export type SrCheckFastqOutput = {
  __typename?: 'SrCheckFastqOutput';
  forwardFastqFile?: Maybe<Scalars['String']>;
  reverseFastqFile?: Maybe<Scalars['String']>;
  sample_id: Scalars['String'];
};

export type SrOutFileUrlOutput = {
  __typename?: 'SrOutFileUrlOutput';
  s3_key: Scalars['String'];
  url: Scalars['String'];
};

export type StatusOutput = {
  __typename?: 'StatusOutput';
  status: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: Maybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: Maybe<Array<Scalars['String']>>;
  _eq?: Maybe<Array<Scalars['String']>>;
  _gt?: Maybe<Array<Scalars['String']>>;
  _gte?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Array<Scalars['String']>>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Array<Scalars['String']>>;
  _lte?: Maybe<Array<Scalars['String']>>;
  _neq?: Maybe<Array<Scalars['String']>>;
  _nin?: Maybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type TaskExecutionOutput = {
  __typename?: 'TaskExecutionOutput';
  taskExecutionArn: Scalars['String'];
};

export enum TaskExecutionStatus {
  Error = 'ERROR',
  Launching = 'LAUNCHING',
  Preparing = 'PREPARING',
  Queued = 'QUEUED',
  Success = 'SUCCESS',
  Transferring = 'TRANSFERRING',
  Verifying = 'VERIFYING'
}

export enum TaskType {
  Mdsa = 'mdsa',
  Sr = 'sr'
}

export type UpdateSrCreatedByIdInput = {
  created_by_id: Scalars['Int'];
  sr_id: Scalars['Int'];
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** ENUM table for mdsa's source */
export type Enum_Mdsa_Source = {
  __typename?: 'enum_mdsa_source';
  value: Scalars['String'];
};

/** aggregated selection of "enum_mdsa_source" */
export type Enum_Mdsa_Source_Aggregate = {
  __typename?: 'enum_mdsa_source_aggregate';
  aggregate?: Maybe<Enum_Mdsa_Source_Aggregate_Fields>;
  nodes: Array<Enum_Mdsa_Source>;
};

/** aggregate fields of "enum_mdsa_source" */
export type Enum_Mdsa_Source_Aggregate_Fields = {
  __typename?: 'enum_mdsa_source_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Mdsa_Source_Max_Fields>;
  min?: Maybe<Enum_Mdsa_Source_Min_Fields>;
};


/** aggregate fields of "enum_mdsa_source" */
export type Enum_Mdsa_Source_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Mdsa_Source_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_mdsa_source". All fields are combined with a logical 'AND'. */
export type Enum_Mdsa_Source_Bool_Exp = {
  _and?: Maybe<Array<Enum_Mdsa_Source_Bool_Exp>>;
  _not?: Maybe<Enum_Mdsa_Source_Bool_Exp>;
  _or?: Maybe<Array<Enum_Mdsa_Source_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_mdsa_source" */
export enum Enum_Mdsa_Source_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMdsaSourcePkey = 'enum_mdsa_source_pkey'
}

export enum Enum_Mdsa_Source_Enum {
  Mdsa = 'mdsa',
  SequencingRuns = 'sequencing_runs'
}

/** Boolean expression to compare columns of type "enum_mdsa_source_enum". All fields are combined with logical 'AND'. */
export type Enum_Mdsa_Source_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Mdsa_Source_Enum>;
  _in?: Maybe<Array<Enum_Mdsa_Source_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Mdsa_Source_Enum>;
  _nin?: Maybe<Array<Enum_Mdsa_Source_Enum>>;
};

/** input type for inserting data into table "enum_mdsa_source" */
export type Enum_Mdsa_Source_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Mdsa_Source_Max_Fields = {
  __typename?: 'enum_mdsa_source_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Mdsa_Source_Min_Fields = {
  __typename?: 'enum_mdsa_source_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_mdsa_source" */
export type Enum_Mdsa_Source_Mutation_Response = {
  __typename?: 'enum_mdsa_source_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Mdsa_Source>;
};

/** on_conflict condition type for table "enum_mdsa_source" */
export type Enum_Mdsa_Source_On_Conflict = {
  constraint: Enum_Mdsa_Source_Constraint;
  update_columns?: Array<Enum_Mdsa_Source_Update_Column>;
  where?: Maybe<Enum_Mdsa_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_mdsa_source". */
export type Enum_Mdsa_Source_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: enum_mdsa_source */
export type Enum_Mdsa_Source_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_mdsa_source" */
export enum Enum_Mdsa_Source_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_mdsa_source" */
export type Enum_Mdsa_Source_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_mdsa_source" */
export type Enum_Mdsa_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Mdsa_Source_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Mdsa_Source_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "enum_mdsa_source" */
export enum Enum_Mdsa_Source_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Mdsa_Source_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Enum_Mdsa_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Mdsa_Source_Bool_Exp;
};

/** ENUM table for mdsa's status */
export type Enum_Mdsa_Status = {
  __typename?: 'enum_mdsa_status';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_mdsa_status" */
export type Enum_Mdsa_Status_Aggregate = {
  __typename?: 'enum_mdsa_status_aggregate';
  aggregate?: Maybe<Enum_Mdsa_Status_Aggregate_Fields>;
  nodes: Array<Enum_Mdsa_Status>;
};

/** aggregate fields of "enum_mdsa_status" */
export type Enum_Mdsa_Status_Aggregate_Fields = {
  __typename?: 'enum_mdsa_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Mdsa_Status_Max_Fields>;
  min?: Maybe<Enum_Mdsa_Status_Min_Fields>;
};


/** aggregate fields of "enum_mdsa_status" */
export type Enum_Mdsa_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Mdsa_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_mdsa_status". All fields are combined with a logical 'AND'. */
export type Enum_Mdsa_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Mdsa_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Mdsa_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Mdsa_Status_Bool_Exp>>;
  display_name?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_mdsa_status" */
export enum Enum_Mdsa_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMdsaStatusPkey = 'enum_mdsa_status_pkey'
}

export enum Enum_Mdsa_Status_Enum {
  Completed = 'completed',
  Open = 'open'
}

/** Boolean expression to compare columns of type "enum_mdsa_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Mdsa_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Mdsa_Status_Enum>;
  _in?: Maybe<Array<Enum_Mdsa_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Mdsa_Status_Enum>;
  _nin?: Maybe<Array<Enum_Mdsa_Status_Enum>>;
};

/** input type for inserting data into table "enum_mdsa_status" */
export type Enum_Mdsa_Status_Insert_Input = {
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Mdsa_Status_Max_Fields = {
  __typename?: 'enum_mdsa_status_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Mdsa_Status_Min_Fields = {
  __typename?: 'enum_mdsa_status_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_mdsa_status" */
export type Enum_Mdsa_Status_Mutation_Response = {
  __typename?: 'enum_mdsa_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Mdsa_Status>;
};

/** on_conflict condition type for table "enum_mdsa_status" */
export type Enum_Mdsa_Status_On_Conflict = {
  constraint: Enum_Mdsa_Status_Constraint;
  update_columns?: Array<Enum_Mdsa_Status_Update_Column>;
  where?: Maybe<Enum_Mdsa_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_mdsa_status". */
export type Enum_Mdsa_Status_Order_By = {
  display_name?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: enum_mdsa_status */
export type Enum_Mdsa_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_mdsa_status" */
export enum Enum_Mdsa_Status_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_mdsa_status" */
export type Enum_Mdsa_Status_Set_Input = {
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_mdsa_status" */
export type Enum_Mdsa_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Mdsa_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Mdsa_Status_Stream_Cursor_Value_Input = {
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "enum_mdsa_status" */
export enum Enum_Mdsa_Status_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_Mdsa_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Enum_Mdsa_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Mdsa_Status_Bool_Exp;
};

/** ENUM table for mdsa_task table's key column */
export type Enum_Mdsa_Task_Key = {
  __typename?: 'enum_mdsa_task_key';
  value: Scalars['String'];
};

/** aggregated selection of "enum_mdsa_task_key" */
export type Enum_Mdsa_Task_Key_Aggregate = {
  __typename?: 'enum_mdsa_task_key_aggregate';
  aggregate?: Maybe<Enum_Mdsa_Task_Key_Aggregate_Fields>;
  nodes: Array<Enum_Mdsa_Task_Key>;
};

/** aggregate fields of "enum_mdsa_task_key" */
export type Enum_Mdsa_Task_Key_Aggregate_Fields = {
  __typename?: 'enum_mdsa_task_key_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Mdsa_Task_Key_Max_Fields>;
  min?: Maybe<Enum_Mdsa_Task_Key_Min_Fields>;
};


/** aggregate fields of "enum_mdsa_task_key" */
export type Enum_Mdsa_Task_Key_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Mdsa_Task_Key_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_mdsa_task_key". All fields are combined with a logical 'AND'. */
export type Enum_Mdsa_Task_Key_Bool_Exp = {
  _and?: Maybe<Array<Enum_Mdsa_Task_Key_Bool_Exp>>;
  _not?: Maybe<Enum_Mdsa_Task_Key_Bool_Exp>;
  _or?: Maybe<Array<Enum_Mdsa_Task_Key_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_mdsa_task_key" */
export enum Enum_Mdsa_Task_Key_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMdsaTaskKeyPkey = 'enum_mdsa_task_key_pkey'
}

export enum Enum_Mdsa_Task_Key_Enum {
  AlphaGroupSignificance = 'alphaGroupSignificance',
  Ancom = 'ancom',
  BetaGroupSignificance = 'betaGroupSignificance',
  Blast = 'blast',
  Cart = 'cart',
  CoreMicrobiomeAnalysis = 'coreMicrobiomeAnalysis',
  CustomPcoaPlot = 'customPcoaPlot',
  FilterDistanceMatrix = 'filterDistanceMatrix',
  FilterTableByTaxa = 'filterTableByTaxa',
  GroupTableBarPlot = 'groupTableBarPlot',
  HmqReport = 'hmqReport',
  Lefse = 'lefse',
  MergeTablesSr = 'mergeTablesSr',
  MergeTablesSummary = 'mergeTablesSummary',
  PhylogeneticDiversityAnalysis = 'phylogeneticDiversityAnalysis',
  Picrust2 = 'picrust2',
  TableFilterFeatures = 'tableFilterFeatures',
  TaxaBarPlot = 'taxaBarPlot',
  TaxonomicClassification = 'taxonomicClassification',
  UploadMetadata = 'uploadMetadata'
}

/** Boolean expression to compare columns of type "enum_mdsa_task_key_enum". All fields are combined with logical 'AND'. */
export type Enum_Mdsa_Task_Key_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Mdsa_Task_Key_Enum>;
  _in?: Maybe<Array<Enum_Mdsa_Task_Key_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Mdsa_Task_Key_Enum>;
  _nin?: Maybe<Array<Enum_Mdsa_Task_Key_Enum>>;
};

/** input type for inserting data into table "enum_mdsa_task_key" */
export type Enum_Mdsa_Task_Key_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Mdsa_Task_Key_Max_Fields = {
  __typename?: 'enum_mdsa_task_key_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Mdsa_Task_Key_Min_Fields = {
  __typename?: 'enum_mdsa_task_key_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_mdsa_task_key" */
export type Enum_Mdsa_Task_Key_Mutation_Response = {
  __typename?: 'enum_mdsa_task_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Mdsa_Task_Key>;
};

/** on_conflict condition type for table "enum_mdsa_task_key" */
export type Enum_Mdsa_Task_Key_On_Conflict = {
  constraint: Enum_Mdsa_Task_Key_Constraint;
  update_columns?: Array<Enum_Mdsa_Task_Key_Update_Column>;
  where?: Maybe<Enum_Mdsa_Task_Key_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_mdsa_task_key". */
export type Enum_Mdsa_Task_Key_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: enum_mdsa_task_key */
export type Enum_Mdsa_Task_Key_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_mdsa_task_key" */
export enum Enum_Mdsa_Task_Key_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_mdsa_task_key" */
export type Enum_Mdsa_Task_Key_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_mdsa_task_key" */
export type Enum_Mdsa_Task_Key_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Mdsa_Task_Key_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Mdsa_Task_Key_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "enum_mdsa_task_key" */
export enum Enum_Mdsa_Task_Key_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Mdsa_Task_Key_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Enum_Mdsa_Task_Key_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Mdsa_Task_Key_Bool_Exp;
};

/** ENUM for sequencing type in sequencing_run */
export type Enum_Sequencing_Type = {
  __typename?: 'enum_sequencing_type';
  value: Scalars['String'];
};

/** aggregated selection of "enum_sequencing_type" */
export type Enum_Sequencing_Type_Aggregate = {
  __typename?: 'enum_sequencing_type_aggregate';
  aggregate?: Maybe<Enum_Sequencing_Type_Aggregate_Fields>;
  nodes: Array<Enum_Sequencing_Type>;
};

/** aggregate fields of "enum_sequencing_type" */
export type Enum_Sequencing_Type_Aggregate_Fields = {
  __typename?: 'enum_sequencing_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Sequencing_Type_Max_Fields>;
  min?: Maybe<Enum_Sequencing_Type_Min_Fields>;
};


/** aggregate fields of "enum_sequencing_type" */
export type Enum_Sequencing_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Sequencing_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_sequencing_type". All fields are combined with a logical 'AND'. */
export type Enum_Sequencing_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Sequencing_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Sequencing_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Sequencing_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_sequencing_type" */
export enum Enum_Sequencing_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumSequencingTypePkey = 'enum_sequencing_type_pkey'
}

export enum Enum_Sequencing_Type_Enum {
  Microbiome = 'microbiome',
  RnaSeq = 'rna_seq',
  Wgs = 'wgs'
}

/** Boolean expression to compare columns of type "enum_sequencing_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Sequencing_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Sequencing_Type_Enum>;
  _in?: Maybe<Array<Enum_Sequencing_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Sequencing_Type_Enum>;
  _nin?: Maybe<Array<Enum_Sequencing_Type_Enum>>;
};

/** input type for inserting data into table "enum_sequencing_type" */
export type Enum_Sequencing_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Sequencing_Type_Max_Fields = {
  __typename?: 'enum_sequencing_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Sequencing_Type_Min_Fields = {
  __typename?: 'enum_sequencing_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_sequencing_type" */
export type Enum_Sequencing_Type_Mutation_Response = {
  __typename?: 'enum_sequencing_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Sequencing_Type>;
};

/** on_conflict condition type for table "enum_sequencing_type" */
export type Enum_Sequencing_Type_On_Conflict = {
  constraint: Enum_Sequencing_Type_Constraint;
  update_columns?: Array<Enum_Sequencing_Type_Update_Column>;
  where?: Maybe<Enum_Sequencing_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_sequencing_type". */
export type Enum_Sequencing_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: enum_sequencing_type */
export type Enum_Sequencing_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_sequencing_type" */
export enum Enum_Sequencing_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_sequencing_type" */
export type Enum_Sequencing_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_sequencing_type" */
export type Enum_Sequencing_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Sequencing_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Sequencing_Type_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "enum_sequencing_type" */
export enum Enum_Sequencing_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Sequencing_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Enum_Sequencing_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Sequencing_Type_Bool_Exp;
};

/** columns and relationships of "enum_settings_value_type" */
export type Enum_Settings_Value_Type = {
  __typename?: 'enum_settings_value_type';
  value: Scalars['String'];
};

/** aggregated selection of "enum_settings_value_type" */
export type Enum_Settings_Value_Type_Aggregate = {
  __typename?: 'enum_settings_value_type_aggregate';
  aggregate?: Maybe<Enum_Settings_Value_Type_Aggregate_Fields>;
  nodes: Array<Enum_Settings_Value_Type>;
};

/** aggregate fields of "enum_settings_value_type" */
export type Enum_Settings_Value_Type_Aggregate_Fields = {
  __typename?: 'enum_settings_value_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Settings_Value_Type_Max_Fields>;
  min?: Maybe<Enum_Settings_Value_Type_Min_Fields>;
};


/** aggregate fields of "enum_settings_value_type" */
export type Enum_Settings_Value_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Settings_Value_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_settings_value_type". All fields are combined with a logical 'AND'. */
export type Enum_Settings_Value_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Settings_Value_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Settings_Value_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Settings_Value_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_settings_value_type" */
export enum Enum_Settings_Value_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumSettingsValueTypePkey = 'enum_settings_value_type_pkey'
}

export enum Enum_Settings_Value_Type_Enum {
  Int = 'int',
  Text = 'text'
}

/** Boolean expression to compare columns of type "enum_settings_value_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Settings_Value_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Settings_Value_Type_Enum>;
  _in?: Maybe<Array<Enum_Settings_Value_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Settings_Value_Type_Enum>;
  _nin?: Maybe<Array<Enum_Settings_Value_Type_Enum>>;
};

/** input type for inserting data into table "enum_settings_value_type" */
export type Enum_Settings_Value_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Settings_Value_Type_Max_Fields = {
  __typename?: 'enum_settings_value_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Settings_Value_Type_Min_Fields = {
  __typename?: 'enum_settings_value_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_settings_value_type" */
export type Enum_Settings_Value_Type_Mutation_Response = {
  __typename?: 'enum_settings_value_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Settings_Value_Type>;
};

/** on_conflict condition type for table "enum_settings_value_type" */
export type Enum_Settings_Value_Type_On_Conflict = {
  constraint: Enum_Settings_Value_Type_Constraint;
  update_columns?: Array<Enum_Settings_Value_Type_Update_Column>;
  where?: Maybe<Enum_Settings_Value_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_settings_value_type". */
export type Enum_Settings_Value_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: enum_settings_value_type */
export type Enum_Settings_Value_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_settings_value_type" */
export enum Enum_Settings_Value_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_settings_value_type" */
export type Enum_Settings_Value_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_settings_value_type" */
export type Enum_Settings_Value_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Settings_Value_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Settings_Value_Type_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "enum_settings_value_type" */
export enum Enum_Settings_Value_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Settings_Value_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Enum_Settings_Value_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Settings_Value_Type_Bool_Exp;
};

/** ENUM table for sequencing run status */
export type Enum_Sr_Status = {
  __typename?: 'enum_sr_status';
  value: Scalars['String'];
};

/** aggregated selection of "enum_sr_status" */
export type Enum_Sr_Status_Aggregate = {
  __typename?: 'enum_sr_status_aggregate';
  aggregate?: Maybe<Enum_Sr_Status_Aggregate_Fields>;
  nodes: Array<Enum_Sr_Status>;
};

/** aggregate fields of "enum_sr_status" */
export type Enum_Sr_Status_Aggregate_Fields = {
  __typename?: 'enum_sr_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Sr_Status_Max_Fields>;
  min?: Maybe<Enum_Sr_Status_Min_Fields>;
};


/** aggregate fields of "enum_sr_status" */
export type Enum_Sr_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Sr_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_sr_status". All fields are combined with a logical 'AND'. */
export type Enum_Sr_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Sr_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Sr_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Sr_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_sr_status" */
export enum Enum_Sr_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumSrStatusPkey = 'enum_sr_status_pkey'
}

export enum Enum_Sr_Status_Enum {
  AnalysisCompleted = 'analysis_completed',
  AnalysisStarted = 'analysis_started',
  InfoSaved = 'info_saved',
  Open = 'open'
}

/** Boolean expression to compare columns of type "enum_sr_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Sr_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Sr_Status_Enum>;
  _in?: Maybe<Array<Enum_Sr_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Sr_Status_Enum>;
  _nin?: Maybe<Array<Enum_Sr_Status_Enum>>;
};

/** input type for inserting data into table "enum_sr_status" */
export type Enum_Sr_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Sr_Status_Max_Fields = {
  __typename?: 'enum_sr_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Sr_Status_Min_Fields = {
  __typename?: 'enum_sr_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_sr_status" */
export type Enum_Sr_Status_Mutation_Response = {
  __typename?: 'enum_sr_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Sr_Status>;
};

/** on_conflict condition type for table "enum_sr_status" */
export type Enum_Sr_Status_On_Conflict = {
  constraint: Enum_Sr_Status_Constraint;
  update_columns?: Array<Enum_Sr_Status_Update_Column>;
  where?: Maybe<Enum_Sr_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_sr_status". */
export type Enum_Sr_Status_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: enum_sr_status */
export type Enum_Sr_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_sr_status" */
export enum Enum_Sr_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_sr_status" */
export type Enum_Sr_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_sr_status" */
export type Enum_Sr_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Sr_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Sr_Status_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "enum_sr_status" */
export enum Enum_Sr_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Sr_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Enum_Sr_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Sr_Status_Bool_Exp;
};

/** ENUM for sr_task's key */
export type Enum_Sr_Task_Key = {
  __typename?: 'enum_sr_task_key';
  value: Scalars['String'];
};

/** aggregated selection of "enum_sr_task_key" */
export type Enum_Sr_Task_Key_Aggregate = {
  __typename?: 'enum_sr_task_key_aggregate';
  aggregate?: Maybe<Enum_Sr_Task_Key_Aggregate_Fields>;
  nodes: Array<Enum_Sr_Task_Key>;
};

/** aggregate fields of "enum_sr_task_key" */
export type Enum_Sr_Task_Key_Aggregate_Fields = {
  __typename?: 'enum_sr_task_key_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Sr_Task_Key_Max_Fields>;
  min?: Maybe<Enum_Sr_Task_Key_Min_Fields>;
};


/** aggregate fields of "enum_sr_task_key" */
export type Enum_Sr_Task_Key_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Sr_Task_Key_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_sr_task_key". All fields are combined with a logical 'AND'. */
export type Enum_Sr_Task_Key_Bool_Exp = {
  _and?: Maybe<Array<Enum_Sr_Task_Key_Bool_Exp>>;
  _not?: Maybe<Enum_Sr_Task_Key_Bool_Exp>;
  _or?: Maybe<Array<Enum_Sr_Task_Key_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_sr_task_key" */
export enum Enum_Sr_Task_Key_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumSrTaskKeyPkey = 'enum_sr_task_key_pkey'
}

export enum Enum_Sr_Task_Key_Enum {
  Dada2 = 'dada2',
  LinkFastq = 'linkFastq',
  QcSummary = 'qcSummary',
  UploadMetadata = 'uploadMetadata'
}

/** Boolean expression to compare columns of type "enum_sr_task_key_enum". All fields are combined with logical 'AND'. */
export type Enum_Sr_Task_Key_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Sr_Task_Key_Enum>;
  _in?: Maybe<Array<Enum_Sr_Task_Key_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Sr_Task_Key_Enum>;
  _nin?: Maybe<Array<Enum_Sr_Task_Key_Enum>>;
};

/** input type for inserting data into table "enum_sr_task_key" */
export type Enum_Sr_Task_Key_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Sr_Task_Key_Max_Fields = {
  __typename?: 'enum_sr_task_key_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Sr_Task_Key_Min_Fields = {
  __typename?: 'enum_sr_task_key_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_sr_task_key" */
export type Enum_Sr_Task_Key_Mutation_Response = {
  __typename?: 'enum_sr_task_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Sr_Task_Key>;
};

/** on_conflict condition type for table "enum_sr_task_key" */
export type Enum_Sr_Task_Key_On_Conflict = {
  constraint: Enum_Sr_Task_Key_Constraint;
  update_columns?: Array<Enum_Sr_Task_Key_Update_Column>;
  where?: Maybe<Enum_Sr_Task_Key_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_sr_task_key". */
export type Enum_Sr_Task_Key_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: enum_sr_task_key */
export type Enum_Sr_Task_Key_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_sr_task_key" */
export enum Enum_Sr_Task_Key_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_sr_task_key" */
export type Enum_Sr_Task_Key_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_sr_task_key" */
export type Enum_Sr_Task_Key_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Sr_Task_Key_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Sr_Task_Key_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "enum_sr_task_key" */
export enum Enum_Sr_Task_Key_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Sr_Task_Key_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Enum_Sr_Task_Key_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Sr_Task_Key_Bool_Exp;
};

/** All the feature flags/toggles */
export type Feature_Flag = {
  __typename?: 'feature_flag';
  id: Scalars['String'];
  is_enabled: Scalars['Boolean'];
};

/** aggregated selection of "feature_flag" */
export type Feature_Flag_Aggregate = {
  __typename?: 'feature_flag_aggregate';
  aggregate?: Maybe<Feature_Flag_Aggregate_Fields>;
  nodes: Array<Feature_Flag>;
};

/** aggregate fields of "feature_flag" */
export type Feature_Flag_Aggregate_Fields = {
  __typename?: 'feature_flag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Feature_Flag_Max_Fields>;
  min?: Maybe<Feature_Flag_Min_Fields>;
};


/** aggregate fields of "feature_flag" */
export type Feature_Flag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Feature_Flag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "feature_flag". All fields are combined with a logical 'AND'. */
export type Feature_Flag_Bool_Exp = {
  _and?: Maybe<Array<Feature_Flag_Bool_Exp>>;
  _not?: Maybe<Feature_Flag_Bool_Exp>;
  _or?: Maybe<Array<Feature_Flag_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  is_enabled?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "feature_flag" */
export enum Feature_Flag_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeatureFlagPkey = 'feature_flag_pkey'
}

/** input type for inserting data into table "feature_flag" */
export type Feature_Flag_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  is_enabled?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Feature_Flag_Max_Fields = {
  __typename?: 'feature_flag_max_fields';
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Feature_Flag_Min_Fields = {
  __typename?: 'feature_flag_min_fields';
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "feature_flag" */
export type Feature_Flag_Mutation_Response = {
  __typename?: 'feature_flag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feature_Flag>;
};

/** on_conflict condition type for table "feature_flag" */
export type Feature_Flag_On_Conflict = {
  constraint: Feature_Flag_Constraint;
  update_columns?: Array<Feature_Flag_Update_Column>;
  where?: Maybe<Feature_Flag_Bool_Exp>;
};

/** Ordering options when selecting data from "feature_flag". */
export type Feature_Flag_Order_By = {
  id?: Maybe<Order_By>;
  is_enabled?: Maybe<Order_By>;
};

/** primary key columns input for table: feature_flag */
export type Feature_Flag_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "feature_flag" */
export enum Feature_Flag_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled'
}

/** input type for updating data in table "feature_flag" */
export type Feature_Flag_Set_Input = {
  id?: Maybe<Scalars['String']>;
  is_enabled?: Maybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "feature_flag" */
export type Feature_Flag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feature_Flag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feature_Flag_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['String']>;
  is_enabled?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "feature_flag" */
export enum Feature_Flag_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled'
}

export type Feature_Flag_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Feature_Flag_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feature_Flag_Bool_Exp;
};


export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<CustomJsonb>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<CustomJsonb>;
  _eq?: Maybe<CustomJsonb>;
  _gt?: Maybe<CustomJsonb>;
  _gte?: Maybe<CustomJsonb>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<CustomJsonb>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<CustomJsonb>;
  _lte?: Maybe<CustomJsonb>;
  _neq?: Maybe<CustomJsonb>;
  _nin?: Maybe<Array<CustomJsonb>>;
};

/** Table for Microbiome Downstream Analysis */
export type Mdsa = {
  __typename?: 'mdsa';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by?: Maybe<User>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  mdsa_sequencing_runs: Array<Mdsa_Sequencing_Run>;
  /** An aggregate relationship */
  mdsa_sequencing_runs_aggregate: Mdsa_Sequencing_Run_Aggregate;
  metadata_columns?: Maybe<CustomJsonb>;
  primary_feature_table_file_name: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  sample_ids?: Maybe<Array<Scalars['String']>>;
  source: Enum_Mdsa_Source_Enum;
  /** An object relationship */
  source_mdsa?: Maybe<Mdsa>;
  source_mdsa_id?: Maybe<Scalars['Int']>;
  /** The mdsa_task key upto which we copy tasks from source MDSA */
  source_mdsa_task_key?: Maybe<Enum_Mdsa_Task_Key_Enum>;
  source_root_mdsa_id?: Maybe<Scalars['Int']>;
  status: Enum_Mdsa_Status_Enum;
  tags?: Maybe<Scalars['String']>;
  /** An array relationship */
  tasks: Array<Mdsa_Task>;
  /** An aggregate relationship */
  tasks_aggregate: Mdsa_Task_Aggregate;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Table for Microbiome Downstream Analysis */
export type MdsaMdsa_Sequencing_RunsArgs = {
  distinct_on?: Maybe<Array<Mdsa_Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Sequencing_Run_Order_By>>;
  where?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
};


/** Table for Microbiome Downstream Analysis */
export type MdsaMdsa_Sequencing_Runs_AggregateArgs = {
  distinct_on?: Maybe<Array<Mdsa_Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Sequencing_Run_Order_By>>;
  where?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
};


/** Table for Microbiome Downstream Analysis */
export type MdsaMetadata_ColumnsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Table for Microbiome Downstream Analysis */
export type MdsaTasksArgs = {
  distinct_on?: Maybe<Array<Mdsa_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Task_Order_By>>;
  where?: Maybe<Mdsa_Task_Bool_Exp>;
};


/** Table for Microbiome Downstream Analysis */
export type MdsaTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Mdsa_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Task_Order_By>>;
  where?: Maybe<Mdsa_Task_Bool_Exp>;
};

/** aggregated selection of "mdsa" */
export type Mdsa_Aggregate = {
  __typename?: 'mdsa_aggregate';
  aggregate?: Maybe<Mdsa_Aggregate_Fields>;
  nodes: Array<Mdsa>;
};

/** aggregate fields of "mdsa" */
export type Mdsa_Aggregate_Fields = {
  __typename?: 'mdsa_aggregate_fields';
  avg?: Maybe<Mdsa_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mdsa_Max_Fields>;
  min?: Maybe<Mdsa_Min_Fields>;
  stddev?: Maybe<Mdsa_Stddev_Fields>;
  stddev_pop?: Maybe<Mdsa_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mdsa_Stddev_Samp_Fields>;
  sum?: Maybe<Mdsa_Sum_Fields>;
  var_pop?: Maybe<Mdsa_Var_Pop_Fields>;
  var_samp?: Maybe<Mdsa_Var_Samp_Fields>;
  variance?: Maybe<Mdsa_Variance_Fields>;
};


/** aggregate fields of "mdsa" */
export type Mdsa_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mdsa_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mdsa_Append_Input = {
  metadata_columns?: Maybe<CustomJsonb>;
};

/** aggregate avg on columns */
export type Mdsa_Avg_Fields = {
  __typename?: 'mdsa_avg_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_mdsa_id?: Maybe<Scalars['Float']>;
  source_root_mdsa_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mdsa". All fields are combined with a logical 'AND'. */
export type Mdsa_Bool_Exp = {
  _and?: Maybe<Array<Mdsa_Bool_Exp>>;
  _not?: Maybe<Mdsa_Bool_Exp>;
  _or?: Maybe<Array<Mdsa_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<User_Bool_Exp>;
  created_by_id?: Maybe<Int_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  mdsa_sequencing_runs?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
  mdsa_sequencing_runs_aggregate?: Maybe<Mdsa_Sequencing_Run_Aggregate_Bool_Exp>;
  metadata_columns?: Maybe<Jsonb_Comparison_Exp>;
  primary_feature_table_file_name?: Maybe<String_Comparison_Exp>;
  remarks?: Maybe<String_Comparison_Exp>;
  sample_ids?: Maybe<String_Array_Comparison_Exp>;
  source?: Maybe<Enum_Mdsa_Source_Enum_Comparison_Exp>;
  source_mdsa?: Maybe<Mdsa_Bool_Exp>;
  source_mdsa_id?: Maybe<Int_Comparison_Exp>;
  source_mdsa_task_key?: Maybe<Enum_Mdsa_Task_Key_Enum_Comparison_Exp>;
  source_root_mdsa_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Enum_Mdsa_Status_Enum_Comparison_Exp>;
  tags?: Maybe<String_Comparison_Exp>;
  tasks?: Maybe<Mdsa_Task_Bool_Exp>;
  tasks_aggregate?: Maybe<Mdsa_Task_Aggregate_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "mdsa" */
export enum Mdsa_Constraint {
  /** unique or primary key constraint on columns "id" */
  MdsaPkey = 'mdsa_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mdsa_Delete_At_Path_Input = {
  metadata_columns?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mdsa_Delete_Elem_Input = {
  metadata_columns?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mdsa_Delete_Key_Input = {
  metadata_columns?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "mdsa" */
export type Mdsa_Inc_Input = {
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  source_mdsa_id?: Maybe<Scalars['Int']>;
  source_root_mdsa_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "mdsa" */
export type Mdsa_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<User_Obj_Rel_Insert_Input>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mdsa_sequencing_runs?: Maybe<Mdsa_Sequencing_Run_Arr_Rel_Insert_Input>;
  metadata_columns?: Maybe<CustomJsonb>;
  primary_feature_table_file_name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  sample_ids?: Maybe<Array<Scalars['String']>>;
  source?: Maybe<Enum_Mdsa_Source_Enum>;
  source_mdsa?: Maybe<Mdsa_Obj_Rel_Insert_Input>;
  source_mdsa_id?: Maybe<Scalars['Int']>;
  /** The mdsa_task key upto which we copy tasks from source MDSA */
  source_mdsa_task_key?: Maybe<Enum_Mdsa_Task_Key_Enum>;
  source_root_mdsa_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Enum_Mdsa_Status_Enum>;
  tags?: Maybe<Scalars['String']>;
  tasks?: Maybe<Mdsa_Task_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Mdsa_Max_Fields = {
  __typename?: 'mdsa_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  primary_feature_table_file_name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  sample_ids?: Maybe<Array<Scalars['String']>>;
  source_mdsa_id?: Maybe<Scalars['Int']>;
  source_root_mdsa_id?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Mdsa_Min_Fields = {
  __typename?: 'mdsa_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  primary_feature_table_file_name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  sample_ids?: Maybe<Array<Scalars['String']>>;
  source_mdsa_id?: Maybe<Scalars['Int']>;
  source_root_mdsa_id?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "mdsa" */
export type Mdsa_Mutation_Response = {
  __typename?: 'mdsa_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mdsa>;
};

/** input type for inserting object relation for remote table "mdsa" */
export type Mdsa_Obj_Rel_Insert_Input = {
  data: Mdsa_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Mdsa_On_Conflict>;
};

/** on_conflict condition type for table "mdsa" */
export type Mdsa_On_Conflict = {
  constraint: Mdsa_Constraint;
  update_columns?: Array<Mdsa_Update_Column>;
  where?: Maybe<Mdsa_Bool_Exp>;
};

/** Ordering options when selecting data from "mdsa". */
export type Mdsa_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<User_Order_By>;
  created_by_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mdsa_sequencing_runs_aggregate?: Maybe<Mdsa_Sequencing_Run_Aggregate_Order_By>;
  metadata_columns?: Maybe<Order_By>;
  primary_feature_table_file_name?: Maybe<Order_By>;
  remarks?: Maybe<Order_By>;
  sample_ids?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_mdsa?: Maybe<Mdsa_Order_By>;
  source_mdsa_id?: Maybe<Order_By>;
  source_mdsa_task_key?: Maybe<Order_By>;
  source_root_mdsa_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  tasks_aggregate?: Maybe<Mdsa_Task_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: mdsa */
export type Mdsa_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mdsa_Prepend_Input = {
  metadata_columns?: Maybe<CustomJsonb>;
};

/** select columns of table "mdsa" */
export enum Mdsa_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MetadataColumns = 'metadata_columns',
  /** column name */
  PrimaryFeatureTableFileName = 'primary_feature_table_file_name',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  SampleIds = 'sample_ids',
  /** column name */
  Source = 'source',
  /** column name */
  SourceMdsaId = 'source_mdsa_id',
  /** column name */
  SourceMdsaTaskKey = 'source_mdsa_task_key',
  /** column name */
  SourceRootMdsaId = 'source_root_mdsa_id',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Pivot table for relationship b/w mdsa and sequencing _run */
export type Mdsa_Sequencing_Run = {
  __typename?: 'mdsa_sequencing_run';
  /** An object relationship */
  mdsa: Mdsa;
  mdsa_id: Scalars['Int'];
  sample_ids?: Maybe<Array<Scalars['String']>>;
  /** An object relationship */
  sequencing_run: Sequencing_Run;
  sr_id: Scalars['Int'];
};

/** aggregated selection of "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Aggregate = {
  __typename?: 'mdsa_sequencing_run_aggregate';
  aggregate?: Maybe<Mdsa_Sequencing_Run_Aggregate_Fields>;
  nodes: Array<Mdsa_Sequencing_Run>;
};

export type Mdsa_Sequencing_Run_Aggregate_Bool_Exp = {
  count?: Maybe<Mdsa_Sequencing_Run_Aggregate_Bool_Exp_Count>;
};

export type Mdsa_Sequencing_Run_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Mdsa_Sequencing_Run_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Aggregate_Fields = {
  __typename?: 'mdsa_sequencing_run_aggregate_fields';
  avg?: Maybe<Mdsa_Sequencing_Run_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mdsa_Sequencing_Run_Max_Fields>;
  min?: Maybe<Mdsa_Sequencing_Run_Min_Fields>;
  stddev?: Maybe<Mdsa_Sequencing_Run_Stddev_Fields>;
  stddev_pop?: Maybe<Mdsa_Sequencing_Run_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mdsa_Sequencing_Run_Stddev_Samp_Fields>;
  sum?: Maybe<Mdsa_Sequencing_Run_Sum_Fields>;
  var_pop?: Maybe<Mdsa_Sequencing_Run_Var_Pop_Fields>;
  var_samp?: Maybe<Mdsa_Sequencing_Run_Var_Samp_Fields>;
  variance?: Maybe<Mdsa_Sequencing_Run_Variance_Fields>;
};


/** aggregate fields of "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mdsa_Sequencing_Run_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Aggregate_Order_By = {
  avg?: Maybe<Mdsa_Sequencing_Run_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Mdsa_Sequencing_Run_Max_Order_By>;
  min?: Maybe<Mdsa_Sequencing_Run_Min_Order_By>;
  stddev?: Maybe<Mdsa_Sequencing_Run_Stddev_Order_By>;
  stddev_pop?: Maybe<Mdsa_Sequencing_Run_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Mdsa_Sequencing_Run_Stddev_Samp_Order_By>;
  sum?: Maybe<Mdsa_Sequencing_Run_Sum_Order_By>;
  var_pop?: Maybe<Mdsa_Sequencing_Run_Var_Pop_Order_By>;
  var_samp?: Maybe<Mdsa_Sequencing_Run_Var_Samp_Order_By>;
  variance?: Maybe<Mdsa_Sequencing_Run_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Arr_Rel_Insert_Input = {
  data: Array<Mdsa_Sequencing_Run_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Mdsa_Sequencing_Run_On_Conflict>;
};

/** aggregate avg on columns */
export type Mdsa_Sequencing_Run_Avg_Fields = {
  __typename?: 'mdsa_sequencing_run_avg_fields';
  mdsa_id?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Avg_Order_By = {
  mdsa_id?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mdsa_sequencing_run". All fields are combined with a logical 'AND'. */
export type Mdsa_Sequencing_Run_Bool_Exp = {
  _and?: Maybe<Array<Mdsa_Sequencing_Run_Bool_Exp>>;
  _not?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
  _or?: Maybe<Array<Mdsa_Sequencing_Run_Bool_Exp>>;
  mdsa?: Maybe<Mdsa_Bool_Exp>;
  mdsa_id?: Maybe<Int_Comparison_Exp>;
  sample_ids?: Maybe<String_Array_Comparison_Exp>;
  sequencing_run?: Maybe<Sequencing_Run_Bool_Exp>;
  sr_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "mdsa_sequencing_run" */
export enum Mdsa_Sequencing_Run_Constraint {
  /** unique or primary key constraint on columns "mdsa_id", "sr_id" */
  MdsaSequencingRunPkey = 'mdsa_sequencing_run_pkey'
}

/** input type for incrementing numeric columns in table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Inc_Input = {
  mdsa_id?: Maybe<Scalars['Int']>;
  sr_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Insert_Input = {
  mdsa?: Maybe<Mdsa_Obj_Rel_Insert_Input>;
  mdsa_id?: Maybe<Scalars['Int']>;
  sample_ids?: Maybe<Array<Scalars['String']>>;
  sequencing_run?: Maybe<Sequencing_Run_Obj_Rel_Insert_Input>;
  sr_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Mdsa_Sequencing_Run_Max_Fields = {
  __typename?: 'mdsa_sequencing_run_max_fields';
  mdsa_id?: Maybe<Scalars['Int']>;
  sample_ids?: Maybe<Array<Scalars['String']>>;
  sr_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Max_Order_By = {
  mdsa_id?: Maybe<Order_By>;
  sample_ids?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Mdsa_Sequencing_Run_Min_Fields = {
  __typename?: 'mdsa_sequencing_run_min_fields';
  mdsa_id?: Maybe<Scalars['Int']>;
  sample_ids?: Maybe<Array<Scalars['String']>>;
  sr_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Min_Order_By = {
  mdsa_id?: Maybe<Order_By>;
  sample_ids?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Mutation_Response = {
  __typename?: 'mdsa_sequencing_run_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mdsa_Sequencing_Run>;
};

/** on_conflict condition type for table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_On_Conflict = {
  constraint: Mdsa_Sequencing_Run_Constraint;
  update_columns?: Array<Mdsa_Sequencing_Run_Update_Column>;
  where?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
};

/** Ordering options when selecting data from "mdsa_sequencing_run". */
export type Mdsa_Sequencing_Run_Order_By = {
  mdsa?: Maybe<Mdsa_Order_By>;
  mdsa_id?: Maybe<Order_By>;
  sample_ids?: Maybe<Order_By>;
  sequencing_run?: Maybe<Sequencing_Run_Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** primary key columns input for table: mdsa_sequencing_run */
export type Mdsa_Sequencing_Run_Pk_Columns_Input = {
  mdsa_id: Scalars['Int'];
  sr_id: Scalars['Int'];
};

/** select columns of table "mdsa_sequencing_run" */
export enum Mdsa_Sequencing_Run_Select_Column {
  /** column name */
  MdsaId = 'mdsa_id',
  /** column name */
  SampleIds = 'sample_ids',
  /** column name */
  SrId = 'sr_id'
}

/** input type for updating data in table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Set_Input = {
  mdsa_id?: Maybe<Scalars['Int']>;
  sample_ids?: Maybe<Array<Scalars['String']>>;
  sr_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Mdsa_Sequencing_Run_Stddev_Fields = {
  __typename?: 'mdsa_sequencing_run_stddev_fields';
  mdsa_id?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Stddev_Order_By = {
  mdsa_id?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mdsa_Sequencing_Run_Stddev_Pop_Fields = {
  __typename?: 'mdsa_sequencing_run_stddev_pop_fields';
  mdsa_id?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Stddev_Pop_Order_By = {
  mdsa_id?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mdsa_Sequencing_Run_Stddev_Samp_Fields = {
  __typename?: 'mdsa_sequencing_run_stddev_samp_fields';
  mdsa_id?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Stddev_Samp_Order_By = {
  mdsa_id?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mdsa_Sequencing_Run_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mdsa_Sequencing_Run_Stream_Cursor_Value_Input = {
  mdsa_id?: Maybe<Scalars['Int']>;
  sample_ids?: Maybe<Array<Scalars['String']>>;
  sr_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Mdsa_Sequencing_Run_Sum_Fields = {
  __typename?: 'mdsa_sequencing_run_sum_fields';
  mdsa_id?: Maybe<Scalars['Int']>;
  sr_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Sum_Order_By = {
  mdsa_id?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** update columns of table "mdsa_sequencing_run" */
export enum Mdsa_Sequencing_Run_Update_Column {
  /** column name */
  MdsaId = 'mdsa_id',
  /** column name */
  SampleIds = 'sample_ids',
  /** column name */
  SrId = 'sr_id'
}

export type Mdsa_Sequencing_Run_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Mdsa_Sequencing_Run_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Mdsa_Sequencing_Run_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mdsa_Sequencing_Run_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mdsa_Sequencing_Run_Var_Pop_Fields = {
  __typename?: 'mdsa_sequencing_run_var_pop_fields';
  mdsa_id?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Var_Pop_Order_By = {
  mdsa_id?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mdsa_Sequencing_Run_Var_Samp_Fields = {
  __typename?: 'mdsa_sequencing_run_var_samp_fields';
  mdsa_id?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Var_Samp_Order_By = {
  mdsa_id?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Mdsa_Sequencing_Run_Variance_Fields = {
  __typename?: 'mdsa_sequencing_run_variance_fields';
  mdsa_id?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "mdsa_sequencing_run" */
export type Mdsa_Sequencing_Run_Variance_Order_By = {
  mdsa_id?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** input type for updating data in table "mdsa" */
export type Mdsa_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  metadata_columns?: Maybe<CustomJsonb>;
  primary_feature_table_file_name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  sample_ids?: Maybe<Array<Scalars['String']>>;
  source?: Maybe<Enum_Mdsa_Source_Enum>;
  source_mdsa_id?: Maybe<Scalars['Int']>;
  /** The mdsa_task key upto which we copy tasks from source MDSA */
  source_mdsa_task_key?: Maybe<Enum_Mdsa_Task_Key_Enum>;
  source_root_mdsa_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Enum_Mdsa_Status_Enum>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Mdsa_Stddev_Fields = {
  __typename?: 'mdsa_stddev_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_mdsa_id?: Maybe<Scalars['Float']>;
  source_root_mdsa_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Mdsa_Stddev_Pop_Fields = {
  __typename?: 'mdsa_stddev_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_mdsa_id?: Maybe<Scalars['Float']>;
  source_root_mdsa_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Mdsa_Stddev_Samp_Fields = {
  __typename?: 'mdsa_stddev_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_mdsa_id?: Maybe<Scalars['Float']>;
  source_root_mdsa_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "mdsa" */
export type Mdsa_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mdsa_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mdsa_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  metadata_columns?: Maybe<CustomJsonb>;
  primary_feature_table_file_name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  sample_ids?: Maybe<Array<Scalars['String']>>;
  source?: Maybe<Enum_Mdsa_Source_Enum>;
  source_mdsa_id?: Maybe<Scalars['Int']>;
  /** The mdsa_task key upto which we copy tasks from source MDSA */
  source_mdsa_task_key?: Maybe<Enum_Mdsa_Task_Key_Enum>;
  source_root_mdsa_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Enum_Mdsa_Status_Enum>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Mdsa_Sum_Fields = {
  __typename?: 'mdsa_sum_fields';
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  source_mdsa_id?: Maybe<Scalars['Int']>;
  source_root_mdsa_id?: Maybe<Scalars['Int']>;
};

/** Table for tasks of MDSA */
export type Mdsa_Task = {
  __typename?: 'mdsa_task';
  aws_batch_job?: Maybe<CustomJsonb>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dependent_on?: Maybe<CustomJsonb>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  in_files?: Maybe<CustomJsonb>;
  in_params?: Maybe<CustomJsonb>;
  is_last_position?: Maybe<Scalars['Boolean']>;
  key: Enum_Mdsa_Task_Key_Enum;
  /** An object relationship */
  mdsa: Mdsa;
  mdsa_id: Scalars['Int'];
  out_data?: Maybe<CustomJsonb>;
  out_files?: Maybe<CustomJsonb>;
  position: Scalars['Int'];
  skipped?: Maybe<Scalars['Boolean']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};


/** Table for tasks of MDSA */
export type Mdsa_TaskAws_Batch_JobArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Table for tasks of MDSA */
export type Mdsa_TaskDependent_OnArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Table for tasks of MDSA */
export type Mdsa_TaskIn_FilesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Table for tasks of MDSA */
export type Mdsa_TaskIn_ParamsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Table for tasks of MDSA */
export type Mdsa_TaskOut_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Table for tasks of MDSA */
export type Mdsa_TaskOut_FilesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "mdsa_task" */
export type Mdsa_Task_Aggregate = {
  __typename?: 'mdsa_task_aggregate';
  aggregate?: Maybe<Mdsa_Task_Aggregate_Fields>;
  nodes: Array<Mdsa_Task>;
};

export type Mdsa_Task_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Mdsa_Task_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Mdsa_Task_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Mdsa_Task_Aggregate_Bool_Exp_Count>;
};

export type Mdsa_Task_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mdsa_Task_Select_Column_Mdsa_Task_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Mdsa_Task_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mdsa_Task_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mdsa_Task_Select_Column_Mdsa_Task_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Mdsa_Task_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mdsa_Task_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Mdsa_Task_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Mdsa_Task_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mdsa_task" */
export type Mdsa_Task_Aggregate_Fields = {
  __typename?: 'mdsa_task_aggregate_fields';
  avg?: Maybe<Mdsa_Task_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mdsa_Task_Max_Fields>;
  min?: Maybe<Mdsa_Task_Min_Fields>;
  stddev?: Maybe<Mdsa_Task_Stddev_Fields>;
  stddev_pop?: Maybe<Mdsa_Task_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mdsa_Task_Stddev_Samp_Fields>;
  sum?: Maybe<Mdsa_Task_Sum_Fields>;
  var_pop?: Maybe<Mdsa_Task_Var_Pop_Fields>;
  var_samp?: Maybe<Mdsa_Task_Var_Samp_Fields>;
  variance?: Maybe<Mdsa_Task_Variance_Fields>;
};


/** aggregate fields of "mdsa_task" */
export type Mdsa_Task_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mdsa_Task_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mdsa_task" */
export type Mdsa_Task_Aggregate_Order_By = {
  avg?: Maybe<Mdsa_Task_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Mdsa_Task_Max_Order_By>;
  min?: Maybe<Mdsa_Task_Min_Order_By>;
  stddev?: Maybe<Mdsa_Task_Stddev_Order_By>;
  stddev_pop?: Maybe<Mdsa_Task_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Mdsa_Task_Stddev_Samp_Order_By>;
  sum?: Maybe<Mdsa_Task_Sum_Order_By>;
  var_pop?: Maybe<Mdsa_Task_Var_Pop_Order_By>;
  var_samp?: Maybe<Mdsa_Task_Var_Samp_Order_By>;
  variance?: Maybe<Mdsa_Task_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mdsa_Task_Append_Input = {
  aws_batch_job?: Maybe<CustomJsonb>;
  dependent_on?: Maybe<CustomJsonb>;
  in_files?: Maybe<CustomJsonb>;
  in_params?: Maybe<CustomJsonb>;
  out_data?: Maybe<CustomJsonb>;
  out_files?: Maybe<CustomJsonb>;
};

/** input type for inserting array relation for remote table "mdsa_task" */
export type Mdsa_Task_Arr_Rel_Insert_Input = {
  data: Array<Mdsa_Task_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Mdsa_Task_On_Conflict>;
};

/** aggregate avg on columns */
export type Mdsa_Task_Avg_Fields = {
  __typename?: 'mdsa_task_avg_fields';
  id?: Maybe<Scalars['Float']>;
  mdsa_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "mdsa_task" */
export type Mdsa_Task_Avg_Order_By = {
  id?: Maybe<Order_By>;
  mdsa_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mdsa_task". All fields are combined with a logical 'AND'. */
export type Mdsa_Task_Bool_Exp = {
  _and?: Maybe<Array<Mdsa_Task_Bool_Exp>>;
  _not?: Maybe<Mdsa_Task_Bool_Exp>;
  _or?: Maybe<Array<Mdsa_Task_Bool_Exp>>;
  aws_batch_job?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  dependent_on?: Maybe<Jsonb_Comparison_Exp>;
  ended_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  in_files?: Maybe<Jsonb_Comparison_Exp>;
  in_params?: Maybe<Jsonb_Comparison_Exp>;
  is_last_position?: Maybe<Boolean_Comparison_Exp>;
  key?: Maybe<Enum_Mdsa_Task_Key_Enum_Comparison_Exp>;
  mdsa?: Maybe<Mdsa_Bool_Exp>;
  mdsa_id?: Maybe<Int_Comparison_Exp>;
  out_data?: Maybe<Jsonb_Comparison_Exp>;
  out_files?: Maybe<Jsonb_Comparison_Exp>;
  position?: Maybe<Int_Comparison_Exp>;
  skipped?: Maybe<Boolean_Comparison_Exp>;
  started_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "mdsa_task" */
export enum Mdsa_Task_Constraint {
  /** unique or primary key constraint on columns "key", "mdsa_id" */
  MdsaTaskKeyMdsaIdKey = 'mdsa_task_key_mdsa_id_key',
  /** unique or primary key constraint on columns "id" */
  MdsaTaskPkey = 'mdsa_task_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mdsa_Task_Delete_At_Path_Input = {
  aws_batch_job?: Maybe<Array<Scalars['String']>>;
  dependent_on?: Maybe<Array<Scalars['String']>>;
  in_files?: Maybe<Array<Scalars['String']>>;
  in_params?: Maybe<Array<Scalars['String']>>;
  out_data?: Maybe<Array<Scalars['String']>>;
  out_files?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mdsa_Task_Delete_Elem_Input = {
  aws_batch_job?: Maybe<Scalars['Int']>;
  dependent_on?: Maybe<Scalars['Int']>;
  in_files?: Maybe<Scalars['Int']>;
  in_params?: Maybe<Scalars['Int']>;
  out_data?: Maybe<Scalars['Int']>;
  out_files?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mdsa_Task_Delete_Key_Input = {
  aws_batch_job?: Maybe<Scalars['String']>;
  dependent_on?: Maybe<Scalars['String']>;
  in_files?: Maybe<Scalars['String']>;
  in_params?: Maybe<Scalars['String']>;
  out_data?: Maybe<Scalars['String']>;
  out_files?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "mdsa_task" */
export type Mdsa_Task_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  mdsa_id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "mdsa_task" */
export type Mdsa_Task_Insert_Input = {
  aws_batch_job?: Maybe<CustomJsonb>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dependent_on?: Maybe<CustomJsonb>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  in_files?: Maybe<CustomJsonb>;
  in_params?: Maybe<CustomJsonb>;
  is_last_position?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Enum_Mdsa_Task_Key_Enum>;
  mdsa?: Maybe<Mdsa_Obj_Rel_Insert_Input>;
  mdsa_id?: Maybe<Scalars['Int']>;
  out_data?: Maybe<CustomJsonb>;
  out_files?: Maybe<CustomJsonb>;
  position?: Maybe<Scalars['Int']>;
  skipped?: Maybe<Scalars['Boolean']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Mdsa_Task_Max_Fields = {
  __typename?: 'mdsa_task_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  mdsa_id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "mdsa_task" */
export type Mdsa_Task_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mdsa_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Mdsa_Task_Min_Fields = {
  __typename?: 'mdsa_task_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  mdsa_id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "mdsa_task" */
export type Mdsa_Task_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mdsa_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "mdsa_task" */
export type Mdsa_Task_Mutation_Response = {
  __typename?: 'mdsa_task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mdsa_Task>;
};

/** on_conflict condition type for table "mdsa_task" */
export type Mdsa_Task_On_Conflict = {
  constraint: Mdsa_Task_Constraint;
  update_columns?: Array<Mdsa_Task_Update_Column>;
  where?: Maybe<Mdsa_Task_Bool_Exp>;
};

/** Ordering options when selecting data from "mdsa_task". */
export type Mdsa_Task_Order_By = {
  aws_batch_job?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  dependent_on?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  in_files?: Maybe<Order_By>;
  in_params?: Maybe<Order_By>;
  is_last_position?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  mdsa?: Maybe<Mdsa_Order_By>;
  mdsa_id?: Maybe<Order_By>;
  out_data?: Maybe<Order_By>;
  out_files?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  skipped?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: mdsa_task */
export type Mdsa_Task_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mdsa_Task_Prepend_Input = {
  aws_batch_job?: Maybe<CustomJsonb>;
  dependent_on?: Maybe<CustomJsonb>;
  in_files?: Maybe<CustomJsonb>;
  in_params?: Maybe<CustomJsonb>;
  out_data?: Maybe<CustomJsonb>;
  out_files?: Maybe<CustomJsonb>;
};

/** select columns of table "mdsa_task" */
export enum Mdsa_Task_Select_Column {
  /** column name */
  AwsBatchJob = 'aws_batch_job',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DependentOn = 'dependent_on',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  InFiles = 'in_files',
  /** column name */
  InParams = 'in_params',
  /** column name */
  IsLastPosition = 'is_last_position',
  /** column name */
  Key = 'key',
  /** column name */
  MdsaId = 'mdsa_id',
  /** column name */
  OutData = 'out_data',
  /** column name */
  OutFiles = 'out_files',
  /** column name */
  Position = 'position',
  /** column name */
  Skipped = 'skipped',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "mdsa_task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "mdsa_task" */
export enum Mdsa_Task_Select_Column_Mdsa_Task_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsLastPosition = 'is_last_position',
  /** column name */
  Skipped = 'skipped'
}

/** select "mdsa_task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "mdsa_task" */
export enum Mdsa_Task_Select_Column_Mdsa_Task_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsLastPosition = 'is_last_position',
  /** column name */
  Skipped = 'skipped'
}

/** input type for updating data in table "mdsa_task" */
export type Mdsa_Task_Set_Input = {
  aws_batch_job?: Maybe<CustomJsonb>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dependent_on?: Maybe<CustomJsonb>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  in_files?: Maybe<CustomJsonb>;
  in_params?: Maybe<CustomJsonb>;
  is_last_position?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Enum_Mdsa_Task_Key_Enum>;
  mdsa_id?: Maybe<Scalars['Int']>;
  out_data?: Maybe<CustomJsonb>;
  out_files?: Maybe<CustomJsonb>;
  position?: Maybe<Scalars['Int']>;
  skipped?: Maybe<Scalars['Boolean']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Mdsa_Task_Stddev_Fields = {
  __typename?: 'mdsa_task_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  mdsa_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "mdsa_task" */
export type Mdsa_Task_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  mdsa_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mdsa_Task_Stddev_Pop_Fields = {
  __typename?: 'mdsa_task_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  mdsa_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "mdsa_task" */
export type Mdsa_Task_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  mdsa_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mdsa_Task_Stddev_Samp_Fields = {
  __typename?: 'mdsa_task_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  mdsa_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "mdsa_task" */
export type Mdsa_Task_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  mdsa_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** Streaming cursor of the table "mdsa_task" */
export type Mdsa_Task_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mdsa_Task_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mdsa_Task_Stream_Cursor_Value_Input = {
  aws_batch_job?: Maybe<CustomJsonb>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dependent_on?: Maybe<CustomJsonb>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  in_files?: Maybe<CustomJsonb>;
  in_params?: Maybe<CustomJsonb>;
  is_last_position?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Enum_Mdsa_Task_Key_Enum>;
  mdsa_id?: Maybe<Scalars['Int']>;
  out_data?: Maybe<CustomJsonb>;
  out_files?: Maybe<CustomJsonb>;
  position?: Maybe<Scalars['Int']>;
  skipped?: Maybe<Scalars['Boolean']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Mdsa_Task_Sum_Fields = {
  __typename?: 'mdsa_task_sum_fields';
  id?: Maybe<Scalars['Int']>;
  mdsa_id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "mdsa_task" */
export type Mdsa_Task_Sum_Order_By = {
  id?: Maybe<Order_By>;
  mdsa_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** update columns of table "mdsa_task" */
export enum Mdsa_Task_Update_Column {
  /** column name */
  AwsBatchJob = 'aws_batch_job',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DependentOn = 'dependent_on',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  InFiles = 'in_files',
  /** column name */
  InParams = 'in_params',
  /** column name */
  IsLastPosition = 'is_last_position',
  /** column name */
  Key = 'key',
  /** column name */
  MdsaId = 'mdsa_id',
  /** column name */
  OutData = 'out_data',
  /** column name */
  OutFiles = 'out_files',
  /** column name */
  Position = 'position',
  /** column name */
  Skipped = 'skipped',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mdsa_Task_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Mdsa_Task_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Mdsa_Task_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Mdsa_Task_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Mdsa_Task_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Mdsa_Task_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Mdsa_Task_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Mdsa_Task_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mdsa_Task_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mdsa_Task_Var_Pop_Fields = {
  __typename?: 'mdsa_task_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  mdsa_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "mdsa_task" */
export type Mdsa_Task_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  mdsa_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mdsa_Task_Var_Samp_Fields = {
  __typename?: 'mdsa_task_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  mdsa_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "mdsa_task" */
export type Mdsa_Task_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  mdsa_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Mdsa_Task_Variance_Fields = {
  __typename?: 'mdsa_task_variance_fields';
  id?: Maybe<Scalars['Float']>;
  mdsa_id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "mdsa_task" */
export type Mdsa_Task_Variance_Order_By = {
  id?: Maybe<Order_By>;
  mdsa_id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
};

/** update columns of table "mdsa" */
export enum Mdsa_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MetadataColumns = 'metadata_columns',
  /** column name */
  PrimaryFeatureTableFileName = 'primary_feature_table_file_name',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  SampleIds = 'sample_ids',
  /** column name */
  Source = 'source',
  /** column name */
  SourceMdsaId = 'source_mdsa_id',
  /** column name */
  SourceMdsaTaskKey = 'source_mdsa_task_key',
  /** column name */
  SourceRootMdsaId = 'source_root_mdsa_id',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mdsa_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Mdsa_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Mdsa_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Mdsa_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Mdsa_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Mdsa_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Mdsa_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Mdsa_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mdsa_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mdsa_Var_Pop_Fields = {
  __typename?: 'mdsa_var_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_mdsa_id?: Maybe<Scalars['Float']>;
  source_root_mdsa_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Mdsa_Var_Samp_Fields = {
  __typename?: 'mdsa_var_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_mdsa_id?: Maybe<Scalars['Float']>;
  source_root_mdsa_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Mdsa_Variance_Fields = {
  __typename?: 'mdsa_variance_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  source_mdsa_id?: Maybe<Scalars['Float']>;
  source_root_mdsa_id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  cloudwatchLogs: StatusOutput;
  dataSyncStartTaskExecution?: Maybe<TaskExecutionOutput>;
  deleteFile?: Maybe<DeleteFileOutput>;
  /** delete data from the table: "enum_mdsa_source" */
  delete_enum_mdsa_source?: Maybe<Enum_Mdsa_Source_Mutation_Response>;
  /** delete single row from the table: "enum_mdsa_source" */
  delete_enum_mdsa_source_by_pk?: Maybe<Enum_Mdsa_Source>;
  /** delete data from the table: "enum_mdsa_status" */
  delete_enum_mdsa_status?: Maybe<Enum_Mdsa_Status_Mutation_Response>;
  /** delete single row from the table: "enum_mdsa_status" */
  delete_enum_mdsa_status_by_pk?: Maybe<Enum_Mdsa_Status>;
  /** delete data from the table: "enum_mdsa_task_key" */
  delete_enum_mdsa_task_key?: Maybe<Enum_Mdsa_Task_Key_Mutation_Response>;
  /** delete single row from the table: "enum_mdsa_task_key" */
  delete_enum_mdsa_task_key_by_pk?: Maybe<Enum_Mdsa_Task_Key>;
  /** delete data from the table: "enum_sequencing_type" */
  delete_enum_sequencing_type?: Maybe<Enum_Sequencing_Type_Mutation_Response>;
  /** delete single row from the table: "enum_sequencing_type" */
  delete_enum_sequencing_type_by_pk?: Maybe<Enum_Sequencing_Type>;
  /** delete data from the table: "enum_settings_value_type" */
  delete_enum_settings_value_type?: Maybe<Enum_Settings_Value_Type_Mutation_Response>;
  /** delete single row from the table: "enum_settings_value_type" */
  delete_enum_settings_value_type_by_pk?: Maybe<Enum_Settings_Value_Type>;
  /** delete data from the table: "enum_sr_status" */
  delete_enum_sr_status?: Maybe<Enum_Sr_Status_Mutation_Response>;
  /** delete single row from the table: "enum_sr_status" */
  delete_enum_sr_status_by_pk?: Maybe<Enum_Sr_Status>;
  /** delete data from the table: "enum_sr_task_key" */
  delete_enum_sr_task_key?: Maybe<Enum_Sr_Task_Key_Mutation_Response>;
  /** delete single row from the table: "enum_sr_task_key" */
  delete_enum_sr_task_key_by_pk?: Maybe<Enum_Sr_Task_Key>;
  /** delete data from the table: "feature_flag" */
  delete_feature_flag?: Maybe<Feature_Flag_Mutation_Response>;
  /** delete single row from the table: "feature_flag" */
  delete_feature_flag_by_pk?: Maybe<Feature_Flag>;
  /** delete data from the table: "mdsa" */
  delete_mdsa?: Maybe<Mdsa_Mutation_Response>;
  /** delete single row from the table: "mdsa" */
  delete_mdsa_by_pk?: Maybe<Mdsa>;
  /** delete data from the table: "mdsa_sequencing_run" */
  delete_mdsa_sequencing_run?: Maybe<Mdsa_Sequencing_Run_Mutation_Response>;
  /** delete single row from the table: "mdsa_sequencing_run" */
  delete_mdsa_sequencing_run_by_pk?: Maybe<Mdsa_Sequencing_Run>;
  /** delete data from the table: "mdsa_task" */
  delete_mdsa_task?: Maybe<Mdsa_Task_Mutation_Response>;
  /** delete single row from the table: "mdsa_task" */
  delete_mdsa_task_by_pk?: Maybe<Mdsa_Task>;
  /** delete data from the table: "project" */
  delete_project?: Maybe<Project_Mutation_Response>;
  /** delete single row from the table: "project" */
  delete_project_by_pk?: Maybe<Project>;
  /** delete data from the table: "project_user" */
  delete_project_user?: Maybe<Project_User_Mutation_Response>;
  /** delete single row from the table: "project_user" */
  delete_project_user_by_pk?: Maybe<Project_User>;
  /** delete data from the table: "sequencing_run" */
  delete_sequencing_run?: Maybe<Sequencing_Run_Mutation_Response>;
  /** delete single row from the table: "sequencing_run" */
  delete_sequencing_run_by_pk?: Maybe<Sequencing_Run>;
  /** delete data from the table: "settings" */
  delete_settings?: Maybe<Settings_Mutation_Response>;
  /** delete single row from the table: "settings" */
  delete_settings_by_pk?: Maybe<Settings>;
  /** delete data from the table: "sr_task" */
  delete_sr_task?: Maybe<Sr_Task_Mutation_Response>;
  /** delete single row from the table: "sr_task" */
  delete_sr_task_by_pk?: Maybe<Sr_Task>;
  /** delete data from the table: "taxonomy_classifier" */
  delete_taxonomy_classifier?: Maybe<Taxonomy_Classifier_Mutation_Response>;
  /** delete single row from the table: "taxonomy_classifier" */
  delete_taxonomy_classifier_by_pk?: Maybe<Taxonomy_Classifier>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** insert data into the table: "enum_mdsa_source" */
  insert_enum_mdsa_source?: Maybe<Enum_Mdsa_Source_Mutation_Response>;
  /** insert a single row into the table: "enum_mdsa_source" */
  insert_enum_mdsa_source_one?: Maybe<Enum_Mdsa_Source>;
  /** insert data into the table: "enum_mdsa_status" */
  insert_enum_mdsa_status?: Maybe<Enum_Mdsa_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_mdsa_status" */
  insert_enum_mdsa_status_one?: Maybe<Enum_Mdsa_Status>;
  /** insert data into the table: "enum_mdsa_task_key" */
  insert_enum_mdsa_task_key?: Maybe<Enum_Mdsa_Task_Key_Mutation_Response>;
  /** insert a single row into the table: "enum_mdsa_task_key" */
  insert_enum_mdsa_task_key_one?: Maybe<Enum_Mdsa_Task_Key>;
  /** insert data into the table: "enum_sequencing_type" */
  insert_enum_sequencing_type?: Maybe<Enum_Sequencing_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_sequencing_type" */
  insert_enum_sequencing_type_one?: Maybe<Enum_Sequencing_Type>;
  /** insert data into the table: "enum_settings_value_type" */
  insert_enum_settings_value_type?: Maybe<Enum_Settings_Value_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_settings_value_type" */
  insert_enum_settings_value_type_one?: Maybe<Enum_Settings_Value_Type>;
  /** insert data into the table: "enum_sr_status" */
  insert_enum_sr_status?: Maybe<Enum_Sr_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_sr_status" */
  insert_enum_sr_status_one?: Maybe<Enum_Sr_Status>;
  /** insert data into the table: "enum_sr_task_key" */
  insert_enum_sr_task_key?: Maybe<Enum_Sr_Task_Key_Mutation_Response>;
  /** insert a single row into the table: "enum_sr_task_key" */
  insert_enum_sr_task_key_one?: Maybe<Enum_Sr_Task_Key>;
  /** insert data into the table: "feature_flag" */
  insert_feature_flag?: Maybe<Feature_Flag_Mutation_Response>;
  /** insert a single row into the table: "feature_flag" */
  insert_feature_flag_one?: Maybe<Feature_Flag>;
  /** insert data into the table: "mdsa" */
  insert_mdsa?: Maybe<Mdsa_Mutation_Response>;
  /** insert a single row into the table: "mdsa" */
  insert_mdsa_one?: Maybe<Mdsa>;
  /** insert data into the table: "mdsa_sequencing_run" */
  insert_mdsa_sequencing_run?: Maybe<Mdsa_Sequencing_Run_Mutation_Response>;
  /** insert a single row into the table: "mdsa_sequencing_run" */
  insert_mdsa_sequencing_run_one?: Maybe<Mdsa_Sequencing_Run>;
  /** insert data into the table: "mdsa_task" */
  insert_mdsa_task?: Maybe<Mdsa_Task_Mutation_Response>;
  /** insert a single row into the table: "mdsa_task" */
  insert_mdsa_task_one?: Maybe<Mdsa_Task>;
  /** insert data into the table: "project" */
  insert_project?: Maybe<Project_Mutation_Response>;
  /** insert a single row into the table: "project" */
  insert_project_one?: Maybe<Project>;
  /** insert data into the table: "project_user" */
  insert_project_user?: Maybe<Project_User_Mutation_Response>;
  /** insert a single row into the table: "project_user" */
  insert_project_user_one?: Maybe<Project_User>;
  /** insert data into the table: "sequencing_run" */
  insert_sequencing_run?: Maybe<Sequencing_Run_Mutation_Response>;
  /** insert a single row into the table: "sequencing_run" */
  insert_sequencing_run_one?: Maybe<Sequencing_Run>;
  /** insert data into the table: "settings" */
  insert_settings?: Maybe<Settings_Mutation_Response>;
  /** insert a single row into the table: "settings" */
  insert_settings_one?: Maybe<Settings>;
  /** insert data into the table: "sr_task" */
  insert_sr_task?: Maybe<Sr_Task_Mutation_Response>;
  /** insert a single row into the table: "sr_task" */
  insert_sr_task_one?: Maybe<Sr_Task>;
  /** insert data into the table: "taxonomy_classifier" */
  insert_taxonomy_classifier?: Maybe<Taxonomy_Classifier_Mutation_Response>;
  /** insert a single row into the table: "taxonomy_classifier" */
  insert_taxonomy_classifier_one?: Maybe<Taxonomy_Classifier>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  mdsaCopyTasksFromSource?: Maybe<StatusOutput>;
  newHmqMdsa?: Maybe<NewHmqMdsaOutput>;
  resetMdsaUptoTask?: Maybe<ResetMdsaUptoTaskOutput>;
  srAutomatedAnalysis?: Maybe<StatusOutput>;
  startQiimeAnalysis?: Maybe<StatusOutput>;
  updateSRCreatedById?: Maybe<StatusOutput>;
  /** update data of the table: "enum_mdsa_source" */
  update_enum_mdsa_source?: Maybe<Enum_Mdsa_Source_Mutation_Response>;
  /** update single row of the table: "enum_mdsa_source" */
  update_enum_mdsa_source_by_pk?: Maybe<Enum_Mdsa_Source>;
  /** update multiples rows of table: "enum_mdsa_source" */
  update_enum_mdsa_source_many?: Maybe<Array<Maybe<Enum_Mdsa_Source_Mutation_Response>>>;
  /** update data of the table: "enum_mdsa_status" */
  update_enum_mdsa_status?: Maybe<Enum_Mdsa_Status_Mutation_Response>;
  /** update single row of the table: "enum_mdsa_status" */
  update_enum_mdsa_status_by_pk?: Maybe<Enum_Mdsa_Status>;
  /** update multiples rows of table: "enum_mdsa_status" */
  update_enum_mdsa_status_many?: Maybe<Array<Maybe<Enum_Mdsa_Status_Mutation_Response>>>;
  /** update data of the table: "enum_mdsa_task_key" */
  update_enum_mdsa_task_key?: Maybe<Enum_Mdsa_Task_Key_Mutation_Response>;
  /** update single row of the table: "enum_mdsa_task_key" */
  update_enum_mdsa_task_key_by_pk?: Maybe<Enum_Mdsa_Task_Key>;
  /** update multiples rows of table: "enum_mdsa_task_key" */
  update_enum_mdsa_task_key_many?: Maybe<Array<Maybe<Enum_Mdsa_Task_Key_Mutation_Response>>>;
  /** update data of the table: "enum_sequencing_type" */
  update_enum_sequencing_type?: Maybe<Enum_Sequencing_Type_Mutation_Response>;
  /** update single row of the table: "enum_sequencing_type" */
  update_enum_sequencing_type_by_pk?: Maybe<Enum_Sequencing_Type>;
  /** update multiples rows of table: "enum_sequencing_type" */
  update_enum_sequencing_type_many?: Maybe<Array<Maybe<Enum_Sequencing_Type_Mutation_Response>>>;
  /** update data of the table: "enum_settings_value_type" */
  update_enum_settings_value_type?: Maybe<Enum_Settings_Value_Type_Mutation_Response>;
  /** update single row of the table: "enum_settings_value_type" */
  update_enum_settings_value_type_by_pk?: Maybe<Enum_Settings_Value_Type>;
  /** update multiples rows of table: "enum_settings_value_type" */
  update_enum_settings_value_type_many?: Maybe<Array<Maybe<Enum_Settings_Value_Type_Mutation_Response>>>;
  /** update data of the table: "enum_sr_status" */
  update_enum_sr_status?: Maybe<Enum_Sr_Status_Mutation_Response>;
  /** update single row of the table: "enum_sr_status" */
  update_enum_sr_status_by_pk?: Maybe<Enum_Sr_Status>;
  /** update multiples rows of table: "enum_sr_status" */
  update_enum_sr_status_many?: Maybe<Array<Maybe<Enum_Sr_Status_Mutation_Response>>>;
  /** update data of the table: "enum_sr_task_key" */
  update_enum_sr_task_key?: Maybe<Enum_Sr_Task_Key_Mutation_Response>;
  /** update single row of the table: "enum_sr_task_key" */
  update_enum_sr_task_key_by_pk?: Maybe<Enum_Sr_Task_Key>;
  /** update multiples rows of table: "enum_sr_task_key" */
  update_enum_sr_task_key_many?: Maybe<Array<Maybe<Enum_Sr_Task_Key_Mutation_Response>>>;
  /** update data of the table: "feature_flag" */
  update_feature_flag?: Maybe<Feature_Flag_Mutation_Response>;
  /** update single row of the table: "feature_flag" */
  update_feature_flag_by_pk?: Maybe<Feature_Flag>;
  /** update multiples rows of table: "feature_flag" */
  update_feature_flag_many?: Maybe<Array<Maybe<Feature_Flag_Mutation_Response>>>;
  /** update data of the table: "mdsa" */
  update_mdsa?: Maybe<Mdsa_Mutation_Response>;
  /** update single row of the table: "mdsa" */
  update_mdsa_by_pk?: Maybe<Mdsa>;
  /** update multiples rows of table: "mdsa" */
  update_mdsa_many?: Maybe<Array<Maybe<Mdsa_Mutation_Response>>>;
  /** update data of the table: "mdsa_sequencing_run" */
  update_mdsa_sequencing_run?: Maybe<Mdsa_Sequencing_Run_Mutation_Response>;
  /** update single row of the table: "mdsa_sequencing_run" */
  update_mdsa_sequencing_run_by_pk?: Maybe<Mdsa_Sequencing_Run>;
  /** update multiples rows of table: "mdsa_sequencing_run" */
  update_mdsa_sequencing_run_many?: Maybe<Array<Maybe<Mdsa_Sequencing_Run_Mutation_Response>>>;
  /** update data of the table: "mdsa_task" */
  update_mdsa_task?: Maybe<Mdsa_Task_Mutation_Response>;
  /** update single row of the table: "mdsa_task" */
  update_mdsa_task_by_pk?: Maybe<Mdsa_Task>;
  /** update multiples rows of table: "mdsa_task" */
  update_mdsa_task_many?: Maybe<Array<Maybe<Mdsa_Task_Mutation_Response>>>;
  /** update data of the table: "project" */
  update_project?: Maybe<Project_Mutation_Response>;
  /** update single row of the table: "project" */
  update_project_by_pk?: Maybe<Project>;
  /** update multiples rows of table: "project" */
  update_project_many?: Maybe<Array<Maybe<Project_Mutation_Response>>>;
  /** update data of the table: "project_user" */
  update_project_user?: Maybe<Project_User_Mutation_Response>;
  /** update single row of the table: "project_user" */
  update_project_user_by_pk?: Maybe<Project_User>;
  /** update multiples rows of table: "project_user" */
  update_project_user_many?: Maybe<Array<Maybe<Project_User_Mutation_Response>>>;
  /** update data of the table: "sequencing_run" */
  update_sequencing_run?: Maybe<Sequencing_Run_Mutation_Response>;
  /** update single row of the table: "sequencing_run" */
  update_sequencing_run_by_pk?: Maybe<Sequencing_Run>;
  /** update multiples rows of table: "sequencing_run" */
  update_sequencing_run_many?: Maybe<Array<Maybe<Sequencing_Run_Mutation_Response>>>;
  /** update data of the table: "settings" */
  update_settings?: Maybe<Settings_Mutation_Response>;
  /** update single row of the table: "settings" */
  update_settings_by_pk?: Maybe<Settings>;
  /** update multiples rows of table: "settings" */
  update_settings_many?: Maybe<Array<Maybe<Settings_Mutation_Response>>>;
  /** update data of the table: "sr_task" */
  update_sr_task?: Maybe<Sr_Task_Mutation_Response>;
  /** update single row of the table: "sr_task" */
  update_sr_task_by_pk?: Maybe<Sr_Task>;
  /** update multiples rows of table: "sr_task" */
  update_sr_task_many?: Maybe<Array<Maybe<Sr_Task_Mutation_Response>>>;
  /** update data of the table: "taxonomy_classifier" */
  update_taxonomy_classifier?: Maybe<Taxonomy_Classifier_Mutation_Response>;
  /** update single row of the table: "taxonomy_classifier" */
  update_taxonomy_classifier_by_pk?: Maybe<Taxonomy_Classifier>;
  /** update multiples rows of table: "taxonomy_classifier" */
  update_taxonomy_classifier_many?: Maybe<Array<Maybe<Taxonomy_Classifier_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootCloudwatchLogsArgs = {
  args: CloudwatchLogsInput;
};


/** mutation root */
export type Mutation_RootDeleteFileArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Mdsa_SourceArgs = {
  where: Enum_Mdsa_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Mdsa_Source_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Mdsa_StatusArgs = {
  where: Enum_Mdsa_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Mdsa_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Mdsa_Task_KeyArgs = {
  where: Enum_Mdsa_Task_Key_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Mdsa_Task_Key_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Sequencing_TypeArgs = {
  where: Enum_Sequencing_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Sequencing_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Settings_Value_TypeArgs = {
  where: Enum_Settings_Value_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Settings_Value_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Sr_StatusArgs = {
  where: Enum_Sr_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Sr_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Sr_Task_KeyArgs = {
  where: Enum_Sr_Task_Key_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Sr_Task_Key_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Feature_FlagArgs = {
  where: Feature_Flag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Feature_Flag_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_MdsaArgs = {
  where: Mdsa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mdsa_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Mdsa_Sequencing_RunArgs = {
  where: Mdsa_Sequencing_Run_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mdsa_Sequencing_Run_By_PkArgs = {
  mdsa_id: Scalars['Int'];
  sr_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Mdsa_TaskArgs = {
  where: Mdsa_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mdsa_Task_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectArgs = {
  where: Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Project_UserArgs = {
  where: Project_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_User_By_PkArgs = {
  project_id: Scalars['Int'];
  user_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Sequencing_RunArgs = {
  where: Sequencing_Run_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sequencing_Run_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SettingsArgs = {
  where: Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Settings_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Sr_TaskArgs = {
  where: Sr_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sr_Task_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_ClassifierArgs = {
  where: Taxonomy_Classifier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_Classifier_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_Enum_Mdsa_SourceArgs = {
  objects: Array<Enum_Mdsa_Source_Insert_Input>;
  on_conflict?: Maybe<Enum_Mdsa_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Mdsa_Source_OneArgs = {
  object: Enum_Mdsa_Source_Insert_Input;
  on_conflict?: Maybe<Enum_Mdsa_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Mdsa_StatusArgs = {
  objects: Array<Enum_Mdsa_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Mdsa_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Mdsa_Status_OneArgs = {
  object: Enum_Mdsa_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Mdsa_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Mdsa_Task_KeyArgs = {
  objects: Array<Enum_Mdsa_Task_Key_Insert_Input>;
  on_conflict?: Maybe<Enum_Mdsa_Task_Key_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Mdsa_Task_Key_OneArgs = {
  object: Enum_Mdsa_Task_Key_Insert_Input;
  on_conflict?: Maybe<Enum_Mdsa_Task_Key_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Sequencing_TypeArgs = {
  objects: Array<Enum_Sequencing_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Sequencing_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Sequencing_Type_OneArgs = {
  object: Enum_Sequencing_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Sequencing_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Settings_Value_TypeArgs = {
  objects: Array<Enum_Settings_Value_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Settings_Value_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Settings_Value_Type_OneArgs = {
  object: Enum_Settings_Value_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Settings_Value_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Sr_StatusArgs = {
  objects: Array<Enum_Sr_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Sr_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Sr_Status_OneArgs = {
  object: Enum_Sr_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Sr_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Sr_Task_KeyArgs = {
  objects: Array<Enum_Sr_Task_Key_Insert_Input>;
  on_conflict?: Maybe<Enum_Sr_Task_Key_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Sr_Task_Key_OneArgs = {
  object: Enum_Sr_Task_Key_Insert_Input;
  on_conflict?: Maybe<Enum_Sr_Task_Key_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feature_FlagArgs = {
  objects: Array<Feature_Flag_Insert_Input>;
  on_conflict?: Maybe<Feature_Flag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feature_Flag_OneArgs = {
  object: Feature_Flag_Insert_Input;
  on_conflict?: Maybe<Feature_Flag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MdsaArgs = {
  objects: Array<Mdsa_Insert_Input>;
  on_conflict?: Maybe<Mdsa_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mdsa_OneArgs = {
  object: Mdsa_Insert_Input;
  on_conflict?: Maybe<Mdsa_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mdsa_Sequencing_RunArgs = {
  objects: Array<Mdsa_Sequencing_Run_Insert_Input>;
  on_conflict?: Maybe<Mdsa_Sequencing_Run_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mdsa_Sequencing_Run_OneArgs = {
  object: Mdsa_Sequencing_Run_Insert_Input;
  on_conflict?: Maybe<Mdsa_Sequencing_Run_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mdsa_TaskArgs = {
  objects: Array<Mdsa_Task_Insert_Input>;
  on_conflict?: Maybe<Mdsa_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mdsa_Task_OneArgs = {
  object: Mdsa_Task_Insert_Input;
  on_conflict?: Maybe<Mdsa_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectArgs = {
  objects: Array<Project_Insert_Input>;
  on_conflict?: Maybe<Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_OneArgs = {
  object: Project_Insert_Input;
  on_conflict?: Maybe<Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_UserArgs = {
  objects: Array<Project_User_Insert_Input>;
  on_conflict?: Maybe<Project_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_User_OneArgs = {
  object: Project_User_Insert_Input;
  on_conflict?: Maybe<Project_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequencing_RunArgs = {
  objects: Array<Sequencing_Run_Insert_Input>;
  on_conflict?: Maybe<Sequencing_Run_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequencing_Run_OneArgs = {
  object: Sequencing_Run_Insert_Input;
  on_conflict?: Maybe<Sequencing_Run_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SettingsArgs = {
  objects: Array<Settings_Insert_Input>;
  on_conflict?: Maybe<Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Settings_OneArgs = {
  object: Settings_Insert_Input;
  on_conflict?: Maybe<Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sr_TaskArgs = {
  objects: Array<Sr_Task_Insert_Input>;
  on_conflict?: Maybe<Sr_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sr_Task_OneArgs = {
  object: Sr_Task_Insert_Input;
  on_conflict?: Maybe<Sr_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_ClassifierArgs = {
  objects: Array<Taxonomy_Classifier_Insert_Input>;
  on_conflict?: Maybe<Taxonomy_Classifier_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Classifier_OneArgs = {
  object: Taxonomy_Classifier_Insert_Input;
  on_conflict?: Maybe<Taxonomy_Classifier_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootMdsaCopyTasksFromSourceArgs = {
  destinationMdsaId: Scalars['Int'];
  lastTaskPosition: Scalars['Int'];
  sourceMdsaId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootNewHmqMdsaArgs = {
  sr_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootResetMdsaUptoTaskArgs = {
  mdsaId: Scalars['Int'];
  resetMdsaUptoTaskPosition: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSrAutomatedAnalysisArgs = {
  srId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootStartQiimeAnalysisArgs = {
  setStartedAt?: Maybe<Scalars['Boolean']>;
  taskId: Scalars['Int'];
  taskType: TaskType;
};


/** mutation root */
export type Mutation_RootUpdateSrCreatedByIdArgs = {
  updates: Array<UpdateSrCreatedByIdInput>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Mdsa_SourceArgs = {
  _set?: Maybe<Enum_Mdsa_Source_Set_Input>;
  where: Enum_Mdsa_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Mdsa_Source_By_PkArgs = {
  _set?: Maybe<Enum_Mdsa_Source_Set_Input>;
  pk_columns: Enum_Mdsa_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Mdsa_Source_ManyArgs = {
  updates: Array<Enum_Mdsa_Source_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Mdsa_StatusArgs = {
  _set?: Maybe<Enum_Mdsa_Status_Set_Input>;
  where: Enum_Mdsa_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Mdsa_Status_By_PkArgs = {
  _set?: Maybe<Enum_Mdsa_Status_Set_Input>;
  pk_columns: Enum_Mdsa_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Mdsa_Status_ManyArgs = {
  updates: Array<Enum_Mdsa_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Mdsa_Task_KeyArgs = {
  _set?: Maybe<Enum_Mdsa_Task_Key_Set_Input>;
  where: Enum_Mdsa_Task_Key_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Mdsa_Task_Key_By_PkArgs = {
  _set?: Maybe<Enum_Mdsa_Task_Key_Set_Input>;
  pk_columns: Enum_Mdsa_Task_Key_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Mdsa_Task_Key_ManyArgs = {
  updates: Array<Enum_Mdsa_Task_Key_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Sequencing_TypeArgs = {
  _set?: Maybe<Enum_Sequencing_Type_Set_Input>;
  where: Enum_Sequencing_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Sequencing_Type_By_PkArgs = {
  _set?: Maybe<Enum_Sequencing_Type_Set_Input>;
  pk_columns: Enum_Sequencing_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Sequencing_Type_ManyArgs = {
  updates: Array<Enum_Sequencing_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Settings_Value_TypeArgs = {
  _set?: Maybe<Enum_Settings_Value_Type_Set_Input>;
  where: Enum_Settings_Value_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Settings_Value_Type_By_PkArgs = {
  _set?: Maybe<Enum_Settings_Value_Type_Set_Input>;
  pk_columns: Enum_Settings_Value_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Settings_Value_Type_ManyArgs = {
  updates: Array<Enum_Settings_Value_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Sr_StatusArgs = {
  _set?: Maybe<Enum_Sr_Status_Set_Input>;
  where: Enum_Sr_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Sr_Status_By_PkArgs = {
  _set?: Maybe<Enum_Sr_Status_Set_Input>;
  pk_columns: Enum_Sr_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Sr_Status_ManyArgs = {
  updates: Array<Enum_Sr_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Sr_Task_KeyArgs = {
  _set?: Maybe<Enum_Sr_Task_Key_Set_Input>;
  where: Enum_Sr_Task_Key_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Sr_Task_Key_By_PkArgs = {
  _set?: Maybe<Enum_Sr_Task_Key_Set_Input>;
  pk_columns: Enum_Sr_Task_Key_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Sr_Task_Key_ManyArgs = {
  updates: Array<Enum_Sr_Task_Key_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Feature_FlagArgs = {
  _set?: Maybe<Feature_Flag_Set_Input>;
  where: Feature_Flag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Feature_Flag_By_PkArgs = {
  _set?: Maybe<Feature_Flag_Set_Input>;
  pk_columns: Feature_Flag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Feature_Flag_ManyArgs = {
  updates: Array<Feature_Flag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MdsaArgs = {
  _append?: Maybe<Mdsa_Append_Input>;
  _delete_at_path?: Maybe<Mdsa_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Mdsa_Delete_Elem_Input>;
  _delete_key?: Maybe<Mdsa_Delete_Key_Input>;
  _inc?: Maybe<Mdsa_Inc_Input>;
  _prepend?: Maybe<Mdsa_Prepend_Input>;
  _set?: Maybe<Mdsa_Set_Input>;
  where: Mdsa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mdsa_By_PkArgs = {
  _append?: Maybe<Mdsa_Append_Input>;
  _delete_at_path?: Maybe<Mdsa_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Mdsa_Delete_Elem_Input>;
  _delete_key?: Maybe<Mdsa_Delete_Key_Input>;
  _inc?: Maybe<Mdsa_Inc_Input>;
  _prepend?: Maybe<Mdsa_Prepend_Input>;
  _set?: Maybe<Mdsa_Set_Input>;
  pk_columns: Mdsa_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mdsa_ManyArgs = {
  updates: Array<Mdsa_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mdsa_Sequencing_RunArgs = {
  _inc?: Maybe<Mdsa_Sequencing_Run_Inc_Input>;
  _set?: Maybe<Mdsa_Sequencing_Run_Set_Input>;
  where: Mdsa_Sequencing_Run_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mdsa_Sequencing_Run_By_PkArgs = {
  _inc?: Maybe<Mdsa_Sequencing_Run_Inc_Input>;
  _set?: Maybe<Mdsa_Sequencing_Run_Set_Input>;
  pk_columns: Mdsa_Sequencing_Run_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mdsa_Sequencing_Run_ManyArgs = {
  updates: Array<Mdsa_Sequencing_Run_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mdsa_TaskArgs = {
  _append?: Maybe<Mdsa_Task_Append_Input>;
  _delete_at_path?: Maybe<Mdsa_Task_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Mdsa_Task_Delete_Elem_Input>;
  _delete_key?: Maybe<Mdsa_Task_Delete_Key_Input>;
  _inc?: Maybe<Mdsa_Task_Inc_Input>;
  _prepend?: Maybe<Mdsa_Task_Prepend_Input>;
  _set?: Maybe<Mdsa_Task_Set_Input>;
  where: Mdsa_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mdsa_Task_By_PkArgs = {
  _append?: Maybe<Mdsa_Task_Append_Input>;
  _delete_at_path?: Maybe<Mdsa_Task_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Mdsa_Task_Delete_Elem_Input>;
  _delete_key?: Maybe<Mdsa_Task_Delete_Key_Input>;
  _inc?: Maybe<Mdsa_Task_Inc_Input>;
  _prepend?: Maybe<Mdsa_Task_Prepend_Input>;
  _set?: Maybe<Mdsa_Task_Set_Input>;
  pk_columns: Mdsa_Task_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mdsa_Task_ManyArgs = {
  updates: Array<Mdsa_Task_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectArgs = {
  _append?: Maybe<Project_Append_Input>;
  _delete_at_path?: Maybe<Project_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Project_Delete_Elem_Input>;
  _delete_key?: Maybe<Project_Delete_Key_Input>;
  _inc?: Maybe<Project_Inc_Input>;
  _prepend?: Maybe<Project_Prepend_Input>;
  _set?: Maybe<Project_Set_Input>;
  where: Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_By_PkArgs = {
  _append?: Maybe<Project_Append_Input>;
  _delete_at_path?: Maybe<Project_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Project_Delete_Elem_Input>;
  _delete_key?: Maybe<Project_Delete_Key_Input>;
  _inc?: Maybe<Project_Inc_Input>;
  _prepend?: Maybe<Project_Prepend_Input>;
  _set?: Maybe<Project_Set_Input>;
  pk_columns: Project_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_ManyArgs = {
  updates: Array<Project_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_UserArgs = {
  _inc?: Maybe<Project_User_Inc_Input>;
  _set?: Maybe<Project_User_Set_Input>;
  where: Project_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_By_PkArgs = {
  _inc?: Maybe<Project_User_Inc_Input>;
  _set?: Maybe<Project_User_Set_Input>;
  pk_columns: Project_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_ManyArgs = {
  updates: Array<Project_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sequencing_RunArgs = {
  _inc?: Maybe<Sequencing_Run_Inc_Input>;
  _set?: Maybe<Sequencing_Run_Set_Input>;
  where: Sequencing_Run_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sequencing_Run_By_PkArgs = {
  _inc?: Maybe<Sequencing_Run_Inc_Input>;
  _set?: Maybe<Sequencing_Run_Set_Input>;
  pk_columns: Sequencing_Run_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sequencing_Run_ManyArgs = {
  updates: Array<Sequencing_Run_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SettingsArgs = {
  _inc?: Maybe<Settings_Inc_Input>;
  _set?: Maybe<Settings_Set_Input>;
  where: Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Settings_By_PkArgs = {
  _inc?: Maybe<Settings_Inc_Input>;
  _set?: Maybe<Settings_Set_Input>;
  pk_columns: Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Settings_ManyArgs = {
  updates: Array<Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sr_TaskArgs = {
  _append?: Maybe<Sr_Task_Append_Input>;
  _delete_at_path?: Maybe<Sr_Task_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Sr_Task_Delete_Elem_Input>;
  _delete_key?: Maybe<Sr_Task_Delete_Key_Input>;
  _inc?: Maybe<Sr_Task_Inc_Input>;
  _prepend?: Maybe<Sr_Task_Prepend_Input>;
  _set?: Maybe<Sr_Task_Set_Input>;
  where: Sr_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sr_Task_By_PkArgs = {
  _append?: Maybe<Sr_Task_Append_Input>;
  _delete_at_path?: Maybe<Sr_Task_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Sr_Task_Delete_Elem_Input>;
  _delete_key?: Maybe<Sr_Task_Delete_Key_Input>;
  _inc?: Maybe<Sr_Task_Inc_Input>;
  _prepend?: Maybe<Sr_Task_Prepend_Input>;
  _set?: Maybe<Sr_Task_Set_Input>;
  pk_columns: Sr_Task_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sr_Task_ManyArgs = {
  updates: Array<Sr_Task_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_ClassifierArgs = {
  _inc?: Maybe<Taxonomy_Classifier_Inc_Input>;
  _set?: Maybe<Taxonomy_Classifier_Set_Input>;
  where: Taxonomy_Classifier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_Classifier_By_PkArgs = {
  _inc?: Maybe<Taxonomy_Classifier_Inc_Input>;
  _set?: Maybe<Taxonomy_Classifier_Set_Input>;
  pk_columns: Taxonomy_Classifier_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_Classifier_ManyArgs = {
  updates: Array<Taxonomy_Classifier_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _inc?: Maybe<User_Inc_Input>;
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _inc?: Maybe<User_Inc_Input>;
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** Table for all projects */
export type Project = {
  __typename?: 'project';
  business_unit?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by: User;
  created_by_id: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  /** An array relationship */
  project_users: Array<Project_User>;
  /** An aggregate relationship */
  project_users_aggregate: Project_User_Aggregate;
  protocol_s3_key?: Maybe<Scalars['String']>;
  qiime_metadata_columns?: Maybe<CustomJsonb>;
  /** An array relationship */
  sequencing_runs: Array<Sequencing_Run>;
  /** An aggregate relationship */
  sequencing_runs_aggregate: Sequencing_Run_Aggregate;
  species: Scalars['String'];
  start_date?: Maybe<Scalars['date']>;
  tags?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Table for all projects */
export type ProjectProject_UsersArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


/** Table for all projects */
export type ProjectProject_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


/** Table for all projects */
export type ProjectQiime_Metadata_ColumnsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Table for all projects */
export type ProjectSequencing_RunsArgs = {
  distinct_on?: Maybe<Array<Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequencing_Run_Order_By>>;
  where?: Maybe<Sequencing_Run_Bool_Exp>;
};


/** Table for all projects */
export type ProjectSequencing_Runs_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequencing_Run_Order_By>>;
  where?: Maybe<Sequencing_Run_Bool_Exp>;
};

/** aggregated selection of "project" */
export type Project_Aggregate = {
  __typename?: 'project_aggregate';
  aggregate?: Maybe<Project_Aggregate_Fields>;
  nodes: Array<Project>;
};

/** aggregate fields of "project" */
export type Project_Aggregate_Fields = {
  __typename?: 'project_aggregate_fields';
  avg?: Maybe<Project_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Max_Fields>;
  min?: Maybe<Project_Min_Fields>;
  stddev?: Maybe<Project_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Sum_Fields>;
  var_pop?: Maybe<Project_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Var_Samp_Fields>;
  variance?: Maybe<Project_Variance_Fields>;
};


/** aggregate fields of "project" */
export type Project_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Project_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Project_Append_Input = {
  qiime_metadata_columns?: Maybe<CustomJsonb>;
};

/** aggregate avg on columns */
export type Project_Avg_Fields = {
  __typename?: 'project_avg_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project". All fields are combined with a logical 'AND'. */
export type Project_Bool_Exp = {
  _and?: Maybe<Array<Project_Bool_Exp>>;
  _not?: Maybe<Project_Bool_Exp>;
  _or?: Maybe<Array<Project_Bool_Exp>>;
  business_unit?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<User_Bool_Exp>;
  created_by_id?: Maybe<Int_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  end_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  project_users?: Maybe<Project_User_Bool_Exp>;
  project_users_aggregate?: Maybe<Project_User_Aggregate_Bool_Exp>;
  protocol_s3_key?: Maybe<String_Comparison_Exp>;
  qiime_metadata_columns?: Maybe<Jsonb_Comparison_Exp>;
  sequencing_runs?: Maybe<Sequencing_Run_Bool_Exp>;
  sequencing_runs_aggregate?: Maybe<Sequencing_Run_Aggregate_Bool_Exp>;
  species?: Maybe<String_Comparison_Exp>;
  start_date?: Maybe<Date_Comparison_Exp>;
  tags?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "project" */
export enum Project_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectPkey = 'project_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Project_Delete_At_Path_Input = {
  qiime_metadata_columns?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Project_Delete_Elem_Input = {
  qiime_metadata_columns?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Project_Delete_Key_Input = {
  qiime_metadata_columns?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "project" */
export type Project_Inc_Input = {
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "project" */
export type Project_Insert_Input = {
  business_unit?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<User_Obj_Rel_Insert_Input>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  project_users?: Maybe<Project_User_Arr_Rel_Insert_Input>;
  protocol_s3_key?: Maybe<Scalars['String']>;
  qiime_metadata_columns?: Maybe<CustomJsonb>;
  sequencing_runs?: Maybe<Sequencing_Run_Arr_Rel_Insert_Input>;
  species?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Project_Max_Fields = {
  __typename?: 'project_max_fields';
  business_unit?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  protocol_s3_key?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Project_Min_Fields = {
  __typename?: 'project_min_fields';
  business_unit?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  protocol_s3_key?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "project" */
export type Project_Mutation_Response = {
  __typename?: 'project_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project>;
};

/** input type for inserting object relation for remote table "project" */
export type Project_Obj_Rel_Insert_Input = {
  data: Project_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Project_On_Conflict>;
};

/** on_conflict condition type for table "project" */
export type Project_On_Conflict = {
  constraint: Project_Constraint;
  update_columns?: Array<Project_Update_Column>;
  where?: Maybe<Project_Bool_Exp>;
};

/** Ordering options when selecting data from "project". */
export type Project_Order_By = {
  business_unit?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<User_Order_By>;
  created_by_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_users_aggregate?: Maybe<Project_User_Aggregate_Order_By>;
  protocol_s3_key?: Maybe<Order_By>;
  qiime_metadata_columns?: Maybe<Order_By>;
  sequencing_runs_aggregate?: Maybe<Sequencing_Run_Aggregate_Order_By>;
  species?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: project */
export type Project_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Project_Prepend_Input = {
  qiime_metadata_columns?: Maybe<CustomJsonb>;
};

/** select columns of table "project" */
export enum Project_Select_Column {
  /** column name */
  BusinessUnit = 'business_unit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  ProtocolS3Key = 'protocol_s3_key',
  /** column name */
  QiimeMetadataColumns = 'qiime_metadata_columns',
  /** column name */
  Species = 'species',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Tags = 'tags',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "project" */
export type Project_Set_Input = {
  business_unit?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  protocol_s3_key?: Maybe<Scalars['String']>;
  qiime_metadata_columns?: Maybe<CustomJsonb>;
  species?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Project_Stddev_Fields = {
  __typename?: 'project_stddev_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Stddev_Pop_Fields = {
  __typename?: 'project_stddev_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Stddev_Samp_Fields = {
  __typename?: 'project_stddev_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project" */
export type Project_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Stream_Cursor_Value_Input = {
  business_unit?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  protocol_s3_key?: Maybe<Scalars['String']>;
  qiime_metadata_columns?: Maybe<CustomJsonb>;
  species?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Project_Sum_Fields = {
  __typename?: 'project_sum_fields';
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "project" */
export enum Project_Update_Column {
  /** column name */
  BusinessUnit = 'business_unit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  ProtocolS3Key = 'protocol_s3_key',
  /** column name */
  QiimeMetadataColumns = 'qiime_metadata_columns',
  /** column name */
  Species = 'species',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Tags = 'tags',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Project_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Project_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Project_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Project_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Project_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Project_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Project_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Project_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Bool_Exp;
};

/** Pivot table for storing users' access to projects */
export type Project_User = {
  __typename?: 'project_user';
  /** An object relationship */
  project: Project;
  project_id: Scalars['Int'];
  /** An object relationship */
  user: User;
  user_id: Scalars['Int'];
};

/** aggregated selection of "project_user" */
export type Project_User_Aggregate = {
  __typename?: 'project_user_aggregate';
  aggregate?: Maybe<Project_User_Aggregate_Fields>;
  nodes: Array<Project_User>;
};

export type Project_User_Aggregate_Bool_Exp = {
  count?: Maybe<Project_User_Aggregate_Bool_Exp_Count>;
};

export type Project_User_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Project_User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Project_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_user" */
export type Project_User_Aggregate_Fields = {
  __typename?: 'project_user_aggregate_fields';
  avg?: Maybe<Project_User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_User_Max_Fields>;
  min?: Maybe<Project_User_Min_Fields>;
  stddev?: Maybe<Project_User_Stddev_Fields>;
  stddev_pop?: Maybe<Project_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_User_Stddev_Samp_Fields>;
  sum?: Maybe<Project_User_Sum_Fields>;
  var_pop?: Maybe<Project_User_Var_Pop_Fields>;
  var_samp?: Maybe<Project_User_Var_Samp_Fields>;
  variance?: Maybe<Project_User_Variance_Fields>;
};


/** aggregate fields of "project_user" */
export type Project_User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Project_User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_user" */
export type Project_User_Aggregate_Order_By = {
  avg?: Maybe<Project_User_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Project_User_Max_Order_By>;
  min?: Maybe<Project_User_Min_Order_By>;
  stddev?: Maybe<Project_User_Stddev_Order_By>;
  stddev_pop?: Maybe<Project_User_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Project_User_Stddev_Samp_Order_By>;
  sum?: Maybe<Project_User_Sum_Order_By>;
  var_pop?: Maybe<Project_User_Var_Pop_Order_By>;
  var_samp?: Maybe<Project_User_Var_Samp_Order_By>;
  variance?: Maybe<Project_User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project_user" */
export type Project_User_Arr_Rel_Insert_Input = {
  data: Array<Project_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Project_User_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_User_Avg_Fields = {
  __typename?: 'project_user_avg_fields';
  project_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_user" */
export type Project_User_Avg_Order_By = {
  project_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_user". All fields are combined with a logical 'AND'. */
export type Project_User_Bool_Exp = {
  _and?: Maybe<Array<Project_User_Bool_Exp>>;
  _not?: Maybe<Project_User_Bool_Exp>;
  _or?: Maybe<Array<Project_User_Bool_Exp>>;
  project?: Maybe<Project_Bool_Exp>;
  project_id?: Maybe<Int_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_user" */
export enum Project_User_Constraint {
  /** unique or primary key constraint on columns "project_id", "user_id" */
  ProjectUserPkey = 'project_user_pkey'
}

/** input type for incrementing numeric columns in table "project_user" */
export type Project_User_Inc_Input = {
  project_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_user" */
export type Project_User_Insert_Input = {
  project?: Maybe<Project_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['Int']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Project_User_Max_Fields = {
  __typename?: 'project_user_max_fields';
  project_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "project_user" */
export type Project_User_Max_Order_By = {
  project_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_User_Min_Fields = {
  __typename?: 'project_user_min_fields';
  project_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "project_user" */
export type Project_User_Min_Order_By = {
  project_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "project_user" */
export type Project_User_Mutation_Response = {
  __typename?: 'project_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_User>;
};

/** on_conflict condition type for table "project_user" */
export type Project_User_On_Conflict = {
  constraint: Project_User_Constraint;
  update_columns?: Array<Project_User_Update_Column>;
  where?: Maybe<Project_User_Bool_Exp>;
};

/** Ordering options when selecting data from "project_user". */
export type Project_User_Order_By = {
  project?: Maybe<Project_Order_By>;
  project_id?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: project_user */
export type Project_User_Pk_Columns_Input = {
  project_id: Scalars['Int'];
  user_id: Scalars['Int'];
};

/** select columns of table "project_user" */
export enum Project_User_Select_Column {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "project_user" */
export type Project_User_Set_Input = {
  project_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Project_User_Stddev_Fields = {
  __typename?: 'project_user_stddev_fields';
  project_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_user" */
export type Project_User_Stddev_Order_By = {
  project_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_User_Stddev_Pop_Fields = {
  __typename?: 'project_user_stddev_pop_fields';
  project_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_user" */
export type Project_User_Stddev_Pop_Order_By = {
  project_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_User_Stddev_Samp_Fields = {
  __typename?: 'project_user_stddev_samp_fields';
  project_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_user" */
export type Project_User_Stddev_Samp_Order_By = {
  project_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "project_user" */
export type Project_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_User_Stream_Cursor_Value_Input = {
  project_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Project_User_Sum_Fields = {
  __typename?: 'project_user_sum_fields';
  project_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project_user" */
export type Project_User_Sum_Order_By = {
  project_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "project_user" */
export enum Project_User_Update_Column {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UserId = 'user_id'
}

export type Project_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Project_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Project_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_User_Var_Pop_Fields = {
  __typename?: 'project_user_var_pop_fields';
  project_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_user" */
export type Project_User_Var_Pop_Order_By = {
  project_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_User_Var_Samp_Fields = {
  __typename?: 'project_user_var_samp_fields';
  project_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_user" */
export type Project_User_Var_Samp_Order_By = {
  project_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_User_Variance_Fields = {
  __typename?: 'project_user_variance_fields';
  project_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_user" */
export type Project_User_Variance_Order_By = {
  project_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Project_Var_Pop_Fields = {
  __typename?: 'project_var_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Var_Samp_Fields = {
  __typename?: 'project_var_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Variance_Fields = {
  __typename?: 'project_variance_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  dataSyncDescribeTaskExecution?: Maybe<DescribeTaskExecutionOutput>;
  /** fetch data from the table: "enum_mdsa_source" */
  enum_mdsa_source: Array<Enum_Mdsa_Source>;
  /** fetch aggregated fields from the table: "enum_mdsa_source" */
  enum_mdsa_source_aggregate: Enum_Mdsa_Source_Aggregate;
  /** fetch data from the table: "enum_mdsa_source" using primary key columns */
  enum_mdsa_source_by_pk?: Maybe<Enum_Mdsa_Source>;
  /** fetch data from the table: "enum_mdsa_status" */
  enum_mdsa_status: Array<Enum_Mdsa_Status>;
  /** fetch aggregated fields from the table: "enum_mdsa_status" */
  enum_mdsa_status_aggregate: Enum_Mdsa_Status_Aggregate;
  /** fetch data from the table: "enum_mdsa_status" using primary key columns */
  enum_mdsa_status_by_pk?: Maybe<Enum_Mdsa_Status>;
  /** fetch data from the table: "enum_mdsa_task_key" */
  enum_mdsa_task_key: Array<Enum_Mdsa_Task_Key>;
  /** fetch aggregated fields from the table: "enum_mdsa_task_key" */
  enum_mdsa_task_key_aggregate: Enum_Mdsa_Task_Key_Aggregate;
  /** fetch data from the table: "enum_mdsa_task_key" using primary key columns */
  enum_mdsa_task_key_by_pk?: Maybe<Enum_Mdsa_Task_Key>;
  /** fetch data from the table: "enum_sequencing_type" */
  enum_sequencing_type: Array<Enum_Sequencing_Type>;
  /** fetch aggregated fields from the table: "enum_sequencing_type" */
  enum_sequencing_type_aggregate: Enum_Sequencing_Type_Aggregate;
  /** fetch data from the table: "enum_sequencing_type" using primary key columns */
  enum_sequencing_type_by_pk?: Maybe<Enum_Sequencing_Type>;
  /** fetch data from the table: "enum_settings_value_type" */
  enum_settings_value_type: Array<Enum_Settings_Value_Type>;
  /** fetch aggregated fields from the table: "enum_settings_value_type" */
  enum_settings_value_type_aggregate: Enum_Settings_Value_Type_Aggregate;
  /** fetch data from the table: "enum_settings_value_type" using primary key columns */
  enum_settings_value_type_by_pk?: Maybe<Enum_Settings_Value_Type>;
  /** fetch data from the table: "enum_sr_status" */
  enum_sr_status: Array<Enum_Sr_Status>;
  /** fetch aggregated fields from the table: "enum_sr_status" */
  enum_sr_status_aggregate: Enum_Sr_Status_Aggregate;
  /** fetch data from the table: "enum_sr_status" using primary key columns */
  enum_sr_status_by_pk?: Maybe<Enum_Sr_Status>;
  /** fetch data from the table: "enum_sr_task_key" */
  enum_sr_task_key: Array<Enum_Sr_Task_Key>;
  /** fetch aggregated fields from the table: "enum_sr_task_key" */
  enum_sr_task_key_aggregate: Enum_Sr_Task_Key_Aggregate;
  /** fetch data from the table: "enum_sr_task_key" using primary key columns */
  enum_sr_task_key_by_pk?: Maybe<Enum_Sr_Task_Key>;
  /** fetch data from the table: "feature_flag" */
  feature_flag: Array<Feature_Flag>;
  /** fetch aggregated fields from the table: "feature_flag" */
  feature_flag_aggregate: Feature_Flag_Aggregate;
  /** fetch data from the table: "feature_flag" using primary key columns */
  feature_flag_by_pk?: Maybe<Feature_Flag>;
  fileUploadS3Config?: Maybe<Array<Maybe<FileUploadS3ConfigOutput>>>;
  getFilesUrl?: Maybe<Array<Maybe<GetFilesUrlOutput>>>;
  /** fetch data from the table: "mdsa" */
  mdsa: Array<Mdsa>;
  mdsaFileUploadConfig?: Maybe<Array<Maybe<FileUploadS3ConfigOutput>>>;
  mdsaOutFileUrl?: Maybe<MdsaOutFileUrlOutput>;
  /** fetch aggregated fields from the table: "mdsa" */
  mdsa_aggregate: Mdsa_Aggregate;
  /** fetch data from the table: "mdsa" using primary key columns */
  mdsa_by_pk?: Maybe<Mdsa>;
  /** fetch data from the table: "mdsa_sequencing_run" */
  mdsa_sequencing_run: Array<Mdsa_Sequencing_Run>;
  /** fetch aggregated fields from the table: "mdsa_sequencing_run" */
  mdsa_sequencing_run_aggregate: Mdsa_Sequencing_Run_Aggregate;
  /** fetch data from the table: "mdsa_sequencing_run" using primary key columns */
  mdsa_sequencing_run_by_pk?: Maybe<Mdsa_Sequencing_Run>;
  /** fetch data from the table: "mdsa_task" */
  mdsa_task: Array<Mdsa_Task>;
  /** fetch aggregated fields from the table: "mdsa_task" */
  mdsa_task_aggregate: Mdsa_Task_Aggregate;
  /** fetch data from the table: "mdsa_task" using primary key columns */
  mdsa_task_by_pk?: Maybe<Mdsa_Task>;
  me?: Maybe<MeOutput>;
  /** fetch data from the table: "project" */
  project: Array<Project>;
  /** fetch aggregated fields from the table: "project" */
  project_aggregate: Project_Aggregate;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<Project>;
  /** fetch data from the table: "project_user" */
  project_user: Array<Project_User>;
  /** fetch aggregated fields from the table: "project_user" */
  project_user_aggregate: Project_User_Aggregate;
  /** fetch data from the table: "project_user" using primary key columns */
  project_user_by_pk?: Maybe<Project_User>;
  /** fetch data from the table: "sequencing_run" */
  sequencing_run: Array<Sequencing_Run>;
  /** fetch aggregated fields from the table: "sequencing_run" */
  sequencing_run_aggregate: Sequencing_Run_Aggregate;
  /** fetch data from the table: "sequencing_run" using primary key columns */
  sequencing_run_by_pk?: Maybe<Sequencing_Run>;
  /** fetch data from the table: "settings" */
  settings: Array<Settings>;
  /** fetch aggregated fields from the table: "settings" */
  settings_aggregate: Settings_Aggregate;
  /** fetch data from the table: "settings" using primary key columns */
  settings_by_pk?: Maybe<Settings>;
  srCheckFastq?: Maybe<Array<Maybe<SrCheckFastqOutput>>>;
  srFileUploadConfig?: Maybe<Array<Maybe<FileUploadS3ConfigOutput>>>;
  srOutFileUrl?: Maybe<SrOutFileUrlOutput>;
  /** fetch data from the table: "sr_task" */
  sr_task: Array<Sr_Task>;
  /** fetch aggregated fields from the table: "sr_task" */
  sr_task_aggregate: Sr_Task_Aggregate;
  /** fetch data from the table: "sr_task" using primary key columns */
  sr_task_by_pk?: Maybe<Sr_Task>;
  /** fetch data from the table: "taxonomy_classifier" */
  taxonomy_classifier: Array<Taxonomy_Classifier>;
  /** fetch aggregated fields from the table: "taxonomy_classifier" */
  taxonomy_classifier_aggregate: Taxonomy_Classifier_Aggregate;
  /** fetch data from the table: "taxonomy_classifier" using primary key columns */
  taxonomy_classifier_by_pk?: Maybe<Taxonomy_Classifier>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
};


export type Query_RootDataSyncDescribeTaskExecutionArgs = {
  taskExecutionArn: Scalars['String'];
};


export type Query_RootEnum_Mdsa_SourceArgs = {
  distinct_on?: Maybe<Array<Enum_Mdsa_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Mdsa_Source_Order_By>>;
  where?: Maybe<Enum_Mdsa_Source_Bool_Exp>;
};


export type Query_RootEnum_Mdsa_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Mdsa_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Mdsa_Source_Order_By>>;
  where?: Maybe<Enum_Mdsa_Source_Bool_Exp>;
};


export type Query_RootEnum_Mdsa_Source_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Mdsa_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Mdsa_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Mdsa_Status_Order_By>>;
  where?: Maybe<Enum_Mdsa_Status_Bool_Exp>;
};


export type Query_RootEnum_Mdsa_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Mdsa_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Mdsa_Status_Order_By>>;
  where?: Maybe<Enum_Mdsa_Status_Bool_Exp>;
};


export type Query_RootEnum_Mdsa_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Mdsa_Task_KeyArgs = {
  distinct_on?: Maybe<Array<Enum_Mdsa_Task_Key_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Mdsa_Task_Key_Order_By>>;
  where?: Maybe<Enum_Mdsa_Task_Key_Bool_Exp>;
};


export type Query_RootEnum_Mdsa_Task_Key_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Mdsa_Task_Key_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Mdsa_Task_Key_Order_By>>;
  where?: Maybe<Enum_Mdsa_Task_Key_Bool_Exp>;
};


export type Query_RootEnum_Mdsa_Task_Key_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Sequencing_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Sequencing_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Sequencing_Type_Order_By>>;
  where?: Maybe<Enum_Sequencing_Type_Bool_Exp>;
};


export type Query_RootEnum_Sequencing_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Sequencing_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Sequencing_Type_Order_By>>;
  where?: Maybe<Enum_Sequencing_Type_Bool_Exp>;
};


export type Query_RootEnum_Sequencing_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Settings_Value_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Settings_Value_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Settings_Value_Type_Order_By>>;
  where?: Maybe<Enum_Settings_Value_Type_Bool_Exp>;
};


export type Query_RootEnum_Settings_Value_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Settings_Value_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Settings_Value_Type_Order_By>>;
  where?: Maybe<Enum_Settings_Value_Type_Bool_Exp>;
};


export type Query_RootEnum_Settings_Value_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Sr_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Sr_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Sr_Status_Order_By>>;
  where?: Maybe<Enum_Sr_Status_Bool_Exp>;
};


export type Query_RootEnum_Sr_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Sr_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Sr_Status_Order_By>>;
  where?: Maybe<Enum_Sr_Status_Bool_Exp>;
};


export type Query_RootEnum_Sr_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Sr_Task_KeyArgs = {
  distinct_on?: Maybe<Array<Enum_Sr_Task_Key_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Sr_Task_Key_Order_By>>;
  where?: Maybe<Enum_Sr_Task_Key_Bool_Exp>;
};


export type Query_RootEnum_Sr_Task_Key_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Sr_Task_Key_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Sr_Task_Key_Order_By>>;
  where?: Maybe<Enum_Sr_Task_Key_Bool_Exp>;
};


export type Query_RootEnum_Sr_Task_Key_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFeature_FlagArgs = {
  distinct_on?: Maybe<Array<Feature_Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feature_Flag_Order_By>>;
  where?: Maybe<Feature_Flag_Bool_Exp>;
};


export type Query_RootFeature_Flag_AggregateArgs = {
  distinct_on?: Maybe<Array<Feature_Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feature_Flag_Order_By>>;
  where?: Maybe<Feature_Flag_Bool_Exp>;
};


export type Query_RootFeature_Flag_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootFileUploadS3ConfigArgs = {
  files: Array<FileUploadS3ConfigInput>;
};


export type Query_RootGetFilesUrlArgs = {
  keys: Array<Scalars['String']>;
};


export type Query_RootMdsaArgs = {
  distinct_on?: Maybe<Array<Mdsa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Order_By>>;
  where?: Maybe<Mdsa_Bool_Exp>;
};


export type Query_RootMdsaFileUploadConfigArgs = {
  files: Array<FileUploadS3ConfigInput>;
  mdsa_id: Scalars['Int'];
};


export type Query_RootMdsaOutFileUrlArgs = {
  fileName: Scalars['String'];
  mdsa_id: Scalars['Int'];
};


export type Query_RootMdsa_AggregateArgs = {
  distinct_on?: Maybe<Array<Mdsa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Order_By>>;
  where?: Maybe<Mdsa_Bool_Exp>;
};


export type Query_RootMdsa_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMdsa_Sequencing_RunArgs = {
  distinct_on?: Maybe<Array<Mdsa_Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Sequencing_Run_Order_By>>;
  where?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
};


export type Query_RootMdsa_Sequencing_Run_AggregateArgs = {
  distinct_on?: Maybe<Array<Mdsa_Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Sequencing_Run_Order_By>>;
  where?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
};


export type Query_RootMdsa_Sequencing_Run_By_PkArgs = {
  mdsa_id: Scalars['Int'];
  sr_id: Scalars['Int'];
};


export type Query_RootMdsa_TaskArgs = {
  distinct_on?: Maybe<Array<Mdsa_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Task_Order_By>>;
  where?: Maybe<Mdsa_Task_Bool_Exp>;
};


export type Query_RootMdsa_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Mdsa_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Task_Order_By>>;
  where?: Maybe<Mdsa_Task_Bool_Exp>;
};


export type Query_RootMdsa_Task_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProjectArgs = {
  distinct_on?: Maybe<Array<Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Order_By>>;
  where?: Maybe<Project_Bool_Exp>;
};


export type Query_RootProject_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Order_By>>;
  where?: Maybe<Project_Bool_Exp>;
};


export type Query_RootProject_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProject_UserArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


export type Query_RootProject_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


export type Query_RootProject_User_By_PkArgs = {
  project_id: Scalars['Int'];
  user_id: Scalars['Int'];
};


export type Query_RootSequencing_RunArgs = {
  distinct_on?: Maybe<Array<Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequencing_Run_Order_By>>;
  where?: Maybe<Sequencing_Run_Bool_Exp>;
};


export type Query_RootSequencing_Run_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequencing_Run_Order_By>>;
  where?: Maybe<Sequencing_Run_Bool_Exp>;
};


export type Query_RootSequencing_Run_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSettingsArgs = {
  distinct_on?: Maybe<Array<Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Settings_Order_By>>;
  where?: Maybe<Settings_Bool_Exp>;
};


export type Query_RootSettings_AggregateArgs = {
  distinct_on?: Maybe<Array<Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Settings_Order_By>>;
  where?: Maybe<Settings_Bool_Exp>;
};


export type Query_RootSettings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSrCheckFastqArgs = {
  sr_id: Scalars['Int'];
};


export type Query_RootSrFileUploadConfigArgs = {
  files: Array<FileUploadS3ConfigInput>;
  sr_id: Scalars['Int'];
};


export type Query_RootSrOutFileUrlArgs = {
  fileName: Scalars['String'];
  sr_id: Scalars['Int'];
};


export type Query_RootSr_TaskArgs = {
  distinct_on?: Maybe<Array<Sr_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sr_Task_Order_By>>;
  where?: Maybe<Sr_Task_Bool_Exp>;
};


export type Query_RootSr_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Sr_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sr_Task_Order_By>>;
  where?: Maybe<Sr_Task_Bool_Exp>;
};


export type Query_RootSr_Task_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTaxonomy_ClassifierArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Classifier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Classifier_Order_By>>;
  where?: Maybe<Taxonomy_Classifier_Bool_Exp>;
};


export type Query_RootTaxonomy_Classifier_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Classifier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Classifier_Order_By>>;
  where?: Maybe<Taxonomy_Classifier_Bool_Exp>;
};


export type Query_RootTaxonomy_Classifier_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};

/** Table for Sequencing Runs */
export type Sequencing_Run = {
  __typename?: 'sequencing_run';
  analysis_completed_at?: Maybe<Scalars['timestamptz']>;
  analysis_started_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by?: Maybe<User>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  project?: Maybe<Project>;
  project_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  sequencing_run_mdsas: Array<Mdsa_Sequencing_Run>;
  /** An aggregate relationship */
  sequencing_run_mdsas_aggregate: Mdsa_Sequencing_Run_Aggregate;
  status: Enum_Sr_Status_Enum;
  tags?: Maybe<Scalars['String']>;
  /** An array relationship */
  tasks: Array<Sr_Task>;
  /** An aggregate relationship */
  tasks_aggregate: Sr_Task_Aggregate;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Sequencing_Type_Enum>;
  updated_at: Scalars['timestamptz'];
};


/** Table for Sequencing Runs */
export type Sequencing_RunSequencing_Run_MdsasArgs = {
  distinct_on?: Maybe<Array<Mdsa_Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Sequencing_Run_Order_By>>;
  where?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
};


/** Table for Sequencing Runs */
export type Sequencing_RunSequencing_Run_Mdsas_AggregateArgs = {
  distinct_on?: Maybe<Array<Mdsa_Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Sequencing_Run_Order_By>>;
  where?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
};


/** Table for Sequencing Runs */
export type Sequencing_RunTasksArgs = {
  distinct_on?: Maybe<Array<Sr_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sr_Task_Order_By>>;
  where?: Maybe<Sr_Task_Bool_Exp>;
};


/** Table for Sequencing Runs */
export type Sequencing_RunTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Sr_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sr_Task_Order_By>>;
  where?: Maybe<Sr_Task_Bool_Exp>;
};

/** aggregated selection of "sequencing_run" */
export type Sequencing_Run_Aggregate = {
  __typename?: 'sequencing_run_aggregate';
  aggregate?: Maybe<Sequencing_Run_Aggregate_Fields>;
  nodes: Array<Sequencing_Run>;
};

export type Sequencing_Run_Aggregate_Bool_Exp = {
  count?: Maybe<Sequencing_Run_Aggregate_Bool_Exp_Count>;
};

export type Sequencing_Run_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sequencing_Run_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Sequencing_Run_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sequencing_run" */
export type Sequencing_Run_Aggregate_Fields = {
  __typename?: 'sequencing_run_aggregate_fields';
  avg?: Maybe<Sequencing_Run_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sequencing_Run_Max_Fields>;
  min?: Maybe<Sequencing_Run_Min_Fields>;
  stddev?: Maybe<Sequencing_Run_Stddev_Fields>;
  stddev_pop?: Maybe<Sequencing_Run_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sequencing_Run_Stddev_Samp_Fields>;
  sum?: Maybe<Sequencing_Run_Sum_Fields>;
  var_pop?: Maybe<Sequencing_Run_Var_Pop_Fields>;
  var_samp?: Maybe<Sequencing_Run_Var_Samp_Fields>;
  variance?: Maybe<Sequencing_Run_Variance_Fields>;
};


/** aggregate fields of "sequencing_run" */
export type Sequencing_Run_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sequencing_Run_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sequencing_run" */
export type Sequencing_Run_Aggregate_Order_By = {
  avg?: Maybe<Sequencing_Run_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sequencing_Run_Max_Order_By>;
  min?: Maybe<Sequencing_Run_Min_Order_By>;
  stddev?: Maybe<Sequencing_Run_Stddev_Order_By>;
  stddev_pop?: Maybe<Sequencing_Run_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sequencing_Run_Stddev_Samp_Order_By>;
  sum?: Maybe<Sequencing_Run_Sum_Order_By>;
  var_pop?: Maybe<Sequencing_Run_Var_Pop_Order_By>;
  var_samp?: Maybe<Sequencing_Run_Var_Samp_Order_By>;
  variance?: Maybe<Sequencing_Run_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sequencing_run" */
export type Sequencing_Run_Arr_Rel_Insert_Input = {
  data: Array<Sequencing_Run_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Sequencing_Run_On_Conflict>;
};

/** aggregate avg on columns */
export type Sequencing_Run_Avg_Fields = {
  __typename?: 'sequencing_run_avg_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sequencing_run" */
export type Sequencing_Run_Avg_Order_By = {
  created_by_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sequencing_run". All fields are combined with a logical 'AND'. */
export type Sequencing_Run_Bool_Exp = {
  _and?: Maybe<Array<Sequencing_Run_Bool_Exp>>;
  _not?: Maybe<Sequencing_Run_Bool_Exp>;
  _or?: Maybe<Array<Sequencing_Run_Bool_Exp>>;
  analysis_completed_at?: Maybe<Timestamptz_Comparison_Exp>;
  analysis_started_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<User_Bool_Exp>;
  created_by_id?: Maybe<Int_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  project?: Maybe<Project_Bool_Exp>;
  project_id?: Maybe<Int_Comparison_Exp>;
  sequencing_run_mdsas?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
  sequencing_run_mdsas_aggregate?: Maybe<Mdsa_Sequencing_Run_Aggregate_Bool_Exp>;
  status?: Maybe<Enum_Sr_Status_Enum_Comparison_Exp>;
  tags?: Maybe<String_Comparison_Exp>;
  tasks?: Maybe<Sr_Task_Bool_Exp>;
  tasks_aggregate?: Maybe<Sr_Task_Aggregate_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Enum_Sequencing_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sequencing_run" */
export enum Sequencing_Run_Constraint {
  /** unique or primary key constraint on columns "id" */
  SequencingRunPkey = 'sequencing_run_pkey'
}

/** input type for incrementing numeric columns in table "sequencing_run" */
export type Sequencing_Run_Inc_Input = {
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "sequencing_run" */
export type Sequencing_Run_Insert_Input = {
  analysis_completed_at?: Maybe<Scalars['timestamptz']>;
  analysis_started_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<User_Obj_Rel_Insert_Input>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  project?: Maybe<Project_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['Int']>;
  sequencing_run_mdsas?: Maybe<Mdsa_Sequencing_Run_Arr_Rel_Insert_Input>;
  status?: Maybe<Enum_Sr_Status_Enum>;
  tags?: Maybe<Scalars['String']>;
  tasks?: Maybe<Sr_Task_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Sequencing_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sequencing_Run_Max_Fields = {
  __typename?: 'sequencing_run_max_fields';
  analysis_completed_at?: Maybe<Scalars['timestamptz']>;
  analysis_started_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sequencing_run" */
export type Sequencing_Run_Max_Order_By = {
  analysis_completed_at?: Maybe<Order_By>;
  analysis_started_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sequencing_Run_Min_Fields = {
  __typename?: 'sequencing_run_min_fields';
  analysis_completed_at?: Maybe<Scalars['timestamptz']>;
  analysis_started_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sequencing_run" */
export type Sequencing_Run_Min_Order_By = {
  analysis_completed_at?: Maybe<Order_By>;
  analysis_started_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "sequencing_run" */
export type Sequencing_Run_Mutation_Response = {
  __typename?: 'sequencing_run_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sequencing_Run>;
};

/** input type for inserting object relation for remote table "sequencing_run" */
export type Sequencing_Run_Obj_Rel_Insert_Input = {
  data: Sequencing_Run_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Sequencing_Run_On_Conflict>;
};

/** on_conflict condition type for table "sequencing_run" */
export type Sequencing_Run_On_Conflict = {
  constraint: Sequencing_Run_Constraint;
  update_columns?: Array<Sequencing_Run_Update_Column>;
  where?: Maybe<Sequencing_Run_Bool_Exp>;
};

/** Ordering options when selecting data from "sequencing_run". */
export type Sequencing_Run_Order_By = {
  analysis_completed_at?: Maybe<Order_By>;
  analysis_started_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<User_Order_By>;
  created_by_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project?: Maybe<Project_Order_By>;
  project_id?: Maybe<Order_By>;
  sequencing_run_mdsas_aggregate?: Maybe<Mdsa_Sequencing_Run_Aggregate_Order_By>;
  status?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  tasks_aggregate?: Maybe<Sr_Task_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: sequencing_run */
export type Sequencing_Run_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "sequencing_run" */
export enum Sequencing_Run_Select_Column {
  /** column name */
  AnalysisCompletedAt = 'analysis_completed_at',
  /** column name */
  AnalysisStartedAt = 'analysis_started_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sequencing_run" */
export type Sequencing_Run_Set_Input = {
  analysis_completed_at?: Maybe<Scalars['timestamptz']>;
  analysis_started_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Enum_Sr_Status_Enum>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Sequencing_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sequencing_Run_Stddev_Fields = {
  __typename?: 'sequencing_run_stddev_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sequencing_run" */
export type Sequencing_Run_Stddev_Order_By = {
  created_by_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sequencing_Run_Stddev_Pop_Fields = {
  __typename?: 'sequencing_run_stddev_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sequencing_run" */
export type Sequencing_Run_Stddev_Pop_Order_By = {
  created_by_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sequencing_Run_Stddev_Samp_Fields = {
  __typename?: 'sequencing_run_stddev_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sequencing_run" */
export type Sequencing_Run_Stddev_Samp_Order_By = {
  created_by_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "sequencing_run" */
export type Sequencing_Run_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sequencing_Run_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sequencing_Run_Stream_Cursor_Value_Input = {
  analysis_completed_at?: Maybe<Scalars['timestamptz']>;
  analysis_started_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Enum_Sr_Status_Enum>;
  tags?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Sequencing_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Sequencing_Run_Sum_Fields = {
  __typename?: 'sequencing_run_sum_fields';
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sequencing_run" */
export type Sequencing_Run_Sum_Order_By = {
  created_by_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** update columns of table "sequencing_run" */
export enum Sequencing_Run_Update_Column {
  /** column name */
  AnalysisCompletedAt = 'analysis_completed_at',
  /** column name */
  AnalysisStartedAt = 'analysis_started_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Sequencing_Run_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Sequencing_Run_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Sequencing_Run_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sequencing_Run_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sequencing_Run_Var_Pop_Fields = {
  __typename?: 'sequencing_run_var_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sequencing_run" */
export type Sequencing_Run_Var_Pop_Order_By = {
  created_by_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sequencing_Run_Var_Samp_Fields = {
  __typename?: 'sequencing_run_var_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sequencing_run" */
export type Sequencing_Run_Var_Samp_Order_By = {
  created_by_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Sequencing_Run_Variance_Fields = {
  __typename?: 'sequencing_run_variance_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sequencing_run" */
export type Sequencing_Run_Variance_Order_By = {
  created_by_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
};

/** columns and relationships of "settings" */
export type Settings = {
  __typename?: 'settings';
  id: Scalars['Int'];
  int_value?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  text_value?: Maybe<Scalars['String']>;
  value_type: Enum_Settings_Value_Type_Enum;
};

/** aggregated selection of "settings" */
export type Settings_Aggregate = {
  __typename?: 'settings_aggregate';
  aggregate?: Maybe<Settings_Aggregate_Fields>;
  nodes: Array<Settings>;
};

/** aggregate fields of "settings" */
export type Settings_Aggregate_Fields = {
  __typename?: 'settings_aggregate_fields';
  avg?: Maybe<Settings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Settings_Max_Fields>;
  min?: Maybe<Settings_Min_Fields>;
  stddev?: Maybe<Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Settings_Sum_Fields>;
  var_pop?: Maybe<Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Settings_Var_Samp_Fields>;
  variance?: Maybe<Settings_Variance_Fields>;
};


/** aggregate fields of "settings" */
export type Settings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Settings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Settings_Avg_Fields = {
  __typename?: 'settings_avg_fields';
  id?: Maybe<Scalars['Float']>;
  int_value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "settings". All fields are combined with a logical 'AND'. */
export type Settings_Bool_Exp = {
  _and?: Maybe<Array<Settings_Bool_Exp>>;
  _not?: Maybe<Settings_Bool_Exp>;
  _or?: Maybe<Array<Settings_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  int_value?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  text_value?: Maybe<String_Comparison_Exp>;
  value_type?: Maybe<Enum_Settings_Value_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "settings" */
export enum Settings_Constraint {
  /** unique or primary key constraint on columns "name" */
  SettingsNameKey = 'settings_name_key',
  /** unique or primary key constraint on columns "id" */
  SettingsPkey = 'settings_pkey'
}

/** input type for incrementing numeric columns in table "settings" */
export type Settings_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  int_value?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "settings" */
export type Settings_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  int_value?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  text_value?: Maybe<Scalars['String']>;
  value_type?: Maybe<Enum_Settings_Value_Type_Enum>;
};

/** aggregate max on columns */
export type Settings_Max_Fields = {
  __typename?: 'settings_max_fields';
  id?: Maybe<Scalars['Int']>;
  int_value?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  text_value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Settings_Min_Fields = {
  __typename?: 'settings_min_fields';
  id?: Maybe<Scalars['Int']>;
  int_value?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  text_value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "settings" */
export type Settings_Mutation_Response = {
  __typename?: 'settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Settings>;
};

/** on_conflict condition type for table "settings" */
export type Settings_On_Conflict = {
  constraint: Settings_Constraint;
  update_columns?: Array<Settings_Update_Column>;
  where?: Maybe<Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "settings". */
export type Settings_Order_By = {
  id?: Maybe<Order_By>;
  int_value?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  text_value?: Maybe<Order_By>;
  value_type?: Maybe<Order_By>;
};

/** primary key columns input for table: settings */
export type Settings_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "settings" */
export enum Settings_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IntValue = 'int_value',
  /** column name */
  Name = 'name',
  /** column name */
  TextValue = 'text_value',
  /** column name */
  ValueType = 'value_type'
}

/** input type for updating data in table "settings" */
export type Settings_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  int_value?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  text_value?: Maybe<Scalars['String']>;
  value_type?: Maybe<Enum_Settings_Value_Type_Enum>;
};

/** aggregate stddev on columns */
export type Settings_Stddev_Fields = {
  __typename?: 'settings_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  int_value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Settings_Stddev_Pop_Fields = {
  __typename?: 'settings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  int_value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Settings_Stddev_Samp_Fields = {
  __typename?: 'settings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  int_value?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "settings" */
export type Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Settings_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['Int']>;
  int_value?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  text_value?: Maybe<Scalars['String']>;
  value_type?: Maybe<Enum_Settings_Value_Type_Enum>;
};

/** aggregate sum on columns */
export type Settings_Sum_Fields = {
  __typename?: 'settings_sum_fields';
  id?: Maybe<Scalars['Int']>;
  int_value?: Maybe<Scalars['Int']>;
};

/** update columns of table "settings" */
export enum Settings_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IntValue = 'int_value',
  /** column name */
  Name = 'name',
  /** column name */
  TextValue = 'text_value',
  /** column name */
  ValueType = 'value_type'
}

export type Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Settings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Settings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Settings_Var_Pop_Fields = {
  __typename?: 'settings_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  int_value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Settings_Var_Samp_Fields = {
  __typename?: 'settings_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  int_value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Settings_Variance_Fields = {
  __typename?: 'settings_variance_fields';
  id?: Maybe<Scalars['Float']>;
  int_value?: Maybe<Scalars['Float']>;
};

/** The analysis tasks in a sequencing run */
export type Sr_Task = {
  __typename?: 'sr_task';
  aws_batch_job?: Maybe<CustomJsonb>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dependent_on?: Maybe<CustomJsonb>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  failed_logs?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  in_params?: Maybe<CustomJsonb>;
  is_last_position?: Maybe<Scalars['Boolean']>;
  key: Enum_Sr_Task_Key_Enum;
  out_data?: Maybe<CustomJsonb>;
  out_files?: Maybe<CustomJsonb>;
  position_sr: Scalars['Int'];
  /** An object relationship */
  sequencing_run: Sequencing_Run;
  sr_id: Scalars['Int'];
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};


/** The analysis tasks in a sequencing run */
export type Sr_TaskAws_Batch_JobArgs = {
  path?: Maybe<Scalars['String']>;
};


/** The analysis tasks in a sequencing run */
export type Sr_TaskDependent_OnArgs = {
  path?: Maybe<Scalars['String']>;
};


/** The analysis tasks in a sequencing run */
export type Sr_TaskIn_ParamsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** The analysis tasks in a sequencing run */
export type Sr_TaskOut_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** The analysis tasks in a sequencing run */
export type Sr_TaskOut_FilesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "sr_task" */
export type Sr_Task_Aggregate = {
  __typename?: 'sr_task_aggregate';
  aggregate?: Maybe<Sr_Task_Aggregate_Fields>;
  nodes: Array<Sr_Task>;
};

export type Sr_Task_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Sr_Task_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Sr_Task_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Sr_Task_Aggregate_Bool_Exp_Count>;
};

export type Sr_Task_Aggregate_Bool_Exp_Bool_And = {
  arguments: Sr_Task_Select_Column_Sr_Task_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Sr_Task_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sr_Task_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Sr_Task_Select_Column_Sr_Task_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Sr_Task_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sr_Task_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Sr_Task_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Sr_Task_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sr_task" */
export type Sr_Task_Aggregate_Fields = {
  __typename?: 'sr_task_aggregate_fields';
  avg?: Maybe<Sr_Task_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sr_Task_Max_Fields>;
  min?: Maybe<Sr_Task_Min_Fields>;
  stddev?: Maybe<Sr_Task_Stddev_Fields>;
  stddev_pop?: Maybe<Sr_Task_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sr_Task_Stddev_Samp_Fields>;
  sum?: Maybe<Sr_Task_Sum_Fields>;
  var_pop?: Maybe<Sr_Task_Var_Pop_Fields>;
  var_samp?: Maybe<Sr_Task_Var_Samp_Fields>;
  variance?: Maybe<Sr_Task_Variance_Fields>;
};


/** aggregate fields of "sr_task" */
export type Sr_Task_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sr_Task_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sr_task" */
export type Sr_Task_Aggregate_Order_By = {
  avg?: Maybe<Sr_Task_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sr_Task_Max_Order_By>;
  min?: Maybe<Sr_Task_Min_Order_By>;
  stddev?: Maybe<Sr_Task_Stddev_Order_By>;
  stddev_pop?: Maybe<Sr_Task_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sr_Task_Stddev_Samp_Order_By>;
  sum?: Maybe<Sr_Task_Sum_Order_By>;
  var_pop?: Maybe<Sr_Task_Var_Pop_Order_By>;
  var_samp?: Maybe<Sr_Task_Var_Samp_Order_By>;
  variance?: Maybe<Sr_Task_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Sr_Task_Append_Input = {
  aws_batch_job?: Maybe<CustomJsonb>;
  dependent_on?: Maybe<CustomJsonb>;
  in_params?: Maybe<CustomJsonb>;
  out_data?: Maybe<CustomJsonb>;
  out_files?: Maybe<CustomJsonb>;
};

/** input type for inserting array relation for remote table "sr_task" */
export type Sr_Task_Arr_Rel_Insert_Input = {
  data: Array<Sr_Task_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Sr_Task_On_Conflict>;
};

/** aggregate avg on columns */
export type Sr_Task_Avg_Fields = {
  __typename?: 'sr_task_avg_fields';
  id?: Maybe<Scalars['Float']>;
  position_sr?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sr_task" */
export type Sr_Task_Avg_Order_By = {
  id?: Maybe<Order_By>;
  position_sr?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sr_task". All fields are combined with a logical 'AND'. */
export type Sr_Task_Bool_Exp = {
  _and?: Maybe<Array<Sr_Task_Bool_Exp>>;
  _not?: Maybe<Sr_Task_Bool_Exp>;
  _or?: Maybe<Array<Sr_Task_Bool_Exp>>;
  aws_batch_job?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  dependent_on?: Maybe<Jsonb_Comparison_Exp>;
  ended_at?: Maybe<Timestamptz_Comparison_Exp>;
  failed_at?: Maybe<Timestamptz_Comparison_Exp>;
  failed_logs?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  in_params?: Maybe<Jsonb_Comparison_Exp>;
  is_last_position?: Maybe<Boolean_Comparison_Exp>;
  key?: Maybe<Enum_Sr_Task_Key_Enum_Comparison_Exp>;
  out_data?: Maybe<Jsonb_Comparison_Exp>;
  out_files?: Maybe<Jsonb_Comparison_Exp>;
  position_sr?: Maybe<Int_Comparison_Exp>;
  sequencing_run?: Maybe<Sequencing_Run_Bool_Exp>;
  sr_id?: Maybe<Int_Comparison_Exp>;
  started_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sr_task" */
export enum Sr_Task_Constraint {
  /** unique or primary key constraint on columns "id" */
  SrTaskPkey = 'sr_task_pkey',
  /** unique or primary key constraint on columns "key", "sr_id" */
  SrTaskSrIdKeyKey = 'sr_task_sr_id_key_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Sr_Task_Delete_At_Path_Input = {
  aws_batch_job?: Maybe<Array<Scalars['String']>>;
  dependent_on?: Maybe<Array<Scalars['String']>>;
  in_params?: Maybe<Array<Scalars['String']>>;
  out_data?: Maybe<Array<Scalars['String']>>;
  out_files?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Sr_Task_Delete_Elem_Input = {
  aws_batch_job?: Maybe<Scalars['Int']>;
  dependent_on?: Maybe<Scalars['Int']>;
  in_params?: Maybe<Scalars['Int']>;
  out_data?: Maybe<Scalars['Int']>;
  out_files?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Sr_Task_Delete_Key_Input = {
  aws_batch_job?: Maybe<Scalars['String']>;
  dependent_on?: Maybe<Scalars['String']>;
  in_params?: Maybe<Scalars['String']>;
  out_data?: Maybe<Scalars['String']>;
  out_files?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "sr_task" */
export type Sr_Task_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  position_sr?: Maybe<Scalars['Int']>;
  sr_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "sr_task" */
export type Sr_Task_Insert_Input = {
  aws_batch_job?: Maybe<CustomJsonb>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dependent_on?: Maybe<CustomJsonb>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  failed_logs?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  in_params?: Maybe<CustomJsonb>;
  is_last_position?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Enum_Sr_Task_Key_Enum>;
  out_data?: Maybe<CustomJsonb>;
  out_files?: Maybe<CustomJsonb>;
  position_sr?: Maybe<Scalars['Int']>;
  sequencing_run?: Maybe<Sequencing_Run_Obj_Rel_Insert_Input>;
  sr_id?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sr_Task_Max_Fields = {
  __typename?: 'sr_task_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  failed_logs?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  position_sr?: Maybe<Scalars['Int']>;
  sr_id?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sr_task" */
export type Sr_Task_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  failed_at?: Maybe<Order_By>;
  failed_logs?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position_sr?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sr_Task_Min_Fields = {
  __typename?: 'sr_task_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  failed_logs?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  position_sr?: Maybe<Scalars['Int']>;
  sr_id?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sr_task" */
export type Sr_Task_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  failed_at?: Maybe<Order_By>;
  failed_logs?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  position_sr?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "sr_task" */
export type Sr_Task_Mutation_Response = {
  __typename?: 'sr_task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sr_Task>;
};

/** on_conflict condition type for table "sr_task" */
export type Sr_Task_On_Conflict = {
  constraint: Sr_Task_Constraint;
  update_columns?: Array<Sr_Task_Update_Column>;
  where?: Maybe<Sr_Task_Bool_Exp>;
};

/** Ordering options when selecting data from "sr_task". */
export type Sr_Task_Order_By = {
  aws_batch_job?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  dependent_on?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  failed_at?: Maybe<Order_By>;
  failed_logs?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  in_params?: Maybe<Order_By>;
  is_last_position?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  out_data?: Maybe<Order_By>;
  out_files?: Maybe<Order_By>;
  position_sr?: Maybe<Order_By>;
  sequencing_run?: Maybe<Sequencing_Run_Order_By>;
  sr_id?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: sr_task */
export type Sr_Task_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Sr_Task_Prepend_Input = {
  aws_batch_job?: Maybe<CustomJsonb>;
  dependent_on?: Maybe<CustomJsonb>;
  in_params?: Maybe<CustomJsonb>;
  out_data?: Maybe<CustomJsonb>;
  out_files?: Maybe<CustomJsonb>;
};

/** select columns of table "sr_task" */
export enum Sr_Task_Select_Column {
  /** column name */
  AwsBatchJob = 'aws_batch_job',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DependentOn = 'dependent_on',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  FailedAt = 'failed_at',
  /** column name */
  FailedLogs = 'failed_logs',
  /** column name */
  Id = 'id',
  /** column name */
  InParams = 'in_params',
  /** column name */
  IsLastPosition = 'is_last_position',
  /** column name */
  Key = 'key',
  /** column name */
  OutData = 'out_data',
  /** column name */
  OutFiles = 'out_files',
  /** column name */
  PositionSr = 'position_sr',
  /** column name */
  SrId = 'sr_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "sr_task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "sr_task" */
export enum Sr_Task_Select_Column_Sr_Task_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsLastPosition = 'is_last_position'
}

/** select "sr_task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "sr_task" */
export enum Sr_Task_Select_Column_Sr_Task_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsLastPosition = 'is_last_position'
}

/** input type for updating data in table "sr_task" */
export type Sr_Task_Set_Input = {
  aws_batch_job?: Maybe<CustomJsonb>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dependent_on?: Maybe<CustomJsonb>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  failed_logs?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  in_params?: Maybe<CustomJsonb>;
  is_last_position?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Enum_Sr_Task_Key_Enum>;
  out_data?: Maybe<CustomJsonb>;
  out_files?: Maybe<CustomJsonb>;
  position_sr?: Maybe<Scalars['Int']>;
  sr_id?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sr_Task_Stddev_Fields = {
  __typename?: 'sr_task_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  position_sr?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sr_task" */
export type Sr_Task_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  position_sr?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sr_Task_Stddev_Pop_Fields = {
  __typename?: 'sr_task_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position_sr?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sr_task" */
export type Sr_Task_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  position_sr?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sr_Task_Stddev_Samp_Fields = {
  __typename?: 'sr_task_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position_sr?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sr_task" */
export type Sr_Task_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  position_sr?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "sr_task" */
export type Sr_Task_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sr_Task_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sr_Task_Stream_Cursor_Value_Input = {
  aws_batch_job?: Maybe<CustomJsonb>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dependent_on?: Maybe<CustomJsonb>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  failed_at?: Maybe<Scalars['timestamptz']>;
  failed_logs?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  in_params?: Maybe<CustomJsonb>;
  is_last_position?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Enum_Sr_Task_Key_Enum>;
  out_data?: Maybe<CustomJsonb>;
  out_files?: Maybe<CustomJsonb>;
  position_sr?: Maybe<Scalars['Int']>;
  sr_id?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Sr_Task_Sum_Fields = {
  __typename?: 'sr_task_sum_fields';
  id?: Maybe<Scalars['Int']>;
  position_sr?: Maybe<Scalars['Int']>;
  sr_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sr_task" */
export type Sr_Task_Sum_Order_By = {
  id?: Maybe<Order_By>;
  position_sr?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** update columns of table "sr_task" */
export enum Sr_Task_Update_Column {
  /** column name */
  AwsBatchJob = 'aws_batch_job',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DependentOn = 'dependent_on',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  FailedAt = 'failed_at',
  /** column name */
  FailedLogs = 'failed_logs',
  /** column name */
  Id = 'id',
  /** column name */
  InParams = 'in_params',
  /** column name */
  IsLastPosition = 'is_last_position',
  /** column name */
  Key = 'key',
  /** column name */
  OutData = 'out_data',
  /** column name */
  OutFiles = 'out_files',
  /** column name */
  PositionSr = 'position_sr',
  /** column name */
  SrId = 'sr_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Sr_Task_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Sr_Task_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Sr_Task_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Sr_Task_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Sr_Task_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Sr_Task_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Sr_Task_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Sr_Task_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sr_Task_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sr_Task_Var_Pop_Fields = {
  __typename?: 'sr_task_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position_sr?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sr_task" */
export type Sr_Task_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  position_sr?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sr_Task_Var_Samp_Fields = {
  __typename?: 'sr_task_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position_sr?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sr_task" */
export type Sr_Task_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  position_sr?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Sr_Task_Variance_Fields = {
  __typename?: 'sr_task_variance_fields';
  id?: Maybe<Scalars['Float']>;
  position_sr?: Maybe<Scalars['Float']>;
  sr_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sr_task" */
export type Sr_Task_Variance_Order_By = {
  id?: Maybe<Order_By>;
  position_sr?: Maybe<Order_By>;
  sr_id?: Maybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "enum_mdsa_source" */
  enum_mdsa_source: Array<Enum_Mdsa_Source>;
  /** fetch aggregated fields from the table: "enum_mdsa_source" */
  enum_mdsa_source_aggregate: Enum_Mdsa_Source_Aggregate;
  /** fetch data from the table: "enum_mdsa_source" using primary key columns */
  enum_mdsa_source_by_pk?: Maybe<Enum_Mdsa_Source>;
  /** fetch data from the table in a streaming manner: "enum_mdsa_source" */
  enum_mdsa_source_stream: Array<Enum_Mdsa_Source>;
  /** fetch data from the table: "enum_mdsa_status" */
  enum_mdsa_status: Array<Enum_Mdsa_Status>;
  /** fetch aggregated fields from the table: "enum_mdsa_status" */
  enum_mdsa_status_aggregate: Enum_Mdsa_Status_Aggregate;
  /** fetch data from the table: "enum_mdsa_status" using primary key columns */
  enum_mdsa_status_by_pk?: Maybe<Enum_Mdsa_Status>;
  /** fetch data from the table in a streaming manner: "enum_mdsa_status" */
  enum_mdsa_status_stream: Array<Enum_Mdsa_Status>;
  /** fetch data from the table: "enum_mdsa_task_key" */
  enum_mdsa_task_key: Array<Enum_Mdsa_Task_Key>;
  /** fetch aggregated fields from the table: "enum_mdsa_task_key" */
  enum_mdsa_task_key_aggregate: Enum_Mdsa_Task_Key_Aggregate;
  /** fetch data from the table: "enum_mdsa_task_key" using primary key columns */
  enum_mdsa_task_key_by_pk?: Maybe<Enum_Mdsa_Task_Key>;
  /** fetch data from the table in a streaming manner: "enum_mdsa_task_key" */
  enum_mdsa_task_key_stream: Array<Enum_Mdsa_Task_Key>;
  /** fetch data from the table: "enum_sequencing_type" */
  enum_sequencing_type: Array<Enum_Sequencing_Type>;
  /** fetch aggregated fields from the table: "enum_sequencing_type" */
  enum_sequencing_type_aggregate: Enum_Sequencing_Type_Aggregate;
  /** fetch data from the table: "enum_sequencing_type" using primary key columns */
  enum_sequencing_type_by_pk?: Maybe<Enum_Sequencing_Type>;
  /** fetch data from the table in a streaming manner: "enum_sequencing_type" */
  enum_sequencing_type_stream: Array<Enum_Sequencing_Type>;
  /** fetch data from the table: "enum_settings_value_type" */
  enum_settings_value_type: Array<Enum_Settings_Value_Type>;
  /** fetch aggregated fields from the table: "enum_settings_value_type" */
  enum_settings_value_type_aggregate: Enum_Settings_Value_Type_Aggregate;
  /** fetch data from the table: "enum_settings_value_type" using primary key columns */
  enum_settings_value_type_by_pk?: Maybe<Enum_Settings_Value_Type>;
  /** fetch data from the table in a streaming manner: "enum_settings_value_type" */
  enum_settings_value_type_stream: Array<Enum_Settings_Value_Type>;
  /** fetch data from the table: "enum_sr_status" */
  enum_sr_status: Array<Enum_Sr_Status>;
  /** fetch aggregated fields from the table: "enum_sr_status" */
  enum_sr_status_aggregate: Enum_Sr_Status_Aggregate;
  /** fetch data from the table: "enum_sr_status" using primary key columns */
  enum_sr_status_by_pk?: Maybe<Enum_Sr_Status>;
  /** fetch data from the table in a streaming manner: "enum_sr_status" */
  enum_sr_status_stream: Array<Enum_Sr_Status>;
  /** fetch data from the table: "enum_sr_task_key" */
  enum_sr_task_key: Array<Enum_Sr_Task_Key>;
  /** fetch aggregated fields from the table: "enum_sr_task_key" */
  enum_sr_task_key_aggregate: Enum_Sr_Task_Key_Aggregate;
  /** fetch data from the table: "enum_sr_task_key" using primary key columns */
  enum_sr_task_key_by_pk?: Maybe<Enum_Sr_Task_Key>;
  /** fetch data from the table in a streaming manner: "enum_sr_task_key" */
  enum_sr_task_key_stream: Array<Enum_Sr_Task_Key>;
  /** fetch data from the table: "feature_flag" */
  feature_flag: Array<Feature_Flag>;
  /** fetch aggregated fields from the table: "feature_flag" */
  feature_flag_aggregate: Feature_Flag_Aggregate;
  /** fetch data from the table: "feature_flag" using primary key columns */
  feature_flag_by_pk?: Maybe<Feature_Flag>;
  /** fetch data from the table in a streaming manner: "feature_flag" */
  feature_flag_stream: Array<Feature_Flag>;
  /** fetch data from the table: "mdsa" */
  mdsa: Array<Mdsa>;
  /** fetch aggregated fields from the table: "mdsa" */
  mdsa_aggregate: Mdsa_Aggregate;
  /** fetch data from the table: "mdsa" using primary key columns */
  mdsa_by_pk?: Maybe<Mdsa>;
  /** fetch data from the table: "mdsa_sequencing_run" */
  mdsa_sequencing_run: Array<Mdsa_Sequencing_Run>;
  /** fetch aggregated fields from the table: "mdsa_sequencing_run" */
  mdsa_sequencing_run_aggregate: Mdsa_Sequencing_Run_Aggregate;
  /** fetch data from the table: "mdsa_sequencing_run" using primary key columns */
  mdsa_sequencing_run_by_pk?: Maybe<Mdsa_Sequencing_Run>;
  /** fetch data from the table in a streaming manner: "mdsa_sequencing_run" */
  mdsa_sequencing_run_stream: Array<Mdsa_Sequencing_Run>;
  /** fetch data from the table in a streaming manner: "mdsa" */
  mdsa_stream: Array<Mdsa>;
  /** fetch data from the table: "mdsa_task" */
  mdsa_task: Array<Mdsa_Task>;
  /** fetch aggregated fields from the table: "mdsa_task" */
  mdsa_task_aggregate: Mdsa_Task_Aggregate;
  /** fetch data from the table: "mdsa_task" using primary key columns */
  mdsa_task_by_pk?: Maybe<Mdsa_Task>;
  /** fetch data from the table in a streaming manner: "mdsa_task" */
  mdsa_task_stream: Array<Mdsa_Task>;
  /** fetch data from the table: "project" */
  project: Array<Project>;
  /** fetch aggregated fields from the table: "project" */
  project_aggregate: Project_Aggregate;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<Project>;
  /** fetch data from the table in a streaming manner: "project" */
  project_stream: Array<Project>;
  /** fetch data from the table: "project_user" */
  project_user: Array<Project_User>;
  /** fetch aggregated fields from the table: "project_user" */
  project_user_aggregate: Project_User_Aggregate;
  /** fetch data from the table: "project_user" using primary key columns */
  project_user_by_pk?: Maybe<Project_User>;
  /** fetch data from the table in a streaming manner: "project_user" */
  project_user_stream: Array<Project_User>;
  /** fetch data from the table: "sequencing_run" */
  sequencing_run: Array<Sequencing_Run>;
  /** fetch aggregated fields from the table: "sequencing_run" */
  sequencing_run_aggregate: Sequencing_Run_Aggregate;
  /** fetch data from the table: "sequencing_run" using primary key columns */
  sequencing_run_by_pk?: Maybe<Sequencing_Run>;
  /** fetch data from the table in a streaming manner: "sequencing_run" */
  sequencing_run_stream: Array<Sequencing_Run>;
  /** fetch data from the table: "settings" */
  settings: Array<Settings>;
  /** fetch aggregated fields from the table: "settings" */
  settings_aggregate: Settings_Aggregate;
  /** fetch data from the table: "settings" using primary key columns */
  settings_by_pk?: Maybe<Settings>;
  /** fetch data from the table in a streaming manner: "settings" */
  settings_stream: Array<Settings>;
  /** fetch data from the table: "sr_task" */
  sr_task: Array<Sr_Task>;
  /** fetch aggregated fields from the table: "sr_task" */
  sr_task_aggregate: Sr_Task_Aggregate;
  /** fetch data from the table: "sr_task" using primary key columns */
  sr_task_by_pk?: Maybe<Sr_Task>;
  /** fetch data from the table in a streaming manner: "sr_task" */
  sr_task_stream: Array<Sr_Task>;
  /** fetch data from the table: "taxonomy_classifier" */
  taxonomy_classifier: Array<Taxonomy_Classifier>;
  /** fetch aggregated fields from the table: "taxonomy_classifier" */
  taxonomy_classifier_aggregate: Taxonomy_Classifier_Aggregate;
  /** fetch data from the table: "taxonomy_classifier" using primary key columns */
  taxonomy_classifier_by_pk?: Maybe<Taxonomy_Classifier>;
  /** fetch data from the table in a streaming manner: "taxonomy_classifier" */
  taxonomy_classifier_stream: Array<Taxonomy_Classifier>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
};


export type Subscription_RootEnum_Mdsa_SourceArgs = {
  distinct_on?: Maybe<Array<Enum_Mdsa_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Mdsa_Source_Order_By>>;
  where?: Maybe<Enum_Mdsa_Source_Bool_Exp>;
};


export type Subscription_RootEnum_Mdsa_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Mdsa_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Mdsa_Source_Order_By>>;
  where?: Maybe<Enum_Mdsa_Source_Bool_Exp>;
};


export type Subscription_RootEnum_Mdsa_Source_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Mdsa_Source_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Enum_Mdsa_Source_Stream_Cursor_Input>>;
  where?: Maybe<Enum_Mdsa_Source_Bool_Exp>;
};


export type Subscription_RootEnum_Mdsa_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Mdsa_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Mdsa_Status_Order_By>>;
  where?: Maybe<Enum_Mdsa_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Mdsa_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Mdsa_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Mdsa_Status_Order_By>>;
  where?: Maybe<Enum_Mdsa_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Mdsa_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Mdsa_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Enum_Mdsa_Status_Stream_Cursor_Input>>;
  where?: Maybe<Enum_Mdsa_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Mdsa_Task_KeyArgs = {
  distinct_on?: Maybe<Array<Enum_Mdsa_Task_Key_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Mdsa_Task_Key_Order_By>>;
  where?: Maybe<Enum_Mdsa_Task_Key_Bool_Exp>;
};


export type Subscription_RootEnum_Mdsa_Task_Key_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Mdsa_Task_Key_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Mdsa_Task_Key_Order_By>>;
  where?: Maybe<Enum_Mdsa_Task_Key_Bool_Exp>;
};


export type Subscription_RootEnum_Mdsa_Task_Key_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Mdsa_Task_Key_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Enum_Mdsa_Task_Key_Stream_Cursor_Input>>;
  where?: Maybe<Enum_Mdsa_Task_Key_Bool_Exp>;
};


export type Subscription_RootEnum_Sequencing_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Sequencing_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Sequencing_Type_Order_By>>;
  where?: Maybe<Enum_Sequencing_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Sequencing_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Sequencing_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Sequencing_Type_Order_By>>;
  where?: Maybe<Enum_Sequencing_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Sequencing_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Sequencing_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Enum_Sequencing_Type_Stream_Cursor_Input>>;
  where?: Maybe<Enum_Sequencing_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Settings_Value_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Settings_Value_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Settings_Value_Type_Order_By>>;
  where?: Maybe<Enum_Settings_Value_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Settings_Value_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Settings_Value_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Settings_Value_Type_Order_By>>;
  where?: Maybe<Enum_Settings_Value_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Settings_Value_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Settings_Value_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Enum_Settings_Value_Type_Stream_Cursor_Input>>;
  where?: Maybe<Enum_Settings_Value_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Sr_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Sr_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Sr_Status_Order_By>>;
  where?: Maybe<Enum_Sr_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Sr_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Sr_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Sr_Status_Order_By>>;
  where?: Maybe<Enum_Sr_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Sr_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Sr_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Enum_Sr_Status_Stream_Cursor_Input>>;
  where?: Maybe<Enum_Sr_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Sr_Task_KeyArgs = {
  distinct_on?: Maybe<Array<Enum_Sr_Task_Key_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Sr_Task_Key_Order_By>>;
  where?: Maybe<Enum_Sr_Task_Key_Bool_Exp>;
};


export type Subscription_RootEnum_Sr_Task_Key_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Sr_Task_Key_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Sr_Task_Key_Order_By>>;
  where?: Maybe<Enum_Sr_Task_Key_Bool_Exp>;
};


export type Subscription_RootEnum_Sr_Task_Key_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Sr_Task_Key_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Enum_Sr_Task_Key_Stream_Cursor_Input>>;
  where?: Maybe<Enum_Sr_Task_Key_Bool_Exp>;
};


export type Subscription_RootFeature_FlagArgs = {
  distinct_on?: Maybe<Array<Feature_Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feature_Flag_Order_By>>;
  where?: Maybe<Feature_Flag_Bool_Exp>;
};


export type Subscription_RootFeature_Flag_AggregateArgs = {
  distinct_on?: Maybe<Array<Feature_Flag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feature_Flag_Order_By>>;
  where?: Maybe<Feature_Flag_Bool_Exp>;
};


export type Subscription_RootFeature_Flag_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootFeature_Flag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Feature_Flag_Stream_Cursor_Input>>;
  where?: Maybe<Feature_Flag_Bool_Exp>;
};


export type Subscription_RootMdsaArgs = {
  distinct_on?: Maybe<Array<Mdsa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Order_By>>;
  where?: Maybe<Mdsa_Bool_Exp>;
};


export type Subscription_RootMdsa_AggregateArgs = {
  distinct_on?: Maybe<Array<Mdsa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Order_By>>;
  where?: Maybe<Mdsa_Bool_Exp>;
};


export type Subscription_RootMdsa_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMdsa_Sequencing_RunArgs = {
  distinct_on?: Maybe<Array<Mdsa_Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Sequencing_Run_Order_By>>;
  where?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
};


export type Subscription_RootMdsa_Sequencing_Run_AggregateArgs = {
  distinct_on?: Maybe<Array<Mdsa_Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Sequencing_Run_Order_By>>;
  where?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
};


export type Subscription_RootMdsa_Sequencing_Run_By_PkArgs = {
  mdsa_id: Scalars['Int'];
  sr_id: Scalars['Int'];
};


export type Subscription_RootMdsa_Sequencing_Run_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Mdsa_Sequencing_Run_Stream_Cursor_Input>>;
  where?: Maybe<Mdsa_Sequencing_Run_Bool_Exp>;
};


export type Subscription_RootMdsa_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Mdsa_Stream_Cursor_Input>>;
  where?: Maybe<Mdsa_Bool_Exp>;
};


export type Subscription_RootMdsa_TaskArgs = {
  distinct_on?: Maybe<Array<Mdsa_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Task_Order_By>>;
  where?: Maybe<Mdsa_Task_Bool_Exp>;
};


export type Subscription_RootMdsa_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Mdsa_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mdsa_Task_Order_By>>;
  where?: Maybe<Mdsa_Task_Bool_Exp>;
};


export type Subscription_RootMdsa_Task_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMdsa_Task_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Mdsa_Task_Stream_Cursor_Input>>;
  where?: Maybe<Mdsa_Task_Bool_Exp>;
};


export type Subscription_RootProjectArgs = {
  distinct_on?: Maybe<Array<Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Order_By>>;
  where?: Maybe<Project_Bool_Exp>;
};


export type Subscription_RootProject_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Order_By>>;
  where?: Maybe<Project_Bool_Exp>;
};


export type Subscription_RootProject_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProject_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Project_Stream_Cursor_Input>>;
  where?: Maybe<Project_Bool_Exp>;
};


export type Subscription_RootProject_UserArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


export type Subscription_RootProject_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


export type Subscription_RootProject_User_By_PkArgs = {
  project_id: Scalars['Int'];
  user_id: Scalars['Int'];
};


export type Subscription_RootProject_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Project_User_Stream_Cursor_Input>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


export type Subscription_RootSequencing_RunArgs = {
  distinct_on?: Maybe<Array<Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequencing_Run_Order_By>>;
  where?: Maybe<Sequencing_Run_Bool_Exp>;
};


export type Subscription_RootSequencing_Run_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequencing_Run_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequencing_Run_Order_By>>;
  where?: Maybe<Sequencing_Run_Bool_Exp>;
};


export type Subscription_RootSequencing_Run_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSequencing_Run_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Sequencing_Run_Stream_Cursor_Input>>;
  where?: Maybe<Sequencing_Run_Bool_Exp>;
};


export type Subscription_RootSettingsArgs = {
  distinct_on?: Maybe<Array<Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Settings_Order_By>>;
  where?: Maybe<Settings_Bool_Exp>;
};


export type Subscription_RootSettings_AggregateArgs = {
  distinct_on?: Maybe<Array<Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Settings_Order_By>>;
  where?: Maybe<Settings_Bool_Exp>;
};


export type Subscription_RootSettings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSettings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Settings_Stream_Cursor_Input>>;
  where?: Maybe<Settings_Bool_Exp>;
};


export type Subscription_RootSr_TaskArgs = {
  distinct_on?: Maybe<Array<Sr_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sr_Task_Order_By>>;
  where?: Maybe<Sr_Task_Bool_Exp>;
};


export type Subscription_RootSr_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Sr_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sr_Task_Order_By>>;
  where?: Maybe<Sr_Task_Bool_Exp>;
};


export type Subscription_RootSr_Task_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSr_Task_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Sr_Task_Stream_Cursor_Input>>;
  where?: Maybe<Sr_Task_Bool_Exp>;
};


export type Subscription_RootTaxonomy_ClassifierArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Classifier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Classifier_Order_By>>;
  where?: Maybe<Taxonomy_Classifier_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Classifier_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Classifier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Classifier_Order_By>>;
  where?: Maybe<Taxonomy_Classifier_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Classifier_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTaxonomy_Classifier_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Taxonomy_Classifier_Stream_Cursor_Input>>;
  where?: Maybe<Taxonomy_Classifier_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<User_Stream_Cursor_Input>>;
  where?: Maybe<User_Bool_Exp>;
};

/** Table to store all taxonomy classifiers for MDSA */
export type Taxonomy_Classifier = {
  __typename?: 'taxonomy_classifier';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  s3_key: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "taxonomy_classifier" */
export type Taxonomy_Classifier_Aggregate = {
  __typename?: 'taxonomy_classifier_aggregate';
  aggregate?: Maybe<Taxonomy_Classifier_Aggregate_Fields>;
  nodes: Array<Taxonomy_Classifier>;
};

/** aggregate fields of "taxonomy_classifier" */
export type Taxonomy_Classifier_Aggregate_Fields = {
  __typename?: 'taxonomy_classifier_aggregate_fields';
  avg?: Maybe<Taxonomy_Classifier_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Taxonomy_Classifier_Max_Fields>;
  min?: Maybe<Taxonomy_Classifier_Min_Fields>;
  stddev?: Maybe<Taxonomy_Classifier_Stddev_Fields>;
  stddev_pop?: Maybe<Taxonomy_Classifier_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Taxonomy_Classifier_Stddev_Samp_Fields>;
  sum?: Maybe<Taxonomy_Classifier_Sum_Fields>;
  var_pop?: Maybe<Taxonomy_Classifier_Var_Pop_Fields>;
  var_samp?: Maybe<Taxonomy_Classifier_Var_Samp_Fields>;
  variance?: Maybe<Taxonomy_Classifier_Variance_Fields>;
};


/** aggregate fields of "taxonomy_classifier" */
export type Taxonomy_Classifier_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Taxonomy_Classifier_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Taxonomy_Classifier_Avg_Fields = {
  __typename?: 'taxonomy_classifier_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "taxonomy_classifier". All fields are combined with a logical 'AND'. */
export type Taxonomy_Classifier_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_Classifier_Bool_Exp>>;
  _not?: Maybe<Taxonomy_Classifier_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_Classifier_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  s3_key?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "taxonomy_classifier" */
export enum Taxonomy_Classifier_Constraint {
  /** unique or primary key constraint on columns "name" */
  TaxonomyClassifierNameKey = 'taxonomy_classifier_name_key',
  /** unique or primary key constraint on columns "id" */
  TaxonomyClassifierPkey = 'taxonomy_classifier_pkey'
}

/** input type for incrementing numeric columns in table "taxonomy_classifier" */
export type Taxonomy_Classifier_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "taxonomy_classifier" */
export type Taxonomy_Classifier_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  s3_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Taxonomy_Classifier_Max_Fields = {
  __typename?: 'taxonomy_classifier_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  s3_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Taxonomy_Classifier_Min_Fields = {
  __typename?: 'taxonomy_classifier_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  s3_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "taxonomy_classifier" */
export type Taxonomy_Classifier_Mutation_Response = {
  __typename?: 'taxonomy_classifier_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Taxonomy_Classifier>;
};

/** on_conflict condition type for table "taxonomy_classifier" */
export type Taxonomy_Classifier_On_Conflict = {
  constraint: Taxonomy_Classifier_Constraint;
  update_columns?: Array<Taxonomy_Classifier_Update_Column>;
  where?: Maybe<Taxonomy_Classifier_Bool_Exp>;
};

/** Ordering options when selecting data from "taxonomy_classifier". */
export type Taxonomy_Classifier_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  s3_key?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: taxonomy_classifier */
export type Taxonomy_Classifier_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "taxonomy_classifier" */
export enum Taxonomy_Classifier_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  S3Key = 's3_key',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "taxonomy_classifier" */
export type Taxonomy_Classifier_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  s3_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Taxonomy_Classifier_Stddev_Fields = {
  __typename?: 'taxonomy_classifier_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Taxonomy_Classifier_Stddev_Pop_Fields = {
  __typename?: 'taxonomy_classifier_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Taxonomy_Classifier_Stddev_Samp_Fields = {
  __typename?: 'taxonomy_classifier_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "taxonomy_classifier" */
export type Taxonomy_Classifier_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Taxonomy_Classifier_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Taxonomy_Classifier_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  s3_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Taxonomy_Classifier_Sum_Fields = {
  __typename?: 'taxonomy_classifier_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "taxonomy_classifier" */
export enum Taxonomy_Classifier_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  S3Key = 's3_key',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Taxonomy_Classifier_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Taxonomy_Classifier_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Taxonomy_Classifier_Set_Input>;
  /** filter the rows which have to be updated */
  where: Taxonomy_Classifier_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Taxonomy_Classifier_Var_Pop_Fields = {
  __typename?: 'taxonomy_classifier_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Taxonomy_Classifier_Var_Samp_Fields = {
  __typename?: 'taxonomy_classifier_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Taxonomy_Classifier_Variance_Fields = {
  __typename?: 'taxonomy_classifier_variance_fields';
  id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** All users of the app */
export type User = {
  __typename?: 'user';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  last_name: Scalars['String'];
  /** An array relationship */
  user_projects: Array<Project_User>;
  /** An aggregate relationship */
  user_projects_aggregate: Project_User_Aggregate;
};


/** All users of the app */
export type UserUser_ProjectsArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};


/** All users of the app */
export type UserUser_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_User_Order_By>>;
  where?: Maybe<Project_User_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<User_Bool_Exp>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<User_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  user_projects?: Maybe<Project_User_Bool_Exp>;
  user_projects_aggregate?: Maybe<Project_User_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  user_projects?: Maybe<Project_User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  user_projects_aggregate?: Maybe<Project_User_Aggregate_Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name'
}

export type User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  id?: Maybe<Scalars['Float']>;
};


    export enum MetadataColumnTypeEnum {
      Categorical = 'categorical',
      Numeric = 'numeric'
    }

    export type MetadataColumn = {
      column: string;
      type: MetadataColumnTypeEnum;
    };

    export type SrTaskDependentOn = Array<Enum_Sr_Task_Key_Enum>

    export type Dada2InParamsKey = '--p-trunc-len-f' | '--p-trunc-len-r' | '--p-trim-left-f' | '--p-trim-left-r';

    export type SrTaskInputParams = Array<{
      key: Dada2InParamsKey | 'sequencedSamples' | 'datasyncTaskExecutionArn';
      value: string | number | Array<SrSamples> |  null
    }>

    export type SrSamples = {
      sample_id: string;
      forwardFastqFile?: string;
      reverseFastqFile?: string;
    }

    export type SrLinkFastqOutData = {
      samples: Array<SrSamples>
    }

    export type SrUploadMetadataOutData = {
      metadata_columns: Array<MetadataColumn>
    }

    export type SrTaskOutData = SrLinkFastqOutData | SrUploadMetadataOutData

    export type OutFile = {
      name: string;
      s3_key: string;
      show_in_dashboard_table: boolean;
    }

    export type InFile = {
      name: string;
      s3_key: string;
      param_key: string;
    }

    export type TaskOutFiles = Array<OutFile>

    export type TaskInFiles = Array<InFile>

    export type MdsaTaskDependentOn = Array<Enum_Mdsa_Task_Key_Enum>

    export type MdsaInParam = {
      key: string;
      value: string | number | null;
      rawValue: string | number | null | Array<string> | Array<number>;
      qiimeCommands: Array<string>;
      enabled: boolean;
    }

    export type AwsBatchJobType = {
      jobId: string;
      jobArn: string;
      jobName: string;
      status?: string;
      logs?: string;
    };

    export type BlastOutData = {
      featureId: string;
      saccver: string;
      ssciname: string;
      staxid: string;
    }

    export type CustomJsonb = Array<MetadataColumn> | SrTaskDependentOn | SrTaskInputParams | SrTaskOutData | TaskOutFiles | TaskInFiles | MdsaTaskDependentOn | Array<MdsaInParam> | AwsBatchJobType | Array<BlastOutData>
export type AddSrsToMdsaMutationVariables = Exact<{
  mdsa_task_id: Scalars['Int'];
  _set: Mdsa_Task_Set_Input;
  objects: Array<Mdsa_Sequencing_Run_Insert_Input>;
  mdsa_id: Scalars['Int'];
  all_sample_ids?: Maybe<Array<Scalars['String']>>;
}>;


export type AddSrsToMdsaMutation = (
  { __typename?: 'mutation_root' }
  & { insert_mdsa_sequencing_run?: Maybe<(
    { __typename?: 'mdsa_sequencing_run_mutation_response' }
    & Pick<Mdsa_Sequencing_Run_Mutation_Response, 'affected_rows'>
  )>, update_mdsa_task_by_pk?: Maybe<(
    { __typename?: 'mdsa_task' }
    & Pick<Mdsa_Task, 'id' | 'started_at'>
  )>, update_mdsa_by_pk?: Maybe<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id' | 'sample_ids'>
  )> }
);

export type AddUserMutationVariables = Exact<{
  object: User_Insert_Input;
}>;


export type AddUserMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_one?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'email'>
  )> }
);

export type AddUsersToProjectMutationVariables = Exact<{
  objects: Array<Project_User_Insert_Input>;
}>;


export type AddUsersToProjectMutation = (
  { __typename?: 'mutation_root' }
  & { insert_project_user?: Maybe<(
    { __typename?: 'project_user_mutation_response' }
    & Pick<Project_User_Mutation_Response, 'affected_rows'>
  )> }
);

export type CloudwatchLogsMutationVariables = Exact<{
  args: CloudwatchLogsInput;
}>;


export type CloudwatchLogsMutation = (
  { __typename?: 'mutation_root' }
  & { cloudwatchLogs: (
    { __typename?: 'StatusOutput' }
    & Pick<StatusOutput, 'status'>
  ) }
);

export type CreateMdsaMutationVariables = Exact<{
  object: Mdsa_Insert_Input;
}>;


export type CreateMdsaMutation = (
  { __typename?: 'mutation_root' }
  & { insert_mdsa_one?: Maybe<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id' | 'source' | 'title' | 'tags' | 'description'>
  )> }
);

export type DataSyncStartTaskExecutionMutationVariables = Exact<{ [key: string]: never; }>;


export type DataSyncStartTaskExecutionMutation = (
  { __typename?: 'mutation_root' }
  & { dataSyncStartTaskExecution?: Maybe<(
    { __typename?: 'TaskExecutionOutput' }
    & Pick<TaskExecutionOutput, 'taskExecutionArn'>
  )> }
);

export type DeleteFileMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type DeleteFileMutation = (
  { __typename?: 'mutation_root' }
  & { deleteFile?: Maybe<(
    { __typename?: 'DeleteFileOutput' }
    & Pick<DeleteFileOutput, 'status'>
  )> }
);

export type DeleteMdsaMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMdsaMutation = (
  { __typename?: 'mutation_root' }
  & { delete_mdsa_by_pk?: Maybe<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id'>
  )> }
);

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProjectMutation = (
  { __typename?: 'mutation_root' }
  & { delete_project_by_pk?: Maybe<(
    { __typename?: 'project' }
    & Pick<Project, 'id'>
  )> }
);

export type DeleteSrMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSrMutation = (
  { __typename?: 'mutation_root' }
  & { delete_sequencing_run_by_pk?: Maybe<(
    { __typename?: 'sequencing_run' }
    & Pick<Sequencing_Run, 'id'>
  )> }
);

export type DeleteTaxonomyClassifierMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTaxonomyClassifierMutation = (
  { __typename?: 'mutation_root' }
  & { delete_taxonomy_classifier_by_pk?: Maybe<(
    { __typename?: 'taxonomy_classifier' }
    & Pick<Taxonomy_Classifier, 'id'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'mutation_root' }
  & { delete_user_by_pk?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, 'id'>
  )> }
);

export type InsertSequencingRunMutationMutationVariables = Exact<{
  objects: Array<Sequencing_Run_Insert_Input>;
}>;


export type InsertSequencingRunMutationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_sequencing_run?: Maybe<(
    { __typename?: 'sequencing_run_mutation_response' }
    & { returning: Array<(
      { __typename?: 'sequencing_run' }
      & Pick<Sequencing_Run, 'id'>
      & { tasks: Array<(
        { __typename?: 'sr_task' }
        & Pick<Sr_Task, 'id'>
      )> }
    )> }
  )> }
);

export type MdsaCopyTasksFromSourceMutationVariables = Exact<{
  sourceMdsaId: Scalars['Int'];
  lastTaskPosition: Scalars['Int'];
  destinationMdsaId: Scalars['Int'];
}>;


export type MdsaCopyTasksFromSourceMutation = (
  { __typename?: 'mutation_root' }
  & { mdsaCopyTasksFromSource?: Maybe<(
    { __typename?: 'StatusOutput' }
    & Pick<StatusOutput, 'status'>
  )> }
);

export type MdsaMarkAsCompletedMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MdsaMarkAsCompletedMutation = (
  { __typename?: 'mutation_root' }
  & { update_mdsa_by_pk?: Maybe<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id' | 'status'>
  )> }
);

export type NewMicrobiomeSequencingRunMutationVariables = Exact<{ [key: string]: never; }>;


export type NewMicrobiomeSequencingRunMutation = (
  { __typename?: 'mutation_root' }
  & { insert_sequencing_run_one?: Maybe<(
    { __typename?: 'sequencing_run' }
    & Pick<Sequencing_Run, 'id' | 'type'>
  )> }
);

export type NewProjectMutationVariables = Exact<{
  business_unit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  protocol_s3_key?: Maybe<Scalars['String']>;
  qiime_metadata_columns?: Maybe<CustomJsonb>;
  species: Scalars['String'];
  start_date?: Maybe<Scalars['date']>;
  tags?: Maybe<Scalars['String']>;
  title: Scalars['String'];
}>;


export type NewProjectMutation = (
  { __typename?: 'mutation_root' }
  & { insert_project_one?: Maybe<(
    { __typename?: 'project' }
    & Pick<Project, 'business_unit' | 'created_at' | 'description' | 'end_date' | 'id' | 'protocol_s3_key' | 'qiime_metadata_columns' | 'species' | 'start_date' | 'tags' | 'title'>
  )> }
);

export type NewTaxonomyClassifierMutationVariables = Exact<{
  name: Scalars['String'];
  s3_key: Scalars['String'];
}>;


export type NewTaxonomyClassifierMutation = (
  { __typename?: 'mutation_root' }
  & { insert_taxonomy_classifier_one?: Maybe<(
    { __typename?: 'taxonomy_classifier' }
    & Pick<Taxonomy_Classifier, 'id' | 'name' | 's3_key' | 'created_at'>
  )> }
);

export type RemoveProjectUserAccessMutationVariables = Exact<{
  project_id: Scalars['Int'];
  user_id: Scalars['Int'];
}>;


export type RemoveProjectUserAccessMutation = (
  { __typename?: 'mutation_root' }
  & { delete_project_user_by_pk?: Maybe<(
    { __typename?: 'project_user' }
    & Pick<Project_User, 'user_id' | 'project_id'>
  )> }
);

export type ResetMdsaUptoTaskMutationVariables = Exact<{
  mdsaId: Scalars['Int'];
  resetMdsaUptoTaskPosition: Scalars['Int'];
}>;


export type ResetMdsaUptoTaskMutation = (
  { __typename?: 'mutation_root' }
  & { resetMdsaUptoTask?: Maybe<(
    { __typename?: 'ResetMdsaUptoTaskOutput' }
    & Pick<ResetMdsaUptoTaskOutput, 'status'>
  )> }
);

export type SkipMdsaTaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SkipMdsaTaskMutation = (
  { __typename?: 'mutation_root' }
  & { update_mdsa_task_by_pk?: Maybe<(
    { __typename?: 'mdsa_task' }
    & Pick<Mdsa_Task, 'id' | 'skipped'>
  )> }
);

export type SrLinkFastqMutationVariables = Exact<{
  task_id: Scalars['Int'];
  ended_at: Scalars['timestamptz'];
  started_at: Scalars['timestamptz'];
  out_data: CustomJsonb;
  sr_id: Scalars['Int'];
}>;


export type SrLinkFastqMutation = (
  { __typename?: 'mutation_root' }
  & { update_task?: Maybe<(
    { __typename?: 'sr_task' }
    & Pick<Sr_Task, 'id' | 'ended_at' | 'out_data' | 'started_at'>
  )>, update_sr?: Maybe<(
    { __typename?: 'sequencing_run' }
    & Pick<Sequencing_Run, 'id' | 'analysis_started_at' | 'status'>
  )> }
);

export type StartMdsaQiimeAnalysisMutationVariables = Exact<{
  taskId: Scalars['Int'];
  setStartedAt?: Maybe<Scalars['Boolean']>;
}>;


export type StartMdsaQiimeAnalysisMutation = (
  { __typename?: 'mutation_root' }
  & { startQiimeAnalysis?: Maybe<(
    { __typename?: 'StatusOutput' }
    & Pick<StatusOutput, 'status'>
  )> }
);

export type StartSrQiimeAnalysisMutationVariables = Exact<{
  taskId: Scalars['Int'];
}>;


export type StartSrQiimeAnalysisMutation = (
  { __typename?: 'mutation_root' }
  & { startQiimeAnalysis?: Maybe<(
    { __typename?: 'StatusOutput' }
    & Pick<StatusOutput, 'status'>
  )> }
);

export type UpdateAndStartMdsaTaskMutationVariables = Exact<{
  taskId: Scalars['Int'];
  taskUpdateInput: Mdsa_Task_Set_Input;
}>;


export type UpdateAndStartMdsaTaskMutation = (
  { __typename?: 'mutation_root' }
  & { update_mdsa_task_by_pk?: Maybe<(
    { __typename?: 'mdsa_task' }
    & Pick<Mdsa_Task, 'id'>
  )>, startQiimeAnalysis?: Maybe<(
    { __typename?: 'StatusOutput' }
    & Pick<StatusOutput, 'status'>
  )> }
);

export type UpdateHmqSrsCreatedByIdMutationVariables = Exact<{
  updates: Array<UpdateSrCreatedByIdInput>;
}>;


export type UpdateHmqSrsCreatedByIdMutation = (
  { __typename?: 'mutation_root' }
  & { updateSRCreatedById?: Maybe<(
    { __typename?: 'StatusOutput' }
    & Pick<StatusOutput, 'status'>
  )> }
);

export type UpdateInparamsOfLinkFastqMutationMutationVariables = Exact<{
  id: Scalars['Int'];
  in_params: CustomJsonb;
}>;


export type UpdateInparamsOfLinkFastqMutationMutation = (
  { __typename?: 'mutation_root' }
  & { update_sr_task_by_pk?: Maybe<(
    { __typename?: 'sr_task' }
    & Pick<Sr_Task, 'id'>
  )> }
);

export type UpdateMdsaMetadataMutationVariables = Exact<{
  mdsa_task_id: Scalars['Int'];
  setInput: Mdsa_Task_Set_Input;
  mdsa_id: Scalars['Int'];
  metadata_columns: CustomJsonb;
}>;


export type UpdateMdsaMetadataMutation = (
  { __typename?: 'mutation_root' }
  & { update_mdsa_task_by_pk?: Maybe<(
    { __typename?: 'mdsa_task' }
    & Pick<Mdsa_Task, 'id' | 'ended_at' | 'out_files' | 'started_at'>
  )>, update_mdsa_by_pk?: Maybe<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id' | 'metadata_columns'>
  )> }
);

export type UpdateMdsaMutationVariables = Exact<{
  id: Scalars['Int'];
  mdsaSetInput: Mdsa_Set_Input;
}>;


export type UpdateMdsaMutation = (
  { __typename?: 'mutation_root' }
  & { update_mdsa_by_pk?: Maybe<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id' | 'title' | 'description' | 'tags'>
  )> }
);

export type UpdateMdsaRemarksMutationVariables = Exact<{
  id: Scalars['Int'];
  remarks?: Maybe<Scalars['String']>;
}>;


export type UpdateMdsaRemarksMutation = (
  { __typename?: 'mutation_root' }
  & { update_mdsa_by_pk?: Maybe<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id'>
  )> }
);

export type UpdateMdsaTaskInParamsMutationVariables = Exact<{
  taskId: Scalars['Int'];
  inParams?: Maybe<CustomJsonb>;
}>;


export type UpdateMdsaTaskInParamsMutation = (
  { __typename?: 'mutation_root' }
  & { update_mdsa_task_by_pk?: Maybe<(
    { __typename?: 'mdsa_task' }
    & Pick<Mdsa_Task, 'in_params'>
  )> }
);

export type UpdateMdsaTaskOutDataMutationVariables = Exact<{
  taskId: Scalars['Int'];
  outData?: Maybe<CustomJsonb>;
}>;


export type UpdateMdsaTaskOutDataMutation = (
  { __typename?: 'mutation_root' }
  & { update_mdsa_task_by_pk?: Maybe<(
    { __typename?: 'mdsa_task' }
    & Pick<Mdsa_Task, 'id' | 'key' | 'in_params' | 'out_data' | 'out_files'>
    & { mdsa: (
      { __typename?: 'mdsa' }
      & Pick<Mdsa, 'id' | 'primary_feature_table_file_name'>
    ) }
  )> }
);

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars['Int'];
  inputValues: Project_Set_Input;
}>;


export type UpdateProjectMutation = (
  { __typename?: 'mutation_root' }
  & { update_project_by_pk?: Maybe<(
    { __typename?: 'project' }
    & Pick<Project, 'id' | 'business_unit' | 'description' | 'end_date' | 'protocol_s3_key' | 'qiime_metadata_columns' | 'species' | 'start_date' | 'tags' | 'title'>
  )> }
);

export type UpdateSrBasicInfoFirstTimeMutationVariables = Exact<{
  id: Scalars['Int'];
  inputValues: Sequencing_Run_Set_Input;
  tasks: Array<Sr_Task_Insert_Input>;
}>;


export type UpdateSrBasicInfoFirstTimeMutation = (
  { __typename?: 'mutation_root' }
  & { update_sequencing_run_by_pk?: Maybe<(
    { __typename?: 'sequencing_run' }
    & Pick<Sequencing_Run, 'description' | 'id' | 'project_id' | 'status' | 'tags' | 'title' | 'type'>
  )>, insert_sr_task?: Maybe<(
    { __typename?: 'sr_task_mutation_response' }
    & Pick<Sr_Task_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateSrMutationVariables = Exact<{
  id: Scalars['Int'];
  inputValues: Sequencing_Run_Set_Input;
}>;


export type UpdateSrMutation = (
  { __typename?: 'mutation_root' }
  & { update_sequencing_run_by_pk?: Maybe<(
    { __typename?: 'sequencing_run' }
    & Pick<Sequencing_Run, 'description' | 'id' | 'project_id' | 'status' | 'tags' | 'title' | 'type'>
  )> }
);

export type UpdateSrTaskIntParamsMutationVariables = Exact<{
  id: Scalars['Int'];
  in_params: CustomJsonb;
}>;


export type UpdateSrTaskIntParamsMutation = (
  { __typename?: 'mutation_root' }
  & { update_sr_task_by_pk?: Maybe<(
    { __typename?: 'sr_task' }
    & Pick<Sr_Task, 'id' | 'in_params'>
  )> }
);

export type UpdateSrTaskMutationVariables = Exact<{
  id: Scalars['Int'];
  setInput: Sr_Task_Set_Input;
}>;


export type UpdateSrTaskMutation = (
  { __typename?: 'mutation_root' }
  & { update_sr_task_by_pk?: Maybe<(
    { __typename?: 'sr_task' }
    & Pick<Sr_Task, 'id' | 'ended_at' | 'out_files' | 'started_at'>
  )> }
);

export type UpdateTaxonomyClassifierS3KeyMutationVariables = Exact<{
  hmq_taxonomy_classifier_s3_key: Scalars['String'];
}>;


export type UpdateTaxonomyClassifierS3KeyMutation = (
  { __typename?: 'mutation_root' }
  & { update_settings?: Maybe<(
    { __typename?: 'settings_mutation_response' }
    & Pick<Settings_Mutation_Response, 'affected_rows'>
  )> }
);

export type AllTaxonomyClassifiersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTaxonomyClassifiersQuery = (
  { __typename?: 'query_root' }
  & { taxonomy_classifier: Array<(
    { __typename?: 'taxonomy_classifier' }
    & Pick<Taxonomy_Classifier, 'id' | 'name' | 's3_key'>
  )> }
);

export type AllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllUsersQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'user' }
    & Pick<User, 'email' | 'first_name' | 'id' | 'last_name'>
  )> }
);

export type CompletedMdsaQueryVariables = Exact<{ [key: string]: never; }>;


export type CompletedMdsaQuery = (
  { __typename?: 'query_root' }
  & { mdsa: Array<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id' | 'title' | 'description' | 'tags' | 'updated_at' | 'remarks'>
  )> }
);

export type CompletedSRsQueryVariables = Exact<{ [key: string]: never; }>;


export type CompletedSRsQuery = (
  { __typename?: 'query_root' }
  & { sequencing_run: Array<(
    { __typename?: 'sequencing_run' }
    & Pick<Sequencing_Run, 'id' | 'title' | 'tags' | 'analysis_completed_at' | 'analysis_started_at' | 'description'>
  )> }
);

export type DataSyncDescribeTaskExecutionQueryVariables = Exact<{
  taskExecutionArn: Scalars['String'];
}>;


export type DataSyncDescribeTaskExecutionQuery = (
  { __typename?: 'query_root' }
  & { dataSyncDescribeTaskExecution?: Maybe<(
    { __typename?: 'DescribeTaskExecutionOutput' }
    & Pick<DescribeTaskExecutionOutput, 'estimatedFilesToTransfer' | 'filesTransferred' | 'startTime' | 'status'>
  )> }
);

export type ExistingMdsaOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExistingMdsaOptionsQuery = (
  { __typename?: 'query_root' }
  & { mdsa: Array<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id' | 'title' | 'source_mdsa_id' | 'source_root_mdsa_id'>
  )> }
);

export type GetFilesUrlQueryVariables = Exact<{
  keys: Array<Scalars['String']>;
}>;


export type GetFilesUrlQuery = (
  { __typename?: 'query_root' }
  & { getFilesUrl?: Maybe<Array<Maybe<(
    { __typename?: 'GetFilesUrlOutput' }
    & Pick<GetFilesUrlOutput, 'key' | 'url'>
  )>>> }
);

export type GetHmqProjectIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHmqProjectIdQuery = (
  { __typename?: 'query_root' }
  & { settings: Array<(
    { __typename?: 'settings' }
    & Pick<Settings, 'id'>
    & { value: Settings['int_value'] }
  )> }
);

export type GetHmqTaxonomyClassifierS3KeyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHmqTaxonomyClassifierS3KeyQuery = (
  { __typename?: 'query_root' }
  & { settings: Array<(
    { __typename?: 'settings' }
    & Pick<Settings, 'id'>
    & { value: Settings['text_value'] }
  )> }
);

export type GetHmqUserIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHmqUserIdQuery = (
  { __typename?: 'query_root' }
  & { settings: Array<(
    { __typename?: 'settings' }
    & Pick<Settings, 'id'>
    & { value: Settings['int_value'] }
  )> }
);

export type GetMdsaMetadataColumnsByPkQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetMdsaMetadataColumnsByPkQuery = (
  { __typename?: 'query_root' }
  & { mdsa_by_pk?: Maybe<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id' | 'metadata_columns'>
  )> }
);

export type GetSrUploadMetadataQueryVariables = Exact<{
  sr_id: Scalars['Int'];
}>;


export type GetSrUploadMetadataQuery = (
  { __typename?: 'query_root' }
  & { sequencing_run_by_pk?: Maybe<(
    { __typename?: 'sequencing_run' }
    & { project?: Maybe<(
      { __typename?: 'project' }
      & Pick<Project, 'qiime_metadata_columns'>
    )>, tasks: Array<(
      { __typename?: 'sr_task' }
      & Pick<Sr_Task, 'id' | 'key' | 'out_data' | 'out_files'>
    )> }
  )> }
);

export type MdsaBasicInfoQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MdsaBasicInfoQuery = (
  { __typename?: 'query_root' }
  & { mdsa_by_pk?: Maybe<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id' | 'description' | 'tags' | 'title'>
  )> }
);

export type MdsaDashboardQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MdsaDashboardQuery = (
  { __typename?: 'query_root' }
  & { mdsa_by_pk?: Maybe<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id' | 'title' | 'tags' | 'sample_ids' | 'metadata_columns' | 'description' | 'status' | 'remarks' | 'source_root_mdsa_id' | 'source_mdsa_id' | 'primary_feature_table_file_name'>
    & { tasks: Array<(
      { __typename?: 'mdsa_task' }
      & Pick<Mdsa_Task, 'id' | 'dependent_on' | 'ended_at' | 'in_files' | 'in_params' | 'key' | 'out_data' | 'out_files' | 'position' | 'started_at' | 'skipped' | 'aws_batch_job'>
    )> }
  )> }
);

export type MdsaFileUploadConfigQueryVariables = Exact<{
  files: Array<FileUploadS3ConfigInput>;
  mdsa_id: Scalars['Int'];
}>;


export type MdsaFileUploadConfigQuery = (
  { __typename?: 'query_root' }
  & { mdsaFileUploadConfig?: Maybe<Array<Maybe<(
    { __typename?: 'FileUploadS3ConfigOutput' }
    & Pick<FileUploadS3ConfigOutput, 'algorithm' | 'contentDisposition' | 'contentType' | 'credential' | 'date' | 'fileName' | 'key' | 'policy' | 'signature' | 'url'>
  )>>> }
);

export type MdsaFilterTableByTaxaDataQueryVariables = Exact<{
  mdsa_id: Scalars['Int'];
  taskId: Scalars['Int'];
}>;


export type MdsaFilterTableByTaxaDataQuery = (
  { __typename?: 'query_root' }
  & { metadataFile?: Maybe<(
    { __typename?: 'MdsaOutFileUrlOutput' }
    & Pick<MdsaOutFileUrlOutput, 'url'>
  )>, task?: Maybe<(
    { __typename?: 'mdsa_task' }
    & Pick<Mdsa_Task, 'id' | 'in_params'>
  )> }
);

export type MdsaLefseQueryVariables = Exact<{
  mdsa_id: Scalars['Int'];
  task_id: Scalars['Int'];
}>;


export type MdsaLefseQuery = (
  { __typename?: 'query_root' }
  & { task?: Maybe<(
    { __typename?: 'mdsa_task' }
    & Pick<Mdsa_Task, 'id' | 'in_params'>
    & { mdsa: (
      { __typename?: 'mdsa' }
      & Pick<Mdsa, 'metadata_columns' | 'primary_feature_table_file_name'>
    ) }
  )>, metadataFile?: Maybe<(
    { __typename?: 'MdsaOutFileUrlOutput' }
    & Pick<MdsaOutFileUrlOutput, 'url'>
  )> }
);

export type MdsaMetadataUploadDataQueryVariables = Exact<{
  mdsa_id: Scalars['Int'];
}>;


export type MdsaMetadataUploadDataQuery = (
  { __typename?: 'query_root' }
  & { mdsaOutFileUrl?: Maybe<(
    { __typename?: 'MdsaOutFileUrlOutput' }
    & Pick<MdsaOutFileUrlOutput, 'url'>
  )>, mdsa_by_pk?: Maybe<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id' | 'sample_ids'>
    & { mdsa_sequencing_runs: Array<(
      { __typename?: 'mdsa_sequencing_run' }
      & Pick<Mdsa_Sequencing_Run, 'mdsa_id' | 'sr_id'>
    )> }
  )> }
);

export type MdsaSrQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MdsaSrQuery = (
  { __typename?: 'query_root' }
  & { mdsa_sequencing_run: Array<(
    { __typename?: 'mdsa_sequencing_run' }
    & Pick<Mdsa_Sequencing_Run, 'sr_id' | 'sample_ids'>
  )> }
);

export type MdsaTaskIdOfMergeTableSummaryQueryVariables = Exact<{
  mdsaId: Scalars['Int'];
}>;


export type MdsaTaskIdOfMergeTableSummaryQuery = (
  { __typename?: 'query_root' }
  & { mdsa_task: Array<(
    { __typename?: 'mdsa_task' }
    & Pick<Mdsa_Task, 'id'>
  )> }
);

export type MdsaTaskInParamsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MdsaTaskInParamsQuery = (
  { __typename?: 'query_root' }
  & { task?: Maybe<(
    { __typename?: 'mdsa_task' }
    & Pick<Mdsa_Task, 'id' | 'in_params'>
  )> }
);

export type MdsaTaskInParamsWithPrimaryTableNameQueryVariables = Exact<{
  taskId: Scalars['Int'];
}>;


export type MdsaTaskInParamsWithPrimaryTableNameQuery = (
  { __typename?: 'query_root' }
  & { task?: Maybe<(
    { __typename?: 'mdsa_task' }
    & Pick<Mdsa_Task, 'id' | 'in_params'>
    & { mdsa: (
      { __typename?: 'mdsa' }
      & Pick<Mdsa, 'primary_feature_table_file_name'>
    ) }
  )> }
);

export type MdsaTaskParamsMetadataColumnsQueryVariables = Exact<{
  taskId: Scalars['Int'];
}>;


export type MdsaTaskParamsMetadataColumnsQuery = (
  { __typename?: 'query_root' }
  & { task?: Maybe<(
    { __typename?: 'mdsa_task' }
    & Pick<Mdsa_Task, 'id' | 'in_params'>
    & { mdsa: (
      { __typename?: 'mdsa' }
      & Pick<Mdsa, 'metadata_columns'>
    ) }
  )> }
);

export type MdsaTaskResultQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MdsaTaskResultQuery = (
  { __typename?: 'query_root' }
  & { mdsa_task_by_pk?: Maybe<(
    { __typename?: 'mdsa_task' }
    & Pick<Mdsa_Task, 'id' | 'key' | 'in_params' | 'out_data' | 'out_files'>
    & { mdsa: (
      { __typename?: 'mdsa' }
      & Pick<Mdsa, 'id' | 'primary_feature_table_file_name' | 'title' | 'description' | 'remarks'>
    ) }
  )> }
);

export type MdsaTasksKeysQueryVariables = Exact<{
  mdsaId: Scalars['Int'];
}>;


export type MdsaTasksKeysQuery = (
  { __typename?: 'query_root' }
  & { mdsa_by_pk?: Maybe<(
    { __typename?: 'mdsa' }
    & { tasks: Array<(
      { __typename?: 'mdsa_task' }
      & Pick<Mdsa_Task, 'key'>
    )> }
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'query_root' }
  & { me?: Maybe<(
    { __typename?: 'MeOutput' }
    & { user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'email' | 'last_name'>
    )> }
  )> }
);

export type OngoingMdsaQueryVariables = Exact<{ [key: string]: never; }>;


export type OngoingMdsaQuery = (
  { __typename?: 'query_root' }
  & { mdsa: Array<(
    { __typename?: 'mdsa' }
    & Pick<Mdsa, 'id' | 'title' | 'remarks' | 'created_at' | 'source_mdsa_id' | 'source_root_mdsa_id'>
    & { tasks: Array<(
      { __typename?: 'mdsa_task' }
      & Pick<Mdsa_Task, 'id' | 'key' | 'started_at' | 'ended_at' | 'aws_batch_job' | 'skipped'>
    )>, mdsa_sequencing_runs: Array<(
      { __typename?: 'mdsa_sequencing_run' }
      & Pick<Mdsa_Sequencing_Run, 'mdsa_id' | 'sr_id'>
    )> }
  )> }
);

export type OpenSRsQueryVariables = Exact<{ [key: string]: never; }>;


export type OpenSRsQuery = (
  { __typename?: 'query_root' }
  & { sequencing_run: Array<(
    { __typename?: 'sequencing_run' }
    & Pick<Sequencing_Run, 'id' | 'created_at' | 'title' | 'status'>
    & { created_by?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'first_name' | 'last_name'>
    )> }
  )> }
);

export type ProjectSrOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectSrOptionsQuery = (
  { __typename?: 'query_root' }
  & { project: Array<(
    { __typename?: 'project' }
    & Pick<Project, 'id' | 'title'>
    & { sequencing_runs: Array<(
      { __typename?: 'sequencing_run' }
      & Pick<Sequencing_Run, 'id' | 'title'>
    )> }
  )> }
);

export type ProjectUserAccessQueryVariables = Exact<{
  project_id: Scalars['Int'];
}>;


export type ProjectUserAccessQuery = (
  { __typename?: 'query_root' }
  & { project_user: Array<(
    { __typename?: 'project_user' }
    & { user: (
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'email'>
    ) }
  )> }
);

export type ProtocolUploadInfoQueryVariables = Exact<{
  contentType: Scalars['String'];
  fileName: Scalars['String'];
}>;


export type ProtocolUploadInfoQuery = (
  { __typename?: 'query_root' }
  & { fileUploadS3Config?: Maybe<Array<Maybe<(
    { __typename?: 'FileUploadS3ConfigOutput' }
    & Pick<FileUploadS3ConfigOutput, 'algorithm' | 'contentDisposition' | 'contentType' | 'credential' | 'date' | 'fileName' | 'key' | 'policy' | 'signature' | 'url'>
  )>>> }
);

export type SeqRunDashboardQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SeqRunDashboardQuery = (
  { __typename?: 'query_root' }
  & { sequencing_run_by_pk?: Maybe<(
    { __typename?: 'sequencing_run' }
    & Pick<Sequencing_Run, 'id' | 'title' | 'tags' | 'description' | 'created_at' | 'analysis_started_at' | 'analysis_completed_at'>
    & { project?: Maybe<(
      { __typename?: 'project' }
      & Pick<Project, 'title'>
    )>, created_by?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'first_name' | 'last_name'>
    )>, tasks: Array<(
      { __typename?: 'sr_task' }
      & Pick<Sr_Task, 'id' | 'dependent_on' | 'ended_at' | 'in_params' | 'key' | 'out_data' | 'out_files' | 'position_sr' | 'started_at' | 'aws_batch_job' | 'failed_at' | 'failed_logs'>
    )> }
  )> }
);

export type SrCheckFastqQueryVariables = Exact<{
  sr_id: Scalars['Int'];
}>;


export type SrCheckFastqQuery = (
  { __typename?: 'query_root' }
  & { srCheckFastq?: Maybe<Array<Maybe<(
    { __typename?: 'SrCheckFastqOutput' }
    & Pick<SrCheckFastqOutput, 'sample_id' | 'reverseFastqFile' | 'forwardFastqFile'>
  )>>> }
);

export type SrFileUploadConfigQueryVariables = Exact<{
  files: Array<FileUploadS3ConfigInput>;
  sr_id: Scalars['Int'];
}>;


export type SrFileUploadConfigQuery = (
  { __typename?: 'query_root' }
  & { s3Config?: Maybe<Array<Maybe<(
    { __typename?: 'FileUploadS3ConfigOutput' }
    & Pick<FileUploadS3ConfigOutput, 'algorithm' | 'contentDisposition' | 'contentType' | 'credential' | 'date' | 'fileName' | 'key' | 'policy' | 'signature' | 'url'>
  )>>> }
);

export type SrIdsOfMdsaQueryVariables = Exact<{
  mdsa_id: Scalars['Int'];
}>;


export type SrIdsOfMdsaQuery = (
  { __typename?: 'query_root' }
  & { mdsa_sequencing_run: Array<(
    { __typename?: 'mdsa_sequencing_run' }
    & Pick<Mdsa_Sequencing_Run, 'sr_id' | 'mdsa_id'>
  )> }
);

export type SrMetadataFileUrlQueryVariables = Exact<{
  sr_id: Scalars['Int'];
}>;


export type SrMetadataFileUrlQuery = (
  { __typename?: 'query_root' }
  & { srOutFileUrl?: Maybe<(
    { __typename?: 'SrOutFileUrlOutput' }
    & Pick<SrOutFileUrlOutput, 'url' | 's3_key'>
  )> }
);

export type SrTaskInParamsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SrTaskInParamsQuery = (
  { __typename?: 'query_root' }
  & { sr_task_by_pk?: Maybe<(
    { __typename?: 'sr_task' }
    & Pick<Sr_Task, 'in_params' | 'id'>
  )> }
);

export type SrTaskResultDataQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SrTaskResultDataQuery = (
  { __typename?: 'query_root' }
  & { sr_task_by_pk?: Maybe<(
    { __typename?: 'sr_task' }
    & Pick<Sr_Task, 'id' | 'in_params' | 'out_data' | 'out_files'>
    & { sequencing_run: (
      { __typename?: 'sequencing_run' }
      & Pick<Sequencing_Run, 'id' | 'title' | 'description'>
      & { project?: Maybe<(
        { __typename?: 'project' }
        & Pick<Project, 'title'>
      )> }
    ) }
  )> }
);

export type TaxonomyClassifierUploadInfoQueryVariables = Exact<{
  fileName: Scalars['String'];
}>;


export type TaxonomyClassifierUploadInfoQuery = (
  { __typename?: 'query_root' }
  & { fileUploadS3Config?: Maybe<Array<Maybe<(
    { __typename?: 'FileUploadS3ConfigOutput' }
    & Pick<FileUploadS3ConfigOutput, 'algorithm' | 'contentDisposition' | 'contentType' | 'credential' | 'date' | 'fileName' | 'key' | 'policy' | 'signature' | 'url'>
  )>>> }
);

export type ViewAllProjectsAndSrByPkQueryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ViewAllProjectsAndSrByPkQueryQuery = (
  { __typename?: 'query_root' }
  & { project: Array<(
    { __typename?: 'project' }
    & Pick<Project, 'id' | 'title' | 'species' | 'start_date' | 'created_by_id'>
  )>, sequencing_run_by_pk?: Maybe<(
    { __typename?: 'sequencing_run' }
    & Pick<Sequencing_Run, 'title' | 'type' | 'description' | 'tags'>
    & { project?: Maybe<(
      { __typename?: 'project' }
      & Pick<Project, 'id' | 'title'>
    )> }
  )> }
);

export type ViewAllProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewAllProjectsQuery = (
  { __typename?: 'query_root' }
  & { project: Array<(
    { __typename?: 'project' }
    & Pick<Project, 'id' | 'title' | 'species' | 'start_date' | 'created_by_id'>
  )> }
);

export type ViewProjectQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ViewProjectQuery = (
  { __typename?: 'query_root' }
  & { project_by_pk?: Maybe<(
    { __typename?: 'project' }
    & Pick<Project, 'business_unit' | 'created_at' | 'description' | 'end_date' | 'id' | 'protocol_s3_key' | 'qiime_metadata_columns' | 'species' | 'start_date' | 'tags' | 'title'>
    & { created_by: (
      { __typename?: 'user' }
      & Pick<User, 'first_name' | 'last_name'>
    ) }
  )> }
);
