import React, { useState } from 'react';
import { Button, Row, Col, Input, Select, Radio, message } from 'antd';
import { useForm } from 'react-hook-form';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import {
  Enum_Mdsa_Source_Enum,
  CreateMdsaMutationVariables,
  CreateMdsaMutation,
  Mdsa_Insert_Input,
  Enum_Mdsa_Task_Key_Enum,
  MdsaCopyTasksFromSourceMutationVariables,
  MdsaCopyTasksFromSourceMutation,
  UpdateMdsaMutation,
  UpdateMdsaMutationVariables,
  ExistingMdsaOptionsQuery,
  MdsaTasksKeysQuery,
  MdsaTasksKeysQueryVariables,
} from '../graphql/graphql-types';
import EcFormItem from '../components/EcFormItem';
import { mdsaTasksInitialValues, mdsaTaskKeyNameMap } from '../utils/globals';
import AppLayout from '../components/AppLayout';
import { logger } from '../utils/helpers';

// intialData prop type declaration
type InitialDataType = Pick<Mdsa_Insert_Input, 'title' | 'tags' | 'description'>;

// mdsa info form type
type MdsaInfoFormType = Pick<
  Mdsa_Insert_Input,
  'title' | 'description' | 'source_mdsa_id' | 'source_mdsa_task_key' | 'source'
> & {
  tags: Array<string> | null;
};

// prop type declaration for mdsa info form
type MdsaInfoFormPropType = {
  // this prop is use to know the mode of the form whether its create or edit
  mode: 'create' | 'edit';
  // this prop is use to pass the initial data when the form is open in edit mode its optional prop
  initialData?: InitialDataType;
};

// call to graphql files
const existingMdsaOptionsQuery = loader('../graphql/queries/existingMdsaOptionsQuery.graphql');
const createMdsaMutation = loader('../graphql/mutations/createMdsaMutation.graphql');
const mdsaCopyTasksFromSourceMutation = loader(
  '../graphql/mutations/mdsaCopyTasksFromSourceMutation.graphql',
);
const updateMdsaMutation = loader('../graphql/mutations/updateMdsaMutation.graphql');
const mdsaTasksKeysQuery = loader('../graphql//queries/mdsaTasksKeysQuery.graphql');

// react functional component
const MdsaInfoForm: React.FC<MdsaInfoFormPropType> = ({ mode, initialData }) => {
  // useForm declaration
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, watch, control, errors } = useForm<MdsaInfoFormType>(
    initialData
      ? {
          defaultValues: {
            title: initialData.title,
            tags: initialData.tags ? initialData.tags.split(',') : null,
            description: initialData.description ? initialData.description : null,
          },
        }
      : {
          defaultValues: {
            title: null,
            tags: null,
            description: null,
            source: Enum_Mdsa_Source_Enum.SequencingRuns,
            source_mdsa_id: null,
            source_mdsa_task_key: Enum_Mdsa_Task_Key_Enum.TaxonomicClassification,
          },
        },
  );
  // state to set loading indicator when user click save or submit button
  const [saveOrSubmitButtonLoading, setSaveOrSubmitButtonLoading] = useState<boolean>(false);

  //  useLazyQuery to fetch data of existing mdsa when existing downstream analysis is selected
  const [fetchExistingMdsaOptions, { data, loading, error }] = useLazyQuery<
    ExistingMdsaOptionsQuery
  >(existingMdsaOptionsQuery, { fetchPolicy: 'network-only' });

  // useMutation to create Mdsa
  const [createMdsa] = useMutation<CreateMdsaMutation, CreateMdsaMutationVariables>(
    createMdsaMutation,
  );

  // useMutation which will call when user check Existing Downstream analysis and after success of createMdsa mutation
  const [mdsaCopyTasksFromSource] = useMutation<
    MdsaCopyTasksFromSourceMutation,
    MdsaCopyTasksFromSourceMutationVariables
  >(mdsaCopyTasksFromSourceMutation);

  // useMutation to update Mdsa
  const [updateMdsa] = useMutation<UpdateMdsaMutation, UpdateMdsaMutationVariables>(
    updateMdsaMutation,
  );

  // useLazyQuery to fetch option to be provided to select task for selected mdsa
  const [
    fetchMdsaTasksOptions,
    { data: mdsaTasksData, loading: mdsaTasksLoading, error: mdsaTasksError },
  ] = useLazyQuery<MdsaTasksKeysQuery, MdsaTasksKeysQueryVariables>(mdsaTasksKeysQuery);

  // get id using useParam
  const { id } = useParams();

  // useNavigate const
  const navigate = useNavigate();

  // const to store array of existing mdsa data fetched by lazyQuery
  const existingMdsaOptions = data?.mdsa;

  // stores error message if any obtained from query or lazy query
  let queryErrorMessage: undefined | string;
  if (error) {
    queryErrorMessage = error.message;
  }
  if (mdsaTasksError) {
    queryErrorMessage = mdsaTasksError.message;
  }

  if (queryErrorMessage) {
    return (
      <AppLayout screenTitle="Create New Downstream Analysis" hideBackBtn>
        <p style={{ color: 'red', textAlign: 'center' }}>{queryErrorMessage}</p>
      </AppLayout>
    );
  }

  return (
    <>
      {mode !== 'create' ? <h2>MDSA-{id}</h2> : null}
      <form
        onSubmit={handleSubmit((formData: Mdsa_Insert_Input) => {
          setSaveOrSubmitButtonLoading(true);
          // common mutation variables for create and update mutation
          const commonMutationVariables = {
            title: formData.title,
            tags: formData.tags ? formData.tags.toString() : null,
            description: formData.description ? formData.description : null,
          };
          if (mode === 'create') {
            // The object that is passed to createMdsa mutation
            const mutationVariablesObject = {
              ...commonMutationVariables,
              source: formData.source,
              source_mdsa_id: formData.source_mdsa_id,
            } as Mdsa_Insert_Input;

            // mutation variable when selected source is sequencing run
            if (formData.source === Enum_Mdsa_Source_Enum.SequencingRuns) {
              mutationVariablesObject.tasks = {
                data: mdsaTasksInitialValues,
              };
            }
            // mutation variable when selected source is mdsa
            if (formData.source === Enum_Mdsa_Source_Enum.Mdsa) {
              // obtains the data of selected mdsa
              const mdsaDataOfSelectedSourceMdsa = existingMdsaOptions?.find(
                (item) => item.id === formData.source_mdsa_id,
              );
              mutationVariablesObject.source_mdsa_task_key = formData.source_mdsa_task_key;
              if (mdsaDataOfSelectedSourceMdsa) {
                // sets value of source_root_mdsa_id
                if (mdsaDataOfSelectedSourceMdsa.source_root_mdsa_id) {
                  // If the source has a root mdsa id then use the source's root mdsa id
                  mutationVariablesObject.source_root_mdsa_id =
                    mdsaDataOfSelectedSourceMdsa.source_root_mdsa_id;
                } else if (mdsaDataOfSelectedSourceMdsa.source_mdsa_id) {
                  // If the source does not have a root mdsa id but has a source then use the source's root mdsa id
                  mutationVariablesObject.source_root_mdsa_id =
                    mdsaDataOfSelectedSourceMdsa.source_mdsa_id;
                } else {
                  // Source has no root mdsa id or source mdsa id
                  mutationVariablesObject.source_root_mdsa_id = mdsaDataOfSelectedSourceMdsa.id;
                }
              }
            }
            // call to create mdsa mutation
            createMdsa({
              variables: {
                object: mutationVariablesObject,
              },
            })
              .then((res) => {
                const mutationResponseMdsaId = res.data?.insert_mdsa_one?.id;
                if (mutationResponseMdsaId) {
                  // When source is MDSA
                  if (formData.source === Enum_Mdsa_Source_Enum.Mdsa && formData.source_mdsa_id) {
                    const lastTaskPosition = mdsaTasksInitialValues.find(
                      (element) => element.key === formData.source_mdsa_task_key,
                    )?.position;

                    if (lastTaskPosition) {
                      // call to mdsaCopyTasksFromSource mutation
                      mdsaCopyTasksFromSource({
                        variables: {
                          sourceMdsaId: formData.source_mdsa_id,
                          destinationMdsaId: mutationResponseMdsaId,
                          lastTaskPosition,
                        },
                      })
                        .then(() => {
                          setSaveOrSubmitButtonLoading(false);
                          // eslint-disable-next-line @typescript-eslint/no-floating-promises
                          message.success('MDSA created successfully');
                          navigate(`/mdsa/${mutationResponseMdsaId}`);
                        })
                        .catch((err) => {
                          setSaveOrSubmitButtonLoading(false);
                          logger(err);
                        });
                    }
                  } else {
                    setSaveOrSubmitButtonLoading(false);
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    message.success('MDSA created successfully');
                    navigate(`/mdsa/${mutationResponseMdsaId}`);
                  }
                }
              })
              .catch((err) => {
                setSaveOrSubmitButtonLoading(false);
                logger(err);
              });
          } else {
            updateMdsa({
              variables: {
                id: parseInt(id, 10),
                mdsaSetInput: commonMutationVariables,
              },
            })
              .then(() => {
                setSaveOrSubmitButtonLoading(false);
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.success('Mdsa successfully updated!');
                navigate(-1);
              })
              .catch((err) => {
                setSaveOrSubmitButtonLoading(false);
                logger(err);
              });
          }
        })}
      >
        <Row gutter={[0, 15]}>
          <Col sm={24} lg={8}>
            <EcFormItem
              label="Title"
              name="title"
              control={control}
              rules={{ required: 'Please enter title and try again' }}
              errors={errors}
              as={<Input allowClear />}
            />
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col sm={24} lg={8}>
            <EcFormItem
              label="Description"
              name="description"
              control={control}
              as={<Input.TextArea rows={2} allowClear />}
            />
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col sm={24} lg={8}>
            <EcFormItem
              label="Keywords/Tags"
              name="tags"
              defaultValue={[]}
              control={control}
              render={({ onChange, value }) => (
                <Select
                  mode="tags"
                  value={value as string}
                  style={{ width: '100%' }}
                  showArrow
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
            />
          </Col>
        </Row>
        {mode === 'create' ? (
          <Row gutter={[0, 15]}>
            <Col sm={24} lg={10}>
              <EcFormItem
                label="Create from"
                name="source"
                control={control}
                rules={{ required: true }}
                render={({ onChange, value }): JSX.Element => (
                  <Radio.Group
                    onChange={(e) => {
                      onChange(e.target.value);
                      if (e.target.value === Enum_Mdsa_Source_Enum.Mdsa) {
                        // call to lazy query function
                        fetchExistingMdsaOptions();
                      }
                    }}
                    value={value as string}
                    defaultValue={Enum_Mdsa_Source_Enum.SequencingRuns}
                  >
                    <Radio value={Enum_Mdsa_Source_Enum.SequencingRuns}>Sequencing Run(s)</Radio>
                    <Radio value={Enum_Mdsa_Source_Enum.Mdsa}>Existing Downstream analysis</Radio>
                  </Radio.Group>
                )}
              />
            </Col>
          </Row>
        ) : null}
        {watch('source') === Enum_Mdsa_Source_Enum.Mdsa ? (
          <>
            <Row gutter={[0, 15]}>
              <Col sm={24} lg={8}>
                <EcFormItem
                  label="Select existing analysis"
                  name="source_mdsa_id"
                  control={control}
                  rules={{ required: 'Please select existing analysis and try again' }}
                  errors={errors}
                  render={({ onChange }) => {
                    return (
                      <Select
                        placeholder="Select analysis"
                        style={{ width: 350 }}
                        loading={loading}
                        showSearch
                        optionFilterProp="label"
                        filterOption
                        onChange={(e) => {
                          onChange(e);
                          fetchMdsaTasksOptions({ variables: { mdsaId: e as number } });
                        }}
                        options={
                          Array.isArray(existingMdsaOptions) && existingMdsaOptions.length > 0
                            ? existingMdsaOptions.map((item) => {
                                return { value: item.id, label: `[MDSA-${item.id}] ${item.title}` };
                              })
                            : undefined
                        }
                      />
                    );
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={24} lg={12}>
                <EcFormItem
                  label="Select the last task to copy"
                  name="source_mdsa_task_key"
                  helpText="The results of this task and all the tasks before it will be copied to the new analysis"
                  control={control}
                  rules={{ required: 'Please select a task and try again' }}
                  errors={errors}
                  as={
                    <Select
                      placeholder="Select analysis"
                      style={{ width: 350 }}
                      disabled={!watch('source_mdsa_id')}
                      loading={mdsaTasksLoading}
                      showSearch
                      optionFilterProp="label"
                      filterOption
                      options={
                        mdsaTasksData &&
                        mdsaTasksData.mdsa_by_pk &&
                        Array.isArray(mdsaTasksData.mdsa_by_pk.tasks) &&
                        mdsaTasksData.mdsa_by_pk.tasks.length > 0
                          ? mdsaTasksData.mdsa_by_pk.tasks
                              .filter(
                                ({ key }) =>
                                  key !== Enum_Mdsa_Task_Key_Enum.MergeTablesSr &&
                                  key !== Enum_Mdsa_Task_Key_Enum.MergeTablesSummary &&
                                  key !== Enum_Mdsa_Task_Key_Enum.UploadMetadata,
                              )
                              .map(({ key }) => {
                                return { label: mdsaTaskKeyNameMap[key], value: key };
                              })
                          : undefined
                      }
                    />
                  }
                />
              </Col>
            </Row>
          </>
        ) : null}
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginTop: 20 }}
          loading={saveOrSubmitButtonLoading}
        >
          {mode === 'create' ? 'Submit' : 'Save'}
        </Button>
      </form>
    </>
  );
};

export default MdsaInfoForm;
