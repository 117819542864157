import React, { useState } from 'react';
import { Steps } from 'antd';
import AppLayout from '../../components/AppLayout';
import { UpdateMetadataNewColumnType, UploadFileDetailsType } from '../../utils/types';
import AddColumnsToMetadataForm from '../../forms/AddColumnsToMdsaMetadataForm';
import UpdatedMdsaMetadataConfirmScreen from './UpdatedMdsaMetadataConfirmScreen';
import UpdatedMdsaMetadataUploadScreen from './UpdatedMdsaMetadataUploadScreen';

const { Step } = Steps;

// The list of steps to be shown inside the Steps component
const stepsList = [
  {
    key: 'form',
    title: 'Define new columns',
  },
  {
    key: 'upload',
    title: 'Upload new metadata',
  },
  {
    key: 'confirm',
    title: 'Confirm new metadata',
  },
];

// react functional component
const UpdateMdsaMetadataScreen: React.FC = () => {
  // state to determine whether to show metadata form or to show upload metadata screen or to show confirm metadata screen
  const [metadataScreenToRender, setMetadataScreenToRender] = useState<
    'upload' | 'confirm' | 'form'
  >('form');

  // state to set form data which will pass to mdsaMetadata upload , confirm component
  const [formData, setFormData] = useState<Array<UpdateMetadataNewColumnType>>([]);

  // State to store selected file details
  const [uploadFileDetails, setUploadFileDetails] = useState<UploadFileDetailsType>({
    file: null,
    fileList: [],
  });
  // useState to store uploaded file data which will then pass to  confirm metadata screen as table dataSource
  const [tableDataToConfirm, setTableDataToConfirm] = useState<
    | {
        tableColumns: Array<string>;
        tableDataSource: Array<Record<string, string>>;
      }
    | undefined
  >(undefined);

  return (
    <AppLayout screenTitle="MDSA - Add new column(s) to metadata">
      <>
        <Steps
          type="navigation"
          current={stepsList.findIndex((ele) => ele.key === metadataScreenToRender)}
          style={{
            marginBottom: 30,
            marginTop: 10,
            boxShadow: '0px -1px 0 0 #e8e8e8 inset',
          }}
        >
          {stepsList.map((ele) => {
            return <Step title={ele.title} />;
          })}
        </Steps>

        {metadataScreenToRender === 'form' ? (
          <AddColumnsToMetadataForm
            setFormData={setFormData}
            setMetadataScreenToRender={setMetadataScreenToRender}
          />
        ) : null}
        {metadataScreenToRender === 'upload' ? (
          <UpdatedMdsaMetadataUploadScreen
            newlyAddedColumnsData={formData}
            setUploadFileDetails={setUploadFileDetails}
            uploadFileDetails={uploadFileDetails}
            setMetadataScreenToRender={setMetadataScreenToRender}
            setTableDataToConfirm={setTableDataToConfirm}
          />
        ) : null}
        {metadataScreenToRender === 'confirm' ? (
          <UpdatedMdsaMetadataConfirmScreen
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            uploadedFile={uploadFileDetails.file}
            newlyAddedColumnsData={formData}
            setMetadataScreenToRender={setMetadataScreenToRender}
            tableDataToConfirm={tableDataToConfirm}
          />
        ) : null}
      </>
    </AppLayout>
  );
};

export default UpdateMdsaMetadataScreen;
